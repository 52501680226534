import React from 'react';
import styles from './crypto-tab-products-page.module.scss';
import { ProductsList } from './products-list';
import { ExploreProductsNotification } from '@/features/crypto-tab-products';

export const CryptoTabProductsPage = () => {
  return (
    <div className={styles.container}>
      <ExploreProductsNotification />
      <ProductsList />
    </div>
  );
};
