import styles from './ncwallet-banner.module.scss';
import { Icon } from '@/shared/ui/icons';
import { Button } from '@/shared/ui/button';
import { Trans, useTranslation } from 'react-i18next';
import { NC_WALLET_CONNECT } from '@/shared/lib/utils/links';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '@/shared/store/types';

export const NcwalletBanner = ({ email }: { email: string }) => {
  const { currency: userCurrency } = useSelector((store: RootState) => store.user);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const navigateToWalletConnect = () => {
    navigate(NC_WALLET_CONNECT, { state: { email } });
  };

  const isBTC = userCurrency === 'BTC';
  return (
    <div className={styles.root}>
      <div className={styles.block}>
        <div className={styles.top}>
          <Icon iconName="ncwalletMini" width={40} height={40} className={styles.icon} />
          {isBTC ? (
            <p className={styles.text}>{t('Withdrawal.NCW_Banner.Title')}</p>
          ) : (
            <p className={styles.text}>
              <Trans i18nKey={t('Withdrawal_not_BTC.NCW_Banner.Title')} values={{ currency: userCurrency }} />
            </p>
          )}
        </div>
        <Button className={styles.btn} color="blue" fullWidth onClick={navigateToWalletConnect}>
          <Icon iconName="wallet" width={20} height={20} />
          {t('CONNECT WALLET')}
        </Button>
      </div>
    </div>
  );
};
