import { logoutToken, rootUser, userAction } from '@/shared/store/user/user.slice';
import { clearBalance } from '@/shared/store/balance/balance.slice';
import { withdrawalAction } from '@/shared/store/withdrawal/withdrawal.slice';
import { rpc } from '@/shared/lib/backend/Rpc';
import { setRpcStatus } from '@/shared/store/rpc/rpc.slice';
import { LOGIN_PATH } from '@/shared/lib/utils/links';
import { useDevice } from '@/shared/lib/hooks/useDevice';
import { AppDispatch } from '@/shared/store/types';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTutorialStorage } from '../lib/hooks/useTutorialStorage';

export const useLogout = () => {
  const { isReactNativeApp } = useDevice();
  const dispatch: AppDispatch = useDispatch();
  const { refresh_token } = useSelector((store: { user: rootUser }) => store.user);
  const navigation = useNavigate();
  const { clearTutorialSeen } = useTutorialStorage();

  const logout = async () => {
    clearTutorialSeen();

    if (isReactNativeApp) {
      await dispatch(logoutToken({ refresh_token: refresh_token || '' }));
      dispatch(userAction.unAuth());
      dispatch(clearBalance());
      dispatch(withdrawalAction.clearWithdrawalsToShow());
      await rpc.close();
      dispatch(userAction.setStatus(''));
      dispatch(userAction.setBanned(false));
      dispatch(setRpcStatus(false));
      await window.ct.signOut();
      navigation(LOGIN_PATH);
    }
  };

  return {
    logout,
  };
};
