import ModalLayout from '@/shared/ui/modal/modal';
import styles from './modal-withdraw-suspended.module.scss';
import { Icon } from '@/shared/ui/icons';
import { useTranslation } from 'react-i18next';
import { Button } from '@/shared/ui/button';
import { SUPPORT_SUSPENDED } from '@/shared/lib/utils/links';

type ModalWithdrawSuspended = {
  onClose: (e: boolean) => void;
  isOpen: boolean;
};

export const ModalWithdrawSuspended = ({ onClose, isOpen }: ModalWithdrawSuspended) => {
  const { t } = useTranslation();

  const onContactSupportClick = () => {
    window.open(SUPPORT_SUSPENDED);
  };

  return (
    <ModalLayout background iconClose onClose={() => onClose(false)} isOpen={isOpen} id="error">
      <div className={styles.container}>
        <div className={styles.innerContainer}>
          <div className={styles.contentContainer}>
            <Icon
              iconName="close"
              width="20"
              height="20"
              className={styles.closeButton}
              onClick={() => onClose(false)}
            />

            <div className={styles.iconContainer}>
              <Icon iconName="error" width={80} height={80} />
            </div>

            <h1 className={styles.title}>{t('Acc_susp.Title')}</h1>
            <p className={styles.description}>{t('Acc_susp.Description')}</p>
          </div>

          <div className={styles.footer}>
            <Button color="blue" fullWidth onClick={onContactSupportClick}>
              {t('Acc_susp.Btn')}
              <Icon iconName="arrow" width="20" height="20" />
            </Button>
            <Button color="blue" variant="outline" fullWidth onClick={() => onClose(false)}>
              <Icon iconName="arrow" width="20" height="20" className={styles.goBackIcon} />
              {t('GO BACK')}
            </Button>
          </div>
        </div>
      </div>
    </ModalLayout>
  );
};
