export function checkE(num: number | string, flag?: boolean): string {
  if (num === undefined || num === null || num === '') {
    return '0';
  }

  // Ensure num is treated as a number
  const numericValue = Number(num);

  // Check for NaN
  if (isNaN(numericValue)) {
    return '0';
  }

  // Handle the zero case to ensure '0' doesn't return 'NaN'
  if (numericValue === 0) {
    return numericValue.toFixed(8);
  }

  // Convert number to string and check for scientific notation
  let numStr = numericValue.toString();
  if (numStr.includes('e') || flag) {
    // Convert scientific notation to fixed decimal
    numStr = numericValue.toFixed(8);
  }

  // Ensure there are no trailing zeros after the decimal point
  numStr = parseFloat(numStr).toString();

  // Additional check to handle cases where very small numbers might revert to scientific notation
  if (numericValue < 1 && numericValue > -1 && !flag) {
    numStr = numericValue.toFixed(8)?.replace(/\.?0+$/, '');
  }

  return numStr;
}
export const decimalPlaces = (number: number | string | undefined): number => {
  if (number === undefined || (typeof number !== 'string' && typeof number !== 'number')) {
    return 0;
  }

  const numberString = number?.toString();

  // Check if the number is in exponential notation
  if (numberString.includes('e')) {
    // Extract and return the absolute value of the exponent
    const exponent = parseInt(numberString.split('e')[1], 10);
    return Math.abs(exponent);
  } else if (numberString.includes('.')) {
    // Count the digits after the decimal point
    const decimalPart = numberString.split('.')[1];
    return decimalPart.length;
  }

  // If there's no decimal point or exponential notation, return 0
  return 0;
};

// export const decimalPlaces = (number: number | string | undefined) => {
//   // eslint-disable-next-line @typescript-eslint/ban-ts-comment
//   //@ts-ignore
//   if (number.toString().split(/[.e]/).length === 1) return 0;
//   let result;
//   // eslint-disable-next-line @typescript-eslint/ban-ts-comment
//   //@ts-ignore
//   number.toString().replace(/[.e]/, reg => {
//     switch (reg) {
//       case '.': {
//         // eslint-disable-next-line @typescript-eslint/ban-ts-comment
//         //@ts-ignore
//         result = number.toString().split('.')[1].length;
//         return;
//       }
//       case 'e': {
//         // eslint-disable-next-line @typescript-eslint/ban-ts-comment
//         //@ts-ignore
//         result = Math.abs(number.toString().split('e')[1]);
//         return;
//       }
//     }
//   });
//   return result;
// };
