import { Icon } from '@/shared/ui/icons';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '@/shared/store/types';
import styles from './email-verify.module.scss';
import { Button } from '@/shared/ui/button';
import { confirmEmail } from '@/shared/store/withdrawal/withdrawal.slice';
import { toaster } from '@/shared/ui/custom-toast/toaster';

export const EmailCheck = () => {
  const email = useSelector((store: RootState) => store.user.email);
  const { t } = useTranslation();
  const dispatch: AppDispatch = useDispatch();

  const handleClick = () => {
    window.open('https://mail.google.com/', '_blank');
  };

  const handleClickResend = () => {
    dispatch(confirmEmail({ email: email }));
    toaster.success(t('Link resent'));
  };

  return (
    <div className={styles.root}>
      <div className={styles.block}>
        <Icon iconName="email-confirm" width={80} height={80} />
        <h6 className={styles.title}>{<Trans i18nKey={t('Check your Email')} components={{ b: <strong /> }} />}</h6>
        <p className={styles.text}>
          <Trans i18nKey={t('Verify.text')} values={{ email: email }} components={{ b: <strong /> }} />
        </p>

        <div className={styles.resend}>
          <Trans
            i18nKey={t('Email_Confirm.Resend')}
            components={{
              b: <strong />,
              a: (
                <button type="button" onClick={handleClickResend}>
                  {t('Resend')}
                </button>
              ),
              span: <span className={styles.noBreak} />, // Prevent line breaks in the sentence
            }}
          />
        </div>
      </div>
      <div className={styles.footer}>
        <Button color="blue" fullWidth onClick={handleClick}>
          <Icon iconName="email" width="24" height="24" />
          {t('MAILBOX')}
        </Button>
      </div>
    </div>
  );
};
