import { useContext } from 'react';
import { TabsContext } from '@/shared/ui/tabs/tabs-context';

export const useTabsContext = () => {
  const tabContext = useContext(TabsContext);

  if (!tabContext) {
    throw new Error(`'useCore' has to be used within  <TabsContext.Provider>`);
  }

  return tabContext;
};
