import { useSelector } from 'react-redux';
import { RootState } from '@/shared/store/types';
import { useCurrentPlan } from '@/page/cart/store';
import { formatPrice, getRegularPrice } from '@/page/cart/ui/v2/total-price/total-price.util';
import { formatBalance } from '@/shared/lib/utils/units';

export const useCurrentPlanPriceInfo = () => {
  const { crypto2usdRate, cryptoBtc2userCurrencyRate, crypto2eurRate } = useSelector((store: RootState) => store.rate);
  const { currency: userCurrency, defaultFiatCurrencyApproximately } = useSelector((store: RootState) => store.user);
  const { currencies } = useSelector((store: RootState) => store.balance);
  const currentPlan = useSelector(useCurrentPlan);
  const selectedCryptoRate = defaultFiatCurrencyApproximately?.code === 'EUR' ? crypto2eurRate : crypto2usdRate;

  // Calculate current price in fiat
  const currentPrice = parseFloat(currentPlan.total_price) || 0;
  const currentPriceFiat = (currentPrice * selectedCryptoRate * cryptoBtc2userCurrencyRate).toFixed(2);

  const currentCurrencyObj = currencies?.find((cur) => cur?.symbol === userCurrency);

  // Format prices based on user's currency
  const btc2UserCurrencyPrice = formatPrice(currentPrice * cryptoBtc2userCurrencyRate);
  const formattedPrice = currentCurrencyObj ? formatBalance(btc2UserCurrencyPrice, currentCurrencyObj.unit) : '0.00';

  // Regular price
  const currentRegularPrice = getRegularPrice(currentPlan);
  const formattedPriceRegular = currentCurrencyObj
    ? formatBalance(currentRegularPrice * cryptoBtc2userCurrencyRate, currentCurrencyObj.unit)
    : '0.00';

  // Calculations for HPS in bonuses
  const hps = currentPlan?.hps ?? 0;
  const bonusHps = hps * 0.1;
  const bonusHpsX2 = hps * 0.2;

  // Total price and conversions to user's currency
  const totalPrice = currentPlan?.total_price ?? '0';
  const currentPriceInUserCurrency = formatPrice(parseFloat(totalPrice)) || '0';
  const currentRegularPriceInUserCurrency = getRegularPrice(currentPlan) || '0';
  const currentPriceUsd = (Number(currentPriceInUserCurrency) * crypto2usdRate * cryptoBtc2userCurrencyRate).toFixed(2);
  const formattedBtcToUserCurrencyPrice =
    formatPrice(Number(currentPriceInUserCurrency) * cryptoBtc2userCurrencyRate) || '0';

  return {
    bonusHps,
    bonusHpsX2,
    currentPrice: currentPriceInUserCurrency,
    currentRegularPrice: currentRegularPriceInUserCurrency,
    currentPriceFiat,
    currentPriceUsd,
    formattedBtcToUserCurrencyPrice,
    userCurrency,
    currencies,
    currentCurrencyObj,
    formattedPrice,
    formattedPriceRegular,
  };
};
