import { AddMiner } from '../add-miner';
import { MakeOrderButton } from '../make-order-button';
import s from './content-switcher.module.scss';
import { InfoContentBlock } from '@/shared/ui/info-block/info-block';
import { useDispatch, useSelector } from 'react-redux';
import { Tabs } from '@/shared/ui/tabs';
import { CurrencyIcon } from '@/shared/ui/currency-icon';
import { AddGoogleMiner } from '@/page/cart/ui/v1/add-google-miner';
import { PurchaseSubsButton } from '@/page/cart/ui/v1/purchase-subs-button/purchase-subs-button';
import {
  useGoogleCurrentPlan,
  useGoogleHashrate,
  useGoogleIsAvailable,
  useGoogleMiningDurations,
  useGoogleStatus,
} from '@/page/cart/store/cart-google-store';
import { setHashrate, useHashrate, useHashrates, useIsCryptoEnable } from '@/page/cart/store/cart-store';
import { Trans, useTranslation } from 'react-i18next';
import { LoaderMobile } from '@/shared/ui/loader/loader';
import { useDevice } from '@/shared/lib/hooks/useDevice';
import { AddIosMiner } from '@/page/cart/ui/v1/add-ios-miner/add-ios-miner';
import { RestoreSubsButton } from '@/page/cart/ui/v1/restore-subs-button/restore-subs-button';
import { PurchaseSubsButtonIos } from '@/page/cart/ui/v1/purchase-subs-button-ios/purchase-subs-button-ios';
import { AppStoreIcon, GooglePlayIcon } from '@/page/cart/assets';
import { Icon } from '@/shared/ui/icons';
import { useCallback, useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import { AppDispatch } from '@/shared/store/types';

export const ContentSwitcher = () => {
  const { t } = useTranslation();
  const { platformCt } = useDevice();
  const { Tab, List, Panel } = Tabs;
  const subtitle = 'add miner subtitle';
  const miningDurations = useSelector(useGoogleMiningDurations);
  const status = useSelector(useGoogleStatus);
  const isLoading = status === 'loading';
  const isAvailable = useSelector(useGoogleIsAvailable);
  const currentPlan = useSelector(useGoogleCurrentPlan);
  const isCryptoEnable = useSelector(useIsCryptoEnable);
  const mobileHashrate = useSelector(useGoogleHashrate);
  const cryptoHashrate = useSelector(useHashrate);
  const cryptoHashrates = useSelector(useHashrates);
  const dispatch: AppDispatch = useDispatch();
  const hasGoogleMining = miningDurations ? miningDurations.length > 0 : false;
  const params = new URLSearchParams(window.location.search);
  const hasCryptoParams = params.has('r') || params.has('m');
  const [currentTab, setCurrentTab] = useState<string>('');
  const [isSticky, setIsSticky] = useState(false);
  const containerRef = useRef<HTMLDivElement | null>(null);

  // Default panel logic
  useEffect(() => {
    if (isLoading) return;

    let panel = 'crypto'; // Fallback to 'crypto' only if neither google nor ios is selected
    if (!isCryptoEnable) {
      panel = platformCt === 'ios' ? 'ios' : 'google';
    } else if (platformCt === 'ios') {
      panel = 'ios';
    } else if (hasGoogleMining && !hasCryptoParams) {
      panel = 'google';
    }
    setCurrentTab(panel);
  }, [isLoading, isCryptoEnable, hasGoogleMining, platformCt, hasCryptoParams]);

  const handleScroll = () => {
    if (containerRef.current) {
      const buttonTop = containerRef.current.getBoundingClientRect().top + 50;
      const windowHeight = window.innerHeight;

      if (buttonTop >= windowHeight) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleSwitchTab = useCallback(() => {
    if (cryptoHashrate < mobileHashrate) {
      const hashrate = cryptoHashrates.find((h) => h > mobileHashrate);
      hashrate && dispatch(setHashrate({ hashrate }));
    }
    setCurrentTab('crypto');
  }, [cryptoHashrate, mobileHashrate, cryptoHashrates, setHashrate]);

  const purchaseInfoBlochShowed = localStorage.getItem('purchaseInfoBlockShowed') === 'hide';
  if (isLoading) {
    return <LoaderMobile />;
  }

  return (
    <div className={s.container}>
      {!purchaseInfoBlochShowed && platformCt !== 'ios' && <InfoContentBlock text={subtitle} />}
      <div className={s.contentContainer}>
        <Tabs orientation="vertical" tab={currentTab} variant="reset" onChange={setCurrentTab} keepMounted={false}>
          {hasGoogleMining && isCryptoEnable ? (
            <>
              <div className={s.methodTitle}>
                <Icon iconName="walletColor" width={20} height={20} />
                {t('N.PaymentMethod.Title')}
              </div>
              <List className={s.tabList}>
                {platformCt === 'ios' ? (
                  <Tab value={'ios'} className={s.tab}>
                    <div className={s.tab__container}>
                      <AppStoreIcon width={20} height={20} /> App Store
                      <span className={s.tab__right}>{t('Rent')}</span>
                    </div>
                  </Tab>
                ) : (
                  <Tab value={'google'} className={s.tab}>
                    <div className={s.tab__container}>
                      <GooglePlayIcon width={20} height={20} /> Google Play
                      <span className={s.tab__right}>{t('Rent')}</span>
                    </div>
                    {currentTab === 'google' && (
                      <div className={s.tab__cancel}>
                        <Icon iconName="refresh" width={20} height={20} />
                        {t('Cancel at any time')}
                      </div>
                    )}
                  </Tab>
                )}
                {isCryptoEnable && ( // Only render Crypto tab if isCryptoEnable is true
                  <Tab value="crypto" className={s.tab}>
                    <div className={s.tab__container}>
                      <span className={s.tab__icons}>
                        <CurrencyIcon currency="TON" />
                        <CurrencyIcon currency="TRX" />
                        <CurrencyIcon currency="BTC" />
                        <CurrencyIcon currency="USDT" />
                      </span>
                      Crypto
                      <span className={s.tab__right}>
                        <span className={s.tab__info}>{t('BUY')}</span>
                      </span>
                    </div>
                  </Tab>
                )}
              </List>
            </>
          ) : null}
          <Panel value="google">
            <div className={s.main}>
              <AddGoogleMiner handleSwitchTab={handleSwitchTab} isCryptoEnable={isCryptoEnable} />
            </div>
            <div ref={containerRef}>
              <div className={cn(s.footer, isSticky && s.fixed)}>
                <PurchaseSubsButton />
                <span className={s.footerDuration}>
                  <Trans i18nKey={t('Purchase_scr.Billing.Description')} values={{ duration: currentPlan?.month }} />{' '}
                  {t('Months_abrv.Subtitle')}
                </span>
              </div>
            </div>
          </Panel>
          <Panel value="ios">
            <div className={s.main}>
              <AddIosMiner handleSwitchTab={handleSwitchTab} isCryptoEnable={isCryptoEnable} />
            </div>
            {isAvailable ? null : (
              <div className={s.footer}>
                <PurchaseSubsButtonIos />
                <span className={s.footerDuration}>
                  <Trans i18nKey={t('Purchase_scr.Billing.Description')} values={{ duration: currentPlan?.month }} />{' '}
                  {t('Months_abrv.Subtitle')}
                </span>
              </div>
            )}
          </Panel>
          {isCryptoEnable && ( // Only render Crypto panel if isCryptoEnable is true
            <Panel value="crypto">
              <div className={s.main}>
                <AddMiner />
              </div>
              <div ref={containerRef}>
                <div className={cn(s.footer, isSticky && s.fixed)}>
                  <MakeOrderButton />
                </div>
              </div>
            </Panel>
          )}
        </Tabs>
      </div>
      {isCryptoEnable &&
        currentTab === 'crypto' && ( // Only render currency section if isCryptoEnable is true
          <div className={s.currency}>
            <div className={s.currency__triangle}></div>
            <p className={s.currency__text}>{t('HSH_Upd.Add_CB.Сard')}</p>
            <div className={s.currency__icons}>
              <CurrencyIcon currency="CTC" />
              <CurrencyIcon currency="SOL" />
              <CurrencyIcon currency="BNB" />
              <CurrencyIcon currency="TRX" />
              <CurrencyIcon currency="TON" />
              <CurrencyIcon currency="USDT" />
              <CurrencyIcon currency="ETH" />
              <CurrencyIcon currency="BTC" />
            </div>
          </div>
        )}
      {platformCt === 'ios' && (
        <div className={s.restore}>
          <RestoreSubsButton />
        </div>
      )}
    </div>
  );
};
