import {
  BtcWithdrawalMeta,
  NcwWithdrawalMeta,
  WalletsWithdrawalsLastAddressesResponseUnion,
} from '@/shared/lib/backend/JsonRpcApi';

type WithdrawalAddressInfo = { isNcw: true; email?: string; account_id: string } | { isNcw: false; address: string };

export function getWithdrawalAddressInfo(
  addressMeta: WalletsWithdrawalsLastAddressesResponseUnion
): WithdrawalAddressInfo {
  const isNcw = 'email' in addressMeta;

  if (isNcw) {
    return {
      isNcw,
      email: (addressMeta as NcwWithdrawalMeta).email,
      account_id: (addressMeta as NcwWithdrawalMeta).account_id,
    };
  } else {
    return {
      isNcw,
      address: (addressMeta as BtcWithdrawalMeta).address,
    };
  }
}
