import { useMemo } from 'react';
import { BONUS_TAG_20 } from '@/shared/lib/constants/bonus-tag';
import { GooglePlan } from '@/page/cart/store/cart-google-store';
import { MiningPoolPlan } from '@/shared/lib/models';

export const useMonthBonusHsh = (plans: GooglePlan[] | MiningPoolPlan[] | undefined, sliceTo: number) =>
  useMemo(
    () =>
      plans
        ?.slice(0, sliceTo)
        .reduce(
          (acc: number[], plan) =>
            plan?.tags?.includes(BONUS_TAG_20) && plan?.hsh_reward ? [...acc, plan?.month] : acc,
          []
        )
        .join(', '),
    [plans, sliceTo]
  );
