import { Trans, useTranslation } from 'react-i18next';

import s from './google-mining-duration.module.scss';
import { Typography } from '@/shared/ui/typography';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '@/shared/store/types';
import { Icon } from '@/shared/ui/icons';
import {
  checkPurchase,
  setGoogleInfo,
  setGoogleMiningDuration,
  useGoogleAvailablePurchase,
  useGoogleCurrentPlan,
} from '@/page/cart/store/cart-google-store';
import { getGoogleMonthPrice } from '@/page/cart/ui/v2/total-price/total-price.util';
import { useState } from 'react';
import { GoogleMiningDurationsModal } from '@/page/cart/ui/v2/google-mining-duration/google-mining-durations-modal';
import { BONUS_TAG_20 } from '@/shared/lib/constants/bonus-tag';
import cn from 'classnames';

export const GoogleMiningDuration = () => {
  const { t } = useTranslation();
  const dispatch: AppDispatch = useDispatch();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const currentPlan = useSelector(useGoogleCurrentPlan);
  const availablePurchase = useSelector(useGoogleAvailablePurchase);

  const hasTrial = useSelector((state: RootState) => state.googleCart.trial);

  const handleSelect = (month: number, productId: string, offerToken: string, basePlanId: string, trial: boolean) => {
    dispatch(checkPurchase(availablePurchase!.some((i) => i.productId === productId)));

    dispatch(setGoogleMiningDuration({ miningDuration: month }));
    dispatch(setGoogleInfo({ productId: productId, offerToken: offerToken, basePlanId: basePlanId, trial: trial }));
  };

  return (
    <div className={s.root}>
      <div className={s.top}>
        <Typography variant="h5" className={s.title}>
          <Icon iconName="clock" width={20} height={20} />
          {t('choose mining duration')}
        </Typography>
        <p className={s.top__text}>{t('Buy_scr.Price_month')}</p>
      </div>

      <div className={s.chooseDurationButton} onClick={() => setIsModalOpen(true)}>
        <div className={s.durationInfo}>
          <span className={s.durationInfo__text}>
            {currentPlan.month} {t('mo.')}
          </span>
          {currentPlan.discount && currentPlan.discount > 1 && (
            <span className={s.durationInfo__discount}>-{currentPlan.discount}%</span>
          )}
          <span className={s.durationInfo__plan}>{getGoogleMonthPrice(currentPlan)}</span>
        </div>
        <div className={s.durationTitle}>{t('N.Duration.Title')}</div>
      </div>
      {currentPlan?.tags?.includes(BONUS_TAG_20) && (
        <div className={cn(s.note, s.note__purple)}>
          <div className={cn(s.note__icon__container, s.note__icon__container__purple)}>
            <Icon iconName="white-gift-x2" width={20} height={20} className={s.note__icon} />
          </div>
          <span className={cn(s.note__description, s.note__description__purple)}>{t('BonusHashx2.Description')}</span>
        </div>
      )}
      {hasTrial && (
        <div className={cn(s.note, s.note__green)}>
          <div className={cn(s.note__icon__container, s.note__icon__container__green)}>
            <Icon iconName="timer" width={20} height={20} className={s.note__icon} />
          </div>
          <span className={cn(s.note__description, s.note__description__green)}>
            <span>{t('Trial.Title')}</span>
            <span className={s.note__description__subtitle}>
              <Trans
                i18nKey={t('Trial.Description')}
                values={{
                  price: getGoogleMonthPrice(currentPlan),
                  duration: currentPlan.month,
                }}
              />
            </span>
          </span>
        </div>
      )}
      <GoogleMiningDurationsModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSelected={handleSelect}
      />
    </div>
  );
};
