import cn from 'classnames';

import s from './discount.module.scss';
import { formatHashrate } from '@/page/cart/ui/v1/hashrate';
import { getFormattedLocaleNumber } from '@/shared/lib/utils/getFormattedPrice';

type DiscountProps = {
  promoDiscount: number;
  discount: number;
  double?: number;
  bonus?: number;
  bonusHSH?: number;
};
export const Discount = ({ promoDiscount, discount, double, bonus, bonusHSH }: DiscountProps) => {
  return (
    <div className={s.container}>
      {promoDiscount > 0 && <div className={cn(s.discountItem, s.discountItemOrange)}>-{promoDiscount}%</div>}
      {discount > 0 && <div className={s.discountItem}>-{discount}%</div>}
      {double && double > 0 && (
        <div className={cn(s.discountItem, s.discountItemPurple)}>+ {formatHashrate(double)}</div>
      )}
      {bonus && bonus > 0 && <div className={cn(s.discountItem, s.discountItemBlue)}>+ {formatHashrate(bonus)}</div>}
      {bonusHSH && bonusHSH > 0 && (
        <div className={cn(s.discountItem, s.discountItemYellow)}>+ {getFormattedLocaleNumber(bonusHSH)} HSH</div>
      )}
    </div>
  );
};
