import React, { useCallback, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import ModalLayout from '@/shared/ui/modal/modal';
import styles from './mega-sale-modal.module.scss';
import { Icon } from '@/shared/ui/icons';
import { Button } from '@/shared/ui/button';
import { useNavigate } from 'react-router-dom';
import { CART_PATH } from '@/shared/lib/utils/links';
import { ColoredBanner } from '@/shared/ui/colored-banner/colored-banner';
import { useTimerWithDeadline } from '@/shared/lib/hooks/useTimerWithDeadline';
import { DISABLE_MEGA_SALE_MODAL, MEGA_SALE_MODAL_CLOSED_TIMESTAMP } from '@/shared/lib/constants/storage-key';

export const MegaSaleModal = ({ onClose, isOpen }: { onClose: (e: boolean) => void; isOpen: boolean }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { timeRemaining, showColon } = useTimerWithDeadline('2024-11-17T23:59:00Z');

  const onCloseModal = () => {
    const timestamp = Date.now();
    localStorage.setItem(MEGA_SALE_MODAL_CLOSED_TIMESTAMP, timestamp.toString());
    onClose(false);
  };

  const [dontShowAgain, setDontShowAgain] = useState<boolean>(() => {
    const storedMegaSaleValue = localStorage.getItem(DISABLE_MEGA_SALE_MODAL);

    // Ensure storedValue is a valid string before parsing
    if (typeof storedMegaSaleValue === 'string') {
      return JSON.parse(storedMegaSaleValue);
    }
    return false;
  });

  const handleCheckboxChange = useCallback(() => {
    const newState = !dontShowAgain;
    localStorage.setItem(DISABLE_MEGA_SALE_MODAL, JSON.stringify(newState));
    setDontShowAgain(newState);
  }, [dontShowAgain]);

  const onCartButtonClock = () => {
    const timestamp = Date.now();
    localStorage.setItem(MEGA_SALE_MODAL_CLOSED_TIMESTAMP, timestamp.toString());
    navigate(CART_PATH);
  };

  return (
    <ModalLayout background iconClose onClose={() => onClose(false)} isOpen={isOpen} id="mega-offer">
      <div className={styles.root}>
        <div className={styles.block}>
          <span className={styles.close} onClick={onCloseModal}>
            <Icon iconName="close" width="20" height="20" />
          </span>
          <div className={styles.top}>
            <Icon iconName="eleven-eleven" width={144} height={68} />
          </div>
          <div className={styles.mid}>
            <h6 className={styles.title}>{t('11.11.Popup.Title')}</h6>
            <p className={styles.text}>
              <Trans
                i18nKey={'11.11.Popup.Description'}
                components={{
                  b: <b />,
                }}
              />
            </p>
            {timeRemaining ? (
              <div className={styles.timer}>
                <Trans
                  i18nKey={'11.11.Popup.TIme'}
                  components={{
                    b: <b />,
                    span: <span />,
                    div: <div />,
                  }}
                  values={{ days: timeRemaining.days, hours: timeRemaining.hours, colon: showColon ? ':' : ' ' }}
                />
              </div>
            ) : null}
            <ColoredBanner
              text={t('11.11.Popup.Bonus')}
              variant="orange"
              icon={<Icon iconName="gift" width={30} height={30} color={'#F7931A'} />}
            />
          </div>
        </div>
        <div className={styles.footer}>
          <Button borderRadius={false} color="blue" fullWidth onClick={onCartButtonClock}>
            <Icon iconName="cartPlus" width="20" height="20" />
            {t('11.11.Popup.Btn')}
          </Button>
          <div className={styles.checkboxWrapper}>
            <input
              type="checkbox"
              checked={dontShowAgain}
              onChange={handleCheckboxChange}
              className={styles.checkbox}
            />
            <label className={styles.checkbox__text}>{t('No_windows_pls')}</label>
          </div>
        </div>
      </div>
    </ModalLayout>
  );
};
