import { useTranslation } from 'react-i18next';
import React from 'react';
import { Button } from '@/shared/ui/button';
import { useNavigate } from 'react-router-dom';
import { Icon } from '@/shared/ui/icons';
import { WithdrawalStatus } from '@/shared/lib/backend/JsonRpcApi';

export const WithdrawalActions = ({ status, onCancel }: { status: WithdrawalStatus; onCancel: () => void }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  switch (status) {
    case WithdrawalStatus.Created:
      return (
        <>
          <Button onClick={onCancel} borderRadius={false} color="blue" fullWidth>
            <Icon iconName="cancel" width={20} height={20} />
            {t('Cancel Withdrawal')}
          </Button>
          <Button onClick={() => navigate(-1)} borderRadius={false} variant="outline" color="blue" fullWidth>
            <Icon iconName="arrowLeft" width={20} height={20} />
            {t('GO BACK')}
          </Button>
        </>
      );
    default:
      return (
        <Button onClick={() => navigate(-1)} borderRadius={false} variant="outline" color="blue" fullWidth>
          <Icon iconName="arrowLeft" width={20} height={20} />
          {t('GO BACK')}
        </Button>
      );
  }
};
