import { Button } from '@/shared/ui/button';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { WITHDRAW_PATH } from '@/shared/lib/utils/links';
import styles from './switch-withdrawal-tabs.module.scss';

export const MINING = 'Mining';
export const BONUS = 'Bonus';

export const SwitchWithdrawalTabs = ({ isMining }: { isMining: boolean }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.root}>
      <Button
        as={NavLink}
        to={`${WITHDRAW_PATH}/${MINING}`}
        borderRadius={false}
        size={'xs'}
        variant={isMining ? 'filled' : 'outline'}
        color={isMining ? 'blue' : 'black'}
        fullWidth
        className={styles.btn}
      >
        {t('Mining')}
      </Button>
      <Button
        as={NavLink}
        to={`${WITHDRAW_PATH}/${BONUS}`}
        borderRadius={false}
        size={'xs'}
        variant={!isMining ? 'filled' : 'outline'}
        color={!isMining ? 'blue' : 'black'}
        fullWidth
        className={styles.btn}
      >
        {t('Bonus')}
      </Button>
    </div>
  );
};
