import { Hashrate } from '../hashrate';
import { MiningDuration } from '../mining-duration';

import s from './add-miner.module.scss';
import { TotalPrice } from '@/page/cart/ui/v1/total-price';
import { useSelector } from 'react-redux';
import { useCurrentPlan } from '@/page/cart/store';

export const AddMiner = () => {
  const currentPlan = useSelector(useCurrentPlan);
  return (
    <div className={s.container}>
      <div className={s.contentContainer}>
        <div className={s.hashrateContainer}>
          {currentPlan && <Hashrate />}
          <MiningDuration />
        </div>

        {currentPlan && <TotalPrice />}

        {/*<AvailablePaymentMethods />*/}
      </div>
    </div>
  );
};
