import React from 'react';
import styles from './product-tile.module.scss';
import { CTProduct } from '../../hooks';
import { useTranslation } from 'react-i18next';

type ProductTileProps = {
  product: CTProduct;
};

export const ProductTile = ({ product }: ProductTileProps) => {
  const { t } = useTranslation();
  return (
    <a target="_blank" href={product.link} className={styles.container}>
      <img className={styles.logo} src={product.icon} alt={product.name} />
      <div className={styles.name}>{t(product.name)}</div>
    </a>
  );
};
