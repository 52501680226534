import React from 'react';
import {
  EmailIcon,
  FacebookIcon,
  // FacebookMessengerIcon,
  LineIcon,
  LinkedInIcon,
  PinterestIcon,
  RedditIcon,
  TelegramIcon,
  TumblrIcon,
  VKIcon,
  WhatsAppIcon,
  XIcon,
} from '../assets';
import { ShareButtonOptions, SocialType } from '../share-social.types';
import cn from 'classnames';
import styles from './share-social-button.module.scss';
import { extractDefaultData } from '@/shared/ui/share-social/share-social.helpers';
import { ShareButton } from '../share-button/share-button';

interface SocialTypeConfig {
  iconComponent: string;
  windowWidth: number;
  windowHeight: number;
}

const socialTypeConfig: Record<SocialType, SocialTypeConfig> = {
  facebook: {
    iconComponent: FacebookIcon,
    windowWidth: 550,
    windowHeight: 400,
  },
  // 'facebook-messenger': {
  //     iconComponent: <FacebookMessengerIcon />,
  //     windowWidth: 1000,
  //     windowHeight: 820
  // },
  whatsapp: {
    iconComponent: WhatsAppIcon,
    windowWidth: 550,
    windowHeight: 400,
  },
  telegram: {
    iconComponent: TelegramIcon,
    windowWidth: 550,
    windowHeight: 400,
  },
  reddit: {
    iconComponent: RedditIcon,
    windowWidth: 660,
    windowHeight: 460,
  },
  pinterest: {
    iconComponent: PinterestIcon,
    windowWidth: 1000,
    windowHeight: 730,
  },
  linkedin: {
    iconComponent: LinkedInIcon,
    windowWidth: 550,
    windowHeight: 400,
  },
  vk: {
    iconComponent: VKIcon,
    windowWidth: 660,
    windowHeight: 460,
  },
  line: {
    iconComponent: LineIcon,
    windowWidth: 550,
    windowHeight: 400,
  },
  email: {
    iconComponent: EmailIcon,
    windowWidth: 550,
    windowHeight: 400,
  },
  tumblr: {
    iconComponent: TumblrIcon,
    windowWidth: 660,
    windowHeight: 460,
  },
  twitter: {
    iconComponent: XIcon,
    windowWidth: 550,
    windowHeight: 400,
  },
};

export type SocialButtonProps = {
  className?: string;
  type: SocialType;
  shareOptions?: ShareButtonOptions;
};

export const ShareSocialButton = ({ type, shareOptions, className }: SocialButtonProps) => {
  const defaultData = extractDefaultData();

  const url = shareOptions?.url ?? defaultData.url;
  const title = shareOptions?.title ?? defaultData.title;
  const description = shareOptions?.description ?? defaultData.description;
  const image = shareOptions?.media || defaultData.media;

  const shareOptionsPrepared = {
    title,
    url,
    description,
    media: image,
  };

  const { iconComponent, ...shareButtonProps } = socialTypeConfig[type];

  return (
    <ShareButton
      {...shareButtonProps}
      shareOptions={shareOptionsPrepared}
      className={cn(styles.container, className)}
      socialType={type}
    >
      <img src={iconComponent} alt={type} />
    </ShareButton>
  );
};
