import { ReactElement } from 'react';
import { Providers } from '@/app/providers';
import { AppRouter } from '@/app/app-router';
import { Slide, ToastContainer } from 'react-toastify';
import { EffectAppContainer } from '@/app/effect-app-container';
import ErrorBoundary from '@/shared/ui/error-boundary/error-boundary';
import { ShareSocialContextProvider } from '@/shared/ui/share-social';

function App(): ReactElement {
  return (
    <ErrorBoundary>
      <ShareSocialContextProvider endpoint={`${window.app_config.baseAPIURL}share`}>
        <Providers>
          <EffectAppContainer>
            <AppRouter />
            <ToastContainer
              closeButton={false}
              hideProgressBar
              closeOnClick={true}
              pauseOnHover={false}
              autoClose={5000}
              position="top-right"
              transition={Slide}
            />
          </EffectAppContainer>
        </Providers>
      </ShareSocialContextProvider>
    </ErrorBoundary>
  );
}

export default App;
