import { useTranslation } from 'react-i18next';
import { Icon } from '@/shared/ui/icons';
import { WithdrawalStatus } from '@/shared/lib/backend/JsonRpcApi';
import { mapStatus } from '@/features/balance';

export const WithdrawalNote = ({ status }: { status: WithdrawalStatus | undefined }) => {
  const { t } = useTranslation();
  const [, description] = mapStatus(status!, t);

  switch (status) {
    case WithdrawalStatus.Created:
      return (
        <>
          <Icon iconName="confirmEmail" width={60} height={60} />
          <span>{t('Confirm_Email')}</span>
        </>
      );
    case WithdrawalStatus.Confirmed:
      return (
        <>
          <Icon iconName="waitClock" width={60} height={60} />
          <span>{t('Wait_confirm')}</span>
        </>
      );
    case WithdrawalStatus.Pending:
    case WithdrawalStatus.Sent:
      return (
        <>
          <Icon iconName="waitClock" width={60} height={60} />
          <span>{description}</span>
        </>
      );
    case WithdrawalStatus.Completed:
      return (
        <>
          <Icon iconName="confirmed" width={60} height={60} style={{ color: '#37CC33' }} />
          <span>{description}</span>
        </>
      );
    default:
      return (
        <>
          <Icon iconName="reject" width={60} height={60} />
          {description}
        </>
      );
  }
};
