import { useEffect, useState } from 'react';
import { rpc } from '@/shared/lib/backend/Rpc';

type CandyData = {
  candyCount: number;
};

export const useCandyData = (): CandyData => {
  const [state, setState] = useState<CandyData>({
    candyCount: 0,
  });

  useEffect(() => {
    const fetchCandyData = async () => {
      try {
        const userCandies = await rpc.transmit('contests.halloween_2024.participant.get', {});
        const candyCount = userCandies?.state?.candy_coins || 0;
        setState({ candyCount });
      } catch (error) {
        console.error('Error fetching candies:', error);
        setState({ candyCount: 0 });
      }
    };

    fetchCandyData();
  }, []);

  return state;
};
