import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import { RootState } from '@/shared/store/types';
import { rpc } from '@/shared/lib/backend/Rpc';
import { HistoryResponse } from '@/shared/lib/backend/JsonRpcApi';

export const initRateThunk = createAsyncThunk<
  void,
  void,
  {
    state: RootState;
  }
>('rate/init', async (_: unknown, { dispatch, getState }) => {
  const state = getState();
  const from_currency = state?.user?.currency;
  const rateParams = {
    from_currency: from_currency || 'BTC',
    to_currency: state?.user?.currency === 'USDT' ? 'USD' : 'USDT',
  };
  const rateParamsEur = {
    from_currency: from_currency || 'BTC',
    to_currency: state.user.defaultFiatCurrencyApproximately?.code,
  };
  const rateParamsBtc = {
    from_currency: 'BTC',
    to_currency: from_currency || 'BTC',
  };
  const historyParams = {
    ...rateParams,
    interval: 'daily',
    time_start: dayjs().add(-1, 'months').toISOString(),
    time_end: dayjs().toISOString(),
  };
  const promises = [];
  promises.push(
    rpc.transmit('currencies.history', historyParams).then((r) => {
      dispatch(setHistory(r));
    })
  );
  //User currency to USD/USDT rate
  promises.push(
    rpc.transmit('currencies.rate', rateParams).then((r) => {
      dispatch(setRate(r));
    })
  );
  //User currency to EUR rate
  promises.push(
    rpc.transmit('currencies.rate', rateParamsEur).then((r) => {
      dispatch(setRateEur(r));
    })
  );
  promises.push(
    rpc.transmit('currencies.rate', rateParamsBtc).then((r) => {
      dispatch(setRateBtcToCurrentUserCurrency(r));
    })
  );
  Promise.all(promises).finally(() => {
    // do something
  });
});

interface TransformedHistoryItem {
  date: string;
  avg: string;
}

export interface RateState {
  usd_request: boolean;
  usd_error: boolean;
  crypto2usdRate: number;
  crypto2eurRate: number;
  cryptoBtc2userCurrencyRate: number;
  btc_history: TransformedHistoryItem[];
}

const initialState: RateState = {
  usd_request: false,
  usd_error: false,
  crypto2usdRate: 0,
  crypto2eurRate: 0,
  cryptoBtc2userCurrencyRate: 0,
  btc_history: [],
};

const rateSlice = createSlice({
  name: 'rate',
  initialState,
  reducers: {
    setRate(state, action) {
      state.crypto2usdRate = action.payload.rate;
    },
    setRateEur(state, action) {
      state.crypto2eurRate = action.payload.rate;
    },
    setRateBtcToCurrentUserCurrency(state, action) {
      state.cryptoBtc2userCurrencyRate = action.payload.rate;
    },
    setHistory(state, action: PayloadAction<HistoryResponse>) {
      state.btc_history = action.payload.map(([timestamp, value]) => ({
        date: dayjs.unix(timestamp).format('YYYY-MM-DD'),
        avg: value.toFixed(2),
      }));
    },
  },
});

export const { setRate, setHistory, setRateBtcToCurrentUserCurrency, setRateEur } = rateSlice.actions;

export default rateSlice.reducer;
