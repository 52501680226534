import React, { ReactNode, useState, useEffect, useRef } from 'react';
import styles from './layout.module.scss';
import { Header } from '@/entities/header/header';
import { useScrollRestoration } from '@/shared/lib/hooks/useScrollRestoration';
import { useSelector } from 'react-redux';
import { rootUser } from '@/shared/store/user/user.slice';
import AnimLoader from '@/shared/ui/loader/images/loader-anim.svg?react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ERROR_CONNECTION, HOME_PATH } from '@/shared/lib/utils/links';
import { log } from '@/shared/lib/utils/log';
import { useDevice } from '@/shared/lib/hooks/useDevice';
import { selectIsMobile } from '@/features/theme/model/slice';
import { rpc } from '@/shared/lib/backend/Rpc';

type TProps = {
  children?: ReactNode;
  navigation?: ReactNode;
  title?: string;
};

export function Layout({ children, navigation, title }: TProps) {
  useScrollRestoration();
  const { rpcStatus } = useSelector((store: { user: rootUser }) => store.user);
  const [isLoading, setIsLoading] = useState(false);
  const [shouldShowLayoutLocker, setShouldShowLayoutLocker] = useState(false);
  const navigate = useNavigate();
  const { isTelegramMiniApp } = useDevice();
  const { pathname } = useLocation();
  const offlineTimerRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const errorTimerRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const isMobileState = useSelector(selectIsMobile);
  const [deviceInfoReady, setDeviceInfoReady] = useState(false);

  useEffect(() => {
    if (isTelegramMiniApp !== undefined && isMobileState !== undefined) {
      setDeviceInfoReady(true);
    }
  }, [isTelegramMiniApp, isMobileState]);

  useEffect(() => {
    const handleOnline = () => {
      log('App is back online');
      navigate('/');
      // window.location.reload();
    };

    const handleOffline = () => {
      setShouldShowLayoutLocker(true);
      log('App is going offline');
    };

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    // Cleanup function
    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
      if (offlineTimerRef.current) {
        clearTimeout(offlineTimerRef?.current);
        offlineTimerRef.current = null;
      }
    };
  }, [navigate]);

  useEffect(() => {
    // Start or clear the timer based on isLoading
    if (isLoading) {
      if (!offlineTimerRef.current) {
        offlineTimerRef.current = setTimeout(() => {
          offlineTimerRef.current = null;
          log('App is offline after delay');
          navigate(ERROR_CONNECTION);
        }, 10 * 1000); // 1 min
      }
    } else {
      if (offlineTimerRef.current) {
        clearTimeout(offlineTimerRef.current);
        offlineTimerRef.current = null;
      }
    }

    // Cleanup function to clear the timer when the component unmounts or isLoading changes
    return () => {
      if (offlineTimerRef.current) {
        clearTimeout(offlineTimerRef.current);
        offlineTimerRef.current = null;
      }
    };
  }, [isLoading, navigate]);

  useEffect(() => {
    const statusCheck = rpcStatus !== 'open' && rpcStatus !== 'auth' && rpcStatus !== '';
    if (pathname === ERROR_CONNECTION) {
      if (!statusCheck) {
        navigate(HOME_PATH);
      }
    } else {
      setShouldShowLayoutLocker(statusCheck);
      if (statusCheck) {
        if (errorTimerRef.current) {
          clearTimeout(errorTimerRef.current);
          errorTimerRef.current = null;
        }
        errorTimerRef.current = setTimeout(() => {
          errorTimerRef.current = null;
          navigate(ERROR_CONNECTION);
        }, 60 * 1000); // 1 min
      }
    }

    return () => {
      if (errorTimerRef.current) {
        clearTimeout(errorTimerRef.current);
        errorTimerRef.current = null;
      }
    }; // Clear the timeout if the component unmounts or the status changes
  }, [rpcStatus, navigate, pathname]);

  const handleLockerClick = () => {
    setIsLoading(true);
    rpc.reopen().then();
  };

  useEffect(() => {
    if (!isTelegramMiniApp) return;

    if (pathname !== '/') {
      Telegram.WebApp.BackButton.show();
    } else {
      Telegram.WebApp.BackButton.hide();
    }

    const handleBackButtonClick = () => {
      navigate(-1);
    };

    Telegram.WebApp.BackButton.onClick(handleBackButtonClick);

    return () => {
      Telegram.WebApp.BackButton.offClick(handleBackButtonClick);
    };
  }, [isTelegramMiniApp, navigate, pathname]);

  return (
    <>
      {deviceInfoReady && (
        <div className={styles.root} style={{ zIndex: shouldShowLayoutLocker ? 9999 : 'auto' }}>
          {!isMobileState && !isTelegramMiniApp ? <Header headerTitle={title} /> : null}
          {shouldShowLayoutLocker ? (
            <div
              className={`${styles.locker} ${isLoading ? styles.loading : ''}`}
              onClick={handleLockerClick}
              style={{
                backgroundColor: isLoading ? 'rgba(0, 0, 0, 0.2)' : 'rgba(0, 0, 0, 0)',
              }}
            >
              {isLoading && <AnimLoader />}
            </div>
          ) : null}
          <main className={styles.main} style={{ pointerEvents: isLoading ? 'none' : 'auto' }}>
            {children}
          </main>
        </div>
      )}
      {navigation}
    </>
  );
}
