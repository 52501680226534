import React from 'react';
import styles from './pool-mining-footer.module.scss';
import { useTranslation } from 'react-i18next';
import { useModalSet } from '@/shared/lib/context/modal/useModalSet';
import { Button } from '@/shared/ui/button';
import { Link } from 'react-router-dom';
import { CART_PATH } from '@/shared/lib/utils/links';
import { Icon } from '@/shared/ui/icons';

export const PoolMiningFooter = () => {
  const { t } = useTranslation();
  const { openModal } = useModalSet();

  return (
    <div className={styles.container}>
      <Button fullWidth color="blue" as={Link} to={CART_PATH}>
        <Icon iconName="plus" width="20" height="20" />
        {t('ADD POOL MINER')}
      </Button>
      <Button onClick={() => openModal('MODAL_INFO_POOL')} color="blue" variant="outline">
        <Icon iconName="info" width="20" height="20" />
        {t('LEARN MORE')}
      </Button>
    </div>
  );
};
