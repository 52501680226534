import styles from '@/page/home/ui/balance-info/balance.module.scss';
import { Icon } from '@/shared/ui/icons';
import Reel from 'react-reel';
import theme from '@/page/home/ui/balance-info/theme.module.scss';
import { CurrentCurrencyRate } from '@/page/home/ui/btc-rate/current-currency-rate';
import { useTranslation } from 'react-i18next';
import { TutorialProps } from '@/shared/ui/tutorial/tutorial';
import { useBalanceInfo } from '@/page/home/hooks/use-balance-info';
import { useNavigate } from 'react-router-dom';
import { SWITCH_BALANCE_PATH } from '@/shared/lib/utils/links';

type BalanceInfoProps = {
  withoutRate?: boolean;
  tutorialPosition?: TutorialProps['position'];
  tutorialArrowPosition?: TutorialProps['arrow'];
};

export const BalanceInfo = ({ withoutRate = false }: BalanceInfoProps) => {
  const { t } = useTranslation();
  const { formattedBalance, usdBalance, currentCurrencyObj, userCurrency } = useBalanceInfo();
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(SWITCH_BALANCE_PATH);
  };
  return (
    <div className={styles.balance}>
      <div className={styles.balance__block}>
        <div className={styles.balance__btc} onClick={handleClick}>
          <img src={currentCurrencyObj?.img} alt="ico" width={32} height={32} className={styles.balance__icon} />
          <div className={styles.balance__reel}>
            <Reel text={formattedBalance} theme={theme} />
          </div>
          <div>
            <span>{userCurrency}</span>
            <Icon iconName="arrowDown" width={12} height={12} className={styles.balance__arrow} />
          </div>
        </div>
        <div className={styles.balance__usd}>
          {t('Approx.')} <strong>USD {usdBalance}</strong>
        </div>
      </div>
      {!withoutRate && (
        <div className={styles.balance__rate}>
          <CurrentCurrencyRate userCurrency={userCurrency} currency={currentCurrencyObj} />
        </div>
      )}
    </div>
  );
};
