import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import s from './mining-duration.module.scss';
import { Typography } from '@/shared/ui/typography';
import { Button } from '@/shared/ui/button';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '@/shared/store/types';
import { MiningPoolPlan } from '@/shared/lib/models';
import { Icon } from '@/shared/ui/icons';
import { formatPrice } from '@/page/cart/ui/v1/total-price/total-price.util';
import { formatBalance } from '@/shared/lib/utils/units';
import { setMiningDuration, useCurrentPlan, useMiningDurations } from '@/page/cart/store';
import { BONUS_TAG, BONUS_TAG_20 } from '@/shared/lib/constants/bonus-tag';
import { formatHashrate } from '../hashrate';
import { getFormattedLocaleNumber } from '@/shared/lib/utils/getFormattedPrice';
import React, { useCallback, useMemo } from 'react';
import { HshAlert } from '@/page/cart/ui/v1/hsh-alert/hsh-alert';
import { useMonthBonusHsh } from '@/page/cart/hooks/use-month-bonus-hsh';

export const MiningDuration = () => {
  const { t } = useTranslation();
  const dispatch: AppDispatch = useDispatch();
  const activeMonth = useSelector((state: RootState) => state.cart.miningDuration);
  const plans = useSelector((state: RootState) => state.cart.data?.plans);
  const miningDurations = useSelector(useMiningDurations);
  const currentPlan = useSelector(useCurrentPlan);
  const { cryptoBtc2userCurrencyRate } = useSelector((store: RootState) => store.rate);
  const { currency: userCurrency } = useSelector((store: RootState) => store.user);
  const { currencies } = useSelector((store: RootState) => store.balance);
  const monthsBonus = useMonthBonusHsh(plans, miningDurations.length);

  const currentCurrencyObj = useMemo(
    () => currencies?.find((cur) => cur?.symbol === userCurrency),
    [currencies, userCurrency]
  );
  const handleButtonClick = useCallback(
    (month: number) => () => dispatch(setMiningDuration({ miningDuration: month })),
    [dispatch]
  );

  return (
    <div className={s.root}>
      <div className={s.top}>
        <Typography variant="h5" className={s.title}>
          <Icon iconName="clock" width={20} height={20} />
          {t('choose mining duration')}
        </Typography>
        <p className={s.top__text}>{t('Buy_scr.Price_month')}</p>
      </div>

      <div className={s.buttonsContainer}>
        {miningDurations &&
          miningDurations.map((month, i) => {
            const discount = plans && (plans.find((i) => i.month === month) as MiningPoolPlan)?.discount;
            const plan = plans && plans.find((i) => i.hps === currentPlan?.hps && i.month === month);
            const currentBtcPrice = (plan && formatPrice(parseFloat(plan?.total_price) / plan?.month)) || 0;
            const btc2UserCurrencyPrice = formatPrice(Number(currentBtcPrice) * cryptoBtc2userCurrencyRate);
            const formattedPrice = currentCurrencyObj
              ? formatBalance(btc2UserCurrencyPrice, currentCurrencyObj?.unit)
              : '0.00';
            const pickedPlan = plan ? activeMonth === month : undefined;

            if (!plan) {
              return <></>;
            }

            return (
              <Button
                borderRadius={false}
                fullWidth
                key={month}
                variant="outline"
                onClick={handleButtonClick(month)}
                color="black"
                className={cn(s.button, activeMonth !== month && s.inactive)}
                style={{ marginBottom: !pickedPlan && i === miningDurations.length - 1 ? '10px' : '0' }}
              >
                <div className={s.button__container}>
                  <span className={s.button__text}>
                    {month} {t('mo.')}
                  </span>
                  <div className={s.button__container_discount}>
                    {discount && discount > 1 ? <span className={s.button__discount}>-{discount}%</span> : null}
                    {plan?.tags?.includes(BONUS_TAG_20) && plan?.hsh_reward && (
                      <Icon iconName="gift-yellow" width={24} height={24} className={s.button__icon} />
                    )}
                  </div>
                  <span className={s.button__plan}>
                    {formattedPrice && formattedPrice} {userCurrency}
                  </span>

                  {!pickedPlan && i === miningDurations.length - 1 && (
                    <span className={s.button__tag}>{t('MTU.Cloud_boost_buy.Tag_profitable')}</span>
                  )}
                </div>
                {activeMonth === month &&
                  (plan?.hsh_reward || plan?.hps_bonus) &&
                  [BONUS_TAG_20, BONUS_TAG].some((tag) => plan?.tags?.includes(tag)) && (
                    <div className={s.button__bonus}>
                      <Icon iconName="gift" width={20} height={20} />
                      {plan?.hsh_reward ? (
                        <>
                          {t('BB_Hsh.Bonus')}:
                          <span className={s.button__right}>
                            +{getFormattedLocaleNumber(plan?.hsh_reward)} HSH{' '}
                            {plan?.tags?.includes(BONUS_TAG_20) && '(x2)'}
                          </span>
                        </>
                      ) : (
                        <>
                          {t('hps_increment')}:
                          <span className={s.button__right}>+{formatHashrate(plan?.hps_bonus)}</span>
                        </>
                      )}
                    </div>
                  )}
              </Button>
            );
          })}
      </div>
      {monthsBonus && !monthsBonus.includes(`${currentPlan?.month}`) ? <HshAlert months={monthsBonus} /> : null}
    </div>
  );
};
