import React, { useEffect, useRef } from 'react';
import { Typography } from '@/shared/ui/typography';
import styles from './affiliate-item.module.scss';
import { Button } from '@/shared/ui/button';
import { Icon } from '@/shared/ui/icons';
import { useTranslation } from 'react-i18next';
import { ShareSocialDropdown } from '@/shared/ui/share-social';
import { AffiliateProductInfo } from '../../hooks';
import { getProductImages } from './affiliate-item.helpers';

type AffiliateItemProps = {
  affiliateInfo: AffiliateProductInfo;
};

export const AffiliateItem = ({ affiliateInfo }: AffiliateItemProps) => {
  const { t } = useTranslation();
  const [copied, setCopied] = React.useState(false);
  const timerRef = useRef<number | null>(null);

  const { productIcon, productImage } = getProductImages(affiliateInfo.productTitle);

  const onClickCopy = () => {
    navigator.clipboard.writeText(affiliateInfo.shareUrl);
    setCopied(true);
    timerRef.current = window.setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  useEffect(() => {
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.infoContainer}>
        <a className={styles.titleContainer} href={affiliateInfo.productUrl} target="_blank">
          <img src={productIcon} alt="Icon" className={styles.icon} />
          <Typography variant="body1" weight="bold" className={styles.title}>
            {t(affiliateInfo.productTitle)}
          </Typography>
          <Icon iconName="info" width={20} height={20} className={styles.infoIcon} />
        </a>
        <p className={styles.description}>{t(affiliateInfo.productDescription)}</p>
      </div>
      <p className={styles.shareLink}>{t('share_link')}</p>
      <div className={styles.buttonsContainer}>
        <Button size="xs" color="blue" onClick={onClickCopy} disabled={copied}>
          <Icon iconName={copied ? 'checked' : 'copy'} width={20} height={20} />
        </Button>
        <ShareSocialDropdown
          shareOptions={{
            url: affiliateInfo.shareUrl,
            description: t(affiliateInfo.productDescription),
            title: affiliateInfo.productTitle,
            media: productImage,
          }}
          position={'bottom'}
        >
          <Button size="xs" color="blue">
            <Icon iconName={'shareIcon'} width={20} height={20} />
          </Button>
        </ShareSocialDropdown>
      </div>
      <img src={productImage} alt={t(affiliateInfo.productTitle)} className={styles.image} />
    </div>
  );
};
