import { ReactNode, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { rootUser, selectUser } from '@/shared/store/user/user.slice';
import { LoaderMobile } from '@/shared/ui/loader/loader';
import { Navigate } from 'react-router-dom';
import { LOGIN_PATH } from '@/shared/lib/utils/links';
import { useDevice } from '@/shared/lib/hooks/useDevice';
import { useTelegram } from '@/shared/lib/hooks/useTelegram';
import { Tokens } from '@/shared/lib/models';
import HalloweenLoader from '@/shared/ui/halloween-loader/halloween-loader';

type TProtectedRoute = {
  children: ReactNode;
};

export const ProtectedRoute = ({ children }: TProtectedRoute) => {
  const isAuthChecked = useSelector((store: { user: rootUser }) => store.user.isAuthChecked);
  const user = useSelector(selectUser);
  const tokens = localStorage.getItem('tokens');
  const isAuth = tokens && JSON?.parse(tokens)?.access_token;
  const tokensParsed: Tokens = tokens && JSON?.parse(tokens);
  const { isReactNativeApp, isTelegramMiniApp } = useDevice();
  const { initDataUnsafe } = useTelegram();
  //Halloween
  const [showHalloweenLoader, setShowHalloweenLoader] = useState(false);

  useEffect(() => {
    if (showHalloweenLoader) {
      const timer = setTimeout(() => {
        setShowHalloweenLoader(false);
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [showHalloweenLoader]);

  if (
    !isAuthChecked ||
    user.loading ||
    tokensParsed?.telegramId !== initDataUnsafe?.user?.id.toString() ||
    (!isReactNativeApp && !isTelegramMiniApp)
  ) {
    if (showHalloweenLoader) {
      return <HalloweenLoader />;
    } else {
      return <LoaderMobile />;
    }
  }

  if (!isTelegramMiniApp && !isAuth) {
    return <Navigate to={LOGIN_PATH} />;
  }

  return children;
};
