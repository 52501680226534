import React, { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import ModalLayout from '@/shared/ui/modal/modal';
import styles from './halloween-winner-modal.module.scss';
import { Icon } from '@/shared/ui/icons';
import { Button } from '@/shared/ui/button';
import { ColoredBanner } from '@/shared/ui/colored-banner/colored-banner';
import { useConfetti } from '@/shared/ui/confetti';
import prizeIconSrc from '@/page/home/ui/halloween/image/prize-icon.svg';
import { HALLOWEEN_INFO_URL, HALLOWEEN_WINNER_MODAL_ID } from '@/page/home/ui/halloween/helpers';
import { toaster } from '@/shared/ui/custom-toast/toaster';

type ModalHalloweenWinnerProps = {
  onClose: (e: boolean) => void;
  isOpen: boolean;
};

export const ModalHalloweenWinner = ({ onClose, isOpen }: ModalHalloweenWinnerProps) => {
  const { t } = useTranslation();
  const { fireConfettiAtCenter } = useConfetti();

  const onCloseModal = () => {
    const timestamp = Date.now();
    localStorage.setItem(HALLOWEEN_WINNER_MODAL_ID, timestamp.toString());
    toaster.success(t('Successfully activated!'));
    onClose(false);
  };

  useEffect(() => {
    fireConfettiAtCenter();
  }, []);

  return (
    <ModalLayout background iconClose onClose={onCloseModal} isOpen={isOpen} id="halloween-winner-modal">
      <div className={styles.root}>
        <div className={styles.block}>
          <span className={styles.close} onClick={onCloseModal}>
            <Icon iconName="close" width="20" height="20" />
          </span>
          <div className={styles.top}>
            <img src={prizeIconSrc} />
          </div>
          <div className={styles.mid}>
            <h6 className={styles.title}>{t('Prize_modal.BF.Title')}</h6>
            <p className={styles.text}>
              <Trans
                i18nKey={'Prize_modal.BF.Description'}
                components={{
                  b: <b />,
                }}
                values={{
                  speed: '113 113',
                  duration: '13',
                }}
              />
            </p>
            <ColoredBanner
              text={
                <Trans
                  i18nKey={'Prize_modal.H24_info.Descr'}
                  components={{
                    a: <a target="_blank" href={HALLOWEEN_INFO_URL} />,
                  }}
                />
              }
              variant="orange"
              icon={<Icon iconName="gift" width={30} height={30} color={'#F7931A'} />}
            />
          </div>
        </div>
        <div className={styles.footer}>
          <Button borderRadius={false} color="blue" fullWidth onClick={onCloseModal}>
            <Icon iconName="checked" width="20" height="20" />
            {t('OK, GOT IT')}
          </Button>
        </div>
      </div>
    </ModalLayout>
  );
};
