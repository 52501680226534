import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '@/shared/store/types';
import { rpc } from '@/shared/lib/backend/Rpc';
import {
  refreshBalanceThunk,
  setBonusCurrencies,
  setBonusTransactions,
  setCurrencies,
  setLinks,
} from '@/shared/store/balance/balance.slice';
import { deviceType, userAction } from '@/shared/store/user/user.slice';
import { withdrawalAction } from '@/shared/store/withdrawal/withdrawal.slice';
import { setLand, setPoolLand } from '@/shared/store/landings/landings.slice';
import { setPromocodes } from '@/shared/store/promocodes/promocodes.slice';
import { setNftPromocodes } from '@/shared/store/nft-promocodes/nft-promocodes.slice';
import { poolMiner } from '@/shared/store/pool-miner/pool-miner.slice';
import { nftMiner } from '@/shared/store/ntf-miner/nft-miner.slice';
import { setCartList, setData } from '@/page/cart/store';
import { initRateThunk } from '@/shared/store/rate/rate.slice';
import { init } from '@/app/init';
import { makeAppStorePlans, makeGooglePlans } from '@/page/cart/store/cart-google-store';
import { WithdrawalStatus } from '@/shared/lib/backend/JsonRpcApi';
import { GetItemFunction, SetItemFunction } from '@/shared/lib/hooks/useCloudStorage';

export type InitDeviceTypes = {
  isReactNativeApp: boolean;
  isTelegramMiniApp: boolean | undefined;
  initData: string;
  appPlatform: deviceType;
  setItem: SetItemFunction;
  getItem: GetItemFunction;
  decodedStartParam?: string | null;
};

export const initApp = createAsyncThunk(
  'rpc/initApp',
  async (
    { isReactNativeApp, isTelegramMiniApp, initData, setItem, getItem, appPlatform }: InitDeviceTypes,
    { dispatch }
  ) => {
    await init({ isReactNativeApp, isTelegramMiniApp, initData, setItem, getItem, appPlatform });
    dispatch(setRpcStatus(true));
  }
);

export const initUserThunk = createAsyncThunk<
  void,
  void,
  {
    state: RootState;
  }
>('rpc/initUser', async (_: unknown, { dispatch }) => {
  dispatch(userAction.setLoading(true));
  dispatch(poolMiner.setLoading(true));
  dispatch(initRateThunk());

  const promises = [];
  promises.push(dispatch(refreshBalanceThunk()).unwrap());
  promises.push(
    rpc.transmit('wallets.currencies', {}).then((response) => {
      dispatch(setCurrencies(response));
    })
  );
  promises.push(
    rpc.transmit('currencies', {}).then((response) => {
      const hshCurrency = response.filter((currency) => currency.symbol === 'HSH');
      if (hshCurrency.length) dispatch(setBonusCurrencies(hshCurrency[0]));
    })
  );
  promises.push(
    rpc.transmit('users.me.eggs.resale_payments', {}).then((r) => {
      dispatch(userAction.setEggsPayments(r));
    })
  );
  promises.push(
    rpc.transmit('wallets.withdrawals.history', {}).then((r) => {
      dispatch(userAction.setCon(r));
      dispatch(withdrawalAction.setWithdrawals(r));
    })
  );
  promises.push(
    rpc.transmit('wallets.withdrawals.history', { status: [WithdrawalStatus.Created] }).then((r) => {
      dispatch(withdrawalAction.setWithdrawalsCreated(r));
    })
  );
  promises.push(
    rpc.transmit('wallets.withdrawals.last_addresses', {}).then((r) => {
      dispatch(withdrawalAction.setAddressesList(r));
    })
  );
  promises.push(
    rpc.transmit('users.me.links', {}).then((r) => {
      dispatch(setLinks(r));
    })
  );
  promises.push(
    rpc.transmit('promo.get_landings', {}).then((resp) => {
      dispatch(
        setLand({
          land: resp.map((item) => ({
            ...item,
            url: item.url?.replace(/{USER_ID}\//gi, ''),
          })),
        })
      );
    })
  );
  promises.push(
    rpc.transmit('promo.get_pool_landings', {}).then((resp) => {
      dispatch(
        setPoolLand({
          land: resp.map((item) => ({
            ...item,
            url: item.url?.replace(/{USER_ID}\/?/gi, ''),
          })),
        })
      );
    })
  );
  promises.push(rpc.transmit('farm.promo-codes', {}).then((res) => dispatch(setPromocodes({ promocodes: res }))));
  promises.push(
    (async () => {
      const resp = await rpc.transmit('nft.promo-codes', {});
      const amountResp = await rpc.transmit('nft.get_payments_statistics', {});
      const nftPromo = resp.map((item) => ({
        ...item,
        amount: amountResp.find((_) => _.promo_code === item.code)?.amount || 0,
      }));
      dispatch(setNftPromocodes({ nftPromocodes: nftPromo }));
    })()
  );
  promises.push(
    rpc.transmit('mining.pool.list', {}).then((r) => {
      console.log('mining.pool.list response rpc', r);
      dispatch(poolMiner.setPoolUsedNow(r));
    })
  );
  promises.push(
    rpc.transmit('mining.pool.miners', {}).then((r) => {
      console.log('mining.pool.miners response rpc', r);
      dispatch(poolMiner.setPoolMinersList(r));
      dispatch(setCartList(r));
    })
  );
  promises.push(
    rpc.transmit('mining.pool.plans.get', {}).then((r) => {
      dispatch(setData({ data: r }));
    })
  );
  promises.push(
    rpc.transmit('auto_withdrawals.list', {}).then((r) => {
      dispatch(
        nftMiner.setAutoWithdrawals({
          autoWithdrawals: r,
        })
      );
    })
  );
  promises.push(
    rpc.transmit('mining.nft.eggs.level_up.tokens', {}).then((r) => {
      dispatch(nftMiner.setLevelUpTokens(r));
    })
  );
  promises.push(
    rpc.transmit('wallets.daily_transactions_history', { currencies: ['HSH'], limit: 20 }).then((r) => {
      dispatch(setBonusTransactions(r));
    })
  );
  if (window.ct && window.ct.requestSubscription !== undefined) {
    if (window.ct.platform === 'ios') {
      promises.push(
        rpc.transmit('platform.ios.products', {}).then((r) => {
          console.warn("rpc.transmit('platform.ios.products'): ", r);
          dispatch(makeAppStorePlans({ products: r, isInit: true }));
        })
      );
    } else {
      promises.push(
        rpc.transmit('platform.android.products', {}).then((r) => {
          dispatch(makeGooglePlans(r));
        })
      );
    }
  }

  Promise.all(promises).finally(() => {
    dispatch(userAction.setLoading(false));
    dispatch(poolMiner.setLoading(false));
    dispatch(setRpcStatus(true));
  });
});

export type rootRpc = {
  init: boolean;
};

const initialState: rootRpc = {
  init: false,
};

export const rpcSlice = createSlice({
  name: 'rpc',
  initialState,
  reducers: {
    setRpcStatus(state, action) {
      state.init = action.payload;
    },
  },
});

export const selectRpcStatus = (state: RootState) => state.rpc.init;

export const { setRpcStatus } = rpcSlice.actions;
