import { useCallback, useState } from 'react';

export const useExploreProductsNotification = () => {
  const [visible, setVisible] = useState(localStorage.getItem('exploreProductsNotification') !== 'true');

  const close = useCallback(() => {
    setVisible(false);
    localStorage.setItem('exploreProductsNotification', 'true');
  }, []);

  return {
    visible,
    close,
  };
};
