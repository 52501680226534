type EmailServiceUrls = {
  [domain: string]: string;
};

export const emailServiceUrls: EmailServiceUrls = {
  'gmail.com': 'https://mail.google.com/',
  'yahoo.com': 'https://mail.yahoo.com/',
  'outlook.com': 'https://outlook.live.com/',
  'hotmail.com': 'https://outlook.live.com/',
  'icloud.com': 'https://www.icloud.com/mail',
  'mail.com': 'https://www.mail.com/int/',
};

export function getEmailServiceUrl(email: string): string {
  const domain = email.split('@')[1];
  return emailServiceUrls?.[domain] || `https://${domain}`;
}
