import { useTranslation, Trans } from 'react-i18next';
import ModalLayout from '@/shared/ui/modal/modal';
import styles from './modal-offer.module.scss';
import { Icon } from '@/shared/ui/icons';
import { Button } from '@/shared/ui/button';
import promoImage from './image/modal-offer@2x.png';
import ArrowIcon from './image/arrow.svg?react';

export const ModalOffer = ({ onClose, isOpen }: { onClose: (e: boolean) => void; isOpen: boolean }) => {
  const { t } = useTranslation();

  return (
    <ModalLayout background iconClose onClose={() => onClose(false)} isOpen={isOpen} id="modal-offer">
      <div className={styles.root}>
        <div className={styles.block}>
          <span className={styles.close} onClick={() => onClose(false)}>
            <Icon iconName="close" width="16" height="16" />
          </span>
          <div className={styles.top}>
            <img src={promoImage} alt={t('WelcomeBonus.Title')} />
          </div>
          <div className={styles.mid}>
            <h6 className={styles.title}>{t('WelcomeBonus.Title')}</h6>
            <p className={styles.text}>
              <Trans i18nKey={'WelcomeBonus.Description'} components={{ b: <b /> }} />
            </p>
          </div>
        </div>
        <div className={styles.footer}>
          <Button color="blue" fullWidth onClick={() => onClose(false)}>
            {t('OK, GOT IT')}
            <ArrowIcon />
          </Button>
        </div>
      </div>
    </ModalLayout>
  );
};
