import cn from 'classnames';
import styles from '../mining-block.module.scss';
import { Icon } from '@/shared/ui/icons';
import { TMiner } from '@/shared/store/pool-miner/pool-miner.slice';
import useMobileDetect from '@/shared/lib/hooks/useMobileDetect';
import { memo, useRef } from 'react';
import { formatDate } from '@/shared/lib/utils/getFormatDate';
import { useScrollIssueFix } from '../hooks';
import { useTranslation } from 'react-i18next';
import { formatHashrate } from '@/page/cart/ui/v1/hashrate';

export const PoolMinersList = memo(({ miners }: { miners: TMiner[] }) => {
  const { isMobile } = useMobileDetect();
  const containerRef = useRef<HTMLDivElement>(null);
  useScrollIssueFix(containerRef);
  const { t } = useTranslation();

  const monthsRounded = (startDate: number, endDate: number): number => {
    const start = new Date(startDate * 1000);
    const end = new Date(endDate * 1000);

    let monthsDiff = (end.getFullYear() - start.getFullYear()) * 12 + (end.getMonth() - start.getMonth());

    const startOfCurrentMonth = new Date(start);
    startOfCurrentMonth.setMonth(start.getMonth() + monthsDiff);

    const daysDifference = (end.getTime() - startOfCurrentMonth.getTime()) / (1000 * 60 * 60 * 24);

    if (daysDifference >= 5) {
      monthsDiff += 1;
    }

    return monthsDiff;
  };

  return (
    <div className={cn(styles.flexContainer, styles.flexContainer_last)} ref={containerRef}>
      {miners?.map((item: TMiner) => {
        const duration = monthsRounded(item.create_time, item.end_time);
        return (
          <div className={styles.flexTable} key={item.id}>
            <p className={cn(styles.flexRow, styles.minerItem)}>
              <Icon iconName="poolMining" className={styles.flexTable__icon} width={64} height={40} />{' '}
              {/* Here we await duration from back */}
              {formatHashrate(item.hps, true)}{' '}
              <span style={{ fontWeight: '400', color: '#757575' }}>
                {`/ ${duration} `}
                <span style={{ textTransform: 'capitalize' }}>{t('mo.')}</span>
              </span>
            </p>
            <p className={cn(styles.flexRow, styles.flexRowSecond, styles.flexTable__date)}>
              {isMobile() ? formatDate(item.end_time, { month: 'short' }) : formatDate(item.end_time)}
            </p>
          </div>
        );
      })}
    </div>
  );
});
