import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styles from './create-withdraw.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '@/shared/store/types';
import { Icon } from '@/shared/ui/icons';
import { Trans, useTranslation } from 'react-i18next';
import { Button } from '@/shared/ui/button';
import cn from 'classnames';
import { HISTORY_PATH } from '@/shared/lib/utils/links';
import { useModalSet } from '@/shared/lib/context/modal/useModalSet';
import { createWithdrawal, selectWithdrawals } from '@/shared/store/withdrawal/withdrawal.slice';
import { NcwalletBanner } from '@/page/withdraw/ui/ncwallet-banner/ncwallet-banner';
import { Link, useNavigate } from 'react-router-dom';
import { LimitView } from '@/page/withdraw/ui/limit-view/limit-view';
import { BalanceInfo } from '@/page/home/ui/balance-info/balance-info';
import { isValidEmail, isValidWithdrawalAddress } from '@/shared/lib/utils/units';
import { WithdrawalCreateType, WithdrawalStatus } from '@/shared/lib/backend/JsonRpcApi';
import { rpc } from '@/shared/lib/backend/Rpc';
import { checkE } from '@/shared/lib/utils/checE';
import { UnconfirmedTransactionInfo } from './unconfirmed-transaction-info';
import { BalanceAdditionalInfo } from '@/page/withdraw/ui/create-withdraw/balance-additional-info';
import { getWithdrawalAddressInfo } from '@/shared/lib/utils/get-withdrawal-address-info';
import { useBalanceInfo } from '@/page/home/hooks/use-balance-info';
import { useDevice } from '@/shared/lib/hooks/useDevice';
import { BonusBalance } from '@/page/bonus-program/ui/bonus-balance-card/ui/bonus-balance';
import { CustomTippy } from '@/shared/ui/custom-tippy/custom-tippy';

const MIN_TEXTAREA_HEIGHT = 50;

export const CreateWithdraw = ({ isMining }: { isMining: boolean }) => {
  const rate = useSelector((store: RootState) => store.rate);
  const { isIos } = useDevice();
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const showTooltip = () => setTooltipVisible(true);
  const hideTooltip = () => setTooltipVisible(false);
  const [minValaue, setMinValue] = useState(false);
  const { currencies, hsh_currency, pool_balance, hsh_balance } = useSelector((store: RootState) => store.balance);
  const { min_withdrawal_amount, email, currency } = useSelector((store: RootState) => store.user);
  const withdrawals = useSelector(selectWithdrawals);
  const status = useSelector((store: RootState) => store.withdrawal.createWithdrawalStatus);
  const allAddresses = useSelector((store: RootState) => store.withdrawal.withdrawalsAddresses);
  const { currentCurrencyObj, formattedBalance, userCurrency } = useBalanceInfo();

  const [amount, setAmount] = useState('');
  const [address, setAddress] = useState('');
  const [errorAmount, setErrorAmount] = useState(false);
  const [isNcw, setIsNcw] = useState(true);
  const { t } = useTranslation();
  const dispatch: AppDispatch = useDispatch();
  const { openModal } = useModalSet();
  const navigate = useNavigate();
  const amountInputRef = useRef<HTMLInputElement>(null);

  const waitTransactions = useMemo(
    () =>
      withdrawals.filter(
        (item) =>
          (item.status === WithdrawalStatus.Created ||
            item.status === WithdrawalStatus.PendingFunds ||
            item.status === WithdrawalStatus.Pending) &&
          item.currency !== 'HSH'
      ),
    [withdrawals]
  );

  const filteredAddresses = useMemo(() => {
    return allAddresses.filter((item) => {
      return userCurrency === 'BTC' && isMining ? true : getWithdrawalAddressInfo(item).isNcw;
    });
  }, [allAddresses, userCurrency, isMining]);

  const hasSavedAddresses = filteredAddresses.length > 0;
  const hasWaitTransaction = waitTransactions.length > 0 && isMining;
  const limitTransaction = waitTransactions.length >= 10 && isMining;
  const isWrongWithdrawalPayload =
    limitTransaction || hasWaitTransaction || errorAmount || !amount || (amount === '0' && !isMining);

  useEffect(() => {
    if (!hasSavedAddresses) {
      return setAddress('');
    }

    const lastAddress = filteredAddresses[0];
    if (!lastAddress) return;

    const addressInfo = getWithdrawalAddressInfo(lastAddress);

    if (addressInfo.isNcw) {
      setAddress(addressInfo.email || addressInfo.account_id);
      setMinValue(true);
      setIsNcw(true);
      return;
    }

    setAddress(addressInfo.address);
    setIsNcw(false);
    setMinValue(true);
  }, [filteredAddresses, hasSavedAddresses]);

  const currentBalance = useMemo(
    () => (isMining ? formattedBalance : hsh_balance.current),
    [isMining, formattedBalance, hsh_balance.current]
  );

  const currentMinimumAmount = useMemo(() => {
    if (userCurrency !== 'BTC' && isMining) {
      return checkE(currentCurrencyObj?.min_withdrawal || 0);
    } else {
      // For BTC, determine the minimum amount based on whether the address is NCW or not
      if (!isMining) return checkE(hsh_currency?.min_withdrawal || 0);
      return isNcw ? checkE(currentCurrencyObj?.min_withdrawal || 0) : checkE(+min_withdrawal_amount);
    }
  }, [userCurrency, min_withdrawal_amount, currentCurrencyObj?.min_withdrawal, isNcw, isMining]);

  const disabledAmount = useMemo(
    () => !address.length || hasWaitTransaction || !isValidWithdrawalAddress(address),
    [address, hasWaitTransaction]
  );

  const handleChange = useCallback(
    (value: string) => {
      let formattedValue = value;

      if (isIos) {
        // Replace comma with period for iOS input
        formattedValue = formattedValue.replace(',', '.');
      }

      // Match only numbers and decimal points
      if (formattedValue.match(/^([0-9]+)?(\.)?([0-9]+)?$/)) {
        setAmount(formattedValue);
      }

      if (+formattedValue > +currentBalance || +formattedValue < (+currentMinimumAmount || 0.01)) {
        setErrorAmount(true);
      } else {
        setErrorAmount(false);
      }
    },
    [isIos, currentBalance, currentMinimumAmount]
  );

  const handleCreate = async () => {
    if (+amount > +currentBalance || +amount < +currentMinimumAmount) {
      setErrorAmount(true);
      return;
    }
    try {
      // Check if the address looks like an email
      if (isValidEmail(address)) {
        try {
          const r = await rpc.transmit('ncw.find_account', { value: address });
          if (r) {
            dispatch(
              createWithdrawal({
                address: r?.account_id,
                amount: +amount,
                type: isMining ? WithdrawalCreateType.Regular : WithdrawalCreateType.Hsh,
                openModal,
                navigate,
              })
            );
          } else {
            // Handle the case where ncw.find_account does not return an account
            openModal('MODAL_NC_WALLET_REJECT', { email: address });
          }
        } catch (error) {
          // If ncw.find_account RPC call fails, open the rejection modal
          openModal('MODAL_NC_WALLET_REJECT', { email: address });
        }
      } else {
        dispatch(
          createWithdrawal({
            address,
            amount: +amount,
            type: isMining ? WithdrawalCreateType.Regular : WithdrawalCreateType.Hsh,
            openModal,
            navigate,
          })
        );
      }
    } catch (e) {
      console.log('Operation error', e);
    }
  };

  const onAddressClick = () => {
    openModal('MODAL_ADDRESS_INFO', {
      addresses: filteredAddresses,
      currentAddress: address,
      onClick: handleSetAddress,
      setIsNcw: setIsNcw,
      userAccountEmail: email,
    });
  };

  const handleSetAddress = async (addr: string) => {
    if (+amount > +currentBalance || +amount < Number(currentMinimumAmount)) {
      setErrorAmount(true);
    }
    setAddress(addr);
    setErrorAmount(false);
    setMinValue(true);
  };

  const onSetMinAmountClick = useCallback(() => {
    const minAmount = currentMinimumAmount;
    const isAmountValid = currentBalance >= minAmount;
    setErrorAmount(!isAmountValid);
    if (isAmountValid && !disabledAmount) {
      setAmount(String(minAmount));
    }
  }, [currentMinimumAmount, currentBalance, disabledAmount]);

  const onSetMaxAmountClick = useCallback(() => {
    const maxAmount = currentBalance;
    const isAmountValid = maxAmount >= currentMinimumAmount;
    setErrorAmount(!isAmountValid);
    if (isAmountValid && !disabledAmount) {
      setAmount(String(maxAmount));
    }
  }, [currentMinimumAmount, currentBalance, disabledAmount]);

  const cardVariants = {
    title: t('Your balance'),
    minedTitle: t('Pool Mining Income:'),
    affTitle: t('Affiliate_income'),
    poolTitle: t('Pool Mining'),
    nftTitle: t('Nft Mining'),
    totalTitle: t('Total Earnings:'),
    mined: pool_balance.pool,
    referral: pool_balance.affiliate,
    total: pool_balance.total,
    balance: pool_balance.balance,
  };

  return (
    <>
      {limitTransaction ? (
        <div className={styles.root}>
          <LimitView />
          <div className={styles.footer}>
            <Button
              color="blue"
              fullWidth
              className={styles.btn}
              onClick={handleCreate}
              disabled={status === 'loading' || limitTransaction}
              loading={status === 'loading'}
            >
              <Icon iconName="transfer" width="24" height="20" />
              {t('Withdraw BTC')}
            </Button>
          </div>
        </div>
      ) : (
        <>
          {isMining ? (
            <>
              <div className={styles.balance}>
                <div className={styles.sectionHeader}>
                  <span>{t('Balance')}</span>
                </div>
                <div className={styles.balanceContent}>
                  <BalanceInfo />
                  <BalanceAdditionalInfo
                    title={cardVariants.totalTitle}
                    balance={cardVariants.mined}
                    btc2usd={rate.crypto2usdRate}
                    className={styles.last}
                    currency={currency}
                    currencies={currencies}
                    hoverable
                  />
                </div>
              </div>
            </>
          ) : (
            <div className={styles.balance}>
              <BonusBalance
                balance={hsh_balance.total}
                income={hsh_balance.income}
                accrued={hsh_balance.accrued}
                nextPayment={hsh_balance.nextPayment}
              />
            </div>
          )}
          <div className={styles.root}>
            <div className={styles.sectionHeader}>
              <span>{t('Withdraw_pg.Main_Title')}</span>
              <Button as={Link} to={HISTORY_PATH} className={styles.sectionHeaderButton} variant="text" color="blue">
                <Icon iconName="history" width="21" height="20" />
              </Button>
            </div>
            <div className={styles.block}>
              <div className={styles.block__header}>
                <span className={styles.label__wrapTitle}>
                  <Icon
                    iconName={isNcw || !isMining ? 'ncwalletMini' : 'wallet'}
                    width={20}
                    height={20}
                    className={styles.label__icon}
                  />
                  {isNcw || !isMining ? t('Withdrawal.NCW_account.Title') : t('Bitcoin Address')}
                  <CustomTippy
                    content={
                      <div className={styles.tooltip__content}>
                        <Trans
                          i18nKey={t('Withdrawal.NCW_Account.Tip')}
                          values={{ currency: isMining ? userCurrency : 'HSH' }}
                        />
                        <Icon
                          iconName="close"
                          width={16}
                          height={16}
                          className={styles.tooltip__close}
                          onClick={hideTooltip}
                        />
                      </div>
                    }
                    visibleTooltip={tooltipVisible}
                    hideTooltip={hideTooltip}
                    icon={
                      <div className={styles.tooltip} onClick={tooltipVisible ? hideTooltip : showTooltip}>
                        <Icon iconName="qa" width={16} height={16} />
                      </div>
                    }
                  />
                </span>

                {hasWaitTransaction && isMining && <div className={styles.label__recent}>{t('recent')}</div>}
              </div>

              <div
                className={styles.labelWrap}
                onClick={(e) => {
                  e.preventDefault();
                  onAddressClick();
                }}
              >
                <label className={styles.label}>
                  <input
                    className={cn(styles.label__input, styles.small, styles.not_focus)}
                    placeholder={t('Add New Address')}
                    readOnly={true}
                    value={address}
                    style={{
                      minHeight: MIN_TEXTAREA_HEIGHT,
                      resize: 'none',
                    }}
                  />
                </label>
                <span className={cn(styles.label__openAddress)}>
                  <Icon iconName="arrowDown" width="12" height="13" />
                </span>
              </div>
            </div>

            {!hasSavedAddresses ? <NcwalletBanner email={email} /> : null}

            {hasWaitTransaction && isMining && (
              <div className={cn(styles.block, styles.unconfirmedWithdrawBlock)}>
                <UnconfirmedTransactionInfo withdrawal={waitTransactions[0]} />
              </div>
            )}

            {address || hasSavedAddresses ? (
              <div className={styles.block}>
                <label className={styles.label}>
                  <div className={styles.label__titleItems}>
                    <div className={styles.label__wrapTitle}>
                      <img
                        src={isMining ? currentCurrencyObj?.img : hsh_currency?.img}
                        alt="ico"
                        width={20}
                        height={20}
                        className={styles.balance__icon}
                      />
                      {t('Withdrawal.Amount.Title')}
                    </div>
                  </div>
                  <input
                    className={cn(styles.label__input, errorAmount && styles.error)}
                    placeholder={`${isMining ? currentCurrencyObj?.min_withdrawal : hsh_currency?.min_withdrawal} ${
                      isMining ? currentCurrencyObj?.symbol : hsh_currency?.symbol
                    }`}
                    type="text"
                    inputMode="decimal"
                    value={amount}
                    disabled={disabledAmount}
                    onChange={(e) => handleChange(e.target.value)}
                    ref={amountInputRef}
                  />
                </label>

                {minValaue && (
                  <div>
                    <div className={styles.amountWrap}>
                      <div className={styles.amount} onClick={onSetMinAmountClick}>
                        {t('min')}. {currentMinimumAmount}{' '}
                        {isMining ? currentCurrencyObj?.symbol : hsh_currency?.symbol}
                      </div>
                      <div className={cn(styles.amount)} onClick={onSetMaxAmountClick}>
                        <strong>
                          {t('Max.')} {currentBalance} {isMining ? currentCurrencyObj?.symbol : hsh_currency?.symbol}
                        </strong>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ) : null}

            <div className={styles.footer}>
              <Button
                color="blue"
                fullWidth
                className={styles.btn}
                onClick={handleCreate}
                disabled={status === 'loading' || isWrongWithdrawalPayload}
                loading={status === 'loading'}
              >
                <Icon iconName="transfer" width="24" height="20" />
                {t('Confirm Withdrawal')}
              </Button>
            </div>
          </div>
        </>
      )}
    </>
  );
};
