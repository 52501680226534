import styles from '@/shared/ui/loader/loader.module.scss';
import AnimLoader from './images/loader-anim.svg?react';
import cn from 'classnames';

export const DefaultLoader = ({ className }: { className?: string }) => {
  return (
    <div className={cn(styles.block, className)}>
      <AnimLoader />
    </div>
  );
};
