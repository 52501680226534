import { useTranslation } from 'react-i18next';
import { Button } from '@/shared/ui/button';
import { Icon } from '@/shared/ui/icons';
import { useMakeOrderMutation } from '@/page/cart/ui/v2/make-order-button/use-make-order-mutation';
import React from 'react';
import s from './make-order-button.module.scss';
import { useCurrentPlanPriceInfo } from '@/page/cart/hooks';

export const MakeOrderButton = () => {
  const { t } = useTranslation();
  const { handleClick, status } = useMakeOrderMutation();
  const isLoading = status === 'loading';

  const { userCurrency, formattedPrice } = useCurrentPlanPriceInfo();

  return (
    <Button fullWidth color="green" onClick={handleClick} disabled={isLoading} loading={isLoading} className={s.button}>
      <Icon iconName="shoppingCart" width="20" height="20" /> {t('BUY')}{' '}
      <span className={s.price}>
        {formattedPrice} {userCurrency}
      </span>
    </Button>
  );
};
