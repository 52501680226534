import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import s from './subscription-mining-duration.module.scss';
import { Typography } from '@/shared/ui/typography';
import { Button } from '@/shared/ui/button';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '@/shared/store/types';
import { MiningPoolPlanGoogle } from '@/shared/lib/models';
import { Icon } from '@/shared/ui/icons';
import { getGoogleMonthPrice } from '@/page/cart/ui/v1/total-price/total-price.util';
import {
  checkPurchase,
  setGoogleInfo,
  setGoogleMiningDuration,
  useGoogleAvailablePurchase,
  useGoogleCurrentPlan,
  useGoogleMiningDurations,
} from '@/page/cart/store/cart-google-store';
import { formatHashrate } from '../hashrate';
import { getLanguage } from '@/shared/store/language';
import { getFormattedLocaleNumber } from '@/shared/lib/utils/getFormattedPrice';
import { BONUS_TAG_20 } from '@/shared/lib/constants/bonus-tag';
import { useCallback } from 'react';
import { HshAlert } from '@/page/cart/ui/v1/hsh-alert/hsh-alert';
import { useMonthBonusHsh } from '@/page/cart/hooks/use-month-bonus-hsh';

const MONTH_IN_MS = 2629746000;

export const SubscriptionMiningDuration = () => {
  const activeMonth = useSelector((state: RootState) => state.googleCart.miningDuration);
  const plans = useSelector((state: RootState) => state.googleCart.googleData?.plans);
  const availablePurchase = useSelector(useGoogleAvailablePurchase);
  const miningDurationList = useSelector(useGoogleMiningDurations);
  const currentPlan = useSelector(useGoogleCurrentPlan);
  const dispatch: AppDispatch = useDispatch();
  const { t } = useTranslation();
  const monthsBonus = useMonthBonusHsh(plans, miningDurationList.length);

  const handleButtonClick = useCallback(
    (month: number, productId: string, offerToken: string, basePlanId: string, trial: boolean) => () => {
      dispatch(checkPurchase(availablePurchase?.some((i) => i?.productId === productId)));
      dispatch(setGoogleMiningDuration({ miningDuration: month }));
      dispatch(setGoogleInfo({ productId: productId, offerToken: offerToken, basePlanId: basePlanId, trial: trial }));
    },
    [dispatch]
  );

  return (
    <div className={s.root}>
      <div className={s.top}>
        <Typography variant="h3" className={s.title}>
          <Icon iconName="clock" width={20} height={20} />
          {t('choose mining duration')}
        </Typography>
        <p className={s.top__text}>{t('Buy_scr.Price_month')}</p>
      </div>

      <div className={s.buttonsContainer}>
        {miningDurationList &&
          miningDurationList.map((month, i) => {
            const discount = plans && (plans?.find((i) => i.month === month) as MiningPoolPlanGoogle)?.discount;
            const plan = plans && plans?.find((i) => i.hps === currentPlan?.hps && i.month === month);
            const lang = getLanguage();
            const purchase = plan && availablePurchase?.find((p) => p.productId === plan?.productId);
            const expirationDate = purchase
              ? new Date(MONTH_IN_MS * month + purchase.transactionDate).toLocaleDateString(lang)
              : undefined;
            const isNotAvailable =
              availablePurchase?.some((p) => {
                return p.productId === plan?.productId;
              }) ?? true;
            const pickedPlan = plan ? activeMonth === month : undefined;

            if (!plan) {
              return <></>;
            }
            return (
              <Button
                borderRadius={false}
                fullWidth
                key={month}
                variant="outline"
                onClick={handleButtonClick(
                  month,
                  plan?.productId || '',
                  plan?.offerToken || '',
                  plan?.basePlanId || '',
                  plan?.trial
                )}
                color="black"
                className={cn(s.button, activeMonth !== month && s.inactive)}
                style={{ marginBottom: !pickedPlan && i === miningDurationList.length - 1 ? '10px' : '0' }}
              >
                <div className={s.button__container}>
                  <span className={s.button__text}>
                    {month} {t('mo.')}
                  </span>
                  <div className={s.button__container_discount}>
                    {discount && discount > 1 ? <span className={s.button__discount}>-{discount}%</span> : null}
                    {plan?.tags?.includes(BONUS_TAG_20) && plan?.hsh_reward && (
                      <Icon iconName="gift-yellow" width={24} height={24} className={s.button__icon} />
                    )}
                  </div>
                  <span className={s.button__plan}>{plan && getGoogleMonthPrice(plan)}</span>
                  {!pickedPlan && i === miningDurationList.length - 1 && !isNotAvailable && (
                    <span className={s.button__tag}>{t('MTU.Cloud_boost_buy.Tag_profitable')}</span>
                  )}
                </div>
                {!isNotAvailable && pickedPlan && (plan?.hsh_reward || plan?.hps_bonus) && (
                  <div className={cn(s.button__bonus, pickedPlan && s.button__bonus__active)}>
                    <Icon iconName="gift" width={20} height={20} />
                    {plan?.hsh_reward ? (
                      <>
                        {t('BB_Hsh.Bonus')}:
                        <span className={s.button__right}>
                          +{getFormattedLocaleNumber(plan?.hsh_reward)} HSH{' '}
                          {plan?.tags?.includes(BONUS_TAG_20) && '(x2)'}
                        </span>
                      </>
                    ) : (
                      <>
                        {t('hps_increment')}:<span className={s.button__right}>+{formatHashrate(plan?.hps_bonus)}</span>
                      </>
                    )}
                  </div>
                )}
                {isNotAvailable && (
                  <div
                    className={cn(
                      s.button__bonus,
                      s.button__bonus__green,
                      !pickedPlan && s.button__bonus__inactive,
                      pickedPlan && s.button__bonus__active
                    )}
                  >
                    <Icon iconName="timer" width={20} height={20} />
                    {t('workerDetails.activated')}
                    <span className={s.button__right}>{expirationDate && expirationDate}</span>
                  </div>
                )}
              </Button>
            );
          })}
      </div>
      {monthsBonus && !monthsBonus.includes(`${currentPlan?.month}`) ? <HshAlert months={monthsBonus} /> : null}
    </div>
  );
};
