import { Trans, useTranslation } from 'react-i18next';
import styles from '@/features/rate-us/ui/rate-us-modal/rate-us-modal.module.scss';
import { Icon } from '@/shared/ui/icons';
import { Button } from '@/shared/ui/button';
import googlePlayIcon from './img/play.svg';
import appleStoreIcon from './img/apple.svg';
import { Rating } from '@/shared/ui/rating/rating';
import { RATING_APPLE_STORE, RATING_GOOGLE_STORE } from '@/shared/lib/utils/links';
import cn from 'classnames';
import { useState } from 'react';
import { rpc } from '@/shared/lib/backend/Rpc';
import { useRating } from '@/shared/lib/hooks/useRating';
import topImg from '@/features/rate-us/ui/market-block/img/particles_top.svg';
import { useDevice } from '@/shared/lib/hooks/useDevice';

type MarketBlockProps = {
  starCount: number;
  onClose: (e: boolean) => void;
};

export const MarketBlock = ({ starCount, onClose }: MarketBlockProps) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const hasOpenUrl = window.ct && Object.hasOwn(window.ct, 'openUrl');
  const { sendRatingDetails } = useRating();
  const { isIos } = useDevice();
  const marketIcon = isIos ? appleStoreIcon : googlePlayIcon;
  const storeName = isIos ? 'App Store' : 'Google Play';
  const storeLink = isIos ? RATING_APPLE_STORE : RATING_GOOGLE_STORE;
  const handleClose = async () => {
    try {
      await sendRatingDetails({
        summary: `${storeName} rating closed from CT-POOL Mobile`,
        rating: 0,
        message: 'Rating window closed',
      });
    } catch (e) {
      onClose(false);
      console.log('Failed to transmit rating:', e);
    }
    onClose(false);
  };

  const handleButtonClick = async () => {
    setLoading(true);
    try {
      await rpc.transmit('rate.send', {
        summary: `${storeName} rating sent from CT-POOL Mobile`,
        rating: starCount,
        message: 'Rating submitted via button',
      });
      if (hasOpenUrl) {
        await window.ct.openUrl(storeLink);
      } else {
        window.open(storeLink, '_blank');
      }
      setLoading(false);
      onClose(false);
    } catch (e) {
      onClose(false);
      setLoading(false);
      console.log('Failed to transmit rating:', e);
    }
  };

  return (
    <>
      <div className={styles.block}>
        <img src={topImg} className={styles.block__particlesTop} alt="center" />
        <h6 className={styles.title}>{t('MTU.Modal.Feedback.Title')}</h6>
        <span className={styles.close} onClick={handleClose}>
          <Icon iconName="close" width="20" height="20" />
        </span>
        <img src={marketIcon} alt="market icon" width={60} height={60} className={styles.marketIcon} />
        <div className={styles.marketRating}>
          <Rating noNumbers onChange={() => {}} value={5} size={24} />
        </div>
        <h6 className={cn(styles.title, styles.title2, styles.center)}>
          {<Trans i18nKey={t('MTU.Modal.Feedback_Stores.Title')} values={{ store: storeName }} />}
        </h6>
        <p className={cn(styles.text, styles.center)}>
          {
            <Trans
              i18nKey={t('MTU.Modal.Feedback_Stores.Description')}
              values={{
                link: storeLink,
                store: storeName,
              }}
              components={{ a: <a href={storeLink} />, b: <strong /> }}
            />
          }
        </p>
        <div className={styles.buttonBlock}>
          <Button color="blue" fullWidth borderRadius={false} onClick={handleButtonClick} loading={loading}>
            <Icon iconName="write-review" width={20} height={20} />
            {t('MTU.Modal.Feedback_Stores.Btn')}
          </Button>
        </div>
        <div className={styles.footer}>
          <span className={styles.line}></span>
        </div>
      </div>
    </>
  );
};
