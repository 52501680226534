import styles from './modal-halloween-skip.module.scss';
import { Icon } from '@/shared/ui/icons';
import { Trans, useTranslation } from 'react-i18next';
import { Button } from '@/shared/ui/button';
import ModalLayout from '@/shared/ui/modal/modal';
import promoImage from '@/page/home/ui/halloween/image/pumpkin-face.svg';
import { HALLOWEEN_LINK } from '@/shared/lib/utils/links';
import { HALLOWEEN_BANNER_ID } from '@/page/home/ui/halloween/helpers';

export const ModalHalloweenSkip = ({
  onClose,
  isOpen,
  onAgree,
}: {
  onClose: (e: boolean) => void;
  isOpen: boolean;
  onAgree: () => void;
}) => {
  const { t } = useTranslation();
  const onConfirm = () => {
    localStorage.setItem(HALLOWEEN_BANNER_ID, 'true');
    onClose(false);
    onAgree();
  };
  return (
    <ModalLayout background iconClose onClose={() => onClose(false)} isOpen={isOpen} id="ModalHalloweenSkip">
      <div className={styles.root}>
        <div className={styles.block}>
          <span className={styles.close} onClick={() => onClose(false)}>
            <Icon iconName="close" width="20" height="20" />
          </span>
          <img src={promoImage} alt={t('H.Skip_modal.Title')} className={styles.icon} />
          <h6 className={styles.title}>{t('H.Skip_modal.Title')}</h6>
          <p className={styles.text}>
            <Trans
              i18nKey="H.Skip_modal.Descr"
              components={{ b: <b />, a: <a href={HALLOWEEN_LINK} target="_blank" /> }}
            />
          </p>
        </div>
        <div className={styles.footer}>
          <Button color="red" fullWidth onClick={onConfirm}>
            <Icon iconName="checked" width="17" height="16" />
            {t('H.Skip_modal.Btn.Confirm')}
          </Button>
          <Button className={styles.footer__second} color="white" fullWidth onClick={() => onClose(false)}>
            <Icon iconName="cancel" width="20" height="20" />
            {t('H.Skip_modal.Btn.Cancel')}
          </Button>
        </div>
      </div>
    </ModalLayout>
  );
};
