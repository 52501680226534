import cn from 'classnames';
import type { CSSProperties, PropsWithChildren, ReactNode, Ref } from 'react';
import React, { forwardRef } from 'react';
import styles from './modal.module.scss';
import Modal from 'react-modal';
import { Icon } from '../icons';
import { useTranslation } from 'react-i18next';

interface ChildrenProps {
  children?: ReactNode;
}

interface ClassNameProps {
  className?: string;
}

interface HeaderProps extends ClassNameProps {
  hiddenDesktop?: boolean;
  image?: boolean;
}

const ModalLayoutHeader = ({
  children,
  className,
  hiddenDesktop,
  image,
  align = 'center',
}: PropsWithChildren<HeaderProps & ContentProps>) => (
  <div
    className={cn(
      className,
      styles.header,
      image && styles.header_image,
      hiddenDesktop && styles.hiddenDesktop,
      styles[align]
    )}
  >
    {children}
  </div>
);

interface TitleProps extends ClassNameProps {
  size?: 'xs' | 's' | 'm';
}

const ModalLayoutTitle = ({ children, className, size = 'm' }: PropsWithChildren<TitleProps>) => (
  <h3 className={cn(className, styles[size], styles.title)}>{children}</h3>
);

const ModalLayoutDescription = ({ children, className }: PropsWithChildren<ClassNameProps>) => (
  <p className={cn(className, styles.description)}>{children}</p>
);

interface IconProps {
  color?: string;
}

const ModalLayoutIcon = ({ children, className, color }: PropsWithChildren<ClassNameProps & IconProps>) => (
  <p className={cn(className, styles.icon, color && styles[color])}>{children}</p>
);

export interface ContentProps {
  align?: 'left' | 'center';
  padding?: 's' | 'm';
  style?: CSSProperties;
}

const ModalLayoutContent = ({
  children,
  className,
  align = 'center',
  padding = 'm',
}: PropsWithChildren<ClassNameProps & ContentProps>) => (
  <div className={cn(className, styles[padding], styles.content, styles[align])}>{children}</div>
);
const ModalLayoutContentMobile = ({
  children,
  className,
  align = 'center',
  padding = 'm',
  style,
}: PropsWithChildren<ClassNameProps & ContentProps>) => (
  <div style={style} className={cn(className, styles[padding], styles.contentMobile, styles[align])}>
    {children}
  </div>
);

interface FooterProps extends ChildrenProps {
  gray?: boolean;
}

const ModalLayoutFooter = ({ children, gray, align = 'center' }: PropsWithChildren<FooterProps & ContentProps>) => (
  <div className={cn(styles.footer, gray && styles.gray, styles[align])}>{children}</div>
);

interface ModalLayoutProps extends ChildrenProps {
  onClose: () => void;
  isOpen?: boolean;
  overlay?: boolean;
  // eslint-disable-next-line
  overlayFunc?: (a: any, p: any) => void;
  overlayClassName?: string;
  closeIconStyle?: 'default' | 'second';
  iconClose?: boolean;
  background?: boolean;
  id: string;
}

const ModalLayout = ({
  className,
  children,
  onClose,
  isOpen,
  overlay = false,
  overlayFunc,
  overlayClassName,
  closeIconStyle = 'default',
  iconClose = false,
  background,
  id,
}: PropsWithChildren<ModalLayoutProps & ClassNameProps>) =>
  overlay ? (
    <Modal
      className={cn(styles.modal, className)}
      isOpen={isOpen}
      onRequestClose={onClose}
      overlayElement={overlayFunc}
      overlayClassName={overlayClassName}
      id={id}
    >
      <>
        <div className={cn(styles.close, styles[closeIconStyle])} onClick={onClose}>
          <Icon className={styles.closeIcon} iconName="close" width="12" height="12" />
          <Icon iconName="arrow" width="20" height="20" className={styles.closeArrow} />
        </div>
        {children}
      </>
    </Modal>
  ) : (
    <Modal
      className={cn(styles.modal, className, background && styles.modal_bg)}
      isOpen={isOpen}
      overlayClassName={cn(styles.overlay, overlayClassName)}
      onRequestClose={onClose}
      id={id}
    >
      <>
        {!iconClose && (
          <div className={cn(styles.close, styles[closeIconStyle])} onClick={onClose}>
            <Icon iconName="close" width="12" height="12" className={styles.closeIcon} />
            <Icon iconName="arrow" width="20" height="20" className={styles.closeArrow} />
          </div>
        )}
        {children}
      </>
    </Modal>
  );

const ModalLayoutForPromo = forwardRef(
  (props: PropsWithChildren<ModalLayoutProps & ClassNameProps>, ref?: Ref<HTMLInputElement>) => {
    const { className, children, onClose, isOpen, overlayClassName } = props;
    const { t } = useTranslation();
    return (
      <Modal
        className={cn(styles.modal, styles.modal_second, className)}
        isOpen={isOpen}
        overlayClassName={cn(styles.overlay, overlayClassName)}
        onRequestClose={onClose}
      >
        <>
          <div className={cn(styles.close, styles.second)} onClick={onClose}>
            <Icon iconName="close" width="12" height="12" className={styles.closeIcon} />
            <Icon iconName="arrow" width="20" height="20" className={styles.closeArrow} />
          </div>
          {children}
          <div className={styles.outside}>
            <label>
              <input type="checkbox" className={styles.checkbox} ref={ref} />
              <span>{t('DontShowThisWindowAgain')}</span>
            </label>
          </div>
        </>
      </Modal>
    );
  }
);

interface ModalBottomSheetProps extends ChildrenProps {
  onClose: () => void;
  isOpen?: boolean;
  overlayClassName?: string;
  className?: string;
  id: string;
}

const ModalBottomSheet = ({ children, overlayClassName, onClose, isOpen, className, id }: ModalBottomSheetProps) => {
  return (
    <Modal
      className={{
        base: cn(styles.bottomSheet, className),
        afterOpen: styles.bottomSheet__after,
        beforeClose: styles.bottomSheet__before,
      }}
      isOpen={isOpen}
      overlayClassName={cn(styles.overlay, styles.bottomSheet__overlay, overlayClassName)}
      onRequestClose={onClose}
      closeTimeoutMS={500}
      id={id}
    >
      <>
        <button onClick={onClose} className={styles.bottomSheet__close}>
          <Icon iconName="close" width="20" height="20" />
        </button>
        {children}
      </>
    </Modal>
  );
};

ModalLayout.Header = ModalLayoutHeader;
ModalLayout.Title = ModalLayoutTitle;
ModalLayout.Description = ModalLayoutDescription;
ModalLayout.Icon = ModalLayoutIcon;
ModalLayout.Content = ModalLayoutContent;
ModalLayout.ContentMobile = ModalLayoutContentMobile;
ModalLayout.Footer = ModalLayoutFooter;
ModalLayout.PromoLayout = ModalLayoutForPromo;
ModalLayout.ModalBottomSheet = ModalBottomSheet;

export default ModalLayout;
