import { ContentSwitcher as ContentSwitcherV2 } from '@/page/cart/ui/v2/content-switcher';
import { ContentSwitcher as ContentSwitcherV1 } from '@/page/cart/ui/v1/content-switcher';
import { rootUser } from '@/shared/store/user/user.slice';
import { useSelector } from 'react-redux';

export const AddPoolMinerPage = () => {
  const { tags } = useSelector((store: { user: rootUser }) => store.user);

  return tags && tags.includes('ctpool_cart_b') ? <ContentSwitcherV2 /> : <ContentSwitcherV1 />;
};
