import { WithdrawalStatus } from '@/shared/lib/backend/JsonRpcApi';

export const getTransactionBorderColor = (status: WithdrawalStatus | undefined) => {
  switch (status) {
    case WithdrawalStatus.Rejected:
    case WithdrawalStatus.RejectedNull:
    case WithdrawalStatus.Error:
      return 'red';
    case WithdrawalStatus.Canceled:
      return 'gray';
    case WithdrawalStatus.Confirmed:
    case WithdrawalStatus.Pending:
    case WithdrawalStatus.Sent:
      return 'blue';
    case WithdrawalStatus.Completed:
      return 'green';
    case WithdrawalStatus.Created:
    case WithdrawalStatus.PendingFunds:
      return 'darkGray';
    default:
      return 'gray';
  }
};

export const getTransactionChipColor = (status: WithdrawalStatus | undefined) => {
  switch (status) {
    case WithdrawalStatus.Rejected:
    case WithdrawalStatus.RejectedNull:
    case WithdrawalStatus.Error:
      return 'red';
    case WithdrawalStatus.Confirmed:
    case WithdrawalStatus.Pending:
    case WithdrawalStatus.Sent:
      return 'blue';
    case WithdrawalStatus.Completed:
      return 'green';
    case WithdrawalStatus.Created:
    case WithdrawalStatus.PendingFunds:
      return 'darkGray';
    case WithdrawalStatus.Canceled:
    default:
      return 'gray';
  }
};
