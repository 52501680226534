import { SelectableColors, selectableColorsSolid, Theme } from './types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@/shared/store/types';
import { STORAGE_BACKGROUND, STORAGE_RANDOM_BACKGROUND, STORAGE_THEME } from '@/shared/lib/constants/storage-key';

export type ThemeSliceState = {
  currentTheme: Theme;
  currentBg: SelectableColors;
  overlapBg?: SelectableColors;
  isTelegramMiniAppState?: boolean;
};

const userTheme = localStorage.getItem(STORAGE_THEME) as Theme;
const userBg = localStorage.getItem(STORAGE_BACKGROUND) as SelectableColors;
const userRandomBg = localStorage.getItem(STORAGE_RANDOM_BACKGROUND);

function getBg() {
  if (userRandomBg === 'true') {
    const randomNumber = Math.floor(Math.random() * selectableColorsSolid.length);
    return selectableColorsSolid[randomNumber];
  }
  if (userBg) {
    return userBg;
  }

  return selectableColorsSolid?.[0];
}

const initialState: ThemeSliceState = {
  currentTheme: userTheme ? userTheme : window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light',
  currentBg: getBg(),
  overlapBg: undefined,
  isTelegramMiniAppState: undefined,
};

export const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    changeTheme: (state, action: PayloadAction<Theme>) => {
      state.currentTheme = action.payload;
    },
    changeBackground: (state, action: PayloadAction<SelectableColors>) => {
      localStorage.setItem(STORAGE_BACKGROUND, action.payload);
      state.currentBg = action.payload;
    },
    changeIsTelegramMiniApp: (state, action: PayloadAction<boolean | undefined>) => {
      state.isTelegramMiniAppState = action.payload;
    },
    addOverlapBg: (state, action: PayloadAction<SelectableColors | undefined>) => {
      state.overlapBg = action.payload;
    },
  },
});

export const selectCurrentTheme = (state: RootState) => state.theme.currentTheme;
export const selectCurrentBg = (state: RootState) => state.theme.currentBg;
export const selectOverlapBg = (state: RootState) => state.theme.overlapBg;
export const selectIsMobile = (state: RootState) => state.theme.isTelegramMiniAppState;

export const { changeTheme, changeBackground, changeIsTelegramMiniApp, addOverlapBg } = themeSlice.actions;
