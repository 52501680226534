import { checkResponse } from './check-response';
import { isResponseError } from './response-error';

export class apiClient {
  public async get(url: string, header?: RequestInit) {
    const r = await fetch(url, header);
    return await checkResponse(r);
  }

  public async post(url: string, headers?: HeadersInit, body = {}) {
    const r = await fetch(url, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        ...headers,
      },
      body: JSON.stringify(body),
    });
    return await checkResponse(r);
  }

  /* eslint-disable  @typescript-eslint/no-explicit-any */
  public async assuredPost(url: string, headers?: HeadersInit, body = {}, sec: number = 1): Promise<any> {
    let response;
    try {
      response = await this.post(url, headers, body);
    } catch (e: any) {
      if (e.message === 'Failed to fetch' || (isResponseError(e) && e.code === 429)) {
        await new Promise((resolve) => {
          setTimeout(resolve, sec * 1000);
        });
        let delay = sec * (1 + Math.random());
        if (delay > 600) {
          delay = 600 - sec * Math.random();
        }
        return this.assuredPost(url, headers, body, delay);
      }
      throw e;
    }
    return response;
  }

  public delete(url: string, headers?: HeadersInit, body = {}) {
    return fetch(url, {
      method: 'DELETE',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        ...headers,
      },
      body: JSON.stringify(body),
    }).then((r) => checkResponse(r));
  }
}
