function extractDefaultDescription(): string {
  const description = document.querySelector('meta[name="og:description"]')?.getAttribute('content');

  return description ?? '';
}

function extractDefaultImage(): string {
  return document.querySelector('meta[name="og:image"]')?.getAttribute('content') ?? '';
}

function extractDefaultUrl(): string {
  const currentUrl = new URL(window.location.href);
  return `${currentUrl.protocol}//${currentUrl.host}`;
}

type DefaultData = {
  url: string;
  title: string;
  description: string;
  media: string;
};

export function extractDefaultData(): DefaultData {
  return {
    url: extractDefaultUrl(),
    title: document.title,
    description: extractDefaultDescription(),
    media: extractDefaultImage(),
  };
}
