export class ResponseError extends Error {
  public readonly isResponseError = true;

  constructor(
    message: string,
    public readonly code: number
  ) {
    super(message);
  }
}

export function isResponseError(e: unknown): e is ResponseError {
  return e !== null && typeof e === 'object' && 'isResponseError' in e;
}
