import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './affiliate-earn-more.module.scss';
import { Typography } from '@/shared/ui/typography';
import { Icon } from '@/shared/ui/icons';
import { Button } from '@/shared/ui/button';
import { affiliateIconSrc, earnMoreSrc } from '../../assets';

export const AffiliateEarnMore = () => {
  const { t } = useTranslation();

  return (
    <a className={styles.container} href={window.app_config.affiliateSiteURL} target="_blank" data-test="1">
      <div className={styles.infoContainer}>
        <img src={affiliateIconSrc} alt="Icon" className={styles.icon} />
        <div className={styles.titleContainer}>
          <Typography variant="body1" weight="bold">
            {t('Affiliate.PromoBlock.Title')}
          </Typography>
        </div>
        <p className={styles.description}>{t('Affiliate.PromoBlock.Desc')}</p>
      </div>
      <img src={earnMoreSrc} alt={t('Affiliate.PromoBlock.Title')} className={styles.image} />
      <Button size="xs" color="blue" className={styles.button}>
        <Icon iconName={'arrow'} width={20} height={20} />
      </Button>
    </a>
  );
};
