import ModalLayout from '@/shared/ui/modal/modal';
import styles from './address-modal.module.scss';
import { Icon } from '@/shared/ui/icons';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { Button } from '@/shared/ui/button';
import React from 'react';
import { WalletsWithdrawalsLastAddressesResponseUnion } from '@/shared/lib/backend/JsonRpcApi';
import { useNavigate } from 'react-router-dom';
import { NC_WALLET_CONNECT } from '@/shared/lib/utils/links';
import { formatBtcAddress } from '@/shared/lib/utils/format-btc-address';
import { getWithdrawalAddressInfo } from '@/shared/lib/utils/get-withdrawal-address-info';

export const AddressModal = ({
  onClose,
  isOpen,
  addresses,
  currentAddress,
  userAccountEmail,
  setIsNcw,
  disabled = false,
  onClick,
}: {
  onClose: (e: boolean) => void;
  isOpen: boolean;
  addresses: WalletsWithdrawalsLastAddressesResponseUnion[];
  currentAddress: string;
  userAccountEmail: string;
  setIsNcw: (val: boolean) => void;
  disabled?: boolean;
  onClick: (addr: string) => void;
}) => {
  const { ModalBottomSheet } = ModalLayout;
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleClick = (addr: string, isNcw: boolean) => {
    onClick(addr);
    setIsNcw(isNcw);
    onClose(false);
  };

  const handleClickAddAddress = () => {
    navigate(NC_WALLET_CONNECT, { state: { email: userAccountEmail } });
    onClose(false);
  };

  // Determine the type of the address
  const renderAddress = (address: WalletsWithdrawalsLastAddressesResponseUnion, idx: number) => {
    const addressInfo = getWithdrawalAddressInfo(address);
    const addressValue = addressInfo.isNcw ? addressInfo.email || addressInfo.account_id : addressInfo.address;
    const addressName = addressInfo.isNcw
      ? addressInfo.email || addressInfo.account_id
      : formatBtcAddress(addressInfo.address);
    const iconName = addressInfo.isNcw ? 'ncwalletMini' : 'bitcoin';
    const isActive = currentAddress === addressValue;

    return (
      <Button
        fullWidth
        borderRadius={false}
        variant="outline"
        key={idx}
        className={cn(styles.item, isActive && styles.active)}
        onClick={() => handleClick(addressValue, addressInfo.isNcw)}
        disabled={disabled}
        color="black"
      >
        <div className={styles.wallet}>
          <Icon className={styles.wallet_icon} iconName={iconName} width="20" height="20" />
          <p>{addressName}</p>
          <div className={styles.wallet_recent}>
            {idx === 0 ? <div className={styles.recent}>{t('recent')}</div> : null}
          </div>
        </div>
      </Button>
    );
  };

  return (
    <ModalBottomSheet isOpen={isOpen} onClose={() => onClose(false)} className={styles.root} id="address">
      <div className={styles.header}>{t('Select_Address')}</div>
      <div className={styles.list}>
        {!disabled && addresses && addresses.map(renderAddress)}
        <Button
          fullWidth
          borderRadius={false}
          variant="outline"
          color="blue"
          className={cn(styles.item, styles.item_second)}
          onClick={handleClickAddAddress}
          disabled={disabled}
        >
          <Icon iconName="plus" width="20" height="20" />
          <p>{t('Add New Address')}</p>
        </Button>
      </div>
      {disabled && (
        <div className={styles.info}>
          <p>{t('address.Info')}</p>
        </div>
      )}
    </ModalBottomSheet>
  );
};
