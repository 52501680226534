import React from 'react';
import styles from './products-list.module.scss';
import { ProductCard, useProductsList } from '@/features/crypto-tab-products';

export const ProductsList = () => {
  const { products } = useProductsList();

  return (
    <div className={styles.container}>
      {products.map((product) => (
        <ProductCard product={product} key={product.name} shadow />
      ))}
    </div>
  );
};
