import React from 'react';
import { Typography } from '@/shared/ui/typography';
import styles from './product-card.module.scss';
import { Alert } from '@/shared/ui/alert';
import { Button } from '@/shared/ui/button';
import { Icon } from '@/shared/ui/icons';
import { CTProduct } from '../../hooks';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

type ProductItemProps = {
  product: CTProduct;
  infoIcon?: boolean;
  shadow?: boolean;
};

export const ProductCard = ({ product, infoIcon, shadow }: ProductItemProps) => {
  const { t } = useTranslation();
  return (
    <a href={product.link} target="_blank" className={cn(styles.container, shadow && styles.containerShadow)}>
      <div className={styles.infoContainer}>
        <div className={styles.titleContainer}>
          <img src={product.icon} alt="Icon" className={styles.icon} />
          <Typography variant="body1" weight="bold" className={styles.title}>
            {t(product.name)}
          </Typography>
          {infoIcon && <Icon iconName={'info'} width={20} height={20} className={styles.infoIcon} />}
        </div>
        <p className={styles.description}>{t(product.description)}</p>
        {product.promo && (
          <Alert variant="default" className={styles.promoContainer}>
            <div className={styles.promoIconContainer}>
              <Icon iconName={'gift'} width={20} height={20} />
            </div>
            {t(product.promo)}
          </Alert>
        )}
      </div>
      <img src={product.image} alt={t(product.name)} className={styles.image} />
      <Button size="xs" color="blue" className={styles.button}>
        <Icon iconName={'arrow'} width={20} height={20} />
      </Button>
    </a>
  );
};
