export function formatSpeed(hps: number) {
  let hs = 'H/s';
  if (hps >= 1000) {
    hps = Math.round(hps / 1000);
    hs = 'kH/s';
  }
  if (hps >= 1000) {
    hps = Math.round(hps / 1000);
    hs = 'MH/s';
  }
  return `${hps} ${hs}`;
}
