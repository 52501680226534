import { ReactNode } from 'react';
import styles from './menu-nav-item.module.scss';
import { Icon } from '@/shared/ui/icons';
import cn from 'classnames';

type TMenuNavItem = {
  children: ReactNode;
  link?: string;
  label?: string;
  labelColor?: 'orange' | 'purple' | 'red' | 'cyan';
  onClick?: () => void;
};

export const MenuNavItem = ({ children, label, labelColor, link, onClick }: TMenuNavItem) => {
  const isLabel = label && label.length;
  return (
    <li className={styles.root}>
      {onClick ? (
        <button className={styles.link} onClick={onClick}>
          <Icon className={styles.icon} iconName="arrow-short" width={14} height={14} />
          {children}
        </button>
      ) : (
        <a className={styles.link} href={link} target="_blank" rel="noreferrer">
          <Icon className={styles.icon} iconName="arrow-short" width={14} height={14} />
          {children}
          {isLabel && <span className={cn(styles.label, styles[labelColor!])}>{label}</span>}
        </a>
      )}
    </li>
  );
};
