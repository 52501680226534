import React from 'react';
import { Button } from '@/shared/ui/button';
import { Icon } from '@/shared/ui/icons';
import { useTranslation } from 'react-i18next';
import s from './restore-subs-button.module.scss';
import { rpc } from '@/shared/lib/backend/Rpc';
import { useDispatch, useSelector } from 'react-redux';
import {
  availablePurchaseIos,
  setAvailablePurchaseIos,
  setStatus,
  useAppleAvailablePurchase,
} from '@/page/cart/store/cart-google-store';
import { AppDispatch } from '@/shared/store/types';
import { PlatformIosAppstorePurchasesRequest } from '@/shared/lib/backend/JsonRpcApi';
import { toaster } from '@/shared/ui/custom-toast/toaster';
import cx from 'classnames';

interface IProps {
  type?: 'credit' | 'credit-gear';
}

export const RestoreSubsButton = ({ type = 'credit' }: IProps) => {
  const { t } = useTranslation();
  const dispatch: AppDispatch = useDispatch();
  const availablePurchaseIos = useSelector(useAppleAvailablePurchase);

  const handleClick = async () => {
    if (type === 'credit-gear') {
      // Open App Store subscriptions page
      await window.ct.openUrl('https://apps.apple.com/account/subscriptions');
    } else {
      let availablePurchase: availablePurchaseIos[] | GetAvailablePurchasesResponse | undefined;
      dispatch(setStatus('loading'));
      if (availablePurchaseIos) {
        availablePurchase = availablePurchaseIos;
      } else {
        try {
          availablePurchase = await window?.ct?.getAvailablePurchases();
          console.warn('availablePurchaseIOS', availablePurchase);
          dispatch(setAvailablePurchaseIos(availablePurchase));
        } catch (e) {
          console.error('availablePurchaseIOS error', e);
        }
      }

      try {
        // Get the latest purchase by date
        const lastPurchase = availablePurchase?.reduce((latest, purchase) => {
          return purchase.transactionDate > latest.transactionDate ? purchase : latest;
        }, availablePurchase[0]);

        if (lastPurchase && lastPurchase?.transactionReceipt) {
          const dataRequestAppStore: PlatformIosAppstorePurchasesRequest = {
            receipt: lastPurchase?.transactionReceipt,
            restore: true,
          };
          console.warn('dataRequestAppStore', dataRequestAppStore);
          try {
            await rpc.transmit('platform.ios.appstore.purchases', dataRequestAppStore).then(() => {
              toaster.success(t('RestorePurchases.Description'));
            });
          } catch (e) {
            console.error('purchaseSubscriptionIosRestoreRequest error:', e);
          }
        }
      } catch (e) {
        console.error('Error restoring subscriptions:', e);
      } finally {
        dispatch(setStatus('idle'));
      }
    }
  };

  return (
    <div className={s.restore}>
      <Button size="xs" onClick={handleClick} className={cx(s.button, { [s.creditButton]: type === 'credit-gear' })}>
        <Icon iconName={type} width={20} height={20} className={s.icon} />
        <span className={s.text}>
          {t(type === 'credit-gear' ? t('Dashboard.Subscriptions.Btn') : t('RestorePurchases.Btn'))}
        </span>
      </Button>
    </div>
  );
};
