import React, { useEffect } from 'react';
import styles from './error-connection.module.scss';
import { Icon } from '@/shared/ui/icons';
import { Button } from '@/shared/ui/button';
import i18next from 'i18next';
import { useNavigate } from 'react-router-dom';
import { rpc } from '@/shared/lib/backend/Rpc';

const ErrorConnection = () => {
  const navigate = useNavigate();
  const reload = async () => {
    await rpc.reopen();
    navigate('/');
    // window.location.reload();
  };

  const visibilitychange = async () => {
    if (document.visibilityState === 'visible') {
      return reload();
    }
  };

  useEffect(() => {
    document.addEventListener('visibilitychange', visibilitychange);
    window.addEventListener('focus', reload);
    return () => {
      document.removeEventListener('visibilitychange', visibilitychange);
      window.removeEventListener('focus', reload);
    };
  });

  return (
    <div className={styles.root}>
      <div>
        <div className={styles.block}>
          <Icon iconName="connection-error" width={80} height={80} className={styles.icon} />
          <h6 className={styles.title}>{i18next.t('Oops.Title')}</h6>
          <p className={styles.text}>{i18next.t('Oops.Description')}</p>
        </div>
        <div className={styles.footer}>
          <Button color="blue" fullWidth onClick={reload}>
            <Icon iconName="refresh" width="20" height="20" />
            {i18next.t('RETRY')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ErrorConnection;
