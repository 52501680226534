import { Button } from '@/shared/ui/button';
import styles from './currency-button.module.scss';
import cn from 'classnames';
import React from 'react';
import { Icon } from '@/shared/ui/icons';

type CurrencyButtonProps = {
  active?: boolean;
  disabled?: boolean;
  arrow?: boolean;
  iconUrl: string;
  name: string;
  symbol: string;
  onClick: () => void;
  currentCurrency?: boolean;
};

export const CurrencyButton = ({
  active,
  iconUrl,
  name,
  onClick,
  currentCurrency,
  disabled,
  symbol,
  arrow,
}: CurrencyButtonProps) => {
  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    onClick();
  };
  return (
    <Button
      borderRadius={false}
      size="l"
      fullWidth
      variant="outline"
      onClick={handleClick}
      disabled={disabled}
      className={cn(styles.button, active && styles.active)}
    >
      <img src={iconUrl} alt="title" width={20} height={20} />
      <strong>{symbol}</strong> / {name}
      {currentCurrency && <span className={styles.current} />}
      {arrow ? <Icon className={styles.icon} iconName="arrow-short" width={14} height={14} /> : null}
    </Button>
  );
};
