import ModalLayout from '@/shared/ui/modal/modal';
import styles from '@/page/withdraw/ui/confirm-withdrawal-modal/confirm-withdrawal-modal.module.scss';
import { Icon } from '@/shared/ui/icons';
import { Trans, useTranslation } from 'react-i18next';
import { Button } from '@/shared/ui/button';
import { RootState } from '@/shared/store/types';
import { useSelector } from 'react-redux';
import { checkE } from '@/shared/lib/utils/checE';

export const ErrorFundsWithdrawalModal = ({
  onClose,
  isOpen,
  amount,
  isMining,
}: {
  onClose: (e: boolean) => void;
  isOpen: boolean;
  amount: number;
  isMining: boolean;
}) => {
  const { t } = useTranslation();
  const { Header, Title, ContentMobile } = ModalLayout;
  const { currency: userCurrency } = useSelector((store: RootState) => store.user);
  return (
    <ModalLayout onClose={() => onClose(false)} isOpen={isOpen} id="error-funds">
      <Header>
        <Title size="xs">{t('Confirm Withdrawal')}</Title>
      </Header>
      <ContentMobile>
        <div className={styles.block}>
          <div className={styles.header}>
            <Icon iconName="attention" width={80} height={80} />
            <h6 className={styles.title}>
              {
                <Trans
                  i18nKey={t('You do not have enough BTC')}
                  values={{ currency: isMining ? userCurrency : 'HSH' }}
                />
              }
            </h6>
            <p className={styles.text}>
              {
                <Trans
                  i18nKey={t('Not_enough.Description')}
                  components={{ b: <strong /> }}
                  values={{ amount: checkE(amount), currency: isMining ? userCurrency : 'HSH' }}
                />
              }
            </p>
          </div>
          <div className={styles.footer}>
            <Button color="blue" className={styles.mailbox} fullWidth onClick={() => onClose(false)}>
              <Icon iconName="checked" width="17" height="16" />
              {t('OK, GOT IT')}
            </Button>
          </div>
        </div>
      </ContentMobile>
    </ModalLayout>
  );
};
