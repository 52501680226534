import { development } from '@/shared/lib/constants/device';

const GRAY = '#c0c0c0';
const BLACK = '#808080';
const ORANGE = '#f0a000';
const RED = '#ff6060';

export function toJsUnsafe(object: unknown): string {
  if (!object || typeof object !== 'object') return '' + object;

  function fix(key: string, value: unknown): unknown {
    if (value instanceof Set) return Array.from(value);
    if (value instanceof Map) return Object.fromEntries(value.entries());
    return value;
  }

  try {
    const text = JSON.stringify(object, fix)
      .replace(/"(\w+)":/g, '$1: ')
      .replace(/https:\/\//g, '')
      .replace(/"/g, "'")
      .replace(/,/g, ', ');
    if (text === '{}') return text;
    return text
      .replace(/\{/g, '{ ')
      .replace(/}/g, ' }')
      .replace(/\{ {2}}/g, '{}');
  } catch (e) {
    const text = object.toString();
    const type = object.constructor?.name;
    if (text === '[object Object]' && type) return '[object ' + type + ']';
    return text;
  }
}

type Arg = ColorText | string | number | null | unknown;

class ColorText {
  private readonly _object: unknown;
  private readonly _color: string;
  private _text?: string;

  constructor(object: unknown, color: string) {
    this._object = object;
    this._color = color;
  }

  text(): string {
    if (this._text == null) this._text = toJsUnsafe(this._object);
    return this._text;
  }

  style(): string {
    return `word-break: break-all; word-wrap: break-word; color: ${this._color};`;
  }

  raw() {
    return this.text().length > 128;
  }

  obj() {
    return this._object;
  }
}

export function color(object: unknown, color: string): ColorText {
  return new ColorText(object, color);
}

export function gray(object: unknown): ColorText {
  return color(object, GRAY);
}

export function black(object: unknown): ColorText {
  return color(object, BLACK);
}

export function orange(object: unknown): ColorText {
  return color(object, ORANGE);
}

export function red(object: unknown): ColorText {
  return color(object, RED);
}

function wrap(a: Arg): ColorText {
  return a instanceof ColorText ? a : black(a);
}

export function error(text: string, error?: unknown): void {
  // if (!development) return;
  if (error == null) log(red(text));
  else if (error instanceof Error)
    console.error('%c' + text + ' %c' + toJsUnsafe(error), 'color: ' + RED, 'color: ' + BLACK, error);
  else log(red(text), black(error));
}

export function log(...args: Arg[]): void {
  if (!development) return;
  const raw = [] as unknown[];
  const colored = args.map(wrap).filter((a) => {
    if (!a.raw()) return true;
    raw.push(a.obj());
    return false;
  });
  const text = colored
    .map((a) => a.text())
    .map((s) => '%c' + s)
    .join(' ');
  const styles = colored.map((a) => a.style());
  console.log(text, ...styles, ...raw);
}

export function debug(text: string, object: unknown): void {
  log(gray(text), black(object));
}
