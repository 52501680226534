import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type NFTPromoType = {
  code: string;
  created_at: number;
  discount?: number;
  amount: number;
};

export type NftPromocodesStore = {
  nftPromocodes: NFTPromoType[];
};

const initialState: NftPromocodesStore = {
  nftPromocodes: [],
};
export const nftPromocodesSlice = createSlice({
  name: 'nft-promocodes',
  initialState,
  reducers: {
    setNftPromocodes(store, payload: PayloadAction<{ nftPromocodes: NFTPromoType[] }>) {
      store.nftPromocodes = payload.payload.nftPromocodes;
    },
  },
});

export const { setNftPromocodes } = nftPromocodesSlice.actions;

export default nftPromocodesSlice.reducer;
