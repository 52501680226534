import React from 'react';
import { Button } from '@/shared/ui/button';
import cn from 'classnames';
import s from './mining-duration.module.scss';
import { useTranslation } from 'react-i18next';
import { useDevice } from '@/shared/lib/hooks/useDevice';
import { Icon } from '@/shared/ui/icons';

type MiningDurationButtonProps = {
  isMostProfitable: boolean;
  month: number;
  discount?: number;
  isBonus?: boolean;
  formattedPrice: string;
  userCurrency: string;
  isActive: boolean;
  onClick: () => void;
};

export const MiningDurationButton = ({
  month,
  isBonus,
  discount,
  formattedPrice,
  userCurrency,
  isActive,
  onClick,
  isMostProfitable,
}: MiningDurationButtonProps) => {
  const { t } = useTranslation();
  const { isTelegramMiniApp } = useDevice();
  return (
    <Button
      borderRadius={false}
      fullWidth
      variant="outline"
      onClick={onClick}
      color="black"
      className={cn(s.button, s.durationInfo, !isActive && s.inactive)}
    >
      <span className={s.durationInfo__text}>
        {month} {t('mo.')}
      </span>
      {discount && discount > 1 && (
        <span className={s.durationInfo__discount}>
          <span className={s.durationInfo__discount__text}>-{discount}%</span>
          {isBonus && (
            <span className={s.durationInfo__discount__gift}>
              <Icon iconName="gift" width={15} height={15} />
            </span>
          )}
        </span>
      )}
      <span className={s.durationInfo__plan}>
        {formattedPrice} {userCurrency}
      </span>
      {isMostProfitable && !isActive && (
        <span className={cn(s.durationInfo__tag, isTelegramMiniApp && s.durationInfo__tagPurple)}>
          {t('MTU.Cloud_boost_buy.Tag_profitable')}
        </span>
      )}
    </Button>
  );
};
