export type Theme = 'light' | 'dark';

export const excludeSelectableColors = ['#C9F85D', '#F7F7F7', '#6B1F77', '#455265'];

export const selectableColorsSolid = [
  '#F7931A',
  '#4285F4',
  '#37CC33',
  '#F7F7F7',
  '#1AB9E5',
  '#A033CC',
  '#1DC886',
  '#F7C21A',
  '#FC4BAC',
  '#C9F85D',
  '#F74249',
  '#17EF46',
  '#3F3DA5',
  '#3CA867',
  '#5D868F',
  '#6B1F77',
  '#826A5C',
  '#791130',
  '#455265',
  '#7A825C',
  '#5D88C8',
  '#437155',
  '#9265A7',
  '#000000',
] as const;

export type SelectableColors = (typeof selectableColorsSolid)[number];
