import cn from 'classnames';
import { Trans } from 'react-i18next';
import s from './special-offer.module.scss';
import { Alert } from '@/shared/ui/alert';
import { Icon } from '@/shared/ui/icons';
import { useCurrentPlan } from '@/page/cart/store';
import { useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import { BONUS_TAG } from '@/shared/lib/constants/bonus-tag';

const variantsBonus = {
  hps: {
    local: 'bonusHashrateOfferBannerLastClosed',
    title: 'Holiday_Season.Title',
    iconName: 'red-gift',
  },
  hsh: {
    local: 'bonusHshOfferBannerLastClosed',
    title: 'Bonus_banner.HSH',
    iconName: 'hsh',
  },
};

export enum variantBonus {
  hps = 'hps',
  hsh = 'hsh',
}

type TChristmasProps = {
  main?: boolean;
  onClick?: () => void;
  variant?: variantBonus;
};

export const BonusOfferBanner = ({ main = false, onClick, variant = variantBonus.hps }: TChristmasProps) => {
  const currentPlan = useSelector(useCurrentPlan);
  const [close, setClose] = useState(false);

  useEffect(() => {
    const lastClosed = localStorage.getItem(variantsBonus[variant].local);
    if (lastClosed) {
      const lastClosedDate = new Date(lastClosed);
      const now = new Date();
      const oneDay = 24 * 60 * 60 * 1000; // 1 day
      if (now.getTime() - lastClosedDate.getTime() < oneDay) {
        setClose(true);
      }
    }
  }, []);

  const handleClose = () => {
    setClose(true);
    localStorage.setItem(variantsBonus[variant].local, new Date().toString());
  };

  if (close) {
    return null;
  }

  if (currentPlan && !currentPlan?.tags?.includes(BONUS_TAG)) return null;

  return (
    <Alert
      variant="warning"
      className={cn(s.personalDiscountAlert, s.christmasAlert, main && s.christmasAlert_second)}
      dashed
    >
      {main && <Icon iconName="close-notification" width={16} height={16} className={s.close} onClick={handleClose} />}
      <div className={cn(s.personalDiscountContainer)} onClick={onClick}>
        <div className={s.titleContainer}>
          <Icon iconName={variantsBonus[variant].iconName} width={30} height={30} />
          <div className={s.christmasTitle}>
            <Trans i18nKey={variantsBonus[variant].title} components={{ b: <strong /> }} />
          </div>
        </div>
      </div>
    </Alert>
  );
};
