import styles from './custom-toast.module.scss';
import { Icon } from '@/shared/ui/icons';
import { ToastOptions } from 'react-toastify';
import cn from 'classnames';
import { CircleProgress } from '@/shared/ui/circle-progress/circle-progress';

export interface CustomToastProps {
  title: string;
  variant: 'success' | 'error' | 'warning' | 'info';
  toastProps?: ToastOptions;
  closeToast?: () => void;
  onClick?: () => void;
}

export const CustomToast = (props: CustomToastProps) => {
  const { title, variant, toastProps, closeToast, onClick } = props;
  return (
    <div className={cn(styles.root, styles[variant])} onClick={onClick}>
      <Icon iconName={`toast-${variant}`} width={20} height={20} />
      <div className={styles.title}>{title}</div>
      <button onClick={closeToast} className={styles.btn}>
        <CircleProgress
          variant="close"
          width={24}
          duration={toastProps ? (toastProps.autoClose as number) : 5000}
          onDurationEnds={closeToast}
        />
      </button>
    </div>
  );
};
