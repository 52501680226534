import { useTranslation } from 'react-i18next';
import styles from '@/features/rate-us/ui/rate-us-modal/rate-us-modal.module.scss';
import { Icon } from '@/shared/ui/icons';
import { Button } from '@/shared/ui/button';
import { ChangeEvent, useState } from 'react';
import cn from 'classnames';

type FeedbackProps = {
  onClose: (e: boolean) => void;
  onClick: (text: string) => void;
  loading: boolean;
};

export const Feedback = ({ onClose, onClick, loading }: FeedbackProps) => {
  const { t } = useTranslation();
  const [value, setValue] = useState('');
  const [disabled, setDisabled] = useState(true);

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setValue(e.target.value);
    if (e.target.value.length > 10) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };

  return (
    <>
      <div className={styles.block}>
        <span className={styles.close} onClick={() => onClose(false)}>
          <Icon iconName="close" width="20" height="20" />
        </span>
        <h6 className={styles.title}>{t('MTU.Modal.Low_Rating.Title')}</h6>
        <p className={cn(styles.text, styles.text2)}>{t('MTU.Modal.Low_Rating.Description')}</p>

        <form className={styles.form}>
          <textarea
            rows={3}
            value={value}
            onChange={handleChange}
            placeholder={t('MTU.Modal.Low_Rating.Placeholder')}
            className={styles.textarea}
          />
        </form>
        <div className={styles.buttonBlock}>
          <Button
            color="blue"
            fullWidth
            borderRadius={false}
            onClick={() => onClick(value)}
            disabled={disabled || loading}
            loading={loading}
          >
            <Icon iconName="email" width="20" height="20" />
            {t('MTU.Modal.Low_Rating.Btn')}
          </Button>
        </div>
        <div className={styles.footer}>
          <span className={styles.line}></span>
        </div>
      </div>
    </>
  );
};
