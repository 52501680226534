import cn from 'classnames';
import { Icon } from '@/shared/ui/icons';
import styles from './error-text.module.scss';

export type ErrorTextType = {
  text: string;
  align?: 'left' | 'center' | 'right';
};

export const ErrorText = ({ text, align = 'left' }: ErrorTextType) => {
  return (
    <div className={cn([styles.error_text, styles[`error_text--${align}`]])}>
      <Icon iconName={'warning-triangle'} width={16} height={16} />
      <span className={styles.error_text__text}>{text}</span>
    </div>
  );
};
