import * as Sentry from '@sentry/browser';
import { getToken, Messaging } from '@firebase/messaging';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { apiClient } from '@/shared/lib/api/app-client';
import {
  getDemoUserPathname,
  getLoginTokenPathnameTelegram,
  getLogoutPathname,
  getOAuthLogin,
  getRefreshTokenPathname,
  getRestorePathname,
} from '@/shared/lib/constants/url';
import { error } from '@/shared/lib/utils/log';
import { EggRefPaymentPublic, Withdrawal, WithdrawalStatus } from '@/shared/lib/backend/JsonRpcApi';
import { MINIMUM_AMOUNT, MINIMUM_AMOUNT_NCW } from '@/shared/lib/constants/min-amount';
import { initApp, InitDeviceTypes } from '@/shared/store/rpc/rpc.slice';
import { Currencies } from '@/shared/ui/currency-icon';
import { RootState } from '@/shared/store/types';
import { Tokens } from '@/shared/lib/models';

export const authChecked = createAsyncThunk(
  'user/authChecked',
  async function (
    { isReactNativeApp, isTelegramMiniApp, initData, setItem, getItem, appPlatform }: InitDeviceTypes,
    { dispatch }
  ) {
    const token = localStorage.getItem('tokens');
    if (token) {
      const json = JSON.parse(token);
      const access_token = json?.access_token;
      const refresh_token = json?.refresh_token;
      const payload = { access_token: access_token, refresh_token: refresh_token, id: 'id' };
      dispatch(userAction.setToken(payload));
      dispatch(initApp({ isReactNativeApp, isTelegramMiniApp, initData, setItem, getItem, appPlatform }));
    } else {
      dispatch(userAction.setLoading(false));
      dispatch(userAction.checkAuth());
    }
  }
);

export const refreshToken = createAsyncThunk(
  'user/refreshToken',
  async ({ refresh_token, telegramUserId }: { refresh_token: string | null; telegramUserId: string }) => {
    const did = localStorage.getItem('did');
    const urlRefresh = getRefreshTokenPathname(did);
    let response: Tokens;
    try {
      response = (await new apiClient().assuredPost(urlRefresh, {}, { refresh_token: refresh_token })) as Tokens;
    } catch (e: unknown) {
      Sentry.captureException(e);
      const urlRestore = getRestorePathname(did);
      response = (await new apiClient().assuredPost(urlRestore, {}, {})) as Tokens;
    }
    if (telegramUserId) {
      response.telegramId = telegramUserId;
    }
    return response;
  }
);

export type platformType = 'google' | 'apple';
export type deviceType = 'ios' | 'android';

export const getAccessTokens = createAsyncThunk(
  'user/accessTokens',
  async function ({ platform }: { platform: 'apple' | 'google' | 'demo' }) {
    try {
      if (window.ct) {
        let did: string | null = null;
        let res: { token: string | null } | null = null;

        // Handle demo platform separately
        if (platform === 'demo') {
          try {
            // Fetch demo-specific tokens directly from the demo endpoint
            const demoResponse = await new apiClient().post(getDemoUserPathname(), {}, {});
            if (demoResponse && demoResponse.access_token && demoResponse.refresh_token && demoResponse.id) {
              return {
                id: demoResponse.id,
                access_token: demoResponse.access_token,
                refresh_token: demoResponse.refresh_token,
              };
            } else {
              throw new Error('Invalid demo response: missing tokens');
            }
          } catch (error) {
            const demoErrorMessage = `Failed to get tokens from demo login`;
            Sentry.captureException(demoErrorMessage, {
              extra: { error, platform },
            });
            throw new Error(demoErrorMessage);
          }
        }

        // Continue with regular OAuth flow for apple and google platforms
        try {
          did = await window.ct.getDid();
        } catch (didError) {
          const didErrorMessage = `Failed to get DID from ${platform} login`;
          Sentry.captureException(didErrorMessage, {
            extra: { error: didError, platform },
          });
          throw new Error(didErrorMessage);
        }

        try {
          res = await window.ct.signIn(platform);
        } catch (signInError) {
          const signInErrorMessage = `Failed to sign in via ${platform}`;
          Sentry.captureException(signInErrorMessage, {
            extra: { error: signInError, platform },
          });
          throw new Error(signInErrorMessage);
        }

        // Handle user cancellation
        if (!res) {
          console.log(`Login was cancelled by the user via ${platform}`);
          throw new Error('Auth cancelled');
        }

        if (!did) {
          const errorMessage = `Can't get DID from ${platform} login`;
          Sentry.captureException(errorMessage, {
            extra: { platform },
          });
          throw new Error(errorMessage);
        }

        // If no token is provided, log the error and throw
        if (!res.token) {
          const errorMessage = `No response token from ${platform} login`;
          Sentry.captureException(errorMessage, {
            extra: { platform, res },
          });
          throw new Error('Authentication failed. Response token is undefined.');
        }

        const url = getOAuthLogin(platform, did);
        return (await new apiClient().assuredPost(url, {}, { token: res.token })) as Tokens;
      } else {
        const errorMessage = 'window.ct is not available';
        Sentry.captureException(errorMessage, {
          extra: { platform },
        });
        throw new Error(errorMessage);
      }
    } catch (error) {
      Sentry.captureException(`Error in getAccessTokens thunk: ${error}`, {
        extra: { platform },
      });
      throw error; // Rethrow the error to propagate it further
    }
  }
);

export const getTelegramAccessTokens = createAsyncThunk(
  'user/telegramAccessTokens',
  async function ({ telegramToken, telegramUserId }: { telegramToken: string; telegramUserId: string }) {
    if (window.TelegramWebviewProxy || window.Telegram?.WebView?.isIframe) {
      const did = localStorage.getItem('did');
      const url = getLoginTokenPathnameTelegram(did);
      let response: Tokens;
      try {
        response = (await new apiClient().assuredPost(url, {}, { token: telegramToken })) as Tokens;
      } catch (e: unknown) {
        if ((e as { code: string }).code === 'expired_token') {
          const token = localStorage.getItem('tokens');
          if (!token) {
            throw e;
          }
          response = { ...JSON.parse(token) };
        } else {
          throw e;
        }
      }
      response.telegramId = telegramUserId;
      return response;
    }
    throw new Error('TelegramWebviewProxy is not available');
  }
);

export const logoutToken = createAsyncThunk(
  'user/logoutTokens',
  async function ({ refresh_token }: { refresh_token: string }) {
    const did = localStorage.getItem('did');
    const url = getLogoutPathname(did);
    return await new apiClient().post(url, {}, { refresh_token: refresh_token });
  }
);

export const getFcmToken = createAsyncThunk('user/fcmToken', async function (messaging: Messaging) {
  return await getToken(messaging, { vapidKey: window.app_config.firebase.vapidKey });
});

type NotificationStatuses = 'granted' | 'denied' | 'default';

const params = new URLSearchParams(document.location.search);
const token = params.get('token');
const refresh = params.get('refresh');

export type fiatCurrencyInfoType = {
  code: string;
  symbol: string;
};

export type rootUser = {
  isAuthChecked: boolean;
  id: number;
  email: string;
  request: boolean;
  hasRequested: boolean;
  error: boolean;
  social_request: boolean;
  social_error: boolean;
  email_verified: boolean;
  min_withdrawal_amount: number;
  ncw_min_withdrawal_amount: number;
  social: UserSocial[];
  tags: string[];
  token_request: boolean;
  token_error: boolean;
  access_token: typeof token;
  refresh_token: typeof refresh;
  uid: string;
  refresh_request: boolean;
  refresh_error: boolean;
  notification_status: NotificationStatuses;
  fcm_token: string;
  fcm_request: boolean;
  fcm_error: boolean;
  withdrawal_processing_list: [] | Withdrawal[];
  withdrawal_list: [] | Withdrawal[];
  rpcStatus: string;
  loading: boolean;
  bid_request: boolean;
  bid_error: boolean;
  eggs_payments: EggRefPaymentPublic[] | [];
  initRpc: boolean;
  currency: Currencies;
  timestamp: number;
  currency_changed_at: number;
  banned: boolean;
  forceDisableRating: boolean;
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  ratingMeta: any;
  reviewRatingId: string;
  defaultFiatCurrencyApproximately: fiatCurrencyInfoType;
};

const initialState: rootUser = {
  isAuthChecked: false,
  id: 0,
  email: '',
  request: false,
  hasRequested: false,
  error: false,
  social: [],
  social_request: false,
  social_error: false,
  tags: [],
  email_verified: false,
  min_withdrawal_amount: MINIMUM_AMOUNT,
  ncw_min_withdrawal_amount: MINIMUM_AMOUNT_NCW,
  token_request: false,
  token_error: false,
  access_token: token || '',
  refresh_token: refresh || '',
  uid: '',
  refresh_request: false,
  refresh_error: false,
  notification_status: 'denied' as NotificationStatuses,
  fcm_token: '',
  fcm_request: false,
  fcm_error: false,
  withdrawal_processing_list: [],
  withdrawal_list: [],
  rpcStatus: '',
  loading: true,
  bid_request: false,
  bid_error: false,
  eggs_payments: [],
  initRpc: false,
  currency: 'BTC',
  timestamp: 0,
  currency_changed_at: 0,
  banned: false,
  forceDisableRating: false,
  ratingMeta: {},
  reviewRatingId: '',
  defaultFiatCurrencyApproximately: { code: 'EUR', symbol: '€' }, //default currency to show in crypto approximately
};

const storage_tokens = localStorage.getItem('tokens');
if (storage_tokens) {
  try {
    const json = JSON.parse(storage_tokens);
    initialState.access_token = json?.access_token;
    initialState.refresh_token = json?.refresh_token;
  } catch (e: unknown) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-expect-error
    console.log(e.message());
  }
}
const storage_fcm = localStorage.getItem('fcmToken');
if (storage_fcm) {
  initialState.fcm_token = storage_fcm;
}

export type UserSocial = {
  id: number;
  created: string;
  extra_data: string;
  modified: string;
  provider: string;
  uid: string;
  user_id: string;
  tags: string[];
  is_ncw: boolean;
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setBanned(state, action: PayloadAction<boolean>) {
      state.banned = action.payload;
    },
    checkAuth(state) {
      state.isAuthChecked = true;
    },
    unAuth(state) {
      state.isAuthChecked = false;
    },
    setRpc(state, action) {
      state.initRpc = action.payload;
    },
    setCon(state, action) {
      state.withdrawal_processing_list = action.payload.items.filter(
        (item: Withdrawal) => item.status === WithdrawalStatus.Pending
      );
      state.withdrawal_list = action.payload.items;
    },
    setRequest(state) {
      state.hasRequested = true;
    },
    setStatus(state, action) {
      state.rpcStatus = action.payload;
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setFcmToken(state, action) {
      state.fcm_token = action.payload;
    },
    setTimestamp(state, action) {
      state.timestamp = action.payload;
    },
    setNotificationStatus(state, action) {
      state.notification_status = action.payload;
    },
    setEggsPayments(state, action) {
      state.eggs_payments = action.payload;
    },
    setToken(state, action) {
      if (action.payload) {
        state.uid = action.payload.id;
        state.access_token = action.payload.access_token;
        state.refresh_token = action.payload.refresh_token;
      } else {
        state.access_token = '';
        state.refresh_token = '';
      }
    },
    clearToken(state) {
      state.access_token = '';
      state.refresh_token = '';
      localStorage.removeItem('tokens');
    },
    checkUserNew(state, action) {
      state.request = false;
      state.error = false;
      state.email = action.payload;
      state.id = action.payload.id;
      state.email = action.payload.email;
      state.tags = action.payload.tags;
      state.min_withdrawal_amount = action.payload.min_withdrawal_amount;
      state.ncw_min_withdrawal_amount = action.payload.ncw_min_withdrawal_amount;
      state.email_verified = action.payload.email_verified;
      state.currency = action.payload.currency;
      state.currency_changed_at = action.payload.currency_changed_at;
    },
    setCurrency(state, action) {
      state.currency = action.payload;
    },
    setForceDisableRating(state, action: PayloadAction<boolean>) {
      state.forceDisableRating = action.payload;
    },
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    setRatingMeta(state, action: PayloadAction<any>) {
      state.ratingMeta = action.payload;
    },
    setReviewRatingId(state, action: PayloadAction<string>) {
      state.reviewRatingId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAccessTokens.pending, (state) => {
        state.token_request = true;
        state.token_error = false;
      })
      .addCase(getAccessTokens.fulfilled, (state, action) => {
        state.token_request = false;
        if (action.payload) {
          localStorage.setItem('tokens', JSON.stringify(action.payload));
          state.access_token = action.payload.access_token;
          state.refresh_token = action.payload.refresh_token;
        } else {
          state.access_token = '';
          state.refresh_token = '';
          localStorage.removeItem('tokens');
        }
      })
      .addCase(getAccessTokens.rejected, (state, action) => {
        state.token_request = false;
        state.access_token = '';
        state.refresh_token = '';
        localStorage.removeItem('tokens');
        state.token_error = !!action.error;
      })
      .addCase(getTelegramAccessTokens.pending, (state) => {
        state.token_request = true;
        state.token_error = false;
      })
      .addCase(getTelegramAccessTokens.fulfilled, (state, action) => {
        state.token_request = false;
        localStorage.setItem('tokens', JSON.stringify(action.payload));
        state.access_token = action.payload.access_token;
        state.refresh_token = action.payload.refresh_token;
      })
      .addCase(getTelegramAccessTokens.rejected, (state, action) => {
        state.token_request = false;
        state.access_token = '';
        state.refresh_token = '';
        localStorage.removeItem('tokens');
        state.token_error = !!action.error;
      })
      .addCase(refreshToken.pending, (state) => {
        state.refresh_request = true;
        state.refresh_error = false;
      })
      .addCase(refreshToken.fulfilled, (state, action) => {
        state.refresh_request = false;
        localStorage.setItem('tokens', JSON.stringify(action.payload));
        state.access_token = action.payload.access_token;
        state.refresh_token = action.payload.refresh_token;
        state.uid = action.payload.id;
      })
      .addCase(refreshToken.rejected, (state, action) => {
        state.refresh_request = false;
        state.access_token = '';
        state.refresh_token = '';
        localStorage.removeItem('tokens');
        state.refresh_error = !!action.error;
        state.isAuthChecked = true;
      })
      .addCase(logoutToken.fulfilled, (state) => {
        state.uid = '';
        state.access_token = '';
        state.refresh_token = '';
        localStorage.removeItem('tokens');
      })
      .addCase(logoutToken.rejected, (state) => {
        state.uid = '';
        state.access_token = '';
        state.refresh_token = '';
        localStorage.removeItem('tokens');
      })
      // Other cases and handlers
      .addCase(getFcmToken.pending, (state) => {
        state.fcm_request = true;
        state.fcm_error = false;
      })
      .addCase(getFcmToken.fulfilled, (state, action) => {
        state.fcm_request = false;
        state.fcm_token = action.payload;
        localStorage.setItem('fcmToken', action.payload);
      })
      .addCase(getFcmToken.rejected, (state, action) => {
        state.fcm_request = false;
        state.fcm_token = '';
        localStorage.removeItem('fcmToken');
        state.fcm_error = !!action.error;
        error('An error occurred while retrieving fcm token.', action.error);
      });
  },
});

export const userAction = {
  setBanned: userSlice.actions.setBanned,
  setCon: userSlice.actions.setCon,
  setStatus: userSlice.actions.setStatus,
  setLoading: userSlice.actions.setLoading,
  setToken: userSlice.actions.setToken,
  clearToken: userSlice.actions.clearToken,
  checkUserNew: userSlice.actions.checkUserNew,
  setRequest: userSlice.actions.setRequest,
  setFcmToken: userSlice.actions.setFcmToken,
  setNotificationStatus: userSlice.actions.setNotificationStatus,
  setEggsPayments: userSlice.actions.setEggsPayments,
  checkAuth: userSlice.actions.checkAuth,
  unAuth: userSlice.actions.unAuth,
  setCurrency: userSlice.actions.setCurrency,
  setTimestamp: userSlice.actions.setTimestamp,
  setForceDisableRating: userSlice.actions.setForceDisableRating,
  setRatingMeta: userSlice.actions.setRatingMeta,
  setReviewRatingId: userSlice.actions.setReviewRatingId,
};

export const selectUser = (state: RootState) => state.user;

export default userSlice.reducer;
