export type SocialType =
  | 'facebook'
  // | 'facebook-messenger'
  | 'whatsapp'
  | 'telegram'
  | 'reddit'
  | 'pinterest'
  | 'linkedin'
  | 'vk'
  | 'line'
  | 'email'
  | 'tumblr'
  | 'twitter';

type SocialKey = 'tw' | 'vk' | 'fb' | 'wa' | 'vb' | 'pt' | 'li' | 'fbm' | 'rd' | 'tg' | 'th' | 'ln' | 'eml';

export const socialsKeysMap: Record<SocialType, SocialKey> = {
  facebook: 'fb',
  // 'facebook-messenger': 'fbm',
  whatsapp: 'wa',
  telegram: 'tg',
  reddit: 'rd',
  pinterest: 'pt',
  linkedin: 'li',
  vk: 'vk',
  line: 'ln',
  email: 'eml',
  tumblr: 'th',
  twitter: 'tw',
};

export interface ShareButtonOptions {
  url: string;
  title?: string;
  description: string;
  media?: string;
}

export const DEFAULT_SOCIALS: SocialType[] = [
  'whatsapp',
  'telegram',
  'facebook',
  'twitter',
  'reddit',
  'tumblr',
  'line',
  'linkedin',
  'pinterest',
  'email',
];
