import { MiningPoolPlan } from '@/shared/lib/models';
import { Currency } from '@/shared/lib/backend/JsonRpcApi';
import { formatPrice } from '@/page/cart/ui/v2/total-price/total-price.util';
import { formatBalance } from '@/shared/lib/utils/units';

export const formatPlanPrice = (
  plan?: MiningPoolPlan,
  cryptoBtc2userCurrencyRate?: number,
  currentCurrencyObj?: Currency
) => {
  const currentBtcPrice = (plan && formatPrice(parseFloat(plan.total_price) / plan.month)) || 0;
  const btc2UserCurrencyPrice = formatPrice(Number(currentBtcPrice) * (cryptoBtc2userCurrencyRate || 0));
  return currentCurrencyObj ? formatBalance(btc2UserCurrencyPrice, currentCurrencyObj.unit) : '0.00';
};
