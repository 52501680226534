import React from 'react';
import ReactDOM from 'react-dom/client';
import '@/shared/lib/utils/sentry';
import '@/shared/lib/utils/fcm';
import '@/app/i18n';
import '@/shared/lib/utils/mock.window.ct';
import App from '@/app';
import '@/shared/ui/styles/_global.scss';
import 'react-toastify/dist/ReactToastify.css';
import Modal from 'react-modal';

Modal.setAppElement('#root');
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(<App />);
