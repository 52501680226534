import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@/shared/store/types';

interface State {
  tutorialName?: string;
  step: number;
  clientRect: DOMRect | null;
}

const initialState: State = {
  tutorialName: undefined,
  step: -1,
  clientRect: null,
};

const tutorialSlice = createSlice({
  name: 'tutorial',
  initialState,
  reducers: {
    setClientRect(state, action) {
      state.clientRect = action.payload;
    },
    startTutorial(state: State, action: PayloadAction<string>) {
      state.step = 0;
      state.tutorialName = action.payload;
    },
    nextTutorialStep(state: State) {
      state.step += 1;
    },
    endTutorial(state: State) {
      state.tutorialName = undefined;
      state.step = -1;
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
  },
});

export type TutorialState = State;
export const tutorialReducer = tutorialSlice.reducer;

export const selectTutorial = (state: RootState) => state.tutorial;

export const { startTutorial, nextTutorialStep, endTutorial, setClientRect } = tutorialSlice.actions;
