import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '@/shared/store/types';
import { withdrawalAction } from '@/shared/store/withdrawal/withdrawal.slice';
import ModalLayout from '@/shared/ui/modal/modal';
import { Icon } from '@/shared/ui/icons';
import { Button } from '@/shared/ui/button';
import { WITHDRAW_PATH } from '@/shared/lib/utils/links';
import { CONFIRMED_EMAIL } from '@/shared/lib/constants/storage-key';
import { toaster } from '@/shared/ui/custom-toast/toaster';
import ncwalletIcon from '@/shared/ui/assets/unsupport-icons/nc-wallet.svg';
import styles from './confirm-ncw-modal.module.scss';

export const ConfirmNcwModal = ({
  onClose,
  isOpen,
  email,
  userId,
}: {
  onClose: (e: boolean) => void;
  isOpen: boolean;
  email: string;
  userId: string;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();

  const handleConfirm = () => {
    localStorage.setItem(CONFIRMED_EMAIL, JSON.stringify({ account_id: userId, email: email }));
    dispatch(withdrawalAction.addNewAddress({ account_id: userId, email: email }));
    toaster.success(t('Successfully_Connected'));
    navigate(WITHDRAW_PATH);
  };

  return (
    <ModalLayout background iconClose onClose={() => onClose(false)} isOpen={isOpen} id="confirm-ncw">
      <div className={styles.wrapper} onClick={() => onClose(false)}>
        <div className={styles.root} onClick={(e) => e.stopPropagation()}>
          <div className={styles.block}>
            <span className={styles.close} onClick={() => onClose(false)}>
              <Icon iconName="close" width="20" height="20" />
            </span>
            <img src={ncwalletIcon} alt="icon" width={80} height={80} className={styles.icon} />
            <h6 className={styles.title}>{t('Connect_NCW.Confirmation.Title')}</h6>
            <p className={styles.text}>
              <Trans
                i18nKey={t('Connect_NCW.Confirmation.Description')}
                components={{ b: <strong /> }}
                values={{ email, userId }}
              />
            </p>
          </div>
          <div className={styles.footer}>
            <Button color="blue" fullWidth onClick={handleConfirm}>
              <Icon iconName="checked" width="17" height="16" />
              {t('Balance_currency.Conversion.Confirm.Btn')}
            </Button>
            <Button color="blue" variant="outline" fullWidth onClick={() => onClose(false)}>
              {t('CANCEL')}
            </Button>
          </div>
        </div>
      </div>
    </ModalLayout>
  );
};
