import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type BrowserPromoLand = {
  id: number;
  category: string;
  url: string;
  image: string;
};

export type LandingsStore = {
  land: BrowserPromoLand[];
  poolLand: BrowserPromoLand[];
};

const initialState: LandingsStore = {
  land: [],
  poolLand: [],
};
export const landingsSlice = createSlice({
  name: 'landings',
  initialState,
  reducers: {
    setLand(store, payload: PayloadAction<{ land: BrowserPromoLand[] }>) {
      store.land = payload.payload.land;
    },
    setPoolLand(store, payload: PayloadAction<{ land: BrowserPromoLand[] }>) {
      store.poolLand = payload.payload.land;
    },
  },
});

export const { setLand, setPoolLand } = landingsSlice.actions;

export default landingsSlice.reducer;
