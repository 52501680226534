import { RefObject, useEffect } from 'react';

// fix for scroll issue on Android devices when there is a scrollable container with max-height
export const useScrollIssueFix = (containerRef: RefObject<HTMLDivElement>) => {
  let timer: number;

  const scrollHandler = () => {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      if (window.scrollY <= 0) {
        window.scrollTo(0, 1);
      }
    }, 100);
  };

  useEffect(() => {
    const isAndroid = /Android/i.test(navigator.userAgent);
    if (!isAndroid) return;

    containerRef.current?.addEventListener('scroll', scrollHandler);

    return () => {
      clearTimeout(timer);
      containerRef.current?.removeEventListener('scroll', scrollHandler);
    };
  }, []);
};
