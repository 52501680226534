import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';
import styles from './affiliate-slider.module.scss';
import { Icon } from '@/shared/ui/icons';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AffiliateEarnMore, AffiliateItem, useAffiliateInfo } from '@/features/affiliate';

export const AffiliateSlider = () => {
  const { t } = useTranslation();

  const { ctBrowser, ctFarm, ctFarmPro, ctPool, ncWallet } = useAffiliateInfo();

  return (
    <div className={styles.container}>
      <div className={styles.sectionTitleContainer}>
        <Link to={window.app_config.affiliateSiteURL} className={styles.title} target="_blank">
          {t('Affiliate Program')} <Icon iconName="arrow" width="20" height="20" />
        </Link>
      </div>
      <div className={styles.sliderContainer}>
        <Swiper
          modules={[Pagination]}
          autoHeight
          className={styles.swiperContainer}
          initialSlide={2}
          pagination={{
            dynamicBullets: true,
            dynamicMainBullets: 1,
            el: `.${styles.pagination}`,
          }}
          loop
        >
          <SwiperSlide className={styles.slideContainer}>
            <AffiliateItem affiliateInfo={ctFarmPro} />
          </SwiperSlide>
          <SwiperSlide className={styles.slideContainer}>
            <AffiliateItem affiliateInfo={ncWallet} />
          </SwiperSlide>
          <SwiperSlide className={styles.slideContainer}>
            <AffiliateEarnMore />
          </SwiperSlide>
          <SwiperSlide className={styles.slideContainer}>
            <AffiliateItem affiliateInfo={ctPool} />
          </SwiperSlide>
          <SwiperSlide className={styles.slideContainer}>
            <AffiliateItem affiliateInfo={ctBrowser} />
          </SwiperSlide>
          <SwiperSlide className={styles.slideContainer}>
            <AffiliateItem affiliateInfo={ctFarm} />
          </SwiperSlide>
        </Swiper>
        <div className={styles.paginationContainer}>
          <div className={styles.pagination} />
        </div>
      </div>
    </div>
  );
};
