export const IconPlay = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.8932 11.859L5.17648 18.3281C4.14113 18.8997 2.8384 18.5238 2.26676 17.4885C2.09178 17.1715 2 16.8154 2 16.4534L2 3.51545C2 2.33277 2.95875 1.37402 4.14143 1.37402C4.50344 1.37402 4.85954 1.4658 5.17646 1.64077L16.8932 8.10966C17.9286 8.68129 18.3045 9.98401 17.7329 11.0194C17.5377 11.3728 17.2467 11.6639 16.8932 11.859Z"
        fill="url(#paint0_linear_9771_294535)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.13281 18.3032L11.2408 9.83069L14.4781 13.1922L5.22923 18.2987C4.52762 18.6861 3.74945 18.6407 3.13281 18.3032Z"
        fill="url(#paint1_linear_9771_294535)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.3967 6.73099L5.22984 1.6699C4.56166 1.301 3.82405 1.32453 3.22266 1.61915L11.2404 9.83292L14.3967 6.73099Z"
        fill="url(#paint2_linear_9771_294535)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 5.46378L2.01319 3.33078C2.09795 2.55174 2.58259 1.93296 3.22096 1.61972L11.2396 9.8301L3.16154 18.3186C2.48811 17.9626 2 17.2611 2 16.3928V5.46378Z"
        fill="url(#paint3_linear_9771_294535)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_9771_294535"
          x1="6.88699"
          y1="1.37402"
          x2="6.88699"
          y2="8.07525"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FBDA09" />
          <stop offset="1" stopColor="#FFBC00" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_9771_294535"
          x1="7.39993"
          y1="8.54032"
          x2="2.13866"
          y2="16.3622"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F2414E" />
          <stop offset="1" stopColor="#E02554" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_9771_294535"
          x1="1.94003"
          y1="5.77217"
          x2="7.09665"
          y2="12.5993"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#28D781" />
          <stop offset="1" stopColor="#02F076" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_9771_294535"
          x1="2.8959"
          y1="17.1712"
          x2="9.63929"
          y2="16.1271"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#01E2FF" />
          <stop offset="1" stopColor="#01C4FF" />
        </linearGradient>
      </defs>
    </svg>
  );
};
