import { Button } from '@/shared/ui/button';
import { AppDispatch, RootState } from '@/shared/store/types';
import { useDispatch, useSelector } from 'react-redux';
import {
  checkPurchase,
  purchaseSubscription,
  useGoogleAvailablePurchase,
  useGoogleCurrentPlan,
  useGoogleIsAvailable,
  useGooglePurchaseStatus,
} from '@/page/cart/store/cart-google-store';
import { useTranslation } from 'react-i18next';
import { Icon } from '@/shared/ui/icons';
import { useNavigate } from 'react-router-dom';
import { useModalSet } from '@/shared/lib/context/modal/useModalSet';
import React, { useEffect } from 'react';
import { FixedLoader } from '@/shared/ui/loader/loader';
import s from './purchase-subs-button.module.scss';

export const PurchaseSubsButton = () => {
  const dispatch: AppDispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const status = useSelector(useGooglePurchaseStatus);
  const isAvailable = useSelector(useGoogleIsAvailable);
  const availablePurchase = useSelector(useGoogleAvailablePurchase);
  const activeId = useSelector((state: RootState) => state.googleCart.productId);
  const currentPlan = useSelector(useGoogleCurrentPlan);

  const { openModal } = useModalSet();
  const isLoading = status === 'loading';

  useEffect(() => {
    dispatch(checkPurchase(availablePurchase?.some((i) => i?.productId === activeId)));
  }, []);

  const handleClick = () => {
    dispatch(purchaseSubscription({ openModal, navigate }));
  };

  return (
    <>
      {isLoading && <FixedLoader />}
      <Button
        fullWidth
        color="green"
        onClick={handleClick}
        disabled={isLoading || isAvailable}
        loading={isLoading}
        className={s.button}
      >
        {isAvailable ? (
          <>
            <Icon iconName="checked" width={20} height={20} />
            {t('workerDetails.activated')}
          </>
        ) : (
          <>
            <Icon iconName="shoppingCart" width="20" height="20" /> {t('SUBSCRIBE')}{' '}
            <span className={s.price}>{currentPlan.month_price}</span>
          </>
        )}
      </Button>
    </>
  );
};
