import React, { useState } from 'react';
import styles from './black-friday-banner.module.scss';
import { BLACK__FRIDAY_LINK } from '@/shared/lib/utils/links';
import { Icon } from '@/shared/ui/icons';
import { useTranslation } from 'react-i18next';
import { Button } from '@/shared/ui/button';

const SKIP_BLACK_FRIDAY_KEY = 'skipBlackFriday';
// Hide black friday banner date
const HIDE_BANNER_DATE = new Date('2024-12-16T23:59:59');

const getDefaultState = () => {
  const skipBlackFriday = localStorage.getItem(SKIP_BLACK_FRIDAY_KEY) === 'true';
  const isAfterHideDate = new Date() > HIDE_BANNER_DATE;
  return !(skipBlackFriday || isAfterHideDate);
};

export const BlackFridayBanner = ({ participate }: { participate?: boolean }) => {
  const { t } = useTranslation();
  const [isVisible, setIsVisible] = useState(getDefaultState);
  const onCloseModal = () => {
    localStorage.setItem(SKIP_BLACK_FRIDAY_KEY, 'true');
    setIsVisible(false);
  };

  const onLinkClick = () => {
    window.open(BLACK__FRIDAY_LINK, '_blank');
  };

  if (!isVisible) {
    return null;
  }

  return (
    <div className="container">
      <div className={styles.root}>
        <span className={styles.close} onClick={onCloseModal}>
          <Icon iconName="close" width="12" height="12" />
        </span>
        <div className={styles.title}>{t('BF24.Banner.Short.Title')}</div>
        <div className={styles.text}>{t('BF24.Banner.Short.Descr')}</div>
        <div className={styles.button}>
          {participate ? (
            <Button color="green" fullWidth onClick={onLinkClick}>
              <Icon iconName="stats-icon" width="20" height="20" className={styles.icon} />
              {t('BF24.Banner.Btn')}
            </Button>
          ) : (
            <Button color="green" fullWidth onClick={onLinkClick}>
              {t('BF24.Banner.Short.Btn')}
              <Icon iconName="arrow" width="20" height="20" className={styles.icon} />
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};
