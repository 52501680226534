import { getFormattedPriceIos } from '@/shared/lib/utils/getFormattedPrice';
import {
  PlatformAndroidProductsResponse,
  PlatformIosAppstoreVerifyReceiptResponse,
} from '@/shared/lib/backend/JsonRpcApi';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@/shared/store/types';
import { TMiner } from '@/shared/store/pool-miner/pool-miner.slice';
import { MiningPoolPlanGoogle } from '@/shared/lib/models';
import { rpc } from '@/shared/lib/backend/Rpc';
import { ModalName } from '@/shared/lib/context/modal/modal-render';
import { NavigateFunction } from 'react-router/dist/lib/hooks';
import { HOME_PATH } from '@/shared/lib/utils/links';
import * as Sentry from '@sentry/browser';
import { IosPurchaseResponse } from './cart-store.model';
import { PlatformIosAppstorePurchasesRequest } from '@/shared/lib/backend/JsonRpcApi';

export type GooglePlan = {
  hps: number;
  hsh_reward?: number;
  hps_bonus: number;
  month: number;
  discount: number;
  promo_discount: number;
  promo_discount_till: number;
  month_base_price: number;
  month_price: string;
  total_price: string;
  frozen_price: string;
  usd_price: string;
  usd_profit: string;
  tags: string[];
  trial: boolean;
  trialPeriod: string;
  offerToken: string;
  productId: string;
  basePlanId: string;
  currentCurrency: string;
};

type GoogleCartData = {
  maxAvailableHashrate: number;
  hps: number[];
  hsh_reward?: number[];
  hps_bonus: number[];
  months: number[];
  plans: GooglePlan[];
};

type AppleCartDate = {
  maxAvailableHashrate: number;
  hps: number[];
  hsh_reward?: number[];
  hps_bonus: number[];
  months: number[];
  plans: GooglePlan[];
};

const YEAR = 'year';
const TRIAL = 'trial';
const MONTHS_OF_YEAR = 12;

function getMonth(interval: string, count: number) {
  let currentMonth = count;
  if (interval === YEAR) {
    currentMonth = count * MONTHS_OF_YEAR;
  }
  return currentMonth;
}

function createResultApple(
  data: PlatformAndroidProductsResponse,
  subs: GetSubscriptionsIosResponse,
  userHashrateSum: number
) {
  const result = {
    hps: [],
    hsh_reward: [],
    hps_bonus: [],
    months: [],
    plans: [],
    maxAvailableHashrate: 0,
  } as AppleCartDate;

  for (const item of data) {
    const hps = item?.settings?.hps;
    const hsh_reward = item?.settings?.hsh_reward;
    const hps_bonus = item?.settings?.hps_bonus;
    const month = getMonth(item?.duration?.interval, item?.duration?.count);
    const hasTrial = !!item?.offer_tags?.includes(TRIAL);
    const offers = subs?.find((s) => s?.productId === item?.sku);

    if (!offers) {
      continue;
    }

    const pricePerMonth = getFormattedPriceIos(offers?.currency, parseFloat(offers?.price) / month);
    const totalPrice = getFormattedPriceIos(offers?.currency, parseFloat(offers?.price));

    if (!result?.hps?.includes(hps)) {
      result?.hps?.push(hps);
    }

    if (!result?.hsh_reward?.includes(hsh_reward)) {
      result?.hsh_reward?.push(hsh_reward);
    }
    if (!result?.hps_bonus?.includes(hps_bonus)) {
      result?.hps_bonus?.push(hps_bonus);
    }

    if (!result?.months?.includes(month)) {
      result?.months?.push(month);
    }

    result.plans.push({
      hps: hps,
      hsh_reward: hsh_reward,
      hps_bonus: hps_bonus,
      month: month,
      discount: item?.discounts?.[0]?.value || 0,
      promo_discount: 0,
      promo_discount_till: 0,
      month_base_price: parseFloat(offers?.price) / month,
      month_price: pricePerMonth,
      total_price: totalPrice,
      frozen_price: '',
      usd_price: '',
      usd_profit: '',
      tags: item.tags,
      trial: hasTrial,
      trialPeriod: '',
      offerToken: offers?.productId,
      productId: item.sku,
      basePlanId: '',
      currentCurrency: offers.currency,
    });
  }

  result?.hps?.sort((a, b) => a - b);
  result?.hsh_reward?.sort((a, b) => a - b);
  result?.hps_bonus?.sort((a, b) => a - b);
  result?.months?.sort((a, b) => a - b);

  result.maxAvailableHashrate = (result?.hps[result?.hps?.length - 1] as number) - userHashrateSum;
  return result;
}

function createResultGoogle(
  data: PlatformAndroidProductsResponse,
  subs: GetSubscriptionsResponse,
  userHashrateSum: number
) {
  const result: GoogleCartData = {
    hps: [],
    hsh_reward: [],
    hps_bonus: [],
    months: [],
    plans: [],
    maxAvailableHashrate: 0,
  };

  const skus: string[] = [];

  for (const item of data) {
    if (skus.includes(item.sku)) {
      continue;
    }
    const hps = item.settings.hps;
    const hsh_reward = item.settings?.hsh_reward;
    const hps_bonus = item.settings?.hps_bonus;
    const month = getMonth(item?.duration?.interval, item?.duration?.count);
    const subscription = subs.find((s) => s.productId === item.sku);

    if (!subscription) {
      continue;
    }

    // Find the offer with 'current' in offerTags
    const subscription_offer_tags = [...(item?.offer_tags || []), item.plan_tag];

    const offer = subscription?.subscriptionOfferDetails?.find((offer) => {
      return (
        subscription_offer_tags.length == offer?.offerTags?.length &&
        subscription_offer_tags.reduce((all, tag) => all && offer?.offerTags?.includes(tag), true)
      );
    });

    if (!offer) {
      // If no offer is found, skip adding plan
      continue;
    }
    skus.push(item.sku);
    const hasTrial = !!item?.offer_tags?.includes(TRIAL);

    const pricingList = offer?.pricingPhases?.pricingPhaseList;
    const monthPrice = pricingList.find((p) => +p.priceAmountMicros !== 0);
    const monthPriceFormatted = pricingList?.find((p) => +p?.formattedPrice !== 0)?.formattedPrice || '0';
    // const pricePerMonth = getFormattedPrice(monthPrice!.priceCurrencyCode, +monthPrice!.priceAmountMicros);

    if (!result.hps.includes(hps)) {
      result.hps.push(hps);
    }

    if (!result?.hsh_reward?.includes(hsh_reward)) {
      result?.hsh_reward?.push(hsh_reward);
    }

    if (!result?.hps_bonus?.includes(hps_bonus)) {
      result?.hps_bonus?.push(hps_bonus);
    }

    if (!result.months.includes(month)) {
      result.months.push(month);
    }

    result.plans.push({
      hps: hps,
      hsh_reward: hsh_reward,
      hps_bonus: hps_bonus,
      month: month,
      discount: item?.discounts?.[0]?.value,
      promo_discount: 0,
      promo_discount_till: 0,
      month_base_price: 0,
      month_price: monthPriceFormatted,
      total_price: '',
      frozen_price: '',
      usd_price: '',
      usd_profit: '',
      tags: item.tags,
      trial: hasTrial,
      trialPeriod: '',
      offerToken: offer ? offer?.offerToken : '',
      productId: item.sku,
      basePlanId: offer ? offer.basePlanId : '',
      currentCurrency: monthPrice!.priceCurrencyCode,
    });
  }

  result.hps.sort((a, b) => a - b);
  result?.hsh_reward?.sort((a, b) => a - b);
  result?.hps_bonus?.sort((a, b) => a - b);
  result.months.sort((a, b) => a - b);

  result.maxAvailableHashrate = (result?.hps[result?.hps?.length - 1] as number) - userHashrateSum;

  return result;
}

export const makeGooglePlans = createAsyncThunk<
  void,
  PlatformAndroidProductsResponse,
  {
    state: RootState;
  }
>('googleCart/makeGooglePlans', async (r, { getState, dispatch }) => {
  const state = getState();
  const userHashrateSum = state.cart.list.reduce((acc, miner) => acc + miner?.hps, 0);
  dispatch(setStatus('loading'));

  try {
    if (window.ct) {
      const availablePurchase = await window.ct.getAvailablePurchases();
      const skus = new Set(
        r.map((i) => {
          return i?.['sku'];
        })
      );
      const subs = await window.ct.getSubscriptions([...skus]);
      const googleDTO = createResultGoogle(r, subs as GetSubscriptionsResponse, userHashrateSum);
      dispatch(setAvailablePurchase(availablePurchase));
      if (!state.googleCart.basePlanId) {
        const preferred = googleDTO?.plans?.find((p) => p?.hps === 75000 && p?.month === 3) || googleDTO?.plans?.[0];
        if (preferred) {
          dispatch(setGoogleHashrate({ hashrate: preferred?.hps }));
          dispatch(setGoogleMiningDuration({ miningDuration: preferred?.month }));
          dispatch(
            setGoogleInfo({
              productId: preferred?.productId,
              offerToken: preferred?.offerToken,
              basePlanId: preferred?.basePlanId,
              trial: preferred?.trial,
            })
          );
        }
      }
      dispatch(setGoogleData(googleDTO));
      dispatch(setStatus('idle'));
    }
  } catch (e) {
    dispatch(setStatus('error'));
    if (e instanceof Error) {
      console.error(e.message);
    }
  }
});

export const makeAppStorePlans = createAsyncThunk<
  void,
  {
    products: PlatformAndroidProductsResponse;
    isInit?: boolean;
  },
  {
    state: RootState;
  }
>('appleCart/makeAppStorePlans', async ({ products: r, isInit }, { getState, dispatch }) => {
  const state = getState();
  const userHashrateSum = state.cart.list.reduce((acc, miner) => acc + miner?.hps, 0);
  dispatch(setStatus('loading'));
  try {
    if (window.ct) {
      if (isInit && window.ct.clearTransactions) {
        await window.ct.clearTransactions();
      }
      dispatch(verifyReceiptIos());
      const skus = r?.map((i) => {
        return i?.['sku'];
      });
      const subs = await window.ct.getSubscriptions(skus);
      console.warn('subs window.ct.getSubscriptions', subs);
      const appleDTO = createResultApple(r, subs as GetSubscriptionsIosResponse, userHashrateSum);
      console.log('appleDTO', appleDTO);
      if (!state?.googleCart?.basePlanId) {
        const preferred = appleDTO?.plans?.find((p) => p?.hps === 75000 && p?.month === 3) || appleDTO?.plans?.[0];
        if (preferred) {
          dispatch(setGoogleHashrate({ hashrate: preferred?.hps }));
          dispatch(setGoogleMiningDuration({ miningDuration: preferred?.month }));
          dispatch(
            setGoogleInfo({
              productId: preferred?.productId,
              offerToken: preferred?.offerToken,
              basePlanId: preferred?.basePlanId,
              trial: preferred?.trial,
            })
          );
        }
      }
      dispatch(setGoogleData(appleDTO));
      dispatch(setStatus('idle'));
    }
  } catch (e) {
    dispatch(setStatus('error'));
    if (e instanceof Error) {
      console.log(e.message);
    }
  }
});

type TOpenModal = (
  name: ModalName,
  // eslint-disable-next-line
  options?: any
) => void;

type purchaseSubscriptionProps = {
  navigate: NavigateFunction;
  openModal: TOpenModal;
};

function checkId(userId: number | undefined) {
  if (!userId) throw Error('Unauthorized');
}

export const purchaseSubscription = createAsyncThunk<
  void,
  purchaseSubscriptionProps,
  {
    state: RootState;
  }
>('googleCart/purchaseSubscription', async ({ navigate, openModal }, { getState, dispatch }) => {
  dispatch(setPurchaseStatus('loading'));
  const state = getState();
  const productId = state.googleCart.productId;
  const offerToken = state.googleCart.offerToken;
  const basePlanId = state.googleCart.basePlanId;
  const hasTrial = state.googleCart.trial;
  const utmParams = state.utm.utmObj;

  try {
    const offerId = await rpc.transmit('platform.android.products.offers.buy', {
      offer_id: hasTrial ? 'trial' : '',
      plan_id: basePlanId ? basePlanId : '',
      sku: productId ? productId : '',
      utm: utmParams,
    });
    const userId = state.user.id;

    // TODO не смог воспроизвести когда userId === undefined поставил иключение, можно лучше!
    if (!userId) {
      Sentry.captureException(`Invalid userId - state.user: ${state.user}`);
    }
    checkId(userId);
    const obfuscatedAccountIdAndroid = `${userId}`;
    const obfuscatedProfileIdAndroid = JSON.stringify({
      m: offerId,
      // TODO поменять при деплое на прод
      // env: development ? 'dev' : 'prod',
      // env: import.meta.env.MODE !== 'production' ? 'dev' : 'prod',
      // env: 'dev',
    });

    const requestData = await window.ct.requestSubscription({
      sku: productId!,
      offerToken: offerToken!,
      obfuscatedAccountIdAndroid: obfuscatedAccountIdAndroid,
      obfuscatedProfileIdAndroid: obfuscatedProfileIdAndroid,
    });

    const dataRequestGooglePlay = {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      purchases: [{ data: requestData?.[0]?.dataAndroid, sign: requestData?.[0]?.signatureAndroid }],
    };
    await rpc.transmit('platform.android.google_play.purchases', dataRequestGooglePlay);
    const lastVisit = localStorage.getItem('lastVisitRating');
    const today = new Date();
    if (!lastVisit) {
      localStorage.setItem('lastVisitRating', today.toString());
    }
    const availablePurchase = await window.ct.getAvailablePurchases();
    dispatch(setPurchaseStatus('idle'));
    dispatch(setAvailablePurchase(availablePurchase));
    const products = await rpc.transmit('platform.android.products', {});
    dispatch(makeGooglePlans(products));
    navigate(HOME_PATH);
  } catch (e) {
    dispatch(setPurchaseStatus('error'));
    if (e instanceof Error) {
      if (
        e.message === 'Trial is not available' ||
        e.message === 'SKU is not found' ||
        e.message === 'User not found' ||
        e.message === 'User banned' ||
        e.message === 'Unauthorized'
      )
        openModal('MODAL_ERROR');

      console.log(e.message);
    }
  }
});

export const purchaseSubscriptionIos = createAsyncThunk<
  void,
  purchaseSubscriptionProps,
  {
    state: RootState;
  }
>('appleCart/purchaseSubscriptionIos', async ({ navigate, openModal }, { getState, dispatch }) => {
  dispatch(setPurchaseStatus('loading'));
  const state = getState();
  const productId = state.googleCart?.productId;

  if (window?.ct?.clearTransactions) {
    await window.ct.clearTransactions();
  }

  try {
    const requestData = (await window.ct.requestSubscription({
      sku: productId!,
    })) as IosPurchaseResponse;
    console.log('requestData', requestData);
    if (requestData?.transactionReceipt || requestData?.verificationResultIOS) {
      const dataRequestAppStore: PlatformIosAppstorePurchasesRequest = {
        receipt: requestData?.transactionReceipt || requestData?.verificationResultIOS,
      };
      console.warn('dataRequestAppStore', dataRequestAppStore);
      try {
        await rpc.transmit('platform.ios.appstore.purchases', dataRequestAppStore);
      } catch (e) {
        console.error('purchaseSubscriptionIosRequest error:', e);
      }
    }

    const lastVisit = localStorage.getItem('lastVisitRating');
    const today = new Date();
    if (!lastVisit) {
      localStorage.setItem('lastVisitRating', today.toString());
    }
    dispatch(verifyReceiptIos());
    dispatch(setPurchaseStatus('idle'));
    const products = await rpc.transmit('platform.ios.products', {});
    console.log('products', products);
    dispatch(makeAppStorePlans({ products }));
    navigate(HOME_PATH);
  } catch (e) {
    console.error('purchaseSubscriptionIosRequest error:', e);
    dispatch(setPurchaseStatus('error'));
    if (e instanceof Error) {
      if (
        e.message === 'Trial is not available' ||
        e.message === 'SKU is not found' ||
        e.message === 'User not found' ||
        e.message === 'User banned' ||
        e.message === 'Unauthorized'
      )
        openModal('MODAL_ERROR');

      console.log(e.message);
    }
  }
});

export const verifyReceiptIos = createAsyncThunk<
  void,
  void,
  {
    state: RootState;
  }
>('appleCart/purchaseSubscriptionIos', async (_, { getState, dispatch }) => {
  dispatch(setPurchaseStatus('loading'));
  const state = getState();
  let receipt: string | undefined = state.googleCart.lastReceiptIOS?.latest_receipt;

  if (!receipt && window?.ct?.getReceipt) {
    receipt = await window.ct.getReceipt();
    console.warn('receiptIOS', receipt);
  }

  if (!receipt) {
    let availablePurchaseIos = state.googleCart.availablePurchaseIOS;
    if (!availablePurchaseIos) {
      try {
        const availablePurchase = await window?.ct?.getAvailablePurchases();
        console.warn('availablePurchaseIOS', availablePurchase);
        dispatch(setAvailablePurchaseIos(availablePurchase));
        availablePurchaseIos = state.googleCart.availablePurchaseIOS;
      } catch (e) {
        console.error('availablePurchaseIOS error', e);
      }
    }
    if (availablePurchaseIos?.length) {
      const lastPurchase = availablePurchaseIos?.reduce((latest, purchase) => {
        return purchase.transactionDate > latest.transactionDate && purchase.transactionReceipt ? purchase : latest;
      }, availablePurchaseIos[0]);
      receipt = lastPurchase?.transactionReceipt;
    }
  }

  if (receipt) {
    try {
      const receiptResponse: PlatformIosAppstoreVerifyReceiptResponse = await rpc.transmit(
        'platform.ios.appstore.verify_receipt',
        { receipt }
      );
      console.warn('verifyReceiptIOS', receiptResponse);
      dispatch(setLastReceiptIos(receiptResponse));
    } catch (e) {
      console.error('verifyReceiptIosRequest error:', e);
    }
  }
  dispatch(setPurchaseStatus('idle'));
});

type status = 'idle' | 'loading' | 'error' | 'successful';

type availablePurchase = {
  autoRenewingAndroid: boolean;
  obfuscatedProfileIdAndroid: string;
  packageNameAndroid: string;
  purchaseStateAndroid: number;
  developerPayloadAndroid: string;
  isAcknowledgedAndroid: boolean;
  signatureAndroid: string;
  orderId: string;
  obfuscatedAccountIdAndroid: string;
  productId: string;
  transactionReceipt: string;
  transactionId: string;
  transactionDate: number;
  purchaseToken: string;
  productIds: string;
};

export type availablePurchaseIos = {
  countryCode: string;
  currency: string;
  description: string;
  discounts: string[];
  introductoryPrice: string;
  introductoryPriceAsAmountIOS: string;
  introductoryPriceNumberOfPeriodsIOS: string;
  introductoryPricePaymentModeIOS: string;
  introductoryPriceSubscriptionPeriodIOS: string;
  localizedPrice: string;
  platform: 'ios';
  transactionReceipt?: string;
  price: string;
  productId: string;
  subscriptionPeriodNumberIOS: string;
  originalTransactionDateIOS: number;
  transactionDate: number;
  subscriptionPeriodUnitIOS: string;
  title: string;
  type: 'subs';
};

export type receiptIOS = PlatformIosAppstoreVerifyReceiptResponse;

export interface googleCartStore {
  miningDuration: number | null;
  hashrate: number | null;
  productId: string | null;
  offerToken: string | null;
  basePlanId: string | null;
  googleData: GoogleCartData | null;
  trial: boolean | null;
  status: status;
  purchaseStatus: status;
  plansLoading: status;
  errorMessage: string | null;
  list: TMiner[];
  isAvailable: boolean;
  availablePurchase: availablePurchase[] | null;
  availablePurchaseIOS: availablePurchaseIos[] | null;
  lastReceiptIOS: receiptIOS | null;
}

const initialState: googleCartStore = {
  miningDuration: null,
  hashrate: null,
  googleData: null,
  productId: null,
  offerToken: null,
  basePlanId: null,
  list: [],
  status: 'idle',
  purchaseStatus: 'idle',
  errorMessage: null,
  trial: null,
  isAvailable: false,
  availablePurchase: null,
  availablePurchaseIOS: null,
  lastReceiptIOS: null,
  plansLoading: 'loading',
};

const googleCartStoreSlice = createSlice({
  name: 'googleCart',
  initialState,
  reducers: {
    setGoogleMiningDuration(state, { payload }: PayloadAction<{ miningDuration: number }>) {
      state.miningDuration = payload.miningDuration;
    },
    setGoogleInfo(
      state,
      {
        payload,
      }: PayloadAction<{
        productId: string;
        offerToken: string;
        basePlanId: string;
        trial: boolean;
      }>
    ) {
      state.productId = payload?.productId;
      state.offerToken = payload?.offerToken;
      state.basePlanId = payload?.basePlanId;
      state.trial = payload?.trial;
    },
    setGoogleHashrate(state, { payload }: PayloadAction<{ hashrate: number }>) {
      state.hashrate = payload.hashrate;
    },
    setAvailablePurchase(state, action) {
      state.availablePurchase = action.payload;
    },
    setAvailablePurchaseIos(state, action) {
      state.availablePurchaseIOS = action.payload;
    },
    setLastReceiptIos(state, { payload }: PayloadAction<receiptIOS>) {
      state.lastReceiptIOS = payload;
    },
    setCartList(state, action) {
      state.list = action.payload;
    },
    setStatus(state, action) {
      state.status = action.payload;
    },
    checkPurchase(state, action) {
      state.isAvailable = action.payload;
    },
    setPurchaseStatus(state, action) {
      state.purchaseStatus = action.payload;
    },
    setGoogleData(state, action: PayloadAction<GoogleCartData>) {
      state.googleData = action.payload;
      state.status = 'idle';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(makeGooglePlans.pending, (state) => {
        state.plansLoading = 'loading';
      })
      .addCase(makeGooglePlans.fulfilled, (state) => {
        state.plansLoading = 'idle';
      })
      .addCase(makeGooglePlans.rejected, (state) => {
        state.plansLoading = 'error';
      })
      .addCase(makeAppStorePlans.pending, (state) => {
        state.plansLoading = 'loading';
      })
      .addCase(makeAppStorePlans.fulfilled, (state) => {
        state.plansLoading = 'idle';
      })
      .addCase(makeAppStorePlans.rejected, (state) => {
        state.plansLoading = 'error';
      });
  },
});

export const useGoogleHashrate = (state: RootState) => state.googleCart.hashrate as number;
export const useGoogleIsAvailable = (state: RootState) => state.googleCart.isAvailable;
export const useGoogleAvailablePurchase = (state: RootState) => state.googleCart.availablePurchase;
export const useAppleAvailablePurchase = (state: RootState) => state.googleCart.availablePurchaseIOS;
export const useGooglePlanStatus = (state: RootState) => state.googleCart.plansLoading;
export const useGoogleHashrates = (state: RootState) => state.googleCart.googleData?.hps as number[];
export const useGoogleStatus = (state: RootState) => state.googleCart.status;
export const useGoogleData = (state: RootState) => state.googleCart.googleData;
export const useGooglePurchaseStatus = (state: RootState) => state.googleCart.purchaseStatus;
export const useGoogleMiningDurations = (state: RootState) => state.googleCart.googleData?.months as number[];

export const useGoogleCurrentPlan = (state: RootState) => {
  const hashrate = state.googleCart.hashrate as number;
  const duration = state.googleCart.miningDuration as number;
  return state.googleCart.googleData?.plans.find(
    (plan) => plan?.hps === hashrate && plan?.month === duration
  ) as MiningPoolPlanGoogle;
};

export const {
  setGoogleData,
  setGoogleHashrate,
  setGoogleMiningDuration,
  setCartList,
  setGoogleInfo,
  setStatus,
  setPurchaseStatus,
  setAvailablePurchase,
  setAvailablePurchaseIos,
  setLastReceiptIos,
  checkPurchase,
} = googleCartStoreSlice.actions;
export const googleCartReducer = googleCartStoreSlice.reducer;
