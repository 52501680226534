import { useTranslation } from 'react-i18next';
import ModalLayout from '@/shared/ui/modal/modal';
import styles from './add-pool-miner-modal.module.scss';
import { Icon } from '@/shared/ui/icons';
import { Button } from '@/shared/ui/button';
import promoImage from './image/promo-image.png';
import { useNavigate } from 'react-router-dom';
import { CART_PATH } from '@/shared/lib/utils/links';

export const AddPoolMinerModal = ({ onClose, isOpen }: { onClose: (e: boolean) => void; isOpen: boolean }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const onCloseModal = () => {
    const currentDate: Date = new Date();
    localStorage.setItem('lastShownDatePoolMinerModal', currentDate.toISOString());
    onClose(false);
  };
  return (
    <ModalLayout background iconClose onClose={() => onClose(false)} isOpen={isOpen} id="add-pool-miner">
      <div className={styles.root}>
        <div className={styles.block}>
          <span className={styles.close} onClick={onCloseModal}>
            <Icon iconName="close" width="20" height="20" />
          </span>
          <div className={styles.top}>
            <img src={promoImage} alt="" />
          </div>
          <div className={styles.mid}>
            <h6 className={styles.title}>{t('First_modal.Title')}</h6>
            <p className={styles.text}>{t('First_modal.Description')}</p>
          </div>
        </div>
        <div className={styles.footer}>
          <Button color="blue" fullWidth onClick={() => navigate(CART_PATH)}>
            <Icon iconName="plus" width="20" height="20" />
            {t('ADD POOL MINER')}
          </Button>
        </div>
      </div>
    </ModalLayout>
  );
};
