import React from 'react';
import styles from './explore-products-notification.module.scss';
import { useExploreProductsNotification } from './use-explore-products-notification';
import { Alert } from '@/shared/ui/alert';
import { Icon } from '@/shared/ui/icons';
import { useTranslation } from 'react-i18next';

export const ExploreProductsNotification = () => {
  const { visible, close } = useExploreProductsNotification();
  const { t } = useTranslation();

  if (!visible) {
    return null;
  }

  return (
    <Alert variant="default" className={styles.container}>
      {t('Products.General_description')}
      <Icon iconName={'close-notification'} className={styles.closeIcon} width={20} height={20} onClick={close} />
    </Alert>
  );
};
