import { CreateWithdraw } from '@/page/withdraw/ui/create-withdraw/create-withdraw';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/shared/store/types';
import { EmailVerify } from '@/page/withdraw/ui/email-verify/email-verify';
import { BalanceBlock } from '@/page/withdraw/ui/balance-block/balance-block';
import { EmptyBalance } from '@/page/withdraw/ui/empty-balance/empty-balance';
import { selectUser } from '@/shared/store/user/user.slice';
import { useEffect, useMemo } from 'react';
import { useModalSet } from '@/shared/lib/context/modal/useModalSet';
import { BONUS, MINING, SwitchWithdrawalTabs } from '@/page/withdraw/ui/switch-withdrawal-tabs/switch-withdrawal-tabs';
import { useNavigate, useParams } from 'react-router-dom';
import { setIsMining } from '@/shared/store/balance/balance.slice';
import { WITHDRAW_PATH } from '@/shared/lib/utils/links';

export const WithdrawPage = () => {
  const balance = useSelector((store: RootState) => store.balance);
  const { email_verified, currency: userCurrency, ncw_min_withdrawal_amount, tags } = useSelector(selectUser);

  const dispatch = useDispatch();
  const { openModal } = useModalSet();
  const { MiningOrBonus } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (!tags.includes('withdraw_suspend') || tags.includes('withdraw_access')) return;

    openModal('MODAL_WITHDRAW_SUSPENDED');
  }, [tags]);

  useEffect(() => {
    if (MiningOrBonus) {
      dispatch(setIsMining(MiningOrBonus === MINING));
    } else {
      navigate(`${WITHDRAW_PATH}/${balance.is_mining ? MINING : BONUS}`);
    }
  }, [MiningOrBonus]);

  const currentCurrencyObj = useMemo(
    () => balance?.currencies?.find((cur) => cur?.symbol === userCurrency),
    [balance.currencies, userCurrency]
  );
  const minAmount = useMemo(
    () =>
      balance.is_mining
        ? currentCurrencyObj?.min_withdrawal || ncw_min_withdrawal_amount.toString()
        : balance?.hsh_currency?.min_withdrawal.toString() || ncw_min_withdrawal_amount.toString(),
    [currentCurrencyObj?.min_withdrawal, ncw_min_withdrawal_amount, balance.hsh_currency, balance.is_mining]
  );

  if (!email_verified) {
    return <EmailVerify />;
  }

  return (
    <div>
      <SwitchWithdrawalTabs isMining={balance.is_mining} />
      <div>
        {(+balance.pool_balance.total > 0 && balance.is_mining) ||
        (+balance.hsh_balance.total > 0 && !balance.is_mining) ? (
          <>
            <CreateWithdraw isMining={balance.is_mining} />
          </>
        ) : (
          <>
            <EmptyBalance minAmount={minAmount} userCurrency={balance.is_mining ? userCurrency : 'HSH'} />
            {balance.is_mining && <BalanceBlock />}
          </>
        )}
      </div>
    </div>
  );
};
