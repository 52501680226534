import React, { RefObject } from 'react';
import styles from './slider.module.scss';
import cn from 'classnames';
import { SwiperRef } from 'swiper/swiper-react';

export type BulletProps = {
  index: number;
  bulletsRef: RefObject<SwiperRef>;
  currentSlideIndex: number;
  isModalOpen: boolean;
};

export function Bullet(props: BulletProps) {
  return (
    <span
      className={cn([
        styles.swiper__bullet,
        props.bulletsRef.current?.swiper.activeIndex === props.index && styles.swiper__bullet_active,
        props.currentSlideIndex > props.index && styles.swiper__slide_filled,
        props.isModalOpen && styles.swiper__bullet_paused,
      ])}
    >
      <i></i>
      <b></b>
    </span>
  );
}
