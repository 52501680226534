import Decimal from 'decimal.js';
import { getLanguage } from '@/shared/store/language';

export function getFormattedPrice(currencyCode: string, price: number) {
  const priceFormatted = price / 1_000_000;
  const lang = getLanguage();
  return new Intl.NumberFormat(lang, { style: 'currency', currency: currencyCode }).format(priceFormatted);
}
export function getFormattedPriceIos(currencyCode: string, price: number) {
  const lang = getLanguage();
  return new Intl.NumberFormat(lang, { style: 'currency', currency: currencyCode }).format(price);
}

export function getFormattedLocaleNumber(price: number | string | Decimal): string {
  const lang = getLanguage();

  let numericValue: number;

  if (typeof price === 'number') {
    numericValue = price;
  } else if (typeof price === 'string') {
    const parsed = parseFloat(price);
    if (isNaN(parsed)) {
      throw new Error(`Invalid price string: ${price}`);
    }
    numericValue = parsed;
  } else if (price instanceof Decimal) {
    numericValue = price.toNumber();
  } else {
    throw new Error(`Unsupported type: ${typeof price}`);
  }

  return numericValue > 1 ? new Intl.NumberFormat(lang, { style: 'decimal' }).format(numericValue) : `${numericValue}`;
}
