import { Icon } from '@/shared/ui/icons';
import { useTranslation } from 'react-i18next';
import styles from './empty-history.module.scss';

export const EmptyHistory = () => {
  const { t } = useTranslation();
  return (
    <div>
      <div className={styles.contentContainer}>
        <Icon iconName="timerHistory" width={80} height={80} />
        <h5 className={styles.title}>{t('Payment_hist.No.Title')}</h5>
        <p className={styles.description}>{t('Payment_hist.No.Description')}</p>
      </div>
    </div>
  );
};
