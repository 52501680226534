import styles from './limit-view.module.scss';
import { Icon } from '@/shared/ui/icons';
import { useTranslation } from 'react-i18next';

export const LimitView = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.block}>
      <Icon iconName="waitClock" width={80} height={80} className={styles.block__icon} />
      <div className={styles.block__title}>{t('Withdrawal requests limit is reached')}</div>
      <div className={styles.block__desc}>
        {t(
          'You have reached the limit of 10 simultaneous withdrawals. To request a new one, please wait for the current withdrawal to be completed or cancel it.'
        )}
      </div>
    </div>
  );
};
