import { useTranslation } from 'react-i18next';
import { Icon } from '@/shared/ui/icons';
import styles from './email-verify.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '@/shared/store/types';
import cn from 'classnames';
import { useState } from 'react';
import { Button } from '@/shared/ui/button';
import { EmailCheck } from '@/page/withdraw/ui/email-verify/email-check';
import { confirmEmail, selectWithdrawal } from '@/shared/store/withdrawal/withdrawal.slice';

export const EmailVerify = () => {
  const email = useSelector((state: RootState) => state.user.email);
  const withdrawal = useSelector(selectWithdrawal);
  const dispatch: AppDispatch = useDispatch();
  const [value, setValue] = useState(email);
  const [error, setError] = useState<string | null>(null);
  const { t } = useTranslation();
  const isLoading = withdrawal.sendEmailStatus === 'loading';

  function isValidEmail(email: string) {
    const regex = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    return regex.test(email);
  }

  const handleClick = () => {
    if (isValidEmail(value)) {
      dispatch(confirmEmail({ email: value }));
    } else {
      setError(t('InvalidEmail'));
    }
  };

  if (withdrawal.sendEmail) {
    return <EmailCheck />;
  }

  return (
    <div className={styles.root}>
      <div className={styles.block}>
        <Icon iconName="email-verify" width={80} height={80} />
        <h6 className={styles.title}>{t('EmailVerification.Title2')}</h6>
        <p className={styles.text}>{t('verify.email.text')}</p>
        <label className={styles.label}>
          <input
            type="email"
            className={cn(styles.label__input, error && styles.error)}
            value={value}
            placeholder={t('Pos_Vibes.Enter_mail')}
            onChange={(e) => setValue(e.target.value)}
          />
          {error && <span className={styles.label__error}>{error}</span>}
        </label>
      </div>
      <div className={styles.footer}>
        <Button color="blue" fullWidth onClick={handleClick} disabled={isLoading} loading={isLoading}>
          <Icon iconName="email" width="24" height="24" />
          {t('Confirm')}
        </Button>
      </div>
    </div>
  );
};
