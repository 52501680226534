import { ConnectionError } from './ConnectionError';
import { AbortedConnection } from './AbortedConnection';
import type {
  JRpcMethodCallback,
  JRpcMethodParam,
  JRpcMethodReturn,
  JsonRpcRequest,
  JsonRpcResponse,
  ManyFn,
} from './types';
import { JsonRpcException } from './JsonRpcException';
import * as Sentry from '@sentry/browser';

interface QueueItem<M extends object, E extends keyof M = keyof M> {
  method: E;
  params: JRpcMethodParam<M[E]>;
  nodes?: boolean;

  resolve?(value: JRpcMethodReturn<M[E]>): void;

  reject?(reason?: unknown): void;
}

// -------------------------------------------------------------------

export const CLOSED = 'closed' as const;
export const CONNECTING = 'connecting' as const;
export const OPEN = 'open' as const;
export type JsonRpcStatus = typeof CLOSED | typeof CONNECTING | typeof OPEN;

interface JsonRpcDispatch {
  open(): Promise<void>;

  message(param: { in?: string; out?: string }): Promise<void>;

  close(): Promise<void>;

  error(e: unknown): Promise<void>;
}

type JsonRpcDispatchListeners = ManyFn<JsonRpcDispatch>;

// -------------------------------------------------------------------

let instanceIdSequence = 0;

export class JsonRpc<M extends object, J extends object> {
  static debug = import.meta.env.MODE !== 'production';
  private readonly _instanceId = ++instanceIdSequence;
  private readonly _url: string;
  private readonly _protocols: string | string[] | undefined;
  private _socket: WebSocket | null;
  private _openPromise: Promise<WebSocket> | undefined;
  private _closePromise: Promise<void> | undefined;
  private _to_queue: boolean;
  private _queue: QueueItem<M>[];
  private _rpc_id: number;
  private _calls: Record<
    string,
    {
      resolve?(value: unknown): void;
      reject?(reason?: unknown): void;
    }
  >;
  private _status: JsonRpcStatus;
  private readonly _listeners: JsonRpcDispatchListeners;
  private readonly _methods: Partial<Record<keyof J, JRpcMethodCallback<unknown>>>;

  constructor(url: string, protocols?: string | string[]) {
    this._log('constructor');
    this._protocols = protocols;
    this._url = url;

    this._socket = null;
    this._to_queue = true;
    this._queue = [];
    this._rpc_id = 0;
    this._calls = {};
    this._status = CLOSED;

    this._listeners = {
      open: [],
      message: [],
      close: [],
      error: [],
    };
    this._methods = {};
  }

  get url(): string {
    return this._url;
  }

  get status(): JsonRpcStatus {
    return this._status;
  }

  on<K extends keyof JsonRpcDispatch>(type: K, callback: JsonRpcDispatch[K]): void {
    if (this._listeners[type]) {
      this._listeners[type].push(callback);
    }
  }

  off<K extends keyof JsonRpcDispatch>(type: K, callback?: JsonRpcDispatch[K]): void {
    type = String(type) as K;
    if (typeof callback !== 'function') {
      this._listeners[type].length = 0;
    } else {
      // eslint-disable-next-line no-constant-condition
      while (true) {
        const index = this._listeners[type].indexOf(callback);
        if (index >= 0) {
          this._listeners[type].splice(index, 1);
        } else {
          break;
        }
      }
    }
  }

  onmethod<E extends keyof J>(method: E, callback: JRpcMethodCallback<JRpcMethodParam<J[E]>>): void {
    if (typeof callback === 'function') {
      method = String(method) as E;
      this._methods[method] = callback as unknown as JRpcMethodCallback<unknown>;
    }
  }

  offmethod<E extends keyof J>(method: E, callback?: JRpcMethodCallback<JRpcMethodParam<J[E]>>): void {
    method = String(method) as E;
    if (typeof callback !== 'function' || this._methods[method] === callback) {
      delete this._methods[method];
    }
  }

  async transmit<E extends keyof M>(
    method: E,
    params: JRpcMethodParam<M[E]>,
    nores = false
  ): Promise<JRpcMethodReturn<M[E]>> {
    this._log('transmit', { method, params, nores });
    if (this._openPromise) {
      await this._openPromise;
    }
    this._assertStatus(OPEN);
    const socket = this._assertOpenSocket();
    const id = nores ? undefined : ++this._rpc_id;
    const request: JsonRpcRequest = {
      jsonrpc: '2.0',
      method: String(method),
      id,
      params,
    };
    const data = JSON.stringify(request);

    if (id === undefined) {
      await this._dispatch('message', { out: data });
      socket.send(data);
      return undefined as JRpcMethodReturn<M[E]>;
    }
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<JRpcMethodReturn<M[E]>>(async (resolve, reject) => {
      try {
        this._calls[`id${id}`] = {
          resolve: resolve as (value: unknown) => void,
          reject,
        };
        await this._dispatch('message', { out: data });
        socket.send(data);
      } catch (e) {
        reject(e);
      }
    });
  }

  push<E extends keyof M>(method: E, params: JRpcMethodParam<M[E]>, nores = false): Promise<JRpcMethodReturn<M[E]>> {
    if (!this._to_queue && this._status === OPEN) {
      return this.transmit(method, params, nores);
    }
    if (nores) {
      this._queue.push({ method, params });
      return Promise.resolve() as Promise<JRpcMethodReturn<M[E]>>;
    }
    return new Promise((resolve, reject) => {
      this._queue.push({ method, params, resolve, reject });
    });
  }

  async close(): Promise<void> {
    this._log('closing...');
    if (this._closePromise) {
      return this._closePromise;
    }
    const status = this._status;
    const socket = this._socket;
    if (status === CLOSED || !socket) {
      return;
    }
    this._closePromise = new Promise<void>((resolve) => {
      socket.addEventListener(
        'close',
        () => {
          resolve();
          this._closePromise = undefined;
        },
        { once: true }
      );
      socket.close();
    });
    return this._closePromise;
  }

  async open(): Promise<void> {
    this._log('open...');
    if (this._openPromise) {
      const isSuccess = await this._openPromise.then(
        () => true,
        () => false
      );
      if (isSuccess) {
        return;
      }
    }
    if (this._closePromise) {
      await this._closePromise.then(
        () => true,
        () => true
      );
      this._closePromise = undefined;
    }
    this._to_queue = true;
    this._status = CONNECTING;
    try {
      const socket = await this._openSocket();
      this._log('open... _openSocket() - ok');
      socket.addEventListener('error', (e) => {
        this._log('open... socket.on-error()');
        this._dispatch('error', e).catch(() => {});
      });
      socket.addEventListener('close', this._handleSocketClose);
      socket.addEventListener('message', this._handleSocketMessage);

      this._socket = socket;
      this._rpc_id = 0;
      this._calls = {};
      this._status = OPEN;
      await this._dispatch('open');
      this._resolveQueue();
      this._to_queue = false;
    } catch (e) {
      this._log('open rejects', e);
      this._rejectQueue();
      this._status = CLOSED;
      await this._dispatch('error', e);
      throw e;
    }
  }

  private _assertNotStatus(notExpected: JsonRpcStatus) {
    if (this._status === notExpected) {
      throw new ConnectionError(`invalid status ${this._status}`);
    }
  }

  private _assertStatus(expected: JsonRpcStatus) {
    if (this._status !== expected) {
      // throw new ConnectionError(`invalid status ${this._status}, expected ${expected}`);
      console.log(`invalid status ${this._status}, expected ${expected}`);
      Sentry.captureException(`invalid status ${this._status}, expected ${expected}`);
    }
  }

  private _assertOpenSocket(): WebSocket {
    if (!this._socket) {
      throw new ConnectionError(`Socket is null, expected not null.`);
    }
    return this._socket;
  }

  private _dispatch<K extends keyof JsonRpcDispatch>(
    type: K,
    ...params: Parameters<JsonRpcDispatch[K]>
  ): Promise<void[]> {
    const listeners = this._listeners[type] || [];
    const wait = listeners.map(
      (listener: JsonRpcDispatch[K]): Promise<void> => (listener as (...args: unknown[]) => Promise<void>)(...params)
    );
    return Promise.all(wait);
  }

  private _resolveQueue(): void {
    for (let item = this._queue.shift(); item; item = this._queue.shift()) {
      const { resolve, reject, method, params } = item;
      if (!resolve && !reject) {
        this.transmit(method, params, true).catch(() => {});
        continue;
      }
      this.transmit(method, params).then(resolve, reject);
    }
  }

  private _rejectQueue() {
    while (this._queue.length) {
      const { reject } = this._queue.shift() || {};
      if (!reject) {
        continue;
      }
      reject({
        code: -32300,
        message: 'Connection closed',
      });
    }
  }

  private _handleSocketMessage = (event: MessageEvent<string>) => {
    this._log('_handleSocketMessage');
    const { data } = event;
    const socket = this._assertOpenSocket();
    this._dispatch('message', { in: data }).then(
      () => {
        const { jsonrpc, id, result, error, method, params } = JSON.parse(data) as JsonRpcResponse;
        if (jsonrpc !== '2.0') {
          return;
        }
        const callId = `id${id}`;
        if (method) {
          const listener = this._methods[method as keyof J];
          if (listener) {
            listener(params).then((result) => {
              if (id === undefined) {
                return;
              }
              const data: JsonRpcResponse = {
                jsonrpc: '2.0',
                result: result,
                id,
              };
              socket.send(JSON.stringify(data));
            });
          } else if (id) {
            const data: JsonRpcResponse = {
              jsonrpc: '2.0',
              id,
              error: {
                code: -32601,
                message: 'Method not found',
              },
            };
            socket.send(JSON.stringify(data));
          }
        } else if (this._calls[callId] && result !== undefined) {
          this._calls[callId].resolve?.(result);
          delete this._calls[callId];
        } else if (this._calls[callId] && error) {
          this._calls[callId].reject?.(new JsonRpcException(error.message, error.code, error.data));
          delete this._calls[callId];
        }
      },
      () => {}
    );
  };

  private _handleSocketClose = (): void => {
    const previous = this._status;
    this._log('_handleSocketClose');

    this._socket = null;
    this._to_queue = true;
    this._status = CLOSED;
    for (const { reject } of Object.values(this._calls)) {
      reject?.({
        code: -32300,
        message: 'Connection closed',
      });
    }
    this._calls = {};
    this._rpc_id = 0;

    if (previous === OPEN) {
      this._dispatch('close').catch(() => {});
    }
  };

  private _openSocket(): Promise<WebSocket> {
    this._openPromise = new Promise<WebSocket>((resolve, reject) => {
      const socket = new WebSocket(this._url, this._protocols);
      socket.onopen = (event) => {
        this._log('_openSocket.onopen');
        socket.onopen = null;
        socket.onclose = null;
        socket.onerror = null;
        resolve(event.target as WebSocket);
      };
      socket.onclose = ({ code, reason }) => {
        this._log('_openSocket.onclose', { code, reason });
        socket.onopen = null;
        socket.onclose = null;
        socket.onerror = null;
        reject(new AbortedConnection());
      };
      socket.onerror = (event) => {
        this._log('_openSocket.onerror', event);
        socket.onopen = null;
        socket.onclose = null;
        socket.onerror = null;
        reject(new ConnectionError());
      };
      this._socket = socket;
    });
    return this._openPromise.then(
      (x) => {
        this._openPromise = undefined;
        return x;
      },
      (e) => {
        this._openPromise = undefined;
        throw e;
      }
    );
  }

  private _log(...args: unknown[]) {
    if (JsonRpc.debug) {
      console.log(`JsonRpc id-${this._instanceId}`, ...args);
    }
  }
}
