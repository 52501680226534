import { Icon } from '@/shared/ui/icons';
import { Trans, useTranslation } from 'react-i18next';
import { Button } from '@/shared/ui/button';
import ModalLayout from '@/shared/ui/modal/modal';
import styles from './modal-pool-miner-active.module.scss';
import minerDecoration from './images/bg_pool_miner.png';
import { PoolMinerMessage } from '@/shared/lib/backend/JsonRpcApi';
import { useMemo } from 'react';
import { formatHashrate } from '@/page/cart/ui/v1/hashrate';
import { getFormattedLocaleNumber } from '@/shared/lib/utils/getFormattedPrice';

const MONTH = 2592000;

export const ModalPoolMinerActive = ({
  onClose,
  isOpen,
  miner,
}: {
  onClose: (e: boolean) => void;
  isOpen: boolean;
  miner: PoolMinerMessage;
}) => {
  const { t } = useTranslation();

  const month = useMemo(() => Math.round((miner?.end_time - miner?.create_time) / MONTH), [miner]);

  return (
    <ModalLayout
      className={styles.modal}
      background
      iconClose
      onClose={() => onClose(false)}
      isOpen={isOpen}
      id="ModalIncreasingHsh"
    >
      <div className={styles.root} onClick={() => onClose(false)}>
        <div className={styles.decoration}>
          <img src={minerDecoration} alt="img-hsh" />
        </div>
        <div className={styles.container} onClick={(e) => e.stopPropagation()}>
          <div className={styles.block}>
            <span className={styles.close} onClick={() => onClose(false)}>
              <Icon iconName="close" width="20" height="20" />
            </span>
            <Icon iconName={'hashrate'} className={styles.icon} width="80" height="81" />
            <h6 className={styles.title}>{t('Miner_activation.Title')}</h6>
            <p className={styles.text}>
              <Trans
                i18nKey="Miner_activation.Description"
                components={{
                  b: <b />,
                }}
                values={{ speed: formatHashrate(miner?.hps), duration: month }}
                shouldUnescape={true}
              />
            </p>

            {miner?.hsh_reward && (
              <div className={styles.bonus}>
                <Icon iconName={'hsh'} width="30" height="31" />
                <p className={styles.bonus__text}>
                  <Trans
                    i18nKey="Miner_activation.HSH"
                    components={{ b: <b className={styles.green} /> }}
                    values={{ HSH: getFormattedLocaleNumber(miner?.hsh_reward) }}
                  />
                </p>
              </div>
            )}
            {!miner?.hsh_reward && miner?.hps_bonus && (
              <div className={styles.bonus}>
                <Icon iconName="gift" width={30} height={30} className={styles.bonus__icon} />
                <p className={styles.bonus__text}>
                  <Trans
                    i18nKey="Bonus_Hps.Frame"
                    components={{ b: <b className={styles.green} /> }}
                    values={{ bonus: formatHashrate(miner?.hps_bonus) }}
                    shouldUnescape={true}
                  />
                </p>
              </div>
            )}
          </div>
          <div className={styles.footer}>
            <Button color="blue" fullWidth onClick={() => onClose(false)}>
              <Icon iconName="checked" width="17" height="16" />
              {t('H.Skip_modal.Btn.Confirm')}
            </Button>
          </div>
        </div>
      </div>
    </ModalLayout>
  );
};
