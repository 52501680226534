import { DEFAULT_SOCIALS, ShareButtonOptions, SocialType } from '../share-social.types';
import { ShareSocialButton } from '../share-social-button';
import { Popover, PopoverPosition } from 'react-tiny-popover';
import React, { useState } from 'react';
import cn from 'classnames';
import styles from './share-social-dropdown.module.scss';

type ShareSocialDropdownProps = {
  socials?: SocialType[];
  containerClassName?: string;
  contentContainerClassName?: string;
  buttonClassName?: string;
  position?: PopoverPosition;
  children: React.ReactNode;
  shareOptions?: ShareButtonOptions;
};

export const ShareSocialDropdown = ({
  shareOptions,
  socials = DEFAULT_SOCIALS,
  contentContainerClassName,
  buttonClassName,
  containerClassName,
  position,
  children,
}: ShareSocialDropdownProps) => {
  const [open, setOpen] = useState(false);

  const onClose = () => {
    setOpen(false);
  };

  const toggleOpen = () => {
    setOpen(!open);
  };

  const renderContent = () => (
    <div className={cn(styles.contentContainer, contentContainerClassName)}>
      {socials.map((social) => (
        <ShareSocialButton className={buttonClassName} key={social} type={social} shareOptions={shareOptions} />
      ))}
    </div>
  );

  return (
    <Popover
      isOpen={open}
      onClickOutside={onClose}
      content={renderContent}
      positions={position || ['top', 'bottom', 'left', 'right']}
      padding={10}
      containerClassName={styles.popoverContainer}
    >
      <div onClick={toggleOpen} className={containerClassName}>
        {children}
      </div>
    </Popover>
  );
};
