import { useTranslation } from 'react-i18next';
import styles from './balance-block.module.scss';
import React from 'react';
import { BalanceInfo } from '@/page/home/ui/balance-info/balance-info';

export const BalanceBlock = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <h6>{t('Balance')}</h6>
      </div>
      <BalanceInfo tutorialPosition="top-start" tutorialArrowPosition="bot" />
    </div>
  );
};
