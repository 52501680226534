import React, { useCallback } from 'react';
import styles from './ratingEmoji.module.scss';
import cn from 'classnames';

const COUNT_STARS = 5;

type RatingProps = {
  onChange: (value: number) => void;
  size?: number;
  count?: number;
  value: number;
  noNumbers?: boolean;
};

const getRatingEmoji = (rating: number) => {
  switch (rating) {
    case 0:
      return '🤬';
    case 1:
      return '☹️';
    case 2:
      return '😬';
    case 3:
      return '🙂';
    case 4:
      return '😃';
    default:
      return '⭐'; // Fallback emoji
  }
};

export const RatingEmoji = (props: RatingProps) => {
  const { onChange, count = COUNT_STARS, value, noNumbers } = props;

  const handleChange = useCallback(
    (index: number) => {
      onChange(index);
    },
    [onChange]
  );

  return (
    <>
      {new Array(count).fill(0).map((_, i) => (
        <div key={i} className={styles.star} onClick={() => handleChange(i + 1)}>
          <div className={styles.emoji}>{getRatingEmoji(i)}</div>
          {noNumbers ? null : (
            <div className={cn([styles.color, (value > i || value === i + 1) && styles.activeColor])}>{i + 1}</div>
          )}
        </div>
      ))}
    </>
  );
};
