import React from 'react';
import { Button } from '@/shared/ui/button';
import cn from 'classnames';
import s from './google-mining-duration.module.scss';
import { useTranslation } from 'react-i18next';
import { useDevice } from '@/shared/lib/hooks/useDevice';
import { Icon } from '@/shared/ui/icons';
import { getLanguage } from '@/shared/store/language';

type GoogleMiningDurationButtonProps = {
  isMostProfitable: boolean;
  month: number;
  discount?: number;
  formattedPrice: string;
  isActive: boolean;
  isNotAvailable: boolean;
  expirationDate?: number;
  onClick: () => void;
};

export const GoogleMiningDurationButton = ({
  month,
  discount,
  formattedPrice,
  isActive,
  isNotAvailable,
  expirationDate,
  onClick,
  isMostProfitable,
}: GoogleMiningDurationButtonProps) => {
  const { t } = useTranslation();
  const { isTelegramMiniApp } = useDevice();
  const lang = getLanguage();
  const expiresAt = new Date(expirationDate!).toLocaleDateString(lang);
  return (
    <Button
      borderRadius={false}
      fullWidth
      variant="outline"
      onClick={onClick}
      color="black"
      className={cn(
        s.button,
        s.durationInfo,
        !isActive && !isNotAvailable && s.inactive,
        isNotAvailable && s.durationInfo__activated
      )}
      disabled={isNotAvailable}
    >
      <span className={s.durationInfo__text}>
        {month} {t('mo.')}
      </span>
      {discount && discount > 1 && <span className={s.durationInfo__discount}>-{discount}%</span>}
      <span className={s.durationInfo__plan}>{formattedPrice}</span>
      {isNotAvailable && (
        <span className={cn(s.durationInfo__additionalElement)}>
          <div className={cn(s.durationInfo__icon__container)}>
            <Icon iconName="timer" width={20} height={20} className={s.durationInfo__icon} />
            {t('workerDetails.activated')}
          </div>
          <span className={s.durationInfo__data}>{expiresAt}</span>
        </span>
      )}
      {isNotAvailable && isMostProfitable && (
        <span className={cn(s.durationInfo__tag, isTelegramMiniApp && s.durationInfo__tagPurple)}>
          {t('MTU.Cloud_boost_buy.Tag_profitable')}
        </span>
      )}
    </Button>
  );
};
