import cn from 'classnames';
import ReactSlider from 'react-slider';

import s from './input-range.module.scss';

type InputRangeProps = {
  value: number;
  onChange: (value: number) => void;
  min?: number;
  max?: number;
  step?: number;
  marks?: number[];
  disabled?: boolean;
};

export const InputRange = ({ value, onChange, min, max, step, marks, disabled }: InputRangeProps) => {
  return (
    <ReactSlider
      className={cn(s.slider, disabled && s.disabled)}
      value={value}
      onChange={onChange}
      min={min}
      max={max}
      step={step}
      marks={marks}
      disabled={disabled}
      renderThumb={(props) => <div {...props} data-role="thumb" />}
      renderTrack={(props, state) => <div {...props} className={s.track} data-role={`track-${state.index}`} />}
    />
  );
};
