import React, { useState } from 'react';
import styles from './bonus-balance.module.scss';
import { useTranslation } from 'react-i18next';
import { BalanceHSH } from '@/page/bonus-program/ui/bonus-balance-card/ui/balance-hsh';
import { getLanguage } from '@/shared/store/language';
import cx from 'classnames';
import { DailyTransactionsHistory } from '@/shared/lib/backend/JsonRpcApi';
import { Icon } from '@/shared/ui/icons';
import { getFormattedLocaleNumber } from '@/shared/lib/utils/getFormattedPrice';
import { CustomTippy } from '@/shared/ui/custom-tippy/custom-tippy';

interface BonusBalanceProps {
  items?: DailyTransactionsHistory[];
  balance?: number;
  increased?: number;
  accrued?: number;
  income?: number;
  nextPayment?: {
    days: number;
    hours: number;
    mins: number;
  };
}

export const BonusBalance = ({ items, balance, increased, accrued, income, nextPayment }: BonusBalanceProps) => {
  const [tooltipVisible, setTooltipVisible] = useState(false);

  const { t } = useTranslation();
  const lang = getLanguage();

  // Format the date using Intl.DateTimeFormat
  const formatDate = (date: string) => {
    const options: Intl.DateTimeFormatOptions = {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    };
    return new Intl.DateTimeFormat(lang, options).format(new Date(date));
  };

  return (
    <div className={styles.bonus}>
      <div className={styles.bonus__title}>
        {t('BB_Hsh.BBalance')}
        {increased ? <span className={styles.bonus__increased}>+{getFormattedLocaleNumber(increased)}</span> : null}
      </div>
      <div className={cx(styles.bonus__balance, { [styles.bonus__balance_border]: items?.length })}>
        <BalanceHSH balance={balance || 0} />
      </div>
      {!!income || !!accrued ? (
        <div className={styles.bonus__container}>
          {!!income && (
            <div className={styles.bonus__pending}>
              <span className={styles.bonus__title_min}>{t('BB_Hsh.Pending')}</span>
              <div className={styles.pending__container}>
                <span className={styles.bonus__amount}>
                  <b>{getFormattedLocaleNumber(income)}</b> HSH
                </span>
                {income > 0 && nextPayment && (
                  <div className={styles.pending__tooltip}>
                    <CustomTippy
                      content={
                        <div className={styles.tooltip__content}>
                          <Icon
                            iconName="close"
                            width={14.4}
                            height={14.4}
                            className={styles.tooltip__close}
                            onClick={() => setTooltipVisible(false)}
                          />
                          <span>
                            <b>{t('BB_Hsh.Next_conf')} </b>
                            {nextPayment?.days > 0 && t('BB_Hsh.Next_conf.Days', { days: nextPayment?.days })}{' '}
                            {nextPayment?.hours > 0 && t('BB_Hsh.Next_conf.Hours', { hours: nextPayment?.hours })}{' '}
                            {nextPayment?.mins > 0 && t('BB_Hsh.Next_conf.Min', { minutes: nextPayment?.mins })}
                          </span>
                        </div>
                      }
                      visibleTooltip={tooltipVisible}
                      hideTooltip={() => setTooltipVisible(false)}
                      icon={
                        <div
                          className={styles.tooltip}
                          onClick={tooltipVisible ? () => setTooltipVisible(false) : () => setTooltipVisible(true)}
                        >
                          <Icon iconName="qa" width={14} height={14} />
                        </div>
                      }
                    />
                  </div>
                )}
              </div>
            </div>
          )}
          {!!accrued && (
            <div className={styles.bonus__accrued}>
              <span className={styles.bonus__title_min}>{t('BB_Hsh.All_time')}</span>
              <span className={styles.bonus__amount}>
                <b>{getFormattedLocaleNumber(accrued)}</b> HSH
              </span>
            </div>
          )}
        </div>
      ) : null}
      {items?.length ? (
        <div className={styles.bonus__list}>
          {items?.map((item, index) => (
            <div key={index} className={styles.bonus__item}>
              <div className={styles.bonus__item__details}>
                <div>
                  <BalanceHSH balance={+item.amount} small />
                </div>
                <div className={styles.bonus__item__date}>{formatDate(item.date)}</div>
              </div>
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
};
