import styles from './menu.navigation.module.scss';
import { MenuNavItem } from '@/features/menu/ui/menu-nav-item/menu-nav-item';
import { Trans, useTranslation } from 'react-i18next';
import { POOL_FEEDBACK, POOL_TERMS } from '@/shared/lib/utils/links';
import { useModalSet } from '@/shared/lib/context/modal/useModalSet';
import { useDevice } from '@/shared/lib/hooks/useDevice';

export const MenuNavigation = ({
  user_id,
  platform,
  appId,
}: {
  user_id?: number;
  platform?: string;
  appId?: string;
}) => {
  const { t } = useTranslation();
  const { openModal } = useModalSet();
  const { platformCt } = useDevice();

  return (
    <ul className={styles.root}>
      {platformCt === 'android' && (
        <MenuNavItem onClick={() => openModal('MODAL_RATE_US')}>
          <Trans i18nKey="rate_ctpool_title" components={{ b: <b /> }} />
        </MenuNavItem>
      )}
      <MenuNavItem link={`${POOL_FEEDBACK}?user_id=${user_id}&platform=${platform}&app_id=${appId}`}>
        {t('Contact Us')}
      </MenuNavItem>
      <MenuNavItem link={POOL_TERMS}>{t('Terms of Use')}</MenuNavItem>
    </ul>
  );
};
