import ModalLayout from '@/shared/ui/modal/modal';
import styles from './modal-account-blocked.module.scss';
import { Icon } from '@/shared/ui/icons';
import { useTranslation } from 'react-i18next';
import { Button } from '@/shared/ui/button';
import { POOL_FEEDBACK } from '@/shared/lib/utils/links';
import { useLogout } from '@/shared/hooks';
import { useSelector } from 'react-redux';
import { rootUser } from '@/shared/store/user/user.slice';
import { useDevice } from '@/shared/lib/hooks/useDevice';

type ModalAccountBlockedProps = {
  isOpen: boolean;
};

export const ModalAccountBlocked = ({ isOpen }: ModalAccountBlockedProps) => {
  const { t } = useTranslation();
  const { id } = useSelector((store: { user: rootUser }) => store.user);
  const { isReactNativeApp, isTelegramMiniApp } = useDevice();

  let platform: string = 'android';
  let appId: string = 'net.ctpool';

  if (isReactNativeApp) {
    platform = window?.ct?.platform;
    appId = 'net.ctpool';
  } else if (isTelegramMiniApp) {
    platform = 'telegram';
    appId = 'pool.telegram.ct';
  }

  const onContactSupportClick = () => {
    window.open(`${POOL_FEEDBACK}?user_id=${id}&platform=${platform}&app_id=${appId}`);
  };

  const { logout } = useLogout();

  return (
    <ModalLayout background iconClose onClose={() => {}} isOpen={isOpen} id="error">
      <div className={styles.container}>
        <div className={styles.innerContainer}>
          <div className={styles.contentContainer}>
            <div className={styles.iconContainer}>
              <Icon iconName="error" width={80} height={80} />
            </div>

            <h1 className={styles.title}>{t('Acc_susp.Title')}</h1>
            <p className={styles.description}>{t('Acc_susp.Description')}</p>
          </div>

          <div className={styles.footer}>
            <Button color="blue" fullWidth onClick={onContactSupportClick}>
              {t('Acc_susp.Btn')}
              <Icon iconName="arrow" width="20" height="20" />
            </Button>
            <Button color="blue" variant="outline" fullWidth onClick={logout}>
              <Icon iconName="arrow" width="20" height="20" className={styles.goBackIcon} />
              {t('GO BACK')}
            </Button>
          </div>
        </div>
      </div>
    </ModalLayout>
  );
};
