export const cloud_boost = {
  v: '5.7.11',
  fr: 25,
  ip: 0,
  op: 50,
  w: 297,
  h: 142,
  nm: '1',
  ddd: 0,
  assets: [],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 4,
      nm: 'dividers Outlines 2',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [135.943, 104.303, 0], ix: 2, l: 2 },
        a: { a: 0, k: [7.287, 11.851, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      hasMask: true,
      masksProperties: [
        {
          inv: true,
          mode: 'a',
          pt: {
            a: 1,
            k: [
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                t: 0,
                s: [
                  {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [19.719, -26.452],
                      [-5.156, -26.452],
                      [-5.156, -1.327],
                      [19.719, -1.327],
                    ],
                    c: true,
                  },
                ],
                e: [
                  {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [19.719, -26.452],
                      [-5.156, -26.452],
                      [-5.156, 4.423],
                      [19.719, 4.423],
                    ],
                    c: true,
                  },
                ],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                t: 1,
                s: [
                  {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [19.719, -26.452],
                      [-5.156, -26.452],
                      [-5.156, 4.423],
                      [19.719, 4.423],
                    ],
                    c: true,
                  },
                ],
                e: [
                  {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [19.719, -26.452],
                      [-5.156, -26.452],
                      [-5.156, 4.423],
                      [19.719, 4.423],
                    ],
                    c: true,
                  },
                ],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                t: 10,
                s: [
                  {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [19.719, -26.452],
                      [-5.156, -26.452],
                      [-5.156, 4.423],
                      [19.719, 4.423],
                    ],
                    c: true,
                  },
                ],
                e: [
                  {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [19.719, -26.452],
                      [-5.156, -26.452],
                      [-5.156, 9.298],
                      [19.719, 9.298],
                    ],
                    c: true,
                  },
                ],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                t: 11,
                s: [
                  {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [19.719, -26.452],
                      [-5.156, -26.452],
                      [-5.156, 9.298],
                      [19.719, 9.298],
                    ],
                    c: true,
                  },
                ],
                e: [
                  {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [19.719, -26.452],
                      [-5.156, -26.452],
                      [-5.156, 9.298],
                      [19.719, 9.298],
                    ],
                    c: true,
                  },
                ],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                t: 17,
                s: [
                  {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [19.719, -26.452],
                      [-5.156, -26.452],
                      [-5.156, 9.298],
                      [19.719, 9.298],
                    ],
                    c: true,
                  },
                ],
                e: [
                  {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [19.719, -26.452],
                      [-5.156, -26.452],
                      [-5.156, -1.327],
                      [19.719, -1.327],
                    ],
                    c: true,
                  },
                ],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                t: 18,
                s: [
                  {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [19.719, -26.452],
                      [-5.156, -26.452],
                      [-5.156, -1.327],
                      [19.719, -1.327],
                    ],
                    c: true,
                  },
                ],
                e: [
                  {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [19.719, -26.452],
                      [-5.156, -26.452],
                      [-5.156, -1.327],
                      [19.719, -1.327],
                    ],
                    c: true,
                  },
                ],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                t: 25,
                s: [
                  {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [19.719, -26.452],
                      [-5.156, -26.452],
                      [-5.156, -1.327],
                      [19.719, -1.327],
                    ],
                    c: true,
                  },
                ],
                e: [
                  {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [19.719, -26.452],
                      [-5.156, -26.452],
                      [-5.156, 4.423],
                      [19.719, 4.423],
                    ],
                    c: true,
                  },
                ],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                t: 26,
                s: [
                  {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [19.719, -26.452],
                      [-5.156, -26.452],
                      [-5.156, 4.423],
                      [19.719, 4.423],
                    ],
                    c: true,
                  },
                ],
                e: [
                  {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [19.719, -26.452],
                      [-5.156, -26.452],
                      [-5.156, 4.423],
                      [19.719, 4.423],
                    ],
                    c: true,
                  },
                ],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                t: 35,
                s: [
                  {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [19.719, -26.452],
                      [-5.156, -26.452],
                      [-5.156, 4.423],
                      [19.719, 4.423],
                    ],
                    c: true,
                  },
                ],
                e: [
                  {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [19.719, -26.452],
                      [-5.156, -26.452],
                      [-5.156, 9.298],
                      [19.719, 9.298],
                    ],
                    c: true,
                  },
                ],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                t: 36,
                s: [
                  {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [19.719, -26.452],
                      [-5.156, -26.452],
                      [-5.156, 9.298],
                      [19.719, 9.298],
                    ],
                    c: true,
                  },
                ],
                e: [
                  {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [19.719, -26.452],
                      [-5.156, -26.452],
                      [-5.156, 9.298],
                      [19.719, 9.298],
                    ],
                    c: true,
                  },
                ],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                t: 42,
                s: [
                  {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [19.719, -26.452],
                      [-5.156, -26.452],
                      [-5.156, 9.298],
                      [19.719, 9.298],
                    ],
                    c: true,
                  },
                ],
                e: [
                  {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [19.719, -26.452],
                      [-5.156, -26.452],
                      [-5.156, -1.327],
                      [19.719, -1.327],
                    ],
                    c: true,
                  },
                ],
              },
              { t: 43 },
            ],
            ix: 1,
          },
          o: { a: 0, k: 100, ix: 3 },
          x: { a: 0, k: 0, ix: 4 },
          nm: 'Mask 1',
        },
      ],
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-0.836, 0],
                    [0, 0],
                    [0, -0.835],
                    [0.835, 0],
                    [0, 0],
                    [0, 0.836],
                  ],
                  o: [
                    [0, 0],
                    [0.835, 0],
                    [0, 0.836],
                    [0, 0],
                    [-0.836, 0],
                    [0, -0.835],
                  ],
                  v: [
                    [-5.524, -1.514],
                    [5.524, -1.514],
                    [7.037, -0.001],
                    [5.524, 1.514],
                    [-5.524, 1.514],
                    [-7.037, -0.001],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.258823529412, 0.521568627451, 0.956862804936, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [7.287, 1.764], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-0.836, 0],
                    [0, 0],
                    [0, -0.835],
                    [0.835, 0],
                    [0, 0],
                    [0, 0.836],
                  ],
                  o: [
                    [0, 0],
                    [0.835, 0],
                    [0, 0.836],
                    [0, 0],
                    [-0.836, 0],
                    [0, -0.835],
                  ],
                  v: [
                    [-5.524, -1.514],
                    [5.524, -1.514],
                    [7.037, -0.001],
                    [5.524, 1.514],
                    [-5.524, 1.514],
                    [-7.037, -0.001],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.258823529412, 0.521568627451, 0.956862804936, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [7.287, 6.807], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 2',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-0.836, 0],
                    [0, 0],
                    [0, -0.835],
                    [0.835, 0],
                    [0, 0],
                    [0, 0.836],
                  ],
                  o: [
                    [0, 0],
                    [0.835, 0],
                    [0, 0.836],
                    [0, 0],
                    [-0.836, 0],
                    [0, -0.835],
                  ],
                  v: [
                    [-5.524, -1.514],
                    [5.524, -1.514],
                    [7.037, -0.001],
                    [5.524, 1.514],
                    [-5.524, 1.514],
                    [-7.037, -0.001],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.258823529412, 0.521568627451, 0.956862804936, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [7.287, 11.851], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 3',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 3,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-0.836, 0],
                    [0, 0],
                    [0, -0.835],
                    [0.835, 0],
                    [0, 0],
                    [0, 0.836],
                  ],
                  o: [
                    [0, 0],
                    [0.835, 0],
                    [0, 0.836],
                    [0, 0],
                    [-0.836, 0],
                    [0, -0.835],
                  ],
                  v: [
                    [-5.524, -1.513],
                    [5.524, -1.513],
                    [7.037, 0],
                    [5.524, 1.513],
                    [-5.524, 1.513],
                    [-7.037, 0],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.258823529412, 0.521568627451, 0.956862804936, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [7.287, 16.895], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 4',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 4,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-0.836, 0],
                    [0, 0],
                    [0, -0.836],
                    [0.835, 0],
                    [0, 0],
                    [0, 0.836],
                  ],
                  o: [
                    [0, 0],
                    [0.835, 0],
                    [0, 0.836],
                    [0, 0],
                    [-0.836, 0],
                    [0, -0.836],
                  ],
                  v: [
                    [-5.524, -1.513],
                    [5.524, -1.513],
                    [7.037, 0],
                    [5.524, 1.513],
                    [-5.524, 1.513],
                    [-7.037, 0],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.258823529412, 0.521568627451, 0.956862804936, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [7.287, 21.939], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 5',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 5,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 50,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 2,
      ty: 4,
      nm: 'dividers Outlines',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [135.943, 104.303, 0], ix: 2, l: 2 },
        a: { a: 0, k: [7.287, 11.851, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-0.836, 0],
                    [0, 0],
                    [0, -0.835],
                    [0.835, 0],
                    [0, 0],
                    [0, 0.836],
                  ],
                  o: [
                    [0, 0],
                    [0.835, 0],
                    [0, 0.836],
                    [0, 0],
                    [-0.836, 0],
                    [0, -0.835],
                  ],
                  v: [
                    [-5.524, -1.514],
                    [5.524, -1.514],
                    [7.037, -0.001],
                    [5.524, 1.514],
                    [-5.524, 1.514],
                    [-7.037, -0.001],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.458823559331, 0.458823559331, 0.458823559331, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [7.287, 1.764], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-0.836, 0],
                    [0, 0],
                    [0, -0.835],
                    [0.835, 0],
                    [0, 0],
                    [0, 0.836],
                  ],
                  o: [
                    [0, 0],
                    [0.835, 0],
                    [0, 0.836],
                    [0, 0],
                    [-0.836, 0],
                    [0, -0.835],
                  ],
                  v: [
                    [-5.524, -1.514],
                    [5.524, -1.514],
                    [7.037, -0.001],
                    [5.524, 1.514],
                    [-5.524, 1.514],
                    [-7.037, -0.001],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.458823559331, 0.458823559331, 0.458823559331, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [7.287, 6.807], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 2',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-0.836, 0],
                    [0, 0],
                    [0, -0.835],
                    [0.835, 0],
                    [0, 0],
                    [0, 0.836],
                  ],
                  o: [
                    [0, 0],
                    [0.835, 0],
                    [0, 0.836],
                    [0, 0],
                    [-0.836, 0],
                    [0, -0.835],
                  ],
                  v: [
                    [-5.524, -1.514],
                    [5.524, -1.514],
                    [7.037, -0.001],
                    [5.524, 1.514],
                    [-5.524, 1.514],
                    [-7.037, -0.001],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.458823559331, 0.458823559331, 0.458823559331, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [7.287, 11.851], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 3',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 3,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-0.836, 0],
                    [0, 0],
                    [0, -0.835],
                    [0.835, 0],
                    [0, 0],
                    [0, 0.836],
                  ],
                  o: [
                    [0, 0],
                    [0.835, 0],
                    [0, 0.836],
                    [0, 0],
                    [-0.836, 0],
                    [0, -0.835],
                  ],
                  v: [
                    [-5.524, -1.513],
                    [5.524, -1.513],
                    [7.037, 0],
                    [5.524, 1.513],
                    [-5.524, 1.513],
                    [-7.037, 0],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.458823559331, 0.458823559331, 0.458823559331, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [7.287, 16.895], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 4',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 4,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-0.836, 0],
                    [0, 0],
                    [0, -0.836],
                    [0.835, 0],
                    [0, 0],
                    [0, 0.836],
                  ],
                  o: [
                    [0, 0],
                    [0.835, 0],
                    [0, 0.836],
                    [0, 0],
                    [-0.836, 0],
                    [0, -0.836],
                  ],
                  v: [
                    [-5.524, -1.513],
                    [5.524, -1.513],
                    [7.037, 0],
                    [5.524, 1.513],
                    [-5.524, 1.513],
                    [-7.037, 0],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.458823559331, 0.458823559331, 0.458823559331, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [7.287, 21.939], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 5',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 5,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 50,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 3,
      ty: 4,
      nm: 'Group 1',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [181.684, 80.595, 0], ix: 2, l: 2 },
        a: { a: 0, k: [43.739, 37.328, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, -5.994],
                    [5.963, 0],
                    [0, 5.994],
                    [-5.962, 0],
                  ],
                  o: [
                    [0, 5.994],
                    [-5.962, 0],
                    [0, -5.994],
                    [5.963, 0],
                  ],
                  v: [
                    [10.803, 0],
                    [-0.002, 10.845],
                    [-10.803, 0],
                    [-0.002, -10.845],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 2.522, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [66.359, 53.47], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 50,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 4,
      ty: 4,
      nm: 'Group 2',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [181.684, 80.595, 0], ix: 2, l: 2 },
        a: { a: 0, k: [43.739, 37.328, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, -5.994],
                    [5.963, 0],
                    [0, 5.994],
                    [-5.962, 0],
                  ],
                  o: [
                    [0, 5.994],
                    [-5.962, 0],
                    [0, -5.994],
                    [5.963, 0],
                  ],
                  v: [
                    [10.803, 0],
                    [-0.002, 10.845],
                    [-10.803, 0],
                    [-0.002, -10.845],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.286000001197, 0.286000001197, 0.286000001197, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [66.359, 53.47], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 2',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 50,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 5,
      ty: 4,
      nm: 'Group 14',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 0,
              s: [0],
              e: [360],
            },
            { t: 50 },
          ],
          ix: 10,
        },
        p: { a: 0, k: [196.053, 52.924, 0], ix: 2, l: 2 },
        a: { a: 0, k: [66.358, 53.469, 0], ix: 1, l: 2 },
        s: { a: 0, k: [44, 44, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-10.549, 0],
                    [0, -10.586],
                    [10.55, 0],
                    [0, 10.586],
                  ],
                  o: [
                    [10.55, 0],
                    [0, 10.586],
                    [-10.549, 0],
                    [0, -10.586],
                  ],
                  v: [
                    [0, -19.168],
                    [19.102, 0],
                    [0, 19.168],
                    [-19.102, 0],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 4.027, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              d: [
                { n: 'd', nm: 'dash', v: { a: 0, k: 5, ix: 1 } },
                { n: 'o', nm: 'offset', v: { a: 0, k: 0, ix: 7 } },
              ],
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [66.358, 53.469], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [105, 105], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 4',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-10.549, 0],
                    [0, -10.586],
                    [10.55, 0],
                    [0, 10.586],
                  ],
                  o: [
                    [10.55, 0],
                    [0, 10.586],
                    [-10.549, 0],
                    [0, -10.586],
                  ],
                  v: [
                    [0, -19.168],
                    [19.102, 0],
                    [0, 19.168],
                    [-19.102, 0],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 3.027, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [66.358, 53.469], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 3',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 50,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 6,
      ty: 4,
      nm: 'Group 13',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 0,
              s: [0],
              e: [360],
            },
            { t: 50 },
          ],
          ix: 10,
        },
        p: { a: 0, k: [152.053, 73.299, 0], ix: 2, l: 2 },
        a: { a: 0, k: [66.358, 53.469, 0], ix: 1, l: 2 },
        s: { a: 0, k: [69, 69, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-10.549, 0],
                    [0, -10.586],
                    [10.55, 0],
                    [0, 10.586],
                  ],
                  o: [
                    [10.55, 0],
                    [0, 10.586],
                    [-10.549, 0],
                    [0, -10.586],
                  ],
                  v: [
                    [0, -19.168],
                    [19.102, 0],
                    [0, 19.168],
                    [-19.102, 0],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 4.027, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              d: [
                { n: 'd', nm: 'dash', v: { a: 0, k: 5, ix: 1 } },
                { n: 'o', nm: 'offset', v: { a: 0, k: 0, ix: 7 } },
              ],
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [66.358, 53.469], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [105, 105], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 4',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-10.549, 0],
                    [0, -10.586],
                    [10.55, 0],
                    [0, 10.586],
                  ],
                  o: [
                    [10.55, 0],
                    [0, 10.586],
                    [-10.549, 0],
                    [0, -10.586],
                  ],
                  v: [
                    [0, -19.168],
                    [19.102, 0],
                    [0, 19.168],
                    [-19.102, 0],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 3.027, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [66.358, 53.469], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 3',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 50,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 7,
      ty: 4,
      nm: 'Group 3',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 0,
              s: [0],
              e: [360],
            },
            { t: 50 },
          ],
          ix: 10,
        },
        p: { a: 0, k: [204.303, 96.737, 0], ix: 2, l: 2 },
        a: { a: 0, k: [66.358, 53.469, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-10.549, 0],
                    [0, -10.586],
                    [10.55, 0],
                    [0, 10.586],
                  ],
                  o: [
                    [10.55, 0],
                    [0, 10.586],
                    [-10.549, 0],
                    [0, -10.586],
                  ],
                  v: [
                    [0, -19.168],
                    [19.102, 0],
                    [0, 19.168],
                    [-19.102, 0],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 4.027, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              d: [
                { n: 'd', nm: 'dash', v: { a: 0, k: 5, ix: 1 } },
                { n: 'o', nm: 'offset', v: { a: 0, k: 0, ix: 7 } },
              ],
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [66.358, 53.469], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [105, 105], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 4',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-10.549, 0],
                    [0, -10.586],
                    [10.55, 0],
                    [0, 10.586],
                  ],
                  o: [
                    [10.55, 0],
                    [0, 10.586],
                    [-10.549, 0],
                    [0, -10.586],
                  ],
                  v: [
                    [0, -19.168],
                    [19.102, 0],
                    [0, 19.168],
                    [-19.102, 0],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 3.027, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [66.358, 53.469], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 3',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 50,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 8,
      ty: 4,
      nm: 'Group 4',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [181.684, 80.595, 0], ix: 2, l: 2 },
        a: { a: 0, k: [43.739, 37.328, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-10.549, 0],
                    [0, -10.586],
                    [10.55, 0],
                    [0, 10.586],
                  ],
                  o: [
                    [10.55, 0],
                    [0, 10.586],
                    [-10.549, 0],
                    [0, -10.586],
                  ],
                  v: [
                    [0, -19.168],
                    [19.102, 0],
                    [0, 19.168],
                    [-19.102, 0],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.286000001197, 0.286000001197, 0.286000001197, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [66.358, 53.469], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 4',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 50,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 9,
      ty: 4,
      nm: 'Group 5',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [181.684, 80.595, 0], ix: 2, l: 2 },
        a: { a: 0, k: [43.739, 37.328, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, -1.535],
                    [1.524, 0],
                    [0, 1.535],
                    [-1.522, 0],
                  ],
                  o: [
                    [0, 1.535],
                    [-1.522, 0],
                    [0, -1.535],
                    [1.524, 0],
                  ],
                  v: [
                    [2.762, 0],
                    [-0.001, 2.774],
                    [-2.762, 0],
                    [-0.001, -2.774],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1.513, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [57.813, 9.585], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 5',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 50,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 10,
      ty: 4,
      nm: 'Group 6',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [181.684, 80.595, 0], ix: 2, l: 2 },
        a: { a: 0, k: [43.739, 37.328, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, -1.535],
                    [1.524, 0],
                    [0, 1.535],
                    [-1.522, 0],
                  ],
                  o: [
                    [0, 1.535],
                    [-1.522, 0],
                    [0, -1.535],
                    [1.524, 0],
                  ],
                  v: [
                    [2.762, 0],
                    [-0.001, 2.774],
                    [-2.762, 0],
                    [-0.001, -2.774],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.286000001197, 0.286000001197, 0.286000001197, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [57.813, 9.585], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 6',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 50,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 11,
      ty: 4,
      nm: 'Group 7',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [195.758, 52.852, 0], ix: 2, l: 2 },
        a: { a: 0, k: [57.813, 9.585, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-4.164, 0],
                    [0, -4.178],
                    [4.164, 0],
                    [0, 4.179],
                  ],
                  o: [
                    [4.164, 0],
                    [0, 4.179],
                    [-4.164, 0],
                    [0, -4.178],
                  ],
                  v: [
                    [-0.001, -7.567],
                    [7.54, -0.001],
                    [-0.001, 7.567],
                    [-7.54, -0.001],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 2.018, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [57.813, 9.585], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 7',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 50,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 12,
      ty: 4,
      nm: 'Group 8',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [181.684, 80.595, 0], ix: 2, l: 2 },
        a: { a: 0, k: [43.739, 37.328, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-4.164, 0],
                    [0, -4.178],
                    [4.164, 0],
                    [0, 4.179],
                  ],
                  o: [
                    [4.164, 0],
                    [0, 4.179],
                    [-4.164, 0],
                    [0, -4.178],
                  ],
                  v: [
                    [-0.001, -7.567],
                    [7.54, -0.001],
                    [-0.001, 7.567],
                    [-7.54, -0.001],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.286000001197, 0.286000001197, 0.286000001197, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [57.813, 9.585], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 8',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 50,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 13,
      ty: 4,
      nm: 'Group 9',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [181.684, 80.595, 0], ix: 2, l: 2 },
        a: { a: 0, k: [43.739, 37.328, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, -3.903],
                    [3.881, 0],
                    [0, 3.904],
                    [-3.882, 0],
                  ],
                  o: [
                    [0, 3.904],
                    [-3.882, 0],
                    [0, -3.903],
                    [3.881, 0],
                  ],
                  v: [
                    [7.034, 0],
                    [0, 7.062],
                    [-7.034, 0],
                    [0, -7.062],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 2.018, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [14.081, 30.266], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 9',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 50,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 14,
      ty: 4,
      nm: 'Group 10',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [181.684, 80.595, 0], ix: 2, l: 2 },
        a: { a: 0, k: [43.739, 37.328, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, -3.903],
                    [3.881, 0],
                    [0, 3.904],
                    [-3.882, 0],
                  ],
                  o: [
                    [0, 3.904],
                    [-3.882, 0],
                    [0, -3.903],
                    [3.881, 0],
                  ],
                  v: [
                    [7.034, 0],
                    [0, 7.062],
                    [-7.034, 0],
                    [0, -7.062],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.286000001197, 0.286000001197, 0.286000001197, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [14.081, 30.266], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 10',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 50,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 15,
      ty: 4,
      nm: 'Group 11',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [152.027, 73.533, 0], ix: 2, l: 2 },
        a: { a: 0, k: [14.081, 30.266, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-6.663, 0],
                    [0, -6.686],
                    [6.662, 0],
                    [0, 6.686],
                  ],
                  o: [
                    [6.662, 0],
                    [0, 6.686],
                    [-6.663, 0],
                    [0, -6.686],
                  ],
                  v: [
                    [0.001, -12.106],
                    [12.064, 0],
                    [0.001, 12.106],
                    [-12.064, 0],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 2.018, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [14.081, 30.266], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 11',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 50,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 16,
      ty: 4,
      nm: 'Group 12',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [181.684, 80.595, 0], ix: 2, l: 2 },
        a: { a: 0, k: [43.739, 37.328, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-6.663, 0],
                    [0, -6.686],
                    [6.662, 0],
                    [0, 6.686],
                  ],
                  o: [
                    [6.662, 0],
                    [0, 6.686],
                    [-6.663, 0],
                    [0, -6.686],
                  ],
                  v: [
                    [0.001, -12.106],
                    [12.064, 0],
                    [0.001, 12.106],
                    [-12.064, 0],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.286000001197, 0.286000001197, 0.286000001197, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [14.081, 30.266], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 12',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 50,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 17,
      ty: 4,
      nm: 'whels Outlines',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [181.684, 80.595, 0], ix: 2, l: 2 },
        a: { a: 0, k: [43.739, 37.328, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [],
      ip: 0,
      op: 50,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 18,
      ty: 4,
      nm: 'remen Outlines',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [184.197, 80.091, 0], ix: 2, l: 2 },
        a: { a: 0, k: [39.218, 33.797, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 1,
                k: [
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 0.333, y: 0 },
                    t: 0,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [-36.191, -16.646],
                          [7.037, -30.77],
                          [17.09, -30.77],
                          [36.191, 9.584],
                          [10.053, 30.77],
                          [-31.164, 5.549],
                        ],
                        c: true,
                      },
                    ],
                    e: [
                      {
                        i: [
                          [0, 0],
                          [-21.609, 3.054],
                          [0, 0],
                          [0.013, -8.7],
                          [8.5, 2.236],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [10.567, -1.493],
                          [0, 0],
                          [-0.013, 8.7],
                          [-8.5, -2.236],
                          [0, 0],
                        ],
                        v: [
                          [-36.191, -16.646],
                          [7.037, -30.77],
                          [17.09, -30.77],
                          [37.441, 11.834],
                          [10.053, 30.77],
                          [-31.164, 5.549],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 0.333, y: 0 },
                    t: 5,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [-21.609, 3.054],
                          [0, 0],
                          [0.013, -8.7],
                          [8.5, 2.236],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [10.567, -1.493],
                          [0, 0],
                          [-0.013, 8.7],
                          [-8.5, -2.236],
                          [0, 0],
                        ],
                        v: [
                          [-36.191, -16.646],
                          [7.037, -30.77],
                          [17.09, -30.77],
                          [37.441, 11.834],
                          [10.053, 30.77],
                          [-31.164, 5.549],
                        ],
                        c: true,
                      },
                    ],
                    e: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [-36.191, -16.646],
                          [7.037, -30.77],
                          [17.09, -30.77],
                          [36.191, 9.584],
                          [10.053, 30.77],
                          [-31.164, 5.549],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 0.333, y: 0 },
                    t: 10,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [-36.191, -16.646],
                          [7.037, -30.77],
                          [17.09, -30.77],
                          [36.191, 9.584],
                          [10.053, 30.77],
                          [-31.164, 5.549],
                        ],
                        c: true,
                      },
                    ],
                    e: [
                      {
                        i: [
                          [0, 0],
                          [-21.609, 3.054],
                          [0, 0],
                          [0.013, -8.7],
                          [8.5, 2.236],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [10.567, -1.493],
                          [0, 0],
                          [-0.013, 8.7],
                          [-8.5, -2.236],
                          [0, 0],
                        ],
                        v: [
                          [-36.191, -16.646],
                          [7.037, -30.77],
                          [17.09, -30.77],
                          [37.441, 11.834],
                          [10.053, 30.77],
                          [-31.164, 5.549],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 0.333, y: 0 },
                    t: 15,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [-21.609, 3.054],
                          [0, 0],
                          [0.013, -8.7],
                          [8.5, 2.236],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [10.567, -1.493],
                          [0, 0],
                          [-0.013, 8.7],
                          [-8.5, -2.236],
                          [0, 0],
                        ],
                        v: [
                          [-36.191, -16.646],
                          [7.037, -30.77],
                          [17.09, -30.77],
                          [37.441, 11.834],
                          [10.053, 30.77],
                          [-31.164, 5.549],
                        ],
                        c: true,
                      },
                    ],
                    e: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [-36.191, -16.646],
                          [7.037, -30.77],
                          [17.09, -30.77],
                          [36.191, 9.584],
                          [10.053, 30.77],
                          [-31.164, 5.549],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 0.333, y: 0 },
                    t: 20,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [-36.191, -16.646],
                          [7.037, -30.77],
                          [17.09, -30.77],
                          [36.191, 9.584],
                          [10.053, 30.77],
                          [-31.164, 5.549],
                        ],
                        c: true,
                      },
                    ],
                    e: [
                      {
                        i: [
                          [0, 0],
                          [-21.609, 3.054],
                          [0, 0],
                          [0.013, -8.7],
                          [8.5, 2.236],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [10.567, -1.493],
                          [0, 0],
                          [-0.013, 8.7],
                          [-8.5, -2.236],
                          [0, 0],
                        ],
                        v: [
                          [-36.191, -16.646],
                          [7.037, -30.77],
                          [17.09, -30.77],
                          [37.441, 11.834],
                          [10.053, 30.77],
                          [-31.164, 5.549],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 0.333, y: 0 },
                    t: 25,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [-21.609, 3.054],
                          [0, 0],
                          [0.013, -8.7],
                          [8.5, 2.236],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [10.567, -1.493],
                          [0, 0],
                          [-0.013, 8.7],
                          [-8.5, -2.236],
                          [0, 0],
                        ],
                        v: [
                          [-36.191, -16.646],
                          [7.037, -30.77],
                          [17.09, -30.77],
                          [37.441, 11.834],
                          [10.053, 30.77],
                          [-31.164, 5.549],
                        ],
                        c: true,
                      },
                    ],
                    e: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [-36.191, -16.646],
                          [7.037, -30.77],
                          [17.09, -30.77],
                          [36.191, 9.584],
                          [10.053, 30.77],
                          [-31.164, 5.549],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 0.333, y: 0 },
                    t: 30,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [-36.191, -16.646],
                          [7.037, -30.77],
                          [17.09, -30.77],
                          [36.191, 9.584],
                          [10.053, 30.77],
                          [-31.164, 5.549],
                        ],
                        c: true,
                      },
                    ],
                    e: [
                      {
                        i: [
                          [0, 0],
                          [-21.609, 3.054],
                          [0, 0],
                          [0.013, -8.7],
                          [8.5, 2.236],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [10.567, -1.493],
                          [0, 0],
                          [-0.013, 8.7],
                          [-8.5, -2.236],
                          [0, 0],
                        ],
                        v: [
                          [-36.191, -16.646],
                          [7.037, -30.77],
                          [17.09, -30.77],
                          [37.441, 11.834],
                          [10.053, 30.77],
                          [-31.164, 5.549],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 0.333, y: 0 },
                    t: 35,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [-21.609, 3.054],
                          [0, 0],
                          [0.013, -8.7],
                          [8.5, 2.236],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [10.567, -1.493],
                          [0, 0],
                          [-0.013, 8.7],
                          [-8.5, -2.236],
                          [0, 0],
                        ],
                        v: [
                          [-36.191, -16.646],
                          [7.037, -30.77],
                          [17.09, -30.77],
                          [37.441, 11.834],
                          [10.053, 30.77],
                          [-31.164, 5.549],
                        ],
                        c: true,
                      },
                    ],
                    e: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [-36.191, -16.646],
                          [7.037, -30.77],
                          [17.09, -30.77],
                          [36.191, 9.584],
                          [10.053, 30.77],
                          [-31.164, 5.549],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 0.333, y: 0 },
                    t: 40,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [-36.191, -16.646],
                          [7.037, -30.77],
                          [17.09, -30.77],
                          [36.191, 9.584],
                          [10.053, 30.77],
                          [-31.164, 5.549],
                        ],
                        c: true,
                      },
                    ],
                    e: [
                      {
                        i: [
                          [0, 0],
                          [-21.609, 3.054],
                          [0, 0],
                          [0.013, -8.7],
                          [8.5, 2.236],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [10.567, -1.493],
                          [0, 0],
                          [-0.013, 8.7],
                          [-8.5, -2.236],
                          [0, 0],
                        ],
                        v: [
                          [-36.191, -16.646],
                          [7.037, -30.77],
                          [17.09, -30.77],
                          [37.441, 11.834],
                          [10.053, 30.77],
                          [-31.164, 5.549],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 0.333, y: 0 },
                    t: 45,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [-21.609, 3.054],
                          [0, 0],
                          [0.013, -8.7],
                          [8.5, 2.236],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [10.567, -1.493],
                          [0, 0],
                          [-0.013, 8.7],
                          [-8.5, -2.236],
                          [0, 0],
                        ],
                        v: [
                          [-36.191, -16.646],
                          [7.037, -30.77],
                          [17.09, -30.77],
                          [37.441, 11.834],
                          [10.053, 30.77],
                          [-31.164, 5.549],
                        ],
                        c: true,
                      },
                    ],
                    e: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [-36.191, -16.646],
                          [7.037, -30.77],
                          [17.09, -30.77],
                          [36.191, 9.584],
                          [10.053, 30.77],
                          [-31.164, 5.549],
                        ],
                        c: true,
                      },
                    ],
                  },
                  { t: 50 },
                ],
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.969000004787, 0.57599995931, 0.102000000898, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 3.027, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [39.218, 33.797], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 50,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 19,
      ty: 4,
      nm: 'energy Outlines',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 0,
              s: [-21],
              e: [13],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 25,
              s: [13],
              e: [-21],
            },
            { t: 50 },
          ],
          ix: 10,
        },
        p: { a: 0, k: [178.795, 73.735, 0], ix: 2, l: 2 },
        a: { a: 0, k: [5.905, 11.6, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [1.884, -11.35],
                    [-5.655, 1.892],
                    [-0.001, 1.892],
                    [-1.886, 11.35],
                    [5.655, -1.891],
                    [-0.001, -1.891],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.969000004787, 0.57599995931, 0.102000000898, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [5.905, 11.6], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 50,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 20,
      ty: 4,
      nm: 'crypto Outlines',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 0,
              s: [13],
              e: [-21],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.167], y: [0] },
              t: 25,
              s: [-21],
              e: [13],
            },
            { t: 50 },
          ],
          ix: 10,
        },
        p: { a: 0, k: [136.043, 51.49, 0], ix: 2, l: 2 },
        a: { a: 0, k: [7.94, 7.968, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-0.185, 0.097],
                    [-0.067, 0],
                    [-0.048, -0.053],
                    [0, -0.069],
                    [0.101, -0.051],
                    [0.247, -0.084],
                    [0.344, 0],
                    [0.295, 0.114],
                    [0.217, 0.217],
                    [0, 0.764],
                    [-0.461, 0.454],
                    [-0.706, 0],
                    [-0.247, -0.083],
                    [-0.132, -0.072],
                    [-0.028, -0.044],
                    [0, -0.068],
                    [0.049, -0.053],
                    [0.067, 0],
                    [0.037, 0.019],
                    [0.194, 0.06],
                    [0.288, 0],
                    [0.233, -0.097],
                    [0.157, -0.172],
                    [0, -0.556],
                    [-0.309, -0.338],
                    [-0.591, 0],
                    [-0.194, 0.06],
                  ],
                  o: [
                    [0.037, -0.019],
                    [0.067, 0],
                    [0.049, 0.053],
                    [0, 0.125],
                    [-0.217, 0.121],
                    [-0.247, 0.083],
                    [-0.343, 0],
                    [-0.296, -0.113],
                    [-0.461, -0.454],
                    [0, -0.764],
                    [0.448, -0.444],
                    [0.341, 0],
                    [0.247, 0.084],
                    [0.131, 0.072],
                    [0.027, 0.044],
                    [0, 0.066],
                    [-0.048, 0.053],
                    [-0.067, 0],
                    [-0.185, -0.097],
                    [-0.194, -0.06],
                    [-0.289, 0],
                    [-0.233, 0.098],
                    [-0.309, 0.338],
                    [0, 0.556],
                    [0.337, 0.367],
                    [0.272, 0],
                    [0.194, -0.06],
                  ],
                  v: [
                    [1.676, 1.563],
                    [1.831, 1.535],
                    [2.004, 1.615],
                    [2.077, 1.799],
                    [1.925, 2.063],
                    [1.229, 2.369],
                    [0.343, 2.494],
                    [-0.615, 2.323],
                    [-1.385, 1.827],
                    [-2.077, 0],
                    [-1.385, -1.827],
                    [0.347, -2.494],
                    [1.229, -2.369],
                    [1.797, -2.136],
                    [2.036, -1.962],
                    [2.077, -1.795],
                    [2.004, -1.615],
                    [1.831, -1.535],
                    [1.676, -1.563],
                    [1.107, -1.799],
                    [0.385, -1.889],
                    [-0.398, -1.744],
                    [-0.982, -1.34],
                    [-1.447, 0],
                    [-0.982, 1.34],
                    [0.409, 1.889],
                    [1.107, 1.799],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 0.386, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [8.017, 7.953], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-0.185, 0.097],
                    [-0.067, 0],
                    [-0.048, -0.053],
                    [0, -0.069],
                    [0.101, -0.051],
                    [0.247, -0.084],
                    [0.344, 0],
                    [0.295, 0.114],
                    [0.217, 0.217],
                    [0, 0.764],
                    [-0.461, 0.454],
                    [-0.706, 0],
                    [-0.247, -0.083],
                    [-0.132, -0.072],
                    [-0.028, -0.044],
                    [0, -0.068],
                    [0.049, -0.053],
                    [0.067, 0],
                    [0.037, 0.019],
                    [0.194, 0.06],
                    [0.288, 0],
                    [0.233, -0.097],
                    [0.157, -0.172],
                    [0, -0.556],
                    [-0.309, -0.338],
                    [-0.591, 0],
                    [-0.194, 0.06],
                  ],
                  o: [
                    [0.037, -0.019],
                    [0.067, 0],
                    [0.049, 0.053],
                    [0, 0.125],
                    [-0.217, 0.121],
                    [-0.247, 0.083],
                    [-0.343, 0],
                    [-0.296, -0.113],
                    [-0.461, -0.454],
                    [0, -0.764],
                    [0.448, -0.444],
                    [0.341, 0],
                    [0.247, 0.084],
                    [0.131, 0.072],
                    [0.027, 0.044],
                    [0, 0.066],
                    [-0.048, 0.053],
                    [-0.067, 0],
                    [-0.185, -0.097],
                    [-0.194, -0.06],
                    [-0.289, 0],
                    [-0.233, 0.098],
                    [-0.309, 0.338],
                    [0, 0.556],
                    [0.337, 0.367],
                    [0.272, 0],
                    [0.194, -0.06],
                  ],
                  v: [
                    [1.676, 1.563],
                    [1.831, 1.535],
                    [2.004, 1.615],
                    [2.077, 1.799],
                    [1.925, 2.063],
                    [1.229, 2.369],
                    [0.343, 2.494],
                    [-0.615, 2.323],
                    [-1.385, 1.827],
                    [-2.077, 0],
                    [-1.385, -1.827],
                    [0.347, -2.494],
                    [1.229, -2.369],
                    [1.797, -2.136],
                    [2.036, -1.962],
                    [2.077, -1.795],
                    [2.004, -1.615],
                    [1.831, -1.535],
                    [1.676, -1.563],
                    [1.107, -1.799],
                    [0.385, -1.889],
                    [-0.398, -1.744],
                    [-0.982, -1.34],
                    [-1.447, 0],
                    [-0.982, 1.34],
                    [0.409, 1.889],
                    [1.107, 1.799],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [8.017, 7.953], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 2',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-0.369, 0.195],
                    [-0.133, 0],
                    [-0.096, -0.106],
                    [0, -0.14],
                    [0.203, -0.102],
                    [0.494, -0.167],
                    [0.688, 0],
                    [0.59, 0.227],
                    [0.434, 0.435],
                    [0, 1.528],
                    [-0.923, 0.907],
                    [-1.412, 0],
                    [-0.494, -0.166],
                    [-0.263, -0.144],
                    [-0.056, -0.088],
                    [0, -0.134],
                    [0.097, -0.107],
                    [0.134, 0],
                    [0.074, 0.037],
                    [0.387, 0.121],
                    [0.577, 0],
                    [0.466, -0.195],
                    [0.314, -0.342],
                    [0, -1.112],
                    [-0.618, -0.677],
                    [-1.182, 0],
                    [-0.388, 0.12],
                  ],
                  o: [
                    [0.074, -0.037],
                    [0.134, 0],
                    [0.097, 0.107],
                    [0, 0.25],
                    [-0.434, 0.241],
                    [-0.494, 0.166],
                    [-0.687, 0],
                    [-0.591, -0.227],
                    [-0.923, -0.907],
                    [0, -1.529],
                    [0.895, -0.889],
                    [0.683, 0],
                    [0.494, 0.167],
                    [0.263, 0.143],
                    [0.055, 0.088],
                    [0, 0.134],
                    [-0.096, 0.106],
                    [-0.133, 0],
                    [-0.369, -0.195],
                    [-0.388, -0.12],
                    [-0.577, 0],
                    [-0.466, 0.194],
                    [-0.618, 0.677],
                    [0, 1.111],
                    [0.674, 0.731],
                    [0.544, 0],
                    [0.387, -0.121],
                  ],
                  v: [
                    [3.35, 3.125],
                    [3.661, 3.07],
                    [4.007, 3.229],
                    [4.153, 3.598],
                    [3.848, 4.125],
                    [2.457, 4.738],
                    [0.685, 4.988],
                    [-1.232, 4.646],
                    [-2.769, 3.654],
                    [-4.153, 0],
                    [-2.769, -3.654],
                    [0.692, -4.988],
                    [2.457, -4.738],
                    [3.592, -4.271],
                    [4.07, -3.925],
                    [4.153, -3.592],
                    [4.007, -3.23],
                    [3.661, -3.071],
                    [3.35, -3.126],
                    [2.215, -3.599],
                    [0.768, -3.779],
                    [-0.796, -3.487],
                    [-1.966, -2.682],
                    [-2.893, 0],
                    [-1.966, 2.682],
                    [0.817, 3.779],
                    [2.215, 3.598],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [7.479, 7.938], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 3',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 3,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-4.247, 0],
                    [0, -4.262],
                    [4.248, 0],
                    [0, 4.262],
                  ],
                  o: [
                    [4.248, 0],
                    [0, 4.262],
                    [-4.247, 0],
                    [0, -4.262],
                  ],
                  v: [
                    [-0.001, -7.718],
                    [7.691, 0],
                    [-0.001, 7.718],
                    [-7.691, 0],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.969000004787, 0.57599995931, 0.102000000898, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [7.94, 7.968], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 4',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 4,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 50,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 21,
      ty: 4,
      nm: '25 Outlines',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.667, y: 1 },
              o: { x: 0.333, y: 0 },
              t: 0,
              s: [44.227, 42.082, 0],
              e: [44.227, 40.082, 0],
              to: [0, -0.333, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.667, y: 1 },
              o: { x: 0.333, y: 0 },
              t: 5,
              s: [44.227, 40.082, 0],
              e: [44.227, 42.082, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.667, y: 1 },
              o: { x: 0.333, y: 0 },
              t: 10,
              s: [44.227, 42.082, 0],
              e: [44.227, 40.082, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.667, y: 1 },
              o: { x: 0.333, y: 0 },
              t: 15,
              s: [44.227, 40.082, 0],
              e: [44.227, 42.082, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.667, y: 1 },
              o: { x: 0.333, y: 0 },
              t: 20,
              s: [44.227, 42.082, 0],
              e: [44.227, 40.082, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.667, y: 1 },
              o: { x: 0.333, y: 0 },
              t: 25,
              s: [44.227, 40.082, 0],
              e: [44.227, 42.082, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.667, y: 1 },
              o: { x: 0.333, y: 0 },
              t: 30,
              s: [44.227, 42.082, 0],
              e: [44.227, 40.082, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.667, y: 1 },
              o: { x: 0.333, y: 0 },
              t: 35,
              s: [44.227, 40.082, 0],
              e: [44.227, 42.082, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.667, y: 1 },
              o: { x: 0.333, y: 0 },
              t: 40,
              s: [44.227, 42.082, 0],
              e: [44.227, 40.082, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.667, y: 1 },
              o: { x: 0.333, y: 0 },
              t: 45,
              s: [44.227, 40.082, 0],
              e: [44.227, 42.082, 0],
              to: [0, 0, 0],
              ti: [0, -0.333, 0],
            },
            { t: 50 },
          ],
          ix: 2,
          l: 2,
        },
        a: { a: 0, k: [33.468, 19.557, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [1.099, -6.593],
                    [-3.295, 1.098],
                    [0.001, 1.098],
                    [-1.098, 6.593],
                    [3.295, -1.098],
                    [0.001, -1.098],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [15.134, 15.904], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [4.852, 0],
                    [0, -4.854],
                    [-4.852, 0],
                    [0, 4.855],
                  ],
                  o: [
                    [-4.852, 0],
                    [0, 4.855],
                    [4.852, 0],
                    [0, -4.854],
                  ],
                  v: [
                    [-0.001, -8.79],
                    [-8.786, 0],
                    [-0.001, 8.79],
                    [8.786, 0],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.258999992819, 0.522000002394, 0.957000014361, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [15.134, 15.904], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 2',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.149, 1.099],
                    [0, 0],
                    [-0.352, -0.335],
                    [-0.46, 0],
                    [-0.364, 0.424],
                    [0, 0.86],
                    [0.364, 0.399],
                    [0.579, 0],
                    [0.573, -0.639],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-0.585, 0],
                    [-0.776, -0.812],
                    [0, -1.296],
                    [0.627, -0.848],
                    [1.517, 0],
                    [0.764, 0.65],
                  ],
                  o: [
                    [0, 0],
                    [0.072, 0.568],
                    [0.353, 0.328],
                    [0.526, 0],
                    [0.364, -0.43],
                    [0, -0.807],
                    [-0.358, -0.407],
                    [-0.723, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0.561, -0.281],
                    [1.117, 0],
                    [0.777, 0.812],
                    [0, 1.081],
                    [-0.854, 1.158],
                    [-1.212, 0],
                    [-0.765, -0.651],
                  ],
                  v: [
                    [7.215, 3.103],
                    [9.723, 2.844],
                    [10.359, 4.197],
                    [11.577, 4.689],
                    [12.912, 4.053],
                    [13.458, 2.118],
                    [12.912, 0.31],
                    [11.506, -0.301],
                    [9.562, 0.659],
                    [7.52, 0.362],
                    [8.81, -6.471],
                    [15.465, -6.471],
                    [15.465, -4.116],
                    [10.717, -4.116],
                    [10.323, -1.885],
                    [12.043, -2.306],
                    [14.882, -1.089],
                    [16.047, 2.073],
                    [15.107, 4.966],
                    [11.55, 6.704],
                    [8.586, 5.728],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 1,
              ty: 'sh',
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.125, 1.105],
                    [0, 0],
                    [-0.341, -0.328],
                    [-0.484, 0],
                    [-0.353, 0.394],
                    [0, 0.669],
                    [0.341, 0.37],
                    [0.489, 0],
                    [0.448, -0.126],
                    [0, 0],
                    [-0.358, 0.311],
                    [0, 0.52],
                    [0.263, 0.263],
                    [0.436, 0],
                    [0.304, -0.298],
                    [0.066, -0.574],
                    [0, 0],
                    [-0.322, 0.471],
                    [-0.579, 0.269],
                    [-0.722, 0],
                    [-0.747, -0.789],
                    [0, -0.812],
                    [1.26, -0.687],
                    [-0.448, -0.561],
                    [0, -0.794],
                    [0.842, -0.812],
                    [1.254, 0],
                    [0.782, 0.681],
                  ],
                  o: [
                    [0, 0],
                    [0.077, 0.621],
                    [0.34, 0.329],
                    [0.519, 0],
                    [0.358, -0.394],
                    [0, -0.633],
                    [-0.34, -0.371],
                    [-0.323, 0],
                    [0, 0],
                    [0.68, 0.018],
                    [0.358, -0.316],
                    [0, -0.442],
                    [-0.262, -0.262],
                    [-0.431, 0],
                    [-0.305, 0.298],
                    [0, 0],
                    [0.161, -0.794],
                    [0.329, -0.478],
                    [0.586, -0.275],
                    [1.236, 0],
                    [0.615, 0.644],
                    [0, 1.152],
                    [0.752, 0.161],
                    [0.454, 0.562],
                    [0, 1.152],
                    [-0.842, 0.812],
                    [-1.189, 0],
                    [-0.782, -0.687],
                  ],
                  v: [
                    [-3.121, 2.996],
                    [-0.685, 2.701],
                    [-0.058, 4.125],
                    [1.178, 4.617],
                    [2.486, 4.026],
                    [3.023, 2.431],
                    [2.512, 0.927],
                    [1.268, 0.371],
                    [0.112, 0.56],
                    [0.39, -1.491],
                    [1.948, -1.931],
                    [2.486, -3.185],
                    [2.091, -4.241],
                    [1.044, -4.635],
                    [-0.058, -4.188],
                    [-0.614, -2.879],
                    [-2.933, -3.273],
                    [-2.208, -5.172],
                    [-0.847, -6.293],
                    [1.115, -6.704],
                    [4.089, -5.522],
                    [5.012, -3.336],
                    [3.122, -0.578],
                    [4.922, 0.505],
                    [5.603, 2.539],
                    [4.34, 5.487],
                    [1.196, 6.704],
                    [-1.76, 5.684],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 2',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 2,
              ty: 'sh',
              ix: 3,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-16.047, 6.48],
                    [-11.559, -0.371],
                    [-15.626, -6.651],
                    [-12.527, -6.651],
                    [-9.893, -2.431],
                    [-7.313, -6.651],
                    [-4.241, -6.651],
                    [-8.326, -0.272],
                    [-3.838, 6.48],
                    [-7.036, 6.48],
                    [-9.947, 1.94],
                    [-12.867, 6.48],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 3',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'mm',
              mm: 1,
              nm: 'Merge Paths 1',
              mn: 'ADBE Vector Filter - Merge',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [0, 0, 0, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [42.353, 16.487], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 3',
          np: 5,
          cix: 2,
          bm: 0,
          ix: 3,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 2.761],
                    [0, 0],
                    [-2.761, 0],
                    [0, 0],
                    [0, -2.761],
                    [0, 0],
                    [2.762, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-2.761, 0],
                    [0, 0],
                    [0, -2.761],
                    [0, 0],
                    [2.762, 0],
                    [0, 0],
                    [0, 2.761],
                    [0, 0],
                  ],
                  v: [
                    [5.73, 10.252],
                    [5.214, 10.252],
                    [4.917, 10.671],
                    [0.001, 17.557],
                    [-4.915, 10.671],
                    [-5.214, 10.252],
                    [-5.729, 10.252],
                    [-26.467, 10.252],
                    [-31.467, 5.253],
                    [-31.467, -12.557],
                    [-26.467, -17.557],
                    [26.467, -17.557],
                    [31.467, -12.557],
                    [31.467, 5.253],
                    [26.467, 10.252],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.258999992819, 0.522000002394, 0.957000014361, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 2, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [33.468, 19.558], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 4',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 4,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 2.761],
                    [0, 0],
                    [-2.761, 0],
                    [0, 0],
                    [0, -2.761],
                    [0, 0],
                    [2.762, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-2.761, 0],
                    [0, 0],
                    [0, -2.761],
                    [0, 0],
                    [2.762, 0],
                    [0, 0],
                    [0, 2.761],
                    [0, 0],
                  ],
                  v: [
                    [5.73, 10.252],
                    [5.214, 10.252],
                    [4.917, 10.671],
                    [0.001, 17.557],
                    [-4.915, 10.671],
                    [-5.214, 10.252],
                    [-5.729, 10.252],
                    [-26.467, 10.252],
                    [-31.467, 5.253],
                    [-31.467, -12.557],
                    [-26.467, -17.557],
                    [26.467, -17.557],
                    [31.467, -12.557],
                    [31.467, 5.253],
                    [26.467, 10.252],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [33.468, 19.558], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 5',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 5,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 50,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 22,
      ty: 4,
      nm: 'arrow_cut Outlines 3',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.167], y: [0] },
              t: -25,
              s: [89],
              e: [89],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: -17,
              s: [89],
              e: [180],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: -14,
              s: [180],
              e: [180],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: -3,
              s: [180],
              e: [271],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 0,
              s: [271],
              e: [271],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 8,
              s: [271],
              e: [362],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 11,
              s: [362],
              e: [358],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 22,
              s: [358],
              e: [449],
            },
            { t: 25 },
          ],
          ix: 10,
        },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.566, y: 0.566 },
              o: { x: 0.167, y: 0.167 },
              t: -25,
              s: [106.625, 75.188, 0],
              e: [106.625, 93.563, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.66, y: 0.66 },
              o: { x: 0.326, y: 0.326 },
              t: -15.865,
              s: [106.625, 93.563, 0],
              e: [104.625, 95.563, 0],
              to: [0, 1.105, 0],
              ti: [1.105, 0, 0],
            },
            {
              i: { x: 0.65, y: 0.65 },
              o: { x: 0.304, y: 0.304 },
              t: -14.303,
              s: [104.625, 95.563, 0],
              e: [79, 95.563, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.666, y: 0.666 },
              o: { x: 0.333, y: 0.333 },
              t: -1.563,
              s: [79, 95.563, 0],
              e: [77, 93.563, 0],
              to: [-1.105, 0, 0],
              ti: [0, 1.105, 0],
            },
            {
              i: { x: 0.677, y: 0.677 },
              o: { x: 0.338, y: 0.338 },
              t: 0,
              s: [77, 93.563, 0],
              e: [77, 75.188, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.669, y: 0.669 },
              o: { x: 0.336, y: 0.336 },
              t: 9.136,
              s: [77, 75.188, 0],
              e: [79, 73.188, 0],
              to: [0, -1.105, 0],
              ti: [-1.105, 0, 0],
            },
            {
              i: { x: 0.777, y: 0.777 },
              o: { x: 0.405, y: 0.405 },
              t: 10.698,
              s: [79, 73.188, 0],
              e: [104.625, 73.188, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.393, y: 0.393 },
              t: 23.438,
              s: [104.625, 73.188, 0],
              e: [106.625, 75.188, 0],
              to: [1.105, 0, 0],
              ti: [0, -1.105, 0],
            },
            { t: 25 },
          ],
          ix: 2,
          l: 2,
        },
        a: { a: 0, k: [2.88, 4.605, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, -0.23],
                    [0.14, -0.16],
                    [0, 0],
                    [0.23, 0],
                    [0.16, 0.16],
                    [-0.33, 0.29],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-0.33, 0.29],
                    [-0.3, -0.33],
                    [0, 0],
                  ],
                  o: [
                    [0, 0.23],
                    [0, 0],
                    [-0.17, 0.16],
                    [-0.23, 0],
                    [-0.33, -0.33],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-0.33, -0.33],
                    [0.33, -0.33],
                    [0, 0],
                    [0.17, 0.16],
                  ],
                  v: [
                    [2.63, 0.045],
                    [2.39, 0.635],
                    [-1.11, 4.125],
                    [-1.71, 4.355],
                    [-2.3, 4.125],
                    [-2.3, 2.975],
                    [-0.91, 1.585],
                    [-0.19, 0.865],
                    [-0.21, 0.865],
                    [0.6, 0.025],
                    [-0.18, -0.775],
                    [-0.15, -0.775],
                    [-1.33, -1.935],
                    [-1.38, -1.995],
                    [-2.27, -2.875],
                    [-2.27, -4.025],
                    [-1.11, -4.025],
                    [2.39, -0.545],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.968627512455, 0.57647061348, 0.101960793138, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [2.88, 4.605], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: -25,
      op: 25,
      st: -25,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 23,
      ty: 4,
      nm: 'arrow_cut Outlines 2',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.167], y: [0] },
              t: 25,
              s: [89],
              e: [89],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 33,
              s: [89],
              e: [180],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 36,
              s: [180],
              e: [180],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 47,
              s: [180],
              e: [271],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 50,
              s: [271],
              e: [271],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 58,
              s: [271],
              e: [362],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 61,
              s: [362],
              e: [358],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 72,
              s: [358],
              e: [449],
            },
            { t: 75 },
          ],
          ix: 10,
        },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.566, y: 0.566 },
              o: { x: 0.167, y: 0.167 },
              t: 25,
              s: [106.625, 75.188, 0],
              e: [106.625, 93.563, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.66, y: 0.66 },
              o: { x: 0.326, y: 0.326 },
              t: 34.135,
              s: [106.625, 93.563, 0],
              e: [104.625, 95.563, 0],
              to: [0, 1.105, 0],
              ti: [1.105, 0, 0],
            },
            {
              i: { x: 0.65, y: 0.65 },
              o: { x: 0.304, y: 0.304 },
              t: 35.697,
              s: [104.625, 95.563, 0],
              e: [79, 95.563, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.666, y: 0.666 },
              o: { x: 0.333, y: 0.333 },
              t: 48.438,
              s: [79, 95.563, 0],
              e: [77, 93.563, 0],
              to: [-1.105, 0, 0],
              ti: [0, 1.105, 0],
            },
            {
              i: { x: 0.677, y: 0.677 },
              o: { x: 0.338, y: 0.338 },
              t: 50,
              s: [77, 93.563, 0],
              e: [77, 75.188, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.669, y: 0.669 },
              o: { x: 0.336, y: 0.336 },
              t: 59.136,
              s: [77, 75.188, 0],
              e: [79, 73.188, 0],
              to: [0, -1.105, 0],
              ti: [-1.105, 0, 0],
            },
            {
              i: { x: 0.777, y: 0.777 },
              o: { x: 0.405, y: 0.405 },
              t: 60.698,
              s: [79, 73.188, 0],
              e: [104.625, 73.188, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.393, y: 0.393 },
              t: 73.438,
              s: [104.625, 73.188, 0],
              e: [106.625, 75.188, 0],
              to: [1.105, 0, 0],
              ti: [0, -1.105, 0],
            },
            { t: 75 },
          ],
          ix: 2,
          l: 2,
        },
        a: { a: 0, k: [2.88, 4.605, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, -0.23],
                    [0.14, -0.16],
                    [0, 0],
                    [0.23, 0],
                    [0.16, 0.16],
                    [-0.33, 0.29],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-0.33, 0.29],
                    [-0.3, -0.33],
                    [0, 0],
                  ],
                  o: [
                    [0, 0.23],
                    [0, 0],
                    [-0.17, 0.16],
                    [-0.23, 0],
                    [-0.33, -0.33],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-0.33, -0.33],
                    [0.33, -0.33],
                    [0, 0],
                    [0.17, 0.16],
                  ],
                  v: [
                    [2.63, 0.045],
                    [2.39, 0.635],
                    [-1.11, 4.125],
                    [-1.71, 4.355],
                    [-2.3, 4.125],
                    [-2.3, 2.975],
                    [-0.91, 1.585],
                    [-0.19, 0.865],
                    [-0.21, 0.865],
                    [0.6, 0.025],
                    [-0.18, -0.775],
                    [-0.15, -0.775],
                    [-1.33, -1.935],
                    [-1.38, -1.995],
                    [-2.27, -2.875],
                    [-2.27, -4.025],
                    [-1.11, -4.025],
                    [2.39, -0.545],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.968627512455, 0.57647061348, 0.101960793138, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [2.88, 4.605], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 25,
      op: 75,
      st: 25,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 24,
      ty: 4,
      nm: 'arrow_cut Outlines',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.167], y: [0] },
              t: 0,
              s: [89],
              e: [89],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 8,
              s: [89],
              e: [180],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 11,
              s: [180],
              e: [180],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 22,
              s: [180],
              e: [271],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 25,
              s: [271],
              e: [271],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 33,
              s: [271],
              e: [362],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 36,
              s: [362],
              e: [358],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 47,
              s: [358],
              e: [449],
            },
            { t: 50 },
          ],
          ix: 10,
        },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 0,
              s: [106.625, 75.188, 0],
              e: [106.625, 93.563, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 9.135,
              s: [106.625, 93.563, 0],
              e: [104.625, 95.563, 0],
              to: [0, 1.105, 0],
              ti: [1.105, 0, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 10.697,
              s: [104.625, 95.563, 0],
              e: [79, 95.563, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 23.438,
              s: [79, 95.563, 0],
              e: [77, 93.563, 0],
              to: [-1.105, 0, 0],
              ti: [0, 1.105, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 25,
              s: [77, 93.563, 0],
              e: [77, 75.188, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 34.135,
              s: [77, 75.188, 0],
              e: [79, 73.188, 0],
              to: [0, -1.105, 0],
              ti: [-1.105, 0, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 35.697,
              s: [79, 73.188, 0],
              e: [104.625, 73.188, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 48.438,
              s: [104.625, 73.188, 0],
              e: [106.625, 75.188, 0],
              to: [1.105, 0, 0],
              ti: [0, -1.105, 0],
            },
            { t: 50 },
          ],
          ix: 2,
          l: 2,
        },
        a: { a: 0, k: [2.88, 4.605, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, -0.23],
                    [0.14, -0.16],
                    [0, 0],
                    [0.23, 0],
                    [0.16, 0.16],
                    [-0.33, 0.29],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-0.33, 0.29],
                    [-0.3, -0.33],
                    [0, 0],
                  ],
                  o: [
                    [0, 0.23],
                    [0, 0],
                    [-0.17, 0.16],
                    [-0.23, 0],
                    [-0.33, -0.33],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-0.33, -0.33],
                    [0.33, -0.33],
                    [0, 0],
                    [0.17, 0.16],
                  ],
                  v: [
                    [2.63, 0.045],
                    [2.39, 0.635],
                    [-1.11, 4.125],
                    [-1.71, 4.355],
                    [-2.3, 4.125],
                    [-2.3, 2.975],
                    [-0.91, 1.585],
                    [-0.19, 0.865],
                    [-0.21, 0.865],
                    [0.6, 0.025],
                    [-0.18, -0.775],
                    [-0.15, -0.775],
                    [-1.33, -1.935],
                    [-1.38, -1.995],
                    [-2.27, -2.875],
                    [-2.27, -4.025],
                    [-1.11, -4.025],
                    [2.39, -0.545],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.258999992819, 0.522000002394, 0.957000014361, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [2.88, 4.605], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 50,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 25,
      ty: 4,
      nm: 'Shape Layer 2',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [148.5, 71, 0], ix: 2, l: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, -1.105],
                    [0, 0],
                    [1.105, 0],
                    [0, 0],
                    [0, 1.105],
                    [0, 0],
                    [-1.105, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 1.105],
                    [0, 0],
                    [-1.105, 0],
                    [0, 0],
                    [0, -1.105],
                    [0, 0],
                    [1.105, 0],
                  ],
                  v: [
                    [14.813, -9.188],
                    [14.813, 9.188],
                    [12.813, 11.188],
                    [-12.813, 11.188],
                    [-14.813, 9.188],
                    [-14.813, -9.188],
                    [-12.813, -11.188],
                    [12.813, -11.188],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.258823529412, 0.521568627451, 0.956862804936, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 2, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [-56.688, 13.375], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Rectangle 1',
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'tm',
          s: { a: 0, k: 0, ix: 1 },
          e: { a: 0, k: 46, ix: 2 },
          o: {
            a: 1,
            k: [
              {
                i: { x: [0.833], y: [0.833] },
                o: { x: [0.167], y: [0.167] },
                t: 0,
                s: [195],
                e: [555],
              },
              { t: 50 },
            ],
            ix: 3,
          },
          m: 1,
          ix: 2,
          nm: 'Trim Paths 1',
          mn: 'ADBE Vector Filter - Trim',
          hd: false,
        },
      ],
      ip: 0,
      op: 50,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 26,
      ty: 4,
      nm: 'Shape Layer 1',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [148.5, 71, 0], ix: 2, l: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ty: 'rc',
              d: 1,
              s: { a: 0, k: [29.625, 22.375], ix: 2 },
              p: { a: 0, k: [0, 0], ix: 3 },
              r: { a: 0, k: 2, ix: 4 },
              nm: 'Rectangle Path 1',
              mn: 'ADBE Vector Shape - Rect',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.968627510819, 0.576470588235, 0.101960791794, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 2, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [-56.688, 13.375], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Rectangle 1',
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'tm',
          s: { a: 0, k: 0, ix: 1 },
          e: { a: 0, k: 46, ix: 2 },
          o: {
            a: 1,
            k: [
              {
                i: { x: [0.833], y: [0.833] },
                o: { x: [0.167], y: [0.167] },
                t: 0,
                s: [18],
                e: [378],
              },
              { t: 50 },
            ],
            ix: 3,
          },
          m: 1,
          ix: 2,
          nm: 'Trim Paths 1',
          mn: 'ADBE Vector Filter - Trim',
          hd: false,
        },
      ],
      ip: 0,
      op: 50,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 27,
      ty: 4,
      nm: 'Group 16',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 38,
              s: [100],
              e: [50],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 44.25,
              s: [50],
              e: [10],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 50.5,
              s: [10],
              e: [50],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 56.75,
              s: [50],
              e: [100],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 63,
              s: [100],
              e: [50],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 69.25,
              s: [50],
              e: [10],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 75.5,
              s: [10],
              e: [50],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 81.75,
              s: [50],
              e: [100],
            },
            { t: 88 },
          ],
          ix: 11,
        },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [90.947, 84.047, 0], ix: 2, l: 2 },
        a: { a: 0, k: [6.591, 1.609, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-0.75, 0],
                    [0, -0.75],
                    [0.75, 0],
                    [0, 0.751],
                  ],
                  o: [
                    [0.75, 0],
                    [0, 0.751],
                    [-0.75, 0],
                    [0, -0.75],
                  ],
                  v: [
                    [0.001, -1.36],
                    [1.359, -0.001],
                    [0.001, 1.36],
                    [-1.359, -0.001],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.838999968884, 0.838999968884, 0.838999968884, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [11.573, 1.61], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 38,
      op: 88,
      st: 38,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 28,
      ty: 4,
      nm: 'Group 1',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: -12,
              s: [100],
              e: [50],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: -5.75,
              s: [50],
              e: [10],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 0.5,
              s: [10],
              e: [50],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 6.75,
              s: [50],
              e: [100],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 13,
              s: [100],
              e: [50],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 19.25,
              s: [50],
              e: [10],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 25.5,
              s: [10],
              e: [50],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 31.75,
              s: [50],
              e: [100],
            },
            { t: 38 },
          ],
          ix: 11,
        },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [90.947, 84.047, 0], ix: 2, l: 2 },
        a: { a: 0, k: [6.591, 1.609, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-0.75, 0],
                    [0, -0.75],
                    [0.75, 0],
                    [0, 0.751],
                  ],
                  o: [
                    [0.75, 0],
                    [0, 0.751],
                    [-0.75, 0],
                    [0, -0.75],
                  ],
                  v: [
                    [0.001, -1.36],
                    [1.359, -0.001],
                    [0.001, 1.36],
                    [-1.359, -0.001],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.838999968884, 0.838999968884, 0.838999968884, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [11.573, 1.61], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: -12,
      op: 38,
      st: -12,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 29,
      ty: 4,
      nm: 'Group 15',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 44,
              s: [100],
              e: [50],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 50.25,
              s: [50],
              e: [10],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 56.5,
              s: [10],
              e: [50],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 62.75,
              s: [50],
              e: [100],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 69,
              s: [100],
              e: [50],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 75.25,
              s: [50],
              e: [10],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 81.5,
              s: [10],
              e: [50],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 87.75,
              s: [50],
              e: [100],
            },
            { t: 94 },
          ],
          ix: 11,
        },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [90.947, 84.047, 0], ix: 2, l: 2 },
        a: { a: 0, k: [6.591, 1.609, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-0.75, 0],
                    [0, -0.75],
                    [0.75, 0],
                    [0, 0.751],
                  ],
                  o: [
                    [0.75, 0],
                    [0, 0.751],
                    [-0.75, 0],
                    [0, -0.75],
                  ],
                  v: [
                    [0.001, -1.36],
                    [1.359, -0.001],
                    [0.001, 1.36],
                    [-1.359, -0.001],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.838999986649, 0.838999986649, 0.838999986649, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [6.589, 1.61], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 2',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 44,
      op: 94,
      st: 44,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 30,
      ty: 4,
      nm: 'Group 2',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: -6,
              s: [100],
              e: [50],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 0.25,
              s: [50],
              e: [10],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 6.5,
              s: [10],
              e: [50],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 12.75,
              s: [50],
              e: [100],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 19,
              s: [100],
              e: [50],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 25.25,
              s: [50],
              e: [10],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 31.5,
              s: [10],
              e: [50],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 37.75,
              s: [50],
              e: [100],
            },
            { t: 44 },
          ],
          ix: 11,
        },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [90.947, 84.047, 0], ix: 2, l: 2 },
        a: { a: 0, k: [6.591, 1.609, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-0.75, 0],
                    [0, -0.75],
                    [0.75, 0],
                    [0, 0.751],
                  ],
                  o: [
                    [0.75, 0],
                    [0, 0.751],
                    [-0.75, 0],
                    [0, -0.75],
                  ],
                  v: [
                    [0.001, -1.36],
                    [1.359, -0.001],
                    [0.001, 1.36],
                    [-1.359, -0.001],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.838999986649, 0.838999986649, 0.838999986649, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [6.589, 1.61], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 2',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: -6,
      op: 44,
      st: -6,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 31,
      ty: 4,
      nm: 'Group 3',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 0,
              s: [100],
              e: [50],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 6.25,
              s: [50],
              e: [10],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 12.5,
              s: [10],
              e: [50],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 18.75,
              s: [50],
              e: [100],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 25,
              s: [100],
              e: [50],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 31.25,
              s: [50],
              e: [10],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 37.5,
              s: [10],
              e: [50],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 43.75,
              s: [50],
              e: [100],
            },
            { t: 50 },
          ],
          ix: 11,
        },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [90.947, 84.047, 0], ix: 2, l: 2 },
        a: { a: 0, k: [6.591, 1.609, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-0.75, 0],
                    [0, -0.75],
                    [0.75, 0],
                    [0, 0.751],
                  ],
                  o: [
                    [0.75, 0],
                    [0, 0.751],
                    [-0.75, 0],
                    [0, -0.75],
                  ],
                  v: [
                    [-0.001, -1.36],
                    [1.359, -0.001],
                    [-0.001, 1.36],
                    [-1.359, -0.001],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.838999986649, 0.838999986649, 0.838999986649, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [1.608, 1.61], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 3',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 50,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 32,
      ty: 4,
      nm: 'graph Outlines',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.667, y: 1 },
              o: { x: 0.333, y: 0 },
              t: 0,
              s: [250.383, 43.242, 0],
              e: [250.383, 40.242, 0],
              to: [0, -0.5, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.667, y: 1 },
              o: { x: 0.333, y: 0 },
              t: 5,
              s: [250.383, 40.242, 0],
              e: [250.383, 43.242, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.667, y: 1 },
              o: { x: 0.333, y: 0 },
              t: 10,
              s: [250.383, 43.242, 0],
              e: [250.383, 40.242, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.667, y: 1 },
              o: { x: 0.333, y: 0 },
              t: 15,
              s: [250.383, 40.242, 0],
              e: [250.383, 43.242, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.667, y: 1 },
              o: { x: 0.333, y: 0 },
              t: 20,
              s: [250.383, 43.242, 0],
              e: [250.383, 40.242, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.667, y: 1 },
              o: { x: 0.333, y: 0 },
              t: 25,
              s: [250.383, 40.242, 0],
              e: [250.383, 43.242, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.667, y: 1 },
              o: { x: 0.333, y: 0 },
              t: 30,
              s: [250.383, 43.242, 0],
              e: [250.383, 40.242, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.667, y: 1 },
              o: { x: 0.333, y: 0 },
              t: 35,
              s: [250.383, 40.242, 0],
              e: [250.383, 43.242, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.667, y: 1 },
              o: { x: 0.333, y: 0 },
              t: 40,
              s: [250.383, 43.242, 0],
              e: [250.383, 40.242, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.667, y: 1 },
              o: { x: 0.333, y: 0 },
              t: 45,
              s: [250.383, 40.242, 0],
              e: [250.383, 43.242, 0],
              to: [0, 0, 0],
              ti: [0, -0.5, 0],
            },
            { t: 50 },
          ],
          ix: 2,
          l: 2,
        },
        a: { a: 0, k: [39.385, 29.257, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-4.598, -0.746],
                    [2.028, -3.326],
                    [4.598, 3.326],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.258999992819, 0.522000002394, 0.957000014361, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 2.522, ix: 5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [69.41, 15.771], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [-4.858, 14.653],
                  ],
                  o: [
                    [0, 0],
                    [27.28, 0],
                    [0, 0],
                  ],
                  v: [
                    [-34.102, 14.124],
                    [-14.29, 14.124],
                    [34.102, -14.124],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.258999992819, 0.522000002394, 0.957000014361, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 2.522, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [36.624, 28.248], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 2',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 1.672],
                    [0, 0],
                    [0, 0],
                    [-4.391, 14.685],
                    [0, 0],
                  ],
                  o: [
                    [-1.671, 0],
                    [0, 0],
                    [0, 0],
                    [27.28, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-31.077, 21.69],
                    [-34.102, 18.663],
                    [-34.102, 7.566],
                    [-14.291, 7.566],
                    [34.102, -21.691],
                    [34.102, 21.69],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.969000004787, 0.57599995931, 0.102000000898, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [36.624, 35.814], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 40, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 3',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 3,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-0.503, -28.248],
                    [0.503, -28.248],
                    [0.503, 28.248],
                    [-0.503, 28.248],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [0.925, 0.925, 0.925, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [66.203, 29.257], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 4',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 4,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-0.503, -28.248],
                    [0.503, -28.248],
                    [0.503, 28.248],
                    [-0.503, 28.248],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [0.925, 0.925, 0.925, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [53.134, 29.257], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 5',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 5,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-0.503, -28.248],
                    [0.503, -28.248],
                    [0.503, 28.248],
                    [-0.503, 28.248],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [0.925, 0.925, 0.925, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [40.065, 29.257], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 6',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 6,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-0.503, -28.248],
                    [0.503, -28.248],
                    [0.503, 28.248],
                    [-0.503, 28.248],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [0.925, 0.925, 0.925, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [26.995, 29.257], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 7',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 7,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-0.111, 0.74],
                    [1.064, 0.363],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0.226, 0.052],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0.176, 0.043],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0.012, -0.009],
                    [0.01, -0.177],
                    [0, 0],
                    [-0.035, -0.013],
                    [0, 0],
                    [0.019, 0.005],
                    [0, 0],
                    [0.24, 0.058],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-0.119, -0.03],
                    [-0.079, -0.02],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-0.219, -0.054],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-0.455, 1.286],
                    [0.757, 0.39],
                  ],
                  o: [
                    [0.152, -1.011],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-0.222, -0.055],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-0.183, -0.041],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0.341, 0.084],
                    [0, 0],
                    [0.023, 0.006],
                    [0, 0],
                    [-0.018, -0.005],
                    [0, 0],
                    [-0.042, 0.103],
                    [0.009, 0.012],
                    [0, 0],
                    [0, 0],
                    [0.123, 0.03],
                    [0.08, 0.021],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0.231, 0.062],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [1.438, 0.269],
                    [0.367, -1.035],
                    [0.551, -0.126],
                  ],
                  v: [
                    [3.594, -0.943],
                    [1.902, -2.861],
                    [2.246, -4.229],
                    [1.405, -4.438],
                    [1.068, -3.103],
                    [0.392, -3.262],
                    [0.73, -4.603],
                    [-0.112, -4.811],
                    [-0.46, -3.443],
                    [-0.999, -3.568],
                    [-0.997, -3.572],
                    [-2.161, -3.858],
                    [-2.385, -2.969],
                    [-1.772, -2.818],
                    [-1.379, -2.332],
                    [-1.772, -0.772],
                    [-1.685, -0.745],
                    [-1.715, -0.751],
                    [-1.773, -0.766],
                    [-2.327, 1.419],
                    [-2.714, 1.617],
                    [-3.327, 1.467],
                    [-3.746, 2.421],
                    [-2.647, 2.691],
                    [-2.284, 2.784],
                    [-2.046, 2.845],
                    [-2.395, 4.23],
                    [-1.552, 4.438],
                    [-1.207, 3.068],
                    [-0.534, 3.24],
                    [-0.879, 4.604],
                    [-0.035, 4.811],
                    [0.315, 3.428],
                    [3.29, 2.303],
                    [2.517, 0.281],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 3',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.245, -0.972],
                        [0.688, 0.18],
                        [0.046, 0.011],
                        [0, 0],
                        [-0.079, -0.018],
                      ],
                      o: [
                        [-0.239, 0.949],
                        [-0.063, -0.016],
                        [0, 0],
                        [0.057, 0.014],
                        [0.712, 0.158],
                      ],
                      v: [
                        [15.754, 14.701],
                        [13.322, 15.078],
                        [13.158, 15.036],
                        [13.62, 13.2],
                        [13.827, 13.248],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 2',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 1,
                  ty: 'sh',
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.053, -0.014],
                        [-0.219, 0.863],
                        [0.596, 0.13],
                        [0.048, 0.012],
                        [0, 0],
                      ],
                      o: [
                        [0.574, 0.151],
                        [0.223, -0.883],
                        [-0.066, -0.015],
                        [0, 0],
                        [0.04, 0.01],
                      ],
                      v: [
                        [13.972, 12.396],
                        [16.017, 12.014],
                        [14.427, 10.735],
                        [14.253, 10.695],
                        [13.833, 12.36],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.968627510819, 0.576470588235, 0.101960791794, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0.414, -0.059], ix: 2 },
                  a: { a: 0, k: [14.601, 13.004], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [14.089, 12.972], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 8',
          np: 3,
          cix: 2,
          bm: 0,
          ix: 8,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [1.026, -4.135],
                    [4.121, 1.03],
                    [-1.027, 4.134],
                    [-4.12, -1.031],
                  ],
                  o: [
                    [-1.028, 4.134],
                    [-4.119, -1.031],
                    [1.027, -4.135],
                    [4.121, 1.031],
                  ],
                  v: [
                    [7.462, 1.867],
                    [-1.861, 7.488],
                    [-7.461, -1.865],
                    [1.859, -7.486],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.969000004787, 0.57599995931, 0.102000000898, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [14.429, 13.266], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 9',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 9,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-0.503, -28.248],
                    [0.503, -28.248],
                    [0.503, 28.248],
                    [-0.503, 28.248],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [0.925, 0.925, 0.925, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [13.927, 29.257], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 10',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 10,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-1.949, 0],
                    [0, 0],
                    [0, -1.95],
                    [0, 0],
                    [1.95, 0],
                    [0, 0],
                    [0, 1.95],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [1.95, 0],
                    [0, 0],
                    [0, 1.95],
                    [0, 0],
                    [-1.949, 0],
                    [0, 0],
                    [0, -1.95],
                  ],
                  v: [
                    [-34.673, -28.752],
                    [34.672, -28.752],
                    [38.203, -25.221],
                    [38.203, 25.222],
                    [34.672, 28.752],
                    [-34.673, 28.752],
                    [-38.203, 25.222],
                    [-38.203, -25.221],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.707189522538, 0.707189522538, 0.707189522538, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1.009, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [40.062, 29.256], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 11',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 11,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-1.672, 0],
                    [0, 0],
                    [0, -1.672],
                    [0, 0],
                    [1.672, 0],
                    [0, 0],
                    [0, 1.672],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [1.672, 0],
                    [0, 0],
                    [0, 1.672],
                    [0, 0],
                    [-1.672, 0],
                    [0, 0],
                    [0, -1.672],
                  ],
                  v: [
                    [-34.673, -28.248],
                    [34.673, -28.248],
                    [37.699, -25.221],
                    [37.699, 25.221],
                    [34.673, 28.248],
                    [-34.673, 28.248],
                    [-37.699, 25.221],
                    [-37.699, -25.221],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [40.063, 29.257], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 12',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 12,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 50,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 33,
      ty: 4,
      nm: 'shadow Outlines',
      parent: 32,
      sr: 1,
      ks: {
        o: { a: 0, k: 15, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [14.083, 40.044, 0], ix: 2, l: 2 },
        a: { a: 0, k: [14.406, 18.824, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 3.511],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-3.509, 0],
                    [0, 0],
                  ],
                  v: [
                    [-14.406, -18.824],
                    [14.406, -18.824],
                    [14.406, 18.824],
                    [-8.05, 18.824],
                    [-14.406, 12.468],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [0, 0, 0, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [14.406, 18.824], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 50,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 34,
      ty: 4,
      nm: 'back Outlines',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [131.949, 80.596, 0], ix: 2, l: 2 },
        a: { a: 0, k: [107.79, 45.648, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-2.786, 0],
                    [0, 0],
                    [0, -2.786],
                    [0, 0],
                    [2.785, 0],
                    [0, 0],
                    [0, 2.785],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [2.785, 0],
                    [0, 0],
                    [0, 2.785],
                    [0, 0],
                    [-2.786, 0],
                    [0, 0],
                    [0, -2.786],
                  ],
                  v: [
                    [-51.504, -41.54],
                    [51.505, -41.54],
                    [56.548, -36.495],
                    [56.548, 36.495],
                    [51.505, 41.54],
                    [-51.504, 41.54],
                    [-56.548, 36.495],
                    [-56.548, -36.495],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.286000001197, 0.286000001197, 0.286000001197, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [154.862, 45.825], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-3.761, 0],
                    [0, 0],
                    [0, -3.761],
                    [0, 0],
                    [3.76, 0],
                    [0, 0],
                    [0, 3.761],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [3.76, 0],
                    [0, 0],
                    [0, 3.761],
                    [0, 0],
                    [-3.761, 0],
                    [0, 0],
                    [0, -3.761],
                  ],
                  v: [
                    [-53.508, -45.398],
                    [53.509, -45.398],
                    [60.318, -38.588],
                    [60.318, 38.589],
                    [53.509, 45.398],
                    [-53.508, 45.398],
                    [-60.318, 38.589],
                    [-60.318, -38.588],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.458999992819, 0.458999992819, 0.458999992819, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [155.012, 45.649], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 2',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-0.824, 0],
                    [0, 0],
                    [0, -0.823],
                    [0, 0],
                    [0.824, 0],
                    [0, 0],
                    [0, 0.823],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0.824, 0],
                    [0, 0],
                    [0, 0.823],
                    [0, 0],
                    [-0.824, 0],
                    [0, 0],
                    [0, -0.823],
                  ],
                  v: [
                    [-11.734, -5.599],
                    [11.734, -5.599],
                    [13.225, -4.107],
                    [13.225, 4.108],
                    [11.734, 5.599],
                    [-11.734, 5.599],
                    [-13.225, 4.108],
                    [-13.225, -4.107],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.847000002394, 0.847000002394, 0.847000002394, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1.018, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [20.087, 73.743], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 3',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 3,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-10.682, -0.509],
                    [10.682, -0.509],
                    [10.682, 0.509],
                    [-10.682, 0.509],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.458999992819, 0.458999992819, 0.458999992819, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [20.086, 60.001], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 4',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 4,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-7.63, -1.018],
                    [7.63, -1.018],
                    [7.63, 1.018],
                    [-7.63, 1.018],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.458999992819, 0.458999992819, 0.458999992819, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [23.137, 73.743], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 5',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 5,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-10.173, -1.018],
                    [10.173, -1.018],
                    [10.173, 1.018],
                    [-10.173, 1.018],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.458999992819, 0.458999992819, 0.458999992819, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1.018, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [20.087, 56.948], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 6',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 6,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-0.824, 0],
                    [0, 0],
                    [0, -0.824],
                    [0, 0],
                    [0.824, 0],
                    [0, 0],
                    [0, 0.823],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0.824, 0],
                    [0, 0],
                    [0, 0.823],
                    [0, 0],
                    [-0.824, 0],
                    [0, 0],
                    [0, -0.824],
                  ],
                  v: [
                    [-11.734, -6.107],
                    [11.734, -6.107],
                    [13.225, -4.616],
                    [13.225, 4.616],
                    [11.734, 6.107],
                    [-11.734, 6.107],
                    [-13.225, 4.616],
                    [-13.225, -4.616],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.847000002394, 0.847000002394, 0.847000002394, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1.018, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [20.087, 57.966], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 7',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 7,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-5.595, -1.018],
                    [5.595, -1.018],
                    [5.595, 1.018],
                    [-5.595, 1.018],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.458999992819, 0.458999992819, 0.458999992819, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1.018, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [15.51, 42.698], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 8',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 8,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-0.843, 0],
                    [0, -0.843],
                    [0.843, 0],
                    [0, 0.843],
                  ],
                  o: [
                    [0.843, 0],
                    [0, 0.843],
                    [-0.843, 0],
                    [0, -0.843],
                  ],
                  v: [
                    [0, -1.527],
                    [1.526, 0],
                    [0, 1.527],
                    [-1.526, 0],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [28.226, 42.698], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 9',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 9,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-1.405, 0],
                    [0, 0],
                    [0, -1.406],
                    [1.406, 0],
                    [0, 0],
                    [0, 1.405],
                  ],
                  o: [
                    [0, 0],
                    [1.406, 0],
                    [0, 1.405],
                    [0, 0],
                    [-1.405, 0],
                    [0, -1.406],
                  ],
                  v: [
                    [-1.016, -2.545],
                    [1.016, -2.545],
                    [3.561, 0.001],
                    [1.016, 2.545],
                    [-1.016, 2.545],
                    [-3.561, 0.001],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.258999992819, 0.522000002394, 0.957000014361, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [27.209, 42.698], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 10',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 10,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-0.824, 0],
                    [0, 0],
                    [0, -0.824],
                    [0, 0],
                    [0.824, 0],
                    [0, 0],
                    [0, 0.823],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0.824, 0],
                    [0, 0],
                    [0, 0.823],
                    [0, 0],
                    [-0.824, 0],
                    [0, 0],
                    [0, -0.824],
                  ],
                  v: [
                    [-11.734, -6.107],
                    [11.734, -6.107],
                    [13.225, -4.616],
                    [13.225, 4.616],
                    [11.734, 6.107],
                    [-11.734, 6.107],
                    [-13.225, 4.616],
                    [-13.225, -4.616],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.847000002394, 0.847000002394, 0.847000002394, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1.018, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [20.087, 42.698], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 11',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 11,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, -0.843],
                    [0.842, 0],
                    [0, 0.844],
                    [-0.843, 0],
                  ],
                  o: [
                    [0, 0.844],
                    [-0.843, 0],
                    [0, -0.843],
                    [0.842, 0],
                  ],
                  v: [
                    [1.525, -0.001],
                    [0.001, 1.527],
                    [-1.525, -0.001],
                    [0.001, -1.527],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.458999992819, 0.458999992819, 0.458999992819, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1.018, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [31.786, 32.011], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 12',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 12,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, -0.843],
                    [0.842, 0],
                    [0, 0.844],
                    [-0.843, 0],
                  ],
                  o: [
                    [0, 0.844],
                    [-0.843, 0],
                    [0, -0.843],
                    [0.842, 0],
                  ],
                  v: [
                    [1.525, -0.001],
                    [0.001, 1.527],
                    [-1.525, -0.001],
                    [0.001, -1.527],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.458999992819, 0.458999992819, 0.458999992819, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1.018, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [25.682, 32.011], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 13',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 13,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-1.124, 0],
                    [0, -1.124],
                    [1.124, 0],
                    [0, 1.124],
                  ],
                  o: [
                    [1.124, 0],
                    [0, 1.124],
                    [-1.124, 0],
                    [0, -1.124],
                  ],
                  v: [
                    [0, -2.036],
                    [2.035, 0],
                    [0, 2.036],
                    [-2.035, 0],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.969000004787, 0.57599995931, 0.102000000898, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [11.439, 73.743], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 14',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 14,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-1.124, 0],
                    [0, -1.124],
                    [1.124, 0],
                    [0, 1.124],
                  ],
                  o: [
                    [1.124, 0],
                    [0, 1.124],
                    [-1.124, 0],
                    [0, -1.124],
                  ],
                  v: [
                    [0, -2.036],
                    [2.035, 0.001],
                    [0, 2.036],
                    [-2.035, 0.001],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.969000004787, 0.57599995931, 0.102000000898, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [8.388, 32.01], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 15',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 15,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-16.786, -26.973],
                    [16.786, -26.973],
                    [16.786, 26.973],
                    [-16.786, 26.973],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [20.086, 54.912], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 16',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 16,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-0.843, 0],
                    [0, 0],
                    [0, -0.843],
                    [0.844, 0],
                    [0, 0],
                    [0, 0.843],
                  ],
                  o: [
                    [0, 0],
                    [0.844, 0],
                    [0, 0.843],
                    [0, 0],
                    [-0.843, 0],
                    [0, -0.843],
                  ],
                  v: [
                    [-2.033, -1.527],
                    [2.033, -1.527],
                    [3.561, 0],
                    [2.033, 1.527],
                    [-2.033, 1.527],
                    [-3.561, 0],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [20.086, 86.466], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 17',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 17,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, -1.687],
                    [0, 0],
                    [-1.686, 0],
                    [0, 0],
                    [0, 1.687],
                    [0, 0],
                    [1.686, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-1.686, 0],
                    [0, 0],
                    [0, 1.687],
                    [0, 0],
                    [1.686, 0],
                    [0, 0],
                    [0, -1.687],
                    [0, 0],
                  ],
                  v: [
                    [4.145, -33.59],
                    [-0.019, -27.758],
                    [-4.182, -33.59],
                    [-16.784, -33.59],
                    [-19.837, -30.536],
                    [-19.837, 30.536],
                    [-16.784, 33.59],
                    [16.784, 33.59],
                    [19.837, 30.536],
                    [19.837, -30.536],
                    [16.784, -33.59],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.286000001197, 0.286000001197, 0.286000001197, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [20.087, 57.457], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 18',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 18,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 50,
      st: 0,
      bm: 0,
    },
  ],
  markers: [],
};
