import {
  ctBrowserIconSrc,
  ctBrowserProductSrc,
  ctFarmIconSrc,
  ctFarmProductSrc,
  ctPoolIconSrc,
  ctPoolProductSrc,
  ncWalletProductSrc,
} from '../../assets';
import { ctFarmProIconSrc, ncWalletIconSrc } from '@/features/crypto-tab-products/assets';
import { AffiliateProductTitle } from '../../hooks';

type ProductImages = {
  productImage: string;
  productIcon: string;
};

export const getProductImages = (title: AffiliateProductTitle): ProductImages => {
  switch (title) {
    case 'CT Browser':
      return { productImage: ctBrowserProductSrc, productIcon: ctBrowserIconSrc };
    case 'CT Farm':
      return { productImage: ctFarmProductSrc, productIcon: ctFarmIconSrc };
    case 'CT Farm Pro':
      return { productImage: ctFarmProductSrc, productIcon: ctFarmProIconSrc };
    case 'CT Pool':
      return { productImage: ctPoolProductSrc, productIcon: ctPoolIconSrc };
    case 'NC Wallet':
      return { productImage: ncWalletProductSrc, productIcon: ncWalletIconSrc };
    default:
      return {
        productImage: '',
        productIcon: '',
      };
  }
};
