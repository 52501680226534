import { useEffect, useState } from 'react';
import { deviceType } from '@/shared/store/user/user.slice';

type DeviceType = 'desktop' | 'mobile' | 'reactNative' | 'telegramMiniApp';

interface DeviceInfo {
  type: DeviceType | '';
  userAgent: string;
  isReactNativeApp: boolean;
  isTelegramMiniApp: boolean;
  isIos: boolean;
  platformCt: deviceType;
}

export const useDevice = (): DeviceInfo => {
  const [deviceInfo, setDeviceInfo] = useState<DeviceInfo>({
    type: '',
    userAgent: '',
    isReactNativeApp: !!window.ct,
    isTelegramMiniApp: false,
    isIos: window?.ct?.platform === 'ios',
    platformCt: window?.ct?.platform,
  });

  useEffect(() => {
    const ua = navigator.userAgent;
    const isMobile = /iPhone|iPad|iPod|Android/i.test(ua);

    let type: DeviceType = 'desktop';
    if (isMobile) {
      type = 'mobile';
    }

    const updateDeviceInfo = (isTelegramApp: boolean) => {
      setDeviceInfo((prev) => ({
        ...prev,
        type: isTelegramApp ? 'telegramMiniApp' : type,
        userAgent: ua,
        isReactNativeApp: !!window.ct,
        isTelegramMiniApp: isTelegramApp,
        isIos: window?.ct?.platform === 'ios',
        platformCt: window?.ct?.platform,
      }));
    };

    // Initialize the device info with default values (Telegram not ready)
    updateDeviceInfo(false);

    // Listener for the Telegram event
    const onTelegramEvent = () => {
      updateDeviceInfo(true);
    };

    if (window.TelegramWebviewProxy || window.Telegram?.WebView?.isIframe) {
      onTelegramEvent(); // Immediate update if Telegram object already exists
    } else {
      window.addEventListener('telegram_event', onTelegramEvent);
    }

    return () => {
      window.removeEventListener('telegram_event', onTelegramEvent);
    };
  }, []);
  return deviceInfo;
};
