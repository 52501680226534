import { MakeOrderButton } from '../make-order-button';

import s from './content-switcher.module.scss';
import { InfoContentBlock } from '@/shared/ui/info-block/info-block';
import { useDispatch, useSelector } from 'react-redux';
import { Tabs } from '@/shared/ui/tabs';
import { CurrencyIcon } from '@/shared/ui/currency-icon';
import { PurchaseSubsButton } from '@/page/cart/ui/v2/purchase-subs-button/purchase-subs-button';
import { useGoogleMiningDurations, useGoogleStatus } from '@/page/cart/store/cart-google-store';
import { LoaderMobile } from '@/shared/ui/loader/loader';
import { GoogleHashrate } from '@/page/cart/ui/v2/google-hashrate';
import { GoogleMiningDuration } from '@/page/cart/ui/v2/google-mining-duration';
import { GoogleTotalPrice } from '@/page/cart/ui/v2/google-total-price';
import { Hashrate } from '@/page/cart/ui/v2/hashrate';
import { MiningDuration } from '@/page/cart/ui/v2/mining-duration';
import { TotalPrice } from '@/page/cart/ui/v2/total-price';
import { useCurrentPlan } from '@/page/cart/store';
import { useTranslation } from 'react-i18next';
import { Icon } from '@/shared/ui/icons';
import { Typography } from '@/shared/ui/typography';
import { CartIcon } from '@/page/cart/assets';
import { addOverlapBg, selectCurrentBg } from '@/features/theme/model/slice';
import { selectCurrentTheme } from '@/features/theme';
import { useEffect, useState } from 'react';
import { useDevice } from '@/shared/lib/hooks/useDevice';

export const ContentSwitcher = () => {
  const { t } = useTranslation();
  const { isIos } = useDevice();
  const { Tab, List, Panel } = Tabs;
  const subtitle = 'add miner subtitle';
  const miningDurations = useSelector(useGoogleMiningDurations);
  const status = useSelector(useGoogleStatus);
  const isLoading = status === 'loading';
  const currentBg = useSelector(selectCurrentBg);
  const theme = useSelector(selectCurrentTheme);
  const dispatch = useDispatch();
  const hasGoogleMining = miningDurations ? miningDurations.length > 0 : false;
  const params = new URLSearchParams(window.location.search);
  const hasCryptoParams = params.has('r') || params.has('m');
  const defaultPanel = isIos ? 'ios' : hasGoogleMining && !hasCryptoParams ? 'crypto' : 'google';
  const [currentTab, setCurrentTab] = useState<string>(defaultPanel);

  useEffect(() => {
    const color = theme === 'dark' ? '#000000' : '#F7F7F7';
    dispatch(addOverlapBg(color));
    return () => {
      dispatch(addOverlapBg());
    };
  }, [currentBg, theme, dispatch]);

  const purchaseInfoBlochShowed = localStorage.getItem('purchaseInfoBlockShowed') === 'hide';

  const currentPlan = useSelector(useCurrentPlan);

  if (isLoading) {
    return <LoaderMobile />;
  }

  return (
    <div className={s.container}>
      {!purchaseInfoBlochShowed && <InfoContentBlock text={subtitle} />}
      <Tabs orientation="vertical" tab={currentTab} variant="reset" onChange={setCurrentTab} keepMounted={false}>
        {hasGoogleMining ? (
          <div className={s.contentContainer}>
            <Typography variant="h5" weight="bold" className={s.sectionTitle}>
              <CartIcon /> {t('N.PaymentMethod.Title')}
            </Typography>
            <List className={s.tabList}>
              {hasGoogleMining && (
                <Tab value="google" className={s.tab}>
                  <span className={s.tab__left}>
                    <Icon iconName="google-icon" width={20} height={20} />
                    Google Play
                  </span>
                  <span className={s.tab__right}>{t('Rent')}</span>
                </Tab>
              )}
              <Tab value="crypto" className={s.tab}>
                <span className={s.tab__left}>
                  <Icon iconName="bitcoin" width={20} height={20} />
                  Crypto
                </span>
                <span className={s.tab__right}>
                  <span className={s.tab__icons}>
                    <CurrencyIcon currency="MATIC" />
                    <CurrencyIcon currency="TON" />
                    <CurrencyIcon currency="BNB" />
                    <CurrencyIcon currency="SOL" />
                    <CurrencyIcon currency="TRX" />
                    <CurrencyIcon currency="USDT" />
                    <CurrencyIcon currency="ETH" />
                  </span>
                  <span className={s.tab__info}>{t('BUY')}</span>
                </span>
              </Tab>
            </List>
          </div>
        ) : null}

        <Panel value="google" className={s.panelContainer}>
          <div className={s.contentContainer}>
            <GoogleHashrate />
          </div>
          <div className={s.contentContainer}>
            <GoogleMiningDuration />
          </div>
          <div className={s.contentContainer}>
            <GoogleTotalPrice />
          </div>
          <div className={s.footer}>
            <PurchaseSubsButton />
          </div>
        </Panel>

        <Panel value="crypto" className={s.panelContainer}>
          {currentPlan && (
            <div className={s.contentContainer}>
              <Hashrate />
            </div>
          )}
          <div className={s.contentContainer}>
            <MiningDuration />
          </div>
          {currentPlan && (
            <div className={s.contentContainer}>
              <TotalPrice />
            </div>
          )}
          <div className={s.footer}>
            <MakeOrderButton />
          </div>
        </Panel>
      </Tabs>
    </div>
  );
};
