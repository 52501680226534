import confetti from 'canvas-confetti';

export const useConfetti = () => {
  const fireConfetti = (origin: { x: number; y: number }) => {
    const defaults: confetti.Options = {
      disableForReducedMotion: true,
      scalar: 2,
      zIndex: 110000,
    };

    const fire = (particleRatio: number, opts: confetti.Options) => {
      confetti(
        Object.assign({}, defaults, opts, {
          particleCount: Math.floor(200 * particleRatio),
        })
      );
    };

    fire(0.25, {
      spread: 26,
      startVelocity: 55,
      origin,
    });
    fire(0.2, {
      spread: 60,
      origin,
    });
    fire(0.35, {
      spread: 100,
      decay: 0.91,
      origin,
    });
    fire(0.1, {
      spread: 120,
      startVelocity: 25,
      decay: 0.92,
      origin,
    });
    fire(0.1, {
      spread: 120,
      startVelocity: 45,
      origin,
    });
  };

  const fireConfettiAtCenter = () => {
    const origin = {
      x: 0.5, // center of the screen
      y: 0.5,
    };
    fireConfetti(origin);
  };

  return { fireConfettiAtCenter };
};
