import React, { useEffect, useState } from 'react';
import styles from './error-timer.module.scss';
import { Icon } from '@/shared/ui/icons';
import { useTranslation } from 'react-i18next';

interface ErrorTimerProps {
  currency_changed_at: number;
  timestamp: number;
}

export const ErrorTimer = ({ currency_changed_at, timestamp }: ErrorTimerProps) => {
  const { t } = useTranslation();
  const [currentTime, setCurrentTime] = useState(timestamp);
  const [timeLeft, setTimeLeft] = useState('');

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime((prevTime) => prevTime + 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (timestamp) {
      setCurrentTime(timestamp);
    }
  }, [timestamp]);

  useEffect(() => {
    const calculateTimeLeft = () => {
      const currentDateTime = currentTime * 1000;
      const changedTime = currency_changed_at * 1000;
      const twentyFourHoursLater = new Date(changedTime + 24 * 60 * 60 * 1000);
      const diff = twentyFourHoursLater.getTime() - currentDateTime;

      if (diff > 0) {
        const hours = Math.floor((diff / (1000 * 60 * 60)) % 24);
        const minutes = Math.floor((diff / 1000 / 60) % 60);

        return `${hours}${t('H')} : ${minutes}${t('M')}`;
      } else {
        return '0H : 0M';
      }
    };

    setTimeLeft(calculateTimeLeft());
  }, [currentTime, currency_changed_at]);

  return (
    <div className={styles.root}>
      <div className={styles.block}>
        <Icon iconName="clockError" width={40} height={40} />
        <div className={styles.text}>
          <p>{t('Balance_currency.Uavailable.Title')}</p>
          <p className={styles.time}>
            {t('Time left:')} {timeLeft}
          </p>
        </div>
      </div>
    </div>
  );
};
