export const HALLOWEEN_INFO_URL = 'https://cryptobrowser.site/promo/halloween-2024/';
export const HALLOWEEN_BANNER_ID = 'skipHalloween';
export const HALLOWEEN_END_DATE = '2024-11-30T23:59:59';

export const HALLOWEEN_FINAL_BANNER_ID = 'halloweenFinalBannerClosedAt';
export const HALLOWEEN_WINNER_MODAL_ID = 'halloweenWinnerModalClosed';

export const getIsHalloween = (): boolean => {
  const halloweenFinalClosedAt = localStorage.getItem(HALLOWEEN_FINAL_BANNER_ID);

  if (halloweenFinalClosedAt) return false;

  const currentDate = new Date();
  const halloweenDate = new Date(HALLOWEEN_END_DATE);
  const isHalloween = currentDate < halloweenDate;

  if (!isHalloween) {
    console.warn('Halloween is over');
  }

  return isHalloween;
};

const HALLOWEEN_WINNER_IDS = [
  43542447,
  43452132,
  8775389,
  26011129,
  42157702,
  43353723,
  43508453,
  11496895,
  43218955,
  40232236,
  41847441,
  43254119,
  43417346,
  14585741, // test id
];

export const getIsHalloweenWinnerModalVisible = (id: number) => {
  const isWinner = HALLOWEEN_WINNER_IDS.includes(id);
  return isWinner && localStorage.getItem(HALLOWEEN_WINNER_MODAL_ID) === null;
};
