import styles from './modal-go-to-payment.module.scss';
import { Icon } from '@/shared/ui/icons';
import { Button } from '@/shared/ui/button';
import ModalLayout from '@/shared/ui/modal/modal';
import { useTranslation } from 'react-i18next';

type ModalGoToPaymentProps = {
  onClose: (e: boolean) => void;
  isOpen: boolean;
  paymentUrl: string;
};

export const ModalGoToPayment = ({ onClose, isOpen, paymentUrl }: ModalGoToPaymentProps) => {
  const { t } = useTranslation();

  return (
    <ModalLayout background iconClose onClose={() => onClose(false)} isOpen={isOpen} id="success-withdrawal">
      <div className={styles.root}>
        <div className={styles.block}>
          <Icon iconName="confirmed" width={80} height={80} className={styles.icon} style={{ color: '#37CC33' }} />
          <h6 className={styles.title}>{t('OrdrConf.Title')}</h6>
          <p className={styles.text}>{t('OrdrConf.Description')}</p>
        </div>
        <div className={styles.footer}>
          <Button as="a" href={paymentUrl} target="_blank" color="blue" fullWidth onClick={() => onClose(false)}>
            {t('OrdrConf.Btn')}
            <Icon iconName="arrow" width="20" height="20" />
          </Button>
        </div>
      </div>
    </ModalLayout>
  );
};
