import React, { Ref } from 'react';
import cn from 'classnames';
import { ShareButtonOptions, SocialType } from '../share-social.types';
import styles from './share-button.module.scss';
import { useShareSocialContext } from '@/shared/ui/share-social';
import {
  createShareUrl,
  getBoxPositionOnScreenCenter,
  getBoxPositionOnWindowCenter,
  windowOpen,
  WindowPosition,
} from './share-button.helpers';

interface CustomProps {
  disabledClassName?: string;
  forwardedRef?: Ref<HTMLButtonElement>;
  onShareWindowClose?: () => void;
  openShareDialogOnClick?: boolean;
  windowWidth?: number;
  windowHeight?: number;
  windowPosition?: WindowPosition;
  shareOptions: ShareButtonOptions;
  socialType: SocialType;
}

export type ShareButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & CustomProps;

export const ShareButton = ({
  children,
  className,
  disabled,
  forwardedRef,
  onClick,
  onShareWindowClose,
  openShareDialogOnClick = true,
  windowHeight = 400,
  windowPosition = 'windowCenter',
  windowWidth = 550,
  disabledClassName,
  shareOptions,
  socialType,
  ...rest
}: ShareButtonProps) => {
  const { endpoint } = useShareSocialContext();

  const handleClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
    if (disabled) {
      return;
    }

    event.preventDefault();

    if (openShareDialogOnClick) {
      const windowConfig = {
        height: windowHeight,
        width: windowWidth,
        ...(windowPosition === 'windowCenter'
          ? getBoxPositionOnWindowCenter(windowWidth, windowHeight)
          : getBoxPositionOnScreenCenter(windowWidth, windowHeight)),
      };

      const url = createShareUrl(endpoint, socialType, shareOptions);
      windowOpen(url, windowConfig, onShareWindowClose);
    }

    if (onClick) {
      onClick(event);
    }
  };

  const newClassName = cn(styles.button, className, {
    [disabledClassName ?? '']: disabled,
  });

  return (
    <button {...rest} className={newClassName} onClick={handleClick} ref={forwardedRef}>
      {children}
    </button>
  );
};
