import { useSelector } from 'react-redux';
import { LandingsStore } from '@/shared/store/landings/landings.slice';
import { rootBalanceType } from '@/shared/store/balance/balance.slice';
import { PromocodesStore } from '@/shared/store/promocodes/promocodes.slice';
import { BROWSER_PROMO, FARM_PROMO, POOL_PROMO } from '@/shared/lib/utils/links';

type AffiliateProduct = 'ctPool' | 'ctBrowser' | 'ctFarm' | 'ctFarmPro' | 'ncWallet';

export type AffiliateProductTitle = 'CT Browser' | 'CT Farm' | 'CT Farm Pro' | 'CT Pool' | 'NC Wallet';

export type AffiliateProductInfo = {
  productUrl: string;
  shareUrl: string;
  productTitle: AffiliateProductTitle;
  productDescription: string;
};

type AffiliateInfo = Record<AffiliateProduct, AffiliateProductInfo>;

export const useAffiliateInfo = (): AffiliateInfo => {
  const landings = useSelector((state: { landings: LandingsStore }) => state.landings);
  const balance = useSelector((state: { balance: rootBalanceType }) => state.balance);
  const promocodes = useSelector((state: { promocodes: PromocodesStore }) => state.promocodes.promocodes);

  return {
    ctBrowser: {
      productUrl: `${window.app_config.affiliateSiteURL}${BROWSER_PROMO}`,
      shareUrl: `${landings.land?.[0]?.url}${balance.links?.[0]?.id}`,
      productTitle: 'CT Browser',
      productDescription: 'Affiliate.PromoBlock.CTB.Description',
    },
    ctFarm: {
      productUrl: `${window.app_config.affiliateSiteURL}${FARM_PROMO}`,
      shareUrl: `${promocodes.length === 0 ? 'N/A' : 'https://cryptotab.farm/'}${promocodes[0]?.code || ''}`,
      productTitle: 'CT Farm',
      productDescription: 'Affiliate.PromoBlock.Farm.Description',
    },
    ctFarmPro: {
      productUrl: `${window.app_config.affiliateSiteURL}${FARM_PROMO}`,
      shareUrl: `${promocodes.length === 0 ? 'N/A' : 'https://cryptotabfarm.com/'}${promocodes[0]?.code || ''}`,
      productTitle: 'CT Farm Pro',
      productDescription: 'Affiliate.PromoBlock.Farm.Description',
    },
    ctPool: {
      productUrl: `${window.app_config.affiliateSiteURL}${POOL_PROMO}`,
      shareUrl: `${landings.poolLand?.[0]?.url}${balance.links?.[0]?.id}`,
      productTitle: 'CT Pool',
      productDescription: 'Affiliate.PromoBlock.Pool.Description',
    },
    ncWallet: {
      productUrl: 'https://ncwallet.net/affiliate/',
      shareUrl: 'https://ncwallet.net/affiliate/',
      productTitle: 'NC Wallet',
      productDescription: 'Affiliate.PromoBlock.NCW.Description',
    },
  };
};
