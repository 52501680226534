import React, { PropsWithChildren, useContext } from 'react';

type ShareSocialContextValue = {
  endpoint: string;
};

type ShareSocialContextProviderProps = {
  endpoint: string;
};

const ShareSocialContext = React.createContext<ShareSocialContextValue | null>(null);

export const ShareSocialContextProvider = ({
  endpoint,
  children,
}: PropsWithChildren<ShareSocialContextProviderProps>) => {
  return <ShareSocialContext.Provider value={{ endpoint }}>{children}</ShareSocialContext.Provider>;
};

export const useShareSocialContext = () => {
  const value = useContext(ShareSocialContext);
  if (value === null) throw new Error('ShareSocialContext should be defined');

  return value;
};
