import s from './add-google-miner.module.scss';
import { SubscriptionMiningDuration } from '@/page/cart/ui/v1/subscription-mining-duration';
import { SubscriptionHashrate } from '@/page/cart/ui/v1/subscription-hashrate';
import { SubscriptionTotalPrice } from '@/page/cart/ui/v1/subscription-total-price';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '@/shared/store/types';
import { useGoogleHashrate, useGoogleIsAvailable } from '@/page/cart/store/cart-google-store';

interface AddGoogleMinerProps {
  handleSwitchTab: () => void;
  isCryptoEnable: boolean;
}

export const AddGoogleMiner = ({ handleSwitchTab, isCryptoEnable }: AddGoogleMinerProps) => {
  const { t } = useTranslation();
  const plans = useSelector((state: RootState) => state.googleCart.googleData?.plans);
  const hps = useSelector(useGoogleHashrate);
  const isAvailable = useSelector(useGoogleIsAvailable);
  const [isLastInRange, setIsLastInRange] = useState(false);
  const maxHpsPlan = useMemo(
    () => plans?.reduce((maxPlan, p) => (p.hps > maxPlan.hps ? p : maxPlan), plans[0]),
    [plans]
  );

  useEffect(() => {
    maxHpsPlan && setIsLastInRange(!!(hps && hps === maxHpsPlan?.hps));
  }, [hps, maxHpsPlan]);

  return (
    <div className={s.container}>
      <div className={s.contentContainer}>
        <div className={s.hashrateContainer}>
          <SubscriptionHashrate />
          {isLastInRange && isCryptoEnable && (
            <div className={s.higher} onClick={() => handleSwitchTab()}>
              {t('HigherFasterStronger')}
            </div>
          )}
          <SubscriptionMiningDuration />
        </div>
        {!isAvailable ? <SubscriptionTotalPrice /> : null}
      </div>
    </div>
  );
};
