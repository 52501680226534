import React, { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { poolMiner, TPoolMiner } from '@/shared/store/pool-miner/pool-miner.slice';
import { rpc } from '@/shared/lib/backend/Rpc';
import { Mining } from '@/page/home/ui/mining-block/ui/mining';
import { nftMiner, TNftMinerState } from '@/shared/store/ntf-miner/nft-miner.slice';
import { Icon } from '@/shared/ui/icons';
import cn from 'classnames';
import { refreshBalanceThunk } from '@/shared/store/balance/balance.slice';
import { LimitAlertModal } from '@/page/home/ui/mining-block/ui/limit-alert/limit-alert.modal';
import { useModalSet } from '@/shared/lib/context/modal/useModalSet';
import { shouldShowContent } from '@/shared/lib/utils/last-show-date';
import { selectTutorial, startTutorial } from '@/shared/store/tutorial/tutorial.slice';
import { useDevice } from '@/shared/lib/hooks/useDevice';
import styles from './mining-block.module.scss';
import dayjs from 'dayjs';
import { useModal } from '@/shared/lib/context/modal/useModal';
import { useTutorialStorage } from '@/shared/lib/hooks/useTutorialStorage';
import { STORAGE_TUTORIAL_ONBOARDING } from '@/shared/lib/constants/storage-key';

const RATE_CHECK_KEY = 'rateCheckTimestamp';

type MiningBlockContextType = React.RefObject<HTMLDivElement> | null;

const MiningBlockContext = createContext<MiningBlockContextType>(null);

export const MiningBlockProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const miningBlockRef = useRef<HTMLDivElement>(null);

  return (
    <MiningBlockContext.Provider value={miningBlockRef}>
      <div className="container" ref={miningBlockRef}>
        {children}
      </div>
    </MiningBlockContext.Provider>
  );
};

export const useMiningBlock = () => {
  const context = useContext(MiningBlockContext);
  if (!context) {
    throw new Error('useMiningBlockRef must be used within a MiningBlockProvider');
  }
  return context;
};

export function MiningBlock() {
  const [isAlertOpen, setAlertOpen] = useState(false);
  const { step } = useSelector(selectTutorial);
  const { isIos } = useDevice();
  const miners = useSelector((store: { pool_miner: TPoolMiner }) => store.pool_miner.list);
  const mining = useSelector((store: { pool_miner: TPoolMiner }) => store.pool_miner.miners_list_used_now);
  const checked = useSelector((store: { pool_miner: TPoolMiner }) => store.pool_miner.checked);
  const nftMining = useSelector((store: { nft_miner: TNftMinerState }) => store.nft_miner.mining);
  const limitAlertShowed = useSelector((store: { nft_miner: TNftMinerState }) => store.nft_miner.limitAlertShowed);
  const [hps, setHps] = useState(0);
  // eslint-disable-next-line
  const dispatch = useDispatch<any>();
  const { openModal } = useModalSet();
  const { modal } = useModal();
  const { isOnboardingSeen } = useTutorialStorage();

  useEffect(() => {
    // wait for side effects on home page before starting the tutorial
    const timer = setTimeout(() => {
      if (!isOnboardingSeen() && !modal && !isIos) {
        dispatch(startTutorial(STORAGE_TUTORIAL_ONBOARDING));
      }
    }, 500);

    return () => {
      clearTimeout(timer);
    };
  }, [dispatch, modal]);

  const startMiningSession = useCallback(async () => {
    if (mining.length) {
      await rpc.transmit('mining.pool.stop', {}).then(() => dispatch(poolMiner.setPoolUsedNow([])));
      localStorage.removeItem('miningStartTimestamp');
      return;
    }
    await rpc.transmit('mining.pool.start', {}).then((r) => dispatch(poolMiner.setPoolUsedNow(r)));
    const timestamp = Date.now();
    localStorage.setItem('miningStartTimestamp', timestamp.toString());

    const timerId = setTimeout(
      async () => {
        try {
          const response = await rpc.transmit('rate.info', {});
          if (response?.allow) {
            openModal('MODAL_RATE_US');
          } else {
            const nextCheckTime = dayjs().add(86400, 'second').valueOf();
            localStorage.setItem(RATE_CHECK_KEY, nextCheckTime.toString());
          }
        } catch (e) {
          console.error('Failed to check rating permission', e);
        }
      },
      10 * 60 * 1000
    ); // 10 minutes

    localStorage.setItem('ratingTimerId', JSON.stringify(timerId));
  }, [dispatch, openModal, mining]);

  const onCloseAlert = useCallback(() => {
    setAlertOpen(false);
    if (!limitAlertShowed) {
      dispatch(nftMiner.setLimitAlertShowed());
    }
  }, [limitAlertShowed, dispatch]);

  useEffect(() => {
    const countHps = miners.reduce((acc, cur) => cur?.hps + acc, 0);
    if (mining.length > 0) {
      const interval = setInterval(() => {
        // Generate a random number between -500 and +500
        const change = Math.random() * 1000 - 500;
        setHps(countHps + change);
      }, 1000);
      return () => clearInterval(interval);
    } else {
      setHps(countHps);
    }
  }, [miners, mining]);

  useEffect(() => {
    if (mining.length || nftMining.length) {
      const interval = setInterval(() => {
        dispatch(refreshBalanceThunk());
      }, 600 * 1000);
      return () => clearInterval(interval);
    }
  }, [dispatch, mining, nftMining]);

  useEffect(() => {
    if (miners.length >= 0 && shouldShowContent() && step === -1) {
      const currentDate = new Date();
      localStorage.setItem('lastShownDate', currentDate.toISOString());
      const timeout = setTimeout(() => {
        openModal('MODAL_PROMO_ADD_POOL_MINER');
      }, 5000);

      return () => clearTimeout(timeout);
    }
  }, [openModal, miners, step]);

  return (
    <MiningBlockProvider>
      <div className={cn(styles.miningBlock)}>
        <Mining
          className={styles.pool}
          mining={mining}
          hps={hps}
          texts={{
            addMiner: 'ADD POOL MINER',
            addMinerMobile: 'ADD MINER',
            turnOnMining: 'titleOnButton',
            activeStatus: 'ACTIVE_Status',
            noMiners: 'You have no Pool Miners',
            expirationDate: 'Expiration date',
            miners: 'Pool Miners',
            mining: 'Pool Mining',
          }}
          checked={checked}
          onStartMiner={startMiningSession}
          icon={
            isIos ? (
              <Icon iconName="miningSpeed" width={40} height={40} />
            ) : (
              <Icon iconName="poolMining" width={48} height={30} />
            )
          }
          miners={miners}
        />
      </div>
      {isAlertOpen && <LimitAlertModal isOpen={isAlertOpen} onClose={onCloseAlert} />}
    </MiningBlockProvider>
  );
}
