import React, { createContext, FC, PropsWithChildren, ReactNode, useCallback, useState } from 'react';
import { Icon as IconName } from '@/shared/ui/icons';
import { Button } from '@/shared/ui/button';
import { useTranslation } from 'react-i18next';
import ModalLayout from '@/shared/ui/modal/modal';

export const ErrorModalContext = createContext({
  // eslint-disable-next-line
  open: (title: ReactNode, description: ReactNode) => {},
});
export const ErrorModalProvider: FC<PropsWithChildren> = ({ children }) => {
  const [isOpen, setOpen] = useState<boolean>(false);
  const [text, setText] = useState<{ title: ReactNode; description: ReactNode } | null>(null);
  const { t } = useTranslation();
  const { Title, Content, Icon, Description, Footer, Header } = ModalLayout;
  return (
    <ErrorModalContext.Provider
      value={{
        open: useCallback((title: ReactNode, description: ReactNode) => {
          setText({
            title,
            description,
          });
          setOpen(true);
        }, []),
      }}
    >
      {isOpen && (
        <ModalLayout isOpen={isOpen} onClose={() => setOpen(false)} id="modal-error-context">
          <Header hiddenDesktop />
          <Content>
            <Icon>
              <IconName iconName="modalError" width="70" height="70" />
            </Icon>
            <Title>{text?.title}</Title>
            <Description>{text?.description}</Description>
          </Content>
          <Footer>
            <Button wide color="blue" onClick={() => setOpen(false)}>
              <IconName iconName="successCross" width="20" height="20" />
              {t('OK, GOT IT')}
            </Button>
          </Footer>
        </ModalLayout>
      )}
      {children}
    </ErrorModalContext.Provider>
  );
};
