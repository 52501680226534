import React from 'react';
import { useSelector } from 'react-redux';
import { TPoolMiner } from '@/shared/store/pool-miner/pool-miner.slice';
import { PoolMinersList, PoolMiningInfo, PoolMiningFooter } from './ui';
import styles from './pool-mining-page.module.scss';

export const PoolMiningPage = () => {
  const miners = useSelector((store: { pool_miner: TPoolMiner }) => store.pool_miner.list);

  return (
    <div className={styles.container}>
      <div className={styles.contentContainer}>
        <PoolMiningInfo hasMiners={miners.length > 0} />
        <PoolMinersList miners={miners} />
      </div>
      <PoolMiningFooter />
    </div>
  );
};
