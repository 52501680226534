import React from 'react';
import styles from './slider-item.module.scss';
import { Trans } from 'react-i18next';

export type SliderItemProps = {
  id: string;
  key: string;
  className?: string;
  title?: string;
  description: string;
  src: string;
  link?: string;
  body?: JSX.Element;
};

export function SliderItem(props: SliderItemProps) {
  const { className, title, description, link, src, body } = props;

  return (
    <div className={`${styles.slide} ${className}`}>
      <div className={styles.title}>
        <Trans i18nKey={title} />
      </div>
      <div className={styles.description}>
        <Trans
          i18nKey={description}
          components={{
            b: <strong />,
            a: link ? <a href={link} target="_blank" rel="noreferrer" /> : <></>,
          }}
        />
      </div>
      {body}
      <div className={styles.image}>
        <picture>
          <img src={src} alt="step" />
        </picture>
      </div>
    </div>
  );
}
