import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MenuAccount } from '@/features/menu/ui/menu-account/menu-account';
import { MenuNavigation } from '@/features/menu/ui/menu-navigation/menu-navigation';
import { MenuSocial } from '@/features/menu/ui/menu-social/menu-social';
import { MenuFamily } from '@/features/menu/ui/menu-family/menu-family';
import { Icon } from '@/shared/ui/icons';
import styles from './menu.module.scss';
import { build, version } from '@/shared/lib/constants/version';
import { useTranslation } from 'react-i18next';
import { rootUser } from '@/shared/store/user/user.slice';
import { HOME_PATH, POOL_FEEDBACK, POOL_LANDING, POOL_PROMO } from '@/shared/lib/utils/links';
import { Button } from '@/shared/ui/button';
import { useModalSet } from '@/shared/lib/context/modal/useModalSet';
import { changeTheme, selectCurrentTheme } from '@/features/theme';
import { AppDispatch } from '@/shared/store/types';
import { STORAGE_THEME, STORAGE_TUTORIAL, STORAGE_TUTORIAL_ONBOARDING } from '@/shared/lib/constants/storage-key';
import { useDevice } from '@/shared/lib/hooks/useDevice';
import { ModalDeleteAccount } from '@/features/menu/ui/modal-delete-account/modal-delete-account';

export const Menu = () => {
  const { t } = useTranslation();
  const { email, id } = useSelector((store: { user: rootUser }) => store.user);
  const { openModal } = useModalSet();
  const currentTheme = useSelector(selectCurrentTheme);
  const dispatch: AppDispatch = useDispatch();
  const { isReactNativeApp, isTelegramMiniApp, isIos } = useDevice();
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [verClick, setVerClick] = useState(0);

  const handleChange = useCallback(() => {
    const setTheme = currentTheme === 'dark' ? 'light' : 'dark';
    localStorage.setItem(STORAGE_THEME, setTheme);
    dispatch(changeTheme(setTheme));
  }, [currentTheme, dispatch]);

  let platform: string = 'android';
  let appId: string = 'net.ctpool';
  let envVer: string = '';

  if (isReactNativeApp) {
    platform = window?.ct?.platform;
    appId = 'net.ctpool';
    envVer = window?.ct?.appVersion;
  } else if (isTelegramMiniApp) {
    platform = 'telegram';
    appId = 'pool.telegram.ct';
    envVer = window.Telegram?.WebApp?.version || envVer;
  }
  const handleOpenDeleteModal = () => {
    setDeleteModalOpen(true);
  };

  const handleCloseDeleteModal = () => {
    setDeleteModalOpen(false);
  };

  const handleVerClick = () => {
    setVerClick(verClick + 1);
    if (verClick > 10) {
      setVerClick(0);
      fetch('https://api.canary.ctpool.net/api/v2/me')
        .then((res) => res.json())
        .then(() => {
          window.location.href = 'https://mobile.canary.ctpool.net';
        })
        .catch(() => {});
    } else if (verClick === 3) {
      if (window.app_config.baseAPIURL.indexOf('canary') > 0) {
        window.location.href = 'https://ct-pool-mobile.dvlt.net';
      } else if (window.app_config.baseAPIURL.indexOf('dvlt') > 0) {
        window.location.href = 'https://mobile.canary.ctpool.net';
      }
    }
  };

  return (
    <div className={styles.root}>
      <MenuAccount email={email} />

      {!isTelegramMiniApp && !isIos ? (
        <div className={styles.box}>
          <a href={window.app_config.affiliateSiteURL} target="_blank" rel="noreferrer" className={styles.box__link}>
            <Icon iconName="promo-up" width={20} height={20} className={styles.box__icon} />
            <span className={styles.box__text}>{t('Affiliate Dashboard')}</span>
            <Icon iconName="arrow" width="20" height="20" className={styles.box__arrow} />
          </a>
        </div>
      ) : null}

      {!isIos ? (
        <div className={styles.box}>
          <a
            href={`${window.app_config.affiliateSiteURL}${POOL_PROMO}`}
            target="_blank"
            rel="noreferrer"
            className={styles.box__link}
          >
            <Icon iconName="materials-up" width={20} height={20} className={styles.box__icon} />
            <span className={styles.box__text}>{t('MTU.Menu.Promo')}</span>
            <Icon iconName="arrow" width="20" height="20" className={styles.box__arrow} />
          </a>
        </div>
      ) : null}

      <div className={styles.box}>
        <a href={POOL_LANDING} target="_blank" rel="noreferrer" className={styles.box__link}>
          <Icon iconName="info" width={20} height={20} className={styles.box__icon} />
          <span className={styles.box__text}>{t('MTU.Menu.About')}</span>
          <Icon iconName="arrow" width="20" height="20" className={styles.box__arrow} />
        </a>
      </div>

      <div className={styles.box}>
        <a
          href={HOME_PATH}
          className={styles.box__link}
          onClick={() => {
            const regex = new RegExp(`^(${STORAGE_TUTORIAL}(${STORAGE_TUTORIAL_ONBOARDING}))`);
            Object.keys(localStorage).forEach((i) => {
              regex.test(i) && localStorage.removeItem(i);
            });
          }}
        >
          <Icon iconName="tutorial" width={21} height={17} className={styles.box__icon} />
          <span className={styles.box__text}>{t('MTU.Menu.Tutorial')}</span>
          <Icon iconName="arrow" width="20" height="20" className={styles.box__arrow} />
        </a>
      </div>

      <div className={styles.box}>
        <a
          href={`${POOL_FEEDBACK}?user_id=${id}&platform=${platform}&app_id=${appId}`}
          target="_blank"
          rel="noreferrer"
          className={styles.box__link}
        >
          <Icon iconName="help-fill" width={20} height={20} className={styles.box__icon} />
          <span className={styles.box__text}>{t('Support')}</span>
          <Icon iconName="arrow" width="20" height="20" className={styles.box__arrow} />
        </a>
      </div>

      <div className={styles.box}>
        <span className={styles.box__link}>
          <Icon iconName="dark-mode" width={20} height={20} className={styles.box__icon} />
          <span className={styles.box__text}>{t('MTU.Menu.Theme')}</span>
          <label className={styles.switch}>
            <input
              type="checkbox"
              className={styles.switchInput}
              onChange={handleChange}
              checked={currentTheme === 'dark'}
            />
            <span className={styles.switchSlider} />
          </label>
        </span>
      </div>

      <div className={styles.box}>
        <Button
          fullWidth
          variant="outline"
          onClick={() => openModal('MODAL_SET_BACKGROUND')}
          className={styles.box__link}
        >
          <Icon iconName="paint" width={20} height={20} className={styles.box__icon} />
          <span className={styles.box__text}>{t('Background.Title')}</span>
          <Icon iconName="arrow" width="20" height="20" className={styles.box__arrow} />
        </Button>
      </div>

      <div className={styles.box}>
        <Button
          fullWidth
          variant="outline"
          onClick={() => openModal('MODAL_SET_LANGUAGE')}
          className={styles.box__link}
        >
          <Icon iconName="globe" width={20} height={20} className={styles.box__icon} />
          <span className={styles.box__text}>{t('Language')}</span>
          <Icon iconName="arrow" width="20" height="20" className={styles.box__arrow} />
        </Button>
      </div>

      <MenuSocial />

      {!isIos && <MenuFamily />}

      <MenuNavigation user_id={id} platform={platform} appId={appId} />

      <div className={styles.version} onClick={handleVerClick}>
        <strong className={styles.version__title}>App Version:</strong> {envVer && `${envVer} / `} {version}.{build}
        {window.app_config.baseAPIURL.indexOf('canary') > 0 && ' (canary)'}
        {window.app_config.baseAPIURL.indexOf('dvlt') > 0 && ' (dvlt)'}
      </div>
      {isIos ? (
        <>
          <div className={styles.box}>
            <Button fullWidth variant="outline" onClick={handleOpenDeleteModal} className={styles.box__link}>
              <Icon iconName="trash" width={20} height={20} className={styles.box__icon} />
              <span className={styles.box__text}>{t('Delete_acc.Btn')}</span>
              <Icon iconName="arrow" width="20" height="20" className={styles.box__arrow} />
            </Button>
          </div>
          <ModalDeleteAccount isOpen={isDeleteModalOpen} onClose={handleCloseDeleteModal} />
        </>
      ) : null}
    </div>
  );
};
