import { debug, error, log } from '@/shared/lib/utils/log';
import { JsonRpc, OPEN } from './JsonRpc';
import { JsonRpcApi, JsonRpcApiMethods } from './JsonRpcApi';

export type Backend = JsonRpc<JsonRpcApi, JsonRpcApiMethods>;

let backend: Backend;

function onRpcOpen(listener: (open: boolean) => void) {
  backend.on('open', async () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    debug('rpc', 'open');
    listener(true);
  });
  backend.on('error', async (e) => {
    error('rpc error:', e);
  });
  backend.on('close', async () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    debug('rpc', 'close');
    listener(false);
  });
}

export interface Rpc {
  readonly open: boolean;
  readonly auth: boolean;

  init(backend: Backend): Promise<void>;

  reopen(sec?: number): Promise<void>;

  authenticate(): void;

  logout(): void;

  event(name: string, data?: Record<string, unknown>): void;

  close(): Promise<void>;

  status: Backend['status'];

  transmit: Backend['transmit'];
  onmethod: Backend['onmethod'];
  offmethod: Backend['offmethod'];
  on: Backend['on'];
  off: Backend['off'];
}

class DefaultRpc implements Rpc {
  open = false;
  auth = false;
  private backendOpenPromise: Promise<void> | null = null;
  private openBackendTimeout: number = 0;
  private reopenPromise: Promise<void> | null = null;
  private reopenPromiseResolve: (() => void) | null = null;

  async init(b: Backend) {
    backend = b;
    onRpcOpen((open) => {
      this.open = open;
      if (!open) {
        this.auth = false;
        if (this.openBackendTimeout) {
          clearTimeout(this.openBackendTimeout);
          this.openBackendTimeout = 0;
        }
        this.reopenPromise = null;
        setTimeout(() => this.reopen(), 1000);
      }
    });
    await this.reopen();
  }

  reopen(): Promise<void> {
    const openBackend = async (sec: number) => {
      if (backend.status === OPEN) {
        return;
      }
      debug('rpc', `connecting ${sec}`);
      try {
        this.backendOpenPromise = backend.open();
        await this.backendOpenPromise;
        this.backendOpenPromise = null;
        this.reopenPromiseResolve!();
      } catch (e) {
        this.backendOpenPromise = null;
        let delay = sec * (1 + Math.random());
        if (delay > 60) {
          delay = 60 - sec * Math.random();
        }
        this.openBackendTimeout = setTimeout(() => {
          this.openBackendTimeout = 0;
          openBackend(delay);
        }, sec * 1000);
      }
    };

    if (!this.reopenPromise) {
      this.reopenPromise = new Promise((resolve) => {
        this.reopenPromiseResolve = resolve;
      });
    }

    if (this.openBackendTimeout) {
      clearTimeout(this.openBackendTimeout);
      this.openBackendTimeout = 0;
    }

    if (!this.backendOpenPromise) {
      openBackend(1);
    }

    return this.reopenPromise;
  }

  authenticate(): void {
    this.auth = true;
  }

  logout(): void {
    this.auth = false;
  }

  async event(name: string, data?: Record<string, unknown>): Promise<void> {
    await this.reopen();
    log(name, data);
    // backend.transmit('analytics.event', { event: name, data }).catch(error)
  }

  // eslint-disable-next-line
  transmit(method: any, params: any): Promise<any> {
    return backend.transmit(method, params);
  }

  // eslint-disable-next-line
  onmethod(method: any, callback: any) {
    backend.onmethod(method, callback);
  }

  // eslint-disable-next-line
  offmethod(method: any, callback: any) {
    backend.offmethod(method, callback);
  }

  // eslint-disable-next-line
  on(type: any, callback: any) {
    backend.on(type, callback);
  }
  // eslint-disable-next-line
  off(type: any, callback: any) {
    backend.off(type, callback);
  }

  async close() {
    this.auth = false;
    backend.off('open');
    backend.off('error');
    backend.off('close');
    return backend.close();
  }

  get status() {
    return backend.status;
  }
}

const defaultRpc = new DefaultRpc();
export const rpc: Rpc = defaultRpc;
