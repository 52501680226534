import cn from 'classnames';

import { formatHashrate } from './hashrate.util';

import s from './hashrate.module.scss';
import { InputRange } from '@/shared/ui/input-range';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '@/shared/store/types';
import { Icon } from '@/shared/ui/icons';
import { useTranslation } from 'react-i18next';
import { useModalSet } from '@/shared/lib/context/modal/useModalSet';
import { Button } from '@/shared/ui/button';
import { useDevice } from '@/shared/lib/hooks/useDevice';
import { setHashrate, useHashrate, useHashrates } from '@/page/cart/store';

export const Hashrate = () => {
  const { t } = useTranslation();
  const { openModal } = useModalSet();
  const { isTelegramMiniApp } = useDevice();
  const hashrates = useSelector(useHashrates);
  const dispatch: AppDispatch = useDispatch();

  const hashrate = useSelector(useHashrate);
  const formattedHashrate = formatHashrate(hashrate);

  const currentValue = hashrates.indexOf(hashrate);
  const minValue = 0;
  const maxValue = hashrates.length - 1;

  const handleChange = (value: number) => {
    dispatch(setHashrate({ hashrate: hashrates[value] }));
  };

  return (
    <div className={s.container}>
      <div className={s.top}>
        <div className={s.top__item}>
          <Icon iconName="speed" className={s.top__icon} width={20} height={20} />
          {t('Buy_scr.Hashrate')}
        </div>
        {isTelegramMiniApp ? (
          <div>
            <Button className={s.btnInfo} variant="text" color="blue" onClick={() => openModal('MODAL_INFO_POOL')}>
              <Icon iconName="info" width="20" height="20" />
            </Button>
          </div>
        ) : null}
      </div>
      <div className={s.hashrateRangeContainer}>
        <InputRange value={currentValue} onChange={handleChange} min={minValue} max={maxValue} />
      </div>
      <span className={cn(s.hashrateValue)}>{formattedHashrate}</span>
    </div>
  );
};
