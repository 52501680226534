import React, { useEffect, useState } from 'react';
import mask from './images/mask.svg';
import bat from './images/bat.svg';
import s from './halloween-loader.module.scss';

const HalloweenLoader = () => {
  const [fade, setFade] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setFade(true);
    }, 500);
  }, []);

  return (
    <div className={s.wrapper}>
      <img src={bat} className={s.bat1} alt="bat" />
      <img src={bat} className={s.bat2} alt="bat" />
      <img src={bat} className={s.bat3} alt="bat" />
      <img src={mask} className={`${s.mask} ${fade ? s.fade : ''}`} alt="mask" />
    </div>
  );
};

export default HalloweenLoader;
