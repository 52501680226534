import { useState, useCallback } from 'react';
import dayjs from 'dayjs';
import {
  RateManagementResponse,
  RateSendRequest,
  RateSendResponse,
  RateSendReviewRequest,
  RatingIcons,
  ReviewType,
} from '@/shared/lib/backend/JsonRpcApi';
import { rpc } from '@/shared/lib/backend/Rpc';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '@/shared/store/types';
import { userAction } from '@/shared/store/user/user.slice';

const RATE_CHECK_KEY = 'rateCheckTimestamp';

export enum RatingServiceStatus {
  Idle,
  Pending,
  Done,
  Error,
}

export const useRating = () => {
  const [status, setStatus] = useState(RatingServiceStatus.Idle);
  const [rating, setRating] = useState(0);
  const [shouldAskRating, setShouldAskRating] = useState(false);
  const [shouldReviewRating, setShouldReviewRating] = useState<ReviewType | null>(null);
  const [ratingIcons, setRatingIcons] = useState<RatingIcons>(RatingIcons.Stars);
  const [isInitialized, setIsInitialized] = useState(false);

  const dispatch = useDispatch();
  const forceDisable = useSelector((state: RootState) => state.user.forceDisableRating);
  const ratingMeta = useSelector((state: RootState) => state.user.ratingMeta);
  const reviewRatingId = useSelector((state: RootState) => state.user.reviewRatingId);

  const checkRatingPermission = useCallback(
    async (skipCheck?: boolean) => {
      if (forceDisable) {
        setShouldAskRating(false);
        setShouldReviewRating(null);
        setIsInitialized(true);
        return;
      }

      const lastRateCheck = localStorage.getItem(RATE_CHECK_KEY);
      if (!skipCheck && lastRateCheck && dayjs().isBefore(dayjs(Number(lastRateCheck)))) {
        setShouldAskRating(false);
        setShouldReviewRating(null);
        setIsInitialized(true);
        return;
      }

      const timeout = new Promise<RateManagementResponse>((_, reject) =>
        setTimeout(() => reject(new Error('Request timed out after 5 seconds')), 5000)
      );

      try {
        const response = await Promise.race([rpc.transmit('rate.management', {}), timeout]);

        if (response?.rating) {
          setShouldAskRating(!!response?.rating ?? false);
          dispatch(userAction.setRatingMeta(response?.rating.meta ?? {}));
          setRatingIcons(response?.rating.icons ?? RatingIcons.Stars);
        } else {
          setShouldAskRating(false);
        }
        if (response?.review) {
          setShouldReviewRating(response?.review?.type);
          dispatch(userAction.setReviewRatingId(response?.review?.id));
          dispatch(userAction.setRatingMeta({ ...ratingMeta, ...response.review.meta }));
        } else {
          setShouldReviewRating(null);
        }
        if (!response?.rating && !response?.review) {
          const nextCheckTime = dayjs().add(3600, 'second').valueOf();
          localStorage.setItem(RATE_CHECK_KEY, nextCheckTime.toString());
        }
        setIsInitialized(true);
      } catch (e) {
        console.error('Failed to check rating permission', e);
        setIsInitialized(true);
      }
    },
    [ratingMeta, forceDisable, dispatch]
  );

  const sendRatingDetails = useCallback(
    async (request: RateSendRequest): Promise<RateSendResponse> => {
      setStatus(RatingServiceStatus.Pending);

      const timeout = new Promise<RateSendResponse>((_, reject) =>
        setTimeout(() => reject(new Error('Request timed out after 5 seconds')), 5000)
      );

      try {
        const response = await Promise.race([
          rpc.transmit('rate.send', {
            ...request,
            meta: ratingMeta,
          }),
          timeout,
        ]);

        setShouldAskRating(false);
        setStatus(RatingServiceStatus.Done);
        return response;
      } catch (e) {
        console.error('rate send error');
        setStatus(RatingServiceStatus.Error);
        throw e;
      }
    },
    [ratingMeta]
  );

  const sendReviewDetails = useCallback(
    async (request: RateSendReviewRequest) => {
      setStatus(RatingServiceStatus.Pending);
      console.log('sendReviewDetails request', request);

      const timeout = new Promise<RateSendReviewRequest>((_, reject) =>
        setTimeout(() => reject(new Error('Request timed out after 5 seconds')), 5000)
      );

      try {
        console.log('request', {
          ...request,
          review_id: reviewRatingId,
          meta: ratingMeta,
        });
        const response = await Promise.race([
          rpc.transmit('rate.send_review', {
            ...request,
            review_id: reviewRatingId,
            meta: ratingMeta,
          }),
          timeout,
        ]);

        setShouldAskRating(false);
        setShouldReviewRating(null);
        setStatus(RatingServiceStatus.Done);
        return response;
      } catch (e) {
        console.error('send review error:', e);
        setStatus(RatingServiceStatus.Error);
        throw e;
      }
    },
    [ratingMeta, reviewRatingId]
  );

  const sendFeedback = useCallback(
    async (request: RateSendRequest) => {
      try {
        setStatus(RatingServiceStatus.Pending);
        await sendRatingDetails({
          rating,
          message: request?.message,
          email: request?.email,
          meta: ratingMeta,
        });
        setShouldAskRating(false);
        setStatus(RatingServiceStatus.Done);
      } catch (e) {
        console.error('send review error:', e);
        setStatus(RatingServiceStatus.Error);
        throw e;
      }
    },
    [rating, ratingMeta, sendRatingDetails]
  );

  return {
    status,
    rating,
    shouldAskRating,
    shouldReviewRating,
    ratingIcons,
    isInitialized,
    forceDisable,
    setRating,
    sendFeedback,
    sendReviewDetails,
    checkRatingPermission,
    sendRatingDetails,
    setShouldReviewRating,
  };
};
