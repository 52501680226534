import React from 'react';
import { Alert } from '@/shared/ui/alert';
import { Typography } from '@/shared/ui/typography';
import { BalanceHistoryItem } from '@/features/balance';
import { useTranslation } from 'react-i18next';
import { Withdrawal } from '@/shared/lib/backend/JsonRpcApi';
import styles from './unconfirmed-transaction-info.module.scss';

type UnconfirmedTransactionInfoProps = {
  withdrawal: Withdrawal;
};

export const UnconfirmedTransactionInfo = ({ withdrawal }: UnconfirmedTransactionInfoProps) => {
  const { t } = useTranslation();
  return (
    <>
      <Alert variant="danger">
        <Typography variant="body2" weight="bold" className={styles.alertDescription}>
          {t('Conf_withdr.Popup.Description')}
        </Typography>
      </Alert>
      <BalanceHistoryItem withdrawal={withdrawal} variant="rounded" />
    </>
  );
};
