type BrowserShareProps = {
  url?: string;
  title?: string;
  text?: string;
  isTelegramMiniApp?: boolean;
};

const browserShare = async ({ url, title, text, isTelegramMiniApp }: BrowserShareProps) => {
  try {
    // Check if the Web Share API is supported
    if ('share' in navigator) {
      const shareData: ShareData = {};

      if (isTelegramMiniApp && url) shareData.url = url;
      if (text) shareData.text = text;
      if (title) shareData.title = title;

      // Validate shareData for minimum required fields
      if (!shareData.url && !shareData.text) {
        console.error('Either text or URL is required for sharing');
        return;
      }

      // Attempt to share using the Web Share API
      return await navigator.share(shareData);
    } else {
      if (isTelegramMiniApp) {
        if (!url && !text) {
          console.error('Either text or URL is required for Telegram sharing');
          return;
        }

        // Build Telegram sharing URL
        const shareUrl = url
          ? `https://t.me/share/url?url=${encodeURIComponent(url)}&text=${encodeURIComponent(text || '')}`
          : `https://t.me/share/url?text=${encodeURIComponent(text || '')}`;

        // Open the sharing link in a new tab
        window.open(shareUrl, '_blank');
      }
    }
  } catch (error) {
    console.error('Error during sharing:', error);
  }
};

export default browserShare;
