import { Id, toast, ToastOptions } from 'react-toastify';
import { CustomToast, CustomToastProps } from '@/shared/ui/custom-toast/custom-toast';

type ToastConfig = Pick<CustomToastProps, 'onClick' | 'toastProps'>;

export const toaster = (
  customProps: Pick<CustomToastProps, 'title' | 'variant' | 'onClick'>,
  toastProps?: ToastOptions
): Id => toast(<CustomToast {...customProps} />, { ...toastProps });

toaster.success = (title: string, config?: ToastConfig) =>
  toaster({ title: title, variant: 'success', onClick: config?.onClick }, config?.toastProps);
toaster.error = (title: string, config?: ToastConfig) =>
  toaster({ title: title, variant: 'error', onClick: config?.onClick }, config?.toastProps);
toaster.warning = (title: string, config?: ToastConfig) =>
  toaster({ title: title, variant: 'warning', onClick: config?.onClick }, config?.toastProps);
toaster.info = (title: string, config?: ToastConfig) =>
  toaster({ title: title, variant: 'info', onClick: config?.onClick }, config?.toastProps);
