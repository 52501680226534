import { createSlice } from '@reduxjs/toolkit';

type newsType = {
  category_name: string;
  title: string;
  description: string;
  body: string;
  id: number;
  image: string;
  name: string;
  placing: string[];
  published_at: string;
  tags: string[];
  url: string;
};

export type rootNews = typeof initialState;

const initialState = {
  list: [] as newsType[],
  news_request: false,
  news_error: false,
  total: 0,
};

const newsSlice = createSlice({
  name: 'news',
  initialState,
  reducers: {
    setNews(state, action) {
      const items = state.list.filter((e) => e.category_name != 'news');
      action.payload.items.forEach((e: newsType) => (e.category_name = 'news'));
      Array.prototype.push.apply(items, action.payload.items);
      items.sort((a, b) => {
        return new Date(b.published_at).getTime() - new Date(a.published_at).getTime();
      });
      state.list = items;
      state.total = action.payload.total;
    },
  },
});

export const { setNews } = newsSlice.actions;

export default newsSlice.reducer;
