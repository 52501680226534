import { getLanguage } from '@/shared/store/language';

export const getDefaultOptions = (): Intl.DateTimeFormatOptions => ({
  day: 'numeric',
  month: 'long',
  year: 'numeric',
});

export function formatDate(ms: number, options?: Intl.DateTimeFormatOptions) {
  const lang = getLanguage();
  const formatOptions = options ? { ...getDefaultOptions(), ...options } : getDefaultOptions();
  return new Date(ms * 1000).toLocaleString(lang, formatOptions);
}
