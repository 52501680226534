import * as Sentry from '@sentry/browser';
import { rpc } from '@/shared/lib/backend/Rpc';
import { refreshToken, userAction } from '@/shared/store/user/user.slice';
import { debug } from '@/shared/lib/utils/log';
import { BROWSER_BANNED } from '@/shared/lib/utils/links';
import { AppDispatch } from '@/shared/store/types';
import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { initUserThunk } from '@/shared/store/rpc/rpc.slice';
import { useTelegram } from '@/shared/lib/hooks/useTelegram';

export const useAuth = () => {
  const dispatch: AppDispatch = useDispatch();
  const { initDataUnsafe } = useTelegram();

  const refreshAuth = useCallback(
    async (refresh_token: string | null) => {
      if (!refresh_token) return;
      debug('refresh token', { refresh_token: refresh_token });
      await dispatch(refreshToken({ refresh_token, telegramUserId: initDataUnsafe?.user?.id?.toString() || '' }));
    },
    [dispatch, initDataUnsafe?.user?.id]
  );

  const auth = useCallback(
    async (access_token: string, refresh_token: string | null) => {
      try {
        await rpc.transmit('auth', { access_token: access_token }).then((r) => dispatch(userAction.checkUserNew(r)));
        rpc.authenticate();
        dispatch(userAction.setRequest());
        dispatch(userAction.setStatus('auth'));
        dispatch(userAction.checkAuth());
        dispatch(userAction.setLoading(false));
        dispatch(initUserThunk());
        // eslint-disable-next-line
      } catch (e: any) {
        if (e.code === 1000 || e.code === 1001 || e.message === 'Invalid token' || e.message === 'Token expired') {
          await refreshAuth(refresh_token);
        } else if (e.message === 'User banned') {
          window.location.href = BROWSER_BANNED;
        } else if (e.code === 2001 || e.message === 'User not found') {
          dispatch(userAction.clearToken());
        } else {
          console.log('auth error', e);
          Sentry.captureException(e);
        }
      }
    },
    [dispatch, refreshAuth]
  );

  return { auth, refreshAuth };
};
