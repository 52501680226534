import styles from '../mining-block.module.scss';
import cn from 'classnames';
import { Button } from '@/shared/ui/button';
import { Icon } from '@/shared/ui/icons';
import React, { memo, ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useMobileDetect from '@/shared/lib/hooks/useMobileDetect';
import { log } from '@/shared/lib/utils/log';
import numberWithSpaces from '@/shared/lib/utils/numberWithSpaces';
import { CART_PATH, POOL_MINING, SWITCH_BALANCE_PATH } from '@/shared/lib/utils/links';
import { Link, useNavigate } from 'react-router-dom';
import { useModalSet } from '@/shared/lib/context/modal/useModalSet';
import { Tutorial } from '@/shared/ui/tutorial/tutorial';
import { useDevice } from '@/shared/lib/hooks/useDevice';
import { Balance } from '@/page/home/ui/mining-block/ui/balance/balance';
import { PoolMinersList } from '@/page/home/ui/mining-block/ui/pool-miners-list';
import { TMiner } from '@/shared/store/pool-miner/pool-miner.slice';
import { useBalanceInfo } from '@/page/home/hooks/use-balance-info';
import { useMiningBlock } from '../mining-block';

export const Mining = memo(
  ({
    hps,
    mining,
    checked = false,
    onStartMiner,
    texts,
    miners,
    icon,
    className,
    minersClassName,
  }: {
    mining: { end_time: number | string }[];
    hps: number;
    checked?: boolean;
    onStartMiner: () => void;
    texts: {
      addMinerMobile: string;
      addMiner: string;
      turnOnMining: string;
      activeStatus: string;
      noMiners: string;
      expirationDate: string;
      miners: string;
      mining: string;
    };
    miners: TMiner[];
    icon: ReactNode;
    className?: string;
    minersClassName?: string;
    isMining?: boolean;
  }) => {
    const { isMobile } = useMobileDetect();
    const { isTelegramMiniApp, isIos } = useDevice();
    const { t } = useTranslation();
    const numOfMiners = miners.length;

    const refDiv = useRef<HTMLDivElement>(null);

    const ref = useRef<HTMLParagraphElement>(null);
    const [isActive, setIsActive] = useState(false);
    const [hours, setHours] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [timeStr, setTimeStr] = useState('');
    const [width, setWidth] = useState(0);
    const { userCurrency } = useBalanceInfo();
    const miningBlock = useMiningBlock().current;

    const navigate = useNavigate();

    useEffect(() => {
      if (mining.length > 0) {
        const interval = setInterval(() => {
          if (ref.current && width < ref.current?.offsetWidth - 10) {
            setWidth(ref.current?.offsetWidth + 10);
          }
        }, 1000);
        return () => clearInterval(interval);
      } else {
        setWidth(0);
      }
    }, [mining, width]);

    useEffect(() => {
      const setTimer = (ts: number) => {
        const time = ts - Date.now();
        let hours = 0;
        let minutes = 0;
        if (time > 0) {
          hours = Math.floor((time / (1000 * 60 * 60)) % 24);
          minutes = Math.floor((time / 1000 / 60) % 60);
        }
        setHours(hours);
        setMinutes(minutes);
        if (time > 86400000) {
          setTimeStr('');
        } else {
          const hours_str = hours < 10 ? '0' + hours : hours;
          const minutes_str = minutes < 10 ? '0' + minutes : minutes;
          setTimeStr(` / ${hours_str + t('H')}: ${minutes_str + t('M')}`);
        }
      };
      if (mining.length > 0) {
        const deadline_ts: number = findMinTime(mining) * 1000;
        log('deadline', deadline_ts);
        const interval = setInterval(() => setTimer(deadline_ts), 1000);
        return () => {
          clearInterval(interval);
        };
      }
    }, [mining]);

    useEffect(() => {
      const checkIfClickedOutside = (e: Event) => {
        if (
          !isMobile() &&
          !isTelegramMiniApp &&
          isActive &&
          refDiv.current &&
          !refDiv.current.contains(e.target as HTMLDivElement)
        ) {
          setIsActive(false);
          document.removeEventListener('mousedown', checkIfClickedOutside);
        }
      };
      if (isActive) {
        document.addEventListener('mousedown', checkIfClickedOutside);
      }

      return () => {
        document.removeEventListener('mousedown', checkIfClickedOutside);
      };
    }, [isMobile, isActive, isTelegramMiniApp]);

    const findMinTime = (array: { end_time: number | string }[]): number => {
      let minTime = array?.[0]?.end_time;
      array.map((item) => {
        if (item.end_time < minTime) {
          minTime = item.end_time;
        }
        return item;
      });
      return +minTime;
    };

    const handleChange = useCallback(async () => {
      if (numOfMiners === 0) {
        if (!isIos) {
          navigate(CART_PATH);
        }
      } else {
        onStartMiner();
      }
    }, [numOfMiners, isIos, navigate, onStartMiner]);

    const hasMining = numOfMiners > 0;
    const miningOverDay = timeStr.length > 0;

    return (
      <div className={cn(styles.root, className)}>
        <div className={styles.block}>
          <div className={cn(styles.flex, styles.header)}>
            <div className={cn(styles.selectBlock, { [styles.active]: isActive })} ref={refDiv}>
              <Button
                variant="text"
                borderRadius={false}
                className={cn(styles.title, styles.btnSelect, minersClassName)}
                as={Link}
                to={POOL_MINING}
              >
                {t(texts.mining)}
                <span>({numOfMiners})</span>
                <Icon iconName="arrow" width="20" height="20" className={styles.arrow} />
              </Button>
            </div>
          </div>
          <div className={cn(styles.flex, styles.flex__second)}>
            <div className={styles.flexWrap}>
              <Tutorial step="hashrate" arrow="top" position="bottom" viewBlock={miningBlock}>
                <div className={styles.speed}>
                  {icon}
                  <p className={styles.speedTitle}>
                    <strong ref={ref} style={{ minWidth: width }}>
                      {numberWithSpaces(hps)}
                    </strong>{' '}
                    H/s
                  </p>
                </div>
              </Tutorial>
              <div className={cn(styles.flexRight, hasMining && styles.hasActiveMiners)}>
                <div className={styles.lineWrap}>
                  <div
                    className={cn(styles.line, {
                      [styles.orange]: checked,
                    })}
                  >
                    <div
                      className={cn(styles.progress2, {
                        [styles.active]: checked,
                      })}
                    />
                  </div>
                  <div
                    className={cn(styles.line, {
                      [styles.blue]: checked,
                    })}
                  >
                    <div
                      className={cn(styles.progress, {
                        [styles.active]: checked,
                      })}
                    />
                  </div>
                </div>
                <div>
                  <label className={styles.switch}>
                    <input
                      type="checkbox"
                      className={styles.switchInput}
                      onChange={handleChange}
                      checked={checked}
                      disabled={!isIos && mining.length !== 0}
                    />
                    <span className={styles.switchSlider} />
                  </label>
                </div>
              </div>
              {hasMining && <PoolMinersList miners={miners} />}
            </div>
            <Balance />
            <Tutorial step="currency" arrow="bot" position="top-start" viewBlock={miningBlock}>
              <div className={styles.currencySelector} onClick={() => navigate(SWITCH_BALANCE_PATH)}>
                <span className={styles.currencySelector__tag}>
                  <Icon iconName="sendMoney" height={20} width={20} />
                  {t('Balance_currency.Main_title')}:
                </span>
                <span className={styles.currencySelector__btn}>
                  {userCurrency} <Icon iconName="circleWithArrow" height={20} width={20} />
                </span>
              </div>
            </Tutorial>
            <Buttons
              checked={checked}
              handleChange={handleChange}
              hasMining={hasMining}
              hours={hours}
              miningOverDay={miningOverDay}
              minutes={minutes}
              texts={texts}
            />
          </div>
        </div>
      </div>
    );
  }
);

type ButtonsProps = {
  hasMining: boolean;
  checked: boolean;
  handleChange: () => Promise<void>;
  texts: {
    addMinerMobile: string;
    addMiner: string;
    turnOnMining: string;
    activeStatus: string;
    noMiners: string;
    expirationDate: string;
    miners: string;
    mining: string;
  };
  miningOverDay: boolean;
  hours: number;
  minutes: number;
};

const Buttons = ({ hasMining, checked, handleChange, texts, miningOverDay, hours, minutes }: ButtonsProps) => {
  const { t } = useTranslation();
  const { openModal } = useModalSet();
  const miningBlockRef = useMiningBlock();

  return (
    <div className={cn(styles.selectBottom, styles.selectBottom_second)}>
      {!hasMining && (
        <>
          <Tutorial step="addPoolButton" arrow="bot" position="top-end" viewBlock={miningBlockRef.current}>
            <Button borderRadius={false} fullWidth color="blue" as={Link} to={CART_PATH}>
              <Icon iconName="plus" width="20" height="20" />
              {t('ADD POOL MINER')}
            </Button>
          </Tutorial>
          <Button borderRadius={false} as={Link} to={CART_PATH} color="blue" variant="outline">
            <Icon iconName="info" width="20" height="20" />
          </Button>
        </>
      )}
      {hasMining && !checked && (
        <>
          <Tutorial step="startButton" arrow="bot" position="top" viewBlock={miningBlockRef.current}>
            <Button
              borderRadius={false}
              fullWidth
              className={styles.buttonDisabled}
              color="blue"
              onClick={handleChange}
            >
              <Icon iconName="lightning" width="20" height="20" />
              {t(texts.turnOnMining)}
            </Button>
          </Tutorial>
          <Tutorial step="addPoolButton" arrow="right" position="top-start" viewBlock={miningBlockRef.current}>
            <Button borderRadius={false} color="blue" as={Link} to={CART_PATH}>
              <Icon iconName="plus" width={20} height={20} />
            </Button>
          </Tutorial>
        </>
      )}
      {hasMining && checked && miningOverDay && (
        <>
          <Tutorial step="startButton" arrow="bot" position="top" viewBlock={miningBlockRef.current}>
            <Button
              borderRadius={false}
              disabled={checked}
              fullWidth
              className={styles.buttonDisabled}
              color="blue"
              onClick={handleChange}
            >
              <Icon iconName="timer" width={20} height={20} />
              {hours + t('H')} : {minutes < 10 ? '0' + minutes + t('M') : minutes + t('M')}
            </Button>
          </Tutorial>
          <Button borderRadius={false} onClick={() => openModal('MODAL_INFO_POOL')} color="blue" variant="outline">
            <Icon iconName="info" width="20" height="20" />
          </Button>
          <Tutorial step="addPoolButton" arrow="right" position="top-end" viewBlock={miningBlockRef.current}>
            <Button borderRadius={false} color="blue" as={Link} to={CART_PATH}>
              <Icon iconName="plus" width="20" height="20" />
            </Button>
          </Tutorial>
        </>
      )}
      {hasMining && checked && !miningOverDay && (
        <>
          <Tutorial step="addPoolButton" arrow="bot" position="top-end" viewBlock={miningBlockRef.current}>
            <Button borderRadius={false} fullWidth color="blue" as={Link} to={CART_PATH}>
              <Icon iconName="plus" width="20" height="20" />
              {t('ADD POOL MINER')}
            </Button>
          </Tutorial>
          <Button borderRadius={false} onClick={() => openModal('MODAL_INFO_POOL')} color="blue" variant="outline">
            <Icon iconName="info" width="20" height="20" />
          </Button>
        </>
      )}
    </div>
  );
};
