import styles from './menu-social.module.scss';
import { Social } from '@/shared/ui/social/social';

export const MenuSocial = () => {
  return (
    <div className={styles.root}>
      <Social color justify="space-between" />
    </div>
  );
};
