import { ShareButtonOptions, socialsKeysMap, SocialType } from '@/shared/ui/share-social/share-social.types';

export type WindowPosition = 'windowCenter' | 'screenCenter';
export const getBoxPositionOnWindowCenter = (width: number, height: number) => ({
  left: window.outerWidth / 2 + (window.screenX || window.screenLeft || 0) - width / 2,
  top: window.outerHeight / 2 + (window.screenY || window.screenTop || 0) - height / 2,
});
export const getBoxPositionOnScreenCenter = (width: number, height: number) => ({
  top: (window.screen.height - height) / 2,
  left: (window.screen.width - width) / 2,
});

export function createShareUrl(endpoint: string, socialType: SocialType, shareOptions: ShareButtonOptions) {
  const paramsObject = {
    url: shareOptions.url,
    title: shareOptions.title,
    description: shareOptions.description,
    image: shareOptions.media,
    t: socialsKeysMap[socialType],
  };

  const filteredParamsObject = Object.fromEntries(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    Object.entries(paramsObject).filter(([_, value]) => value !== undefined)
  ) as Record<string, string>;

  const params = new URLSearchParams(filteredParamsObject);

  return `${endpoint}?${params.toString()}`;
}

export function windowOpen(
  url: string,
  { height, width, ...configRest }: { height: number; width: number; [key: string]: unknown },
  onClose?: (dialog: Window | null) => void
) {
  const config: { [key: string]: string | number } = {
    height,
    width,
    location: 'no',
    toolbar: 'no',
    status: 'no',
    directories: 'no',
    menubar: 'no',
    scrollbars: 'yes',
    resizable: 'no',
    centerscreen: 'yes',
    chrome: 'yes',
    ...configRest,
  };

  const shareDialog = window.open(
    url,
    '',
    Object.keys(config)
      .map((key) => `${key}=${config[key]}`)
      .join(', ')
  );

  if (onClose) {
    const interval = window.setInterval(() => {
      try {
        if (shareDialog === null || shareDialog.closed) {
          window.clearInterval(interval);
          onClose(shareDialog);
        }
      } catch (e) {
        console.error(e);
      }
    }, 1000);
  }

  return shareDialog;
}
