import { Icon } from '@/shared/ui/icons';
import styles from './menu-account.module.scss';
import { useTranslation } from 'react-i18next';
import { Button } from '@/shared/ui/button';
import React, { useCallback, useEffect, useState } from 'react';
import cn from 'classnames';
import {
  APPLE_SUBSCRIPTIONS,
  BROWSER_SETTINGS,
  GOOGLE_SUBSCRIPTIONS,
  HISTORY_PATH,
  SWITCH_BALANCE_PATH,
  WITHDRAW_PATH,
} from '@/shared/lib/utils/links';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '@/shared/store/types';
import { selectUser } from '@/shared/store/user/user.slice';
import { useDevice } from '@/shared/lib/hooks/useDevice';
import { useVerifyEmailNotification } from '@/features/menu/hooks';
import { useLogout } from '@/shared/hooks';
import { toaster } from '@/shared/ui/custom-toast/toaster';
import { toast } from 'react-toastify';
import { MINING } from '@/page/withdraw/ui/switch-withdrawal-tabs/switch-withdrawal-tabs';

type TMenuAccount = {
  email: string;
};

export const MenuAccount = ({ email }: TMenuAccount) => {
  const withdrawals = useSelector((store: RootState) => store.withdrawal.withdrawals);
  const [open, setOpen] = useState(false);
  const [copy, setCopy] = useState(false);
  const { t } = useTranslation();
  const navigation = useNavigate();
  const { id } = useSelector(selectUser);
  const { isTelegramMiniApp, isIos } = useDevice();
  const [toastId, setToastId] = useState<string | number>('');

  useVerifyEmailNotification();
  const { logout } = useLogout();

  const handleVerifyEmailClick = () => {
    navigation(`${WITHDRAW_PATH}/${MINING}`);
  };

  useEffect(() => {
    toast.onChange((payload) => {
      if (payload.status === 'removed' && payload.id === toastId) {
        setCopy(false);
      }
    });
    return () => {
      toast.onChange(() => {});
    };
  }, [toastId]);

  const handleCopy = useCallback(() => {
    if (!copy) {
      window.navigator.clipboard.writeText(`${id}`);
      setToastId(toaster.success(t('Successfully_Copied'), { toastProps: { autoClose: 5000 } }));
      setCopy(true);
    }
  }, [copy, id, t]);

  const hasBalance = withdrawals.length > 0;
  return (
    <div className={styles.root}>
      <div className={styles.account}>
        <div className={styles.account__title}>
          <Icon iconName="user" width="20" height="20" className={styles.account__icon} />
          {t('Account')} / {id}
          <Icon
            iconName={copy ? 'checked' : 'copy'}
            width={20}
            height={20}
            onClick={() => {
              handleCopy();
            }}
            className={cn(styles.account__copy, copy && styles.active)}
            style={{ pointerEvents: copy ? 'none' : 'auto' }}
          />
        </div>

        <Button
          onClick={() => setOpen(!open)}
          color="blue"
          variant="text"
          size="xs"
          className={cn(styles.account__open, !open && styles.active)}
        >
          <Icon iconName="arrow-circle" width="20" height="20" />
        </Button>
      </div>

      {email ? (
        <div className={styles.email} onClick={() => setOpen(!open)}>
          {email}
        </div>
      ) : (
        <div className={cn(styles.email, styles.email__required)} onClick={handleVerifyEmailClick}>
          <Icon iconName={'email'} width={20} height={20} />
          {t('VerifyYourEmail')}
        </div>
      )}
      <ul className={cn(styles.list, !open && styles.active)}>
        <div className={styles.list__row}>
          {!isTelegramMiniApp && (
            <MenuAccountItem link={BROWSER_SETTINGS} iconName="settings" title={t('Login settings')} />
          )}
          <li className={styles.list__item}>
            <Button fullWidth variant="text" size="xs" className={styles.list__link} as={Link} to={SWITCH_BALANCE_PATH}>
              <Icon iconName="sendMoney" width="20" height="20" className={styles.list__icon} />
              {isIos ? t('BlncCurrency') : t('Balance_currency.Main_title')}
            </Button>
          </li>
          {!isTelegramMiniApp && (
            <MenuAccountItem
              title={t('Dashboard.Subscriptions.Btn')}
              iconName="gear-credit-card"
              link={isIos ? APPLE_SUBSCRIPTIONS : GOOGLE_SUBSCRIPTIONS}
            />
          )}
          {hasBalance && (
            <li className={styles.list__item}>
              <Button fullWidth variant="text" size="xs" className={styles.list__link} as={Link} to={HISTORY_PATH}>
                <Icon iconName="history" width="20" height="20" className={styles.list__icon} />
                {t('Payment History')}
              </Button>
            </li>
          )}
          {!isTelegramMiniApp && (
            <li className={styles.list__item}>
              <Button fullWidth variant="text" size="xs" className={styles.list__link} onClick={logout}>
                <Icon iconName="logout" width="20" height="20" className={styles.list__icon} />
                {t('Logout')}
              </Button>
            </li>
          )}
        </div>
      </ul>
    </div>
  );
};

const MenuAccountItem = ({ title, iconName, link }: { title: string; iconName: string; link: string }) => {
  return (
    <li className={styles.list__item}>
      <a href={link} target="_blank" rel="noreferrer" className={styles.list__link}>
        <Icon iconName={iconName} width={20} height={20} className={styles.list__icon} />
        <p>{title}</p>
      </a>
    </li>
  );
};
