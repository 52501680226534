type CurrencyFormat = {
  mainDigits: number;
  fractionalDigits: number;
};

const currencyMap: Record<string, CurrencyFormat> = {
  BTC: { mainDigits: 8, fractionalDigits: 3 },
  ETH: { mainDigits: 6, fractionalDigits: 4 },
  USDT: { mainDigits: 6, fractionalDigits: 0 },
  CTC: { mainDigits: 6, fractionalDigits: 0 },
  DOGE: { mainDigits: 6, fractionalDigits: 0 },
  POL: { mainDigits: 4, fractionalDigits: 4 },
  SOL: { mainDigits: 5, fractionalDigits: 5 },
  BNB: { mainDigits: 8, fractionalDigits: 2 },
  TRX: { mainDigits: 4, fractionalDigits: 4 },
  TON: { mainDigits: 4, fractionalDigits: 4 },
  HSH: { mainDigits: 2, fractionalDigits: 0 },
};

export const splitCurrencyValue = (value: string, currency: keyof typeof currencyMap): string[] => {
  const format = currencyMap[currency];

  const [integerPart, fractionalPart = '0'.repeat(format?.mainDigits + format?.fractionalDigits)] = value.split('.');

  const mainPart = fractionalPart.slice(0, format?.mainDigits);
  const additionalPart = fractionalPart.slice(format?.mainDigits, format?.mainDigits + format?.fractionalDigits);

  return [integerPart, mainPart, additionalPart];
};
