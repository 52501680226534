import React from 'react';
import s from './miner-info.module.scss';
import { Typography } from '@/shared/ui/typography';
import { useCurrentPlan } from '@/page/cart/store';
import { useSelector } from 'react-redux';
import { formatHashrate } from '@/page/cart/ui/v2/hashrate';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { RootState } from '@/shared/store/types';
import { Icon } from '@/shared/ui/icons';

export const MinerInfo = () => {
  const { t } = useTranslation();
  const currentPlan = useSelector(useCurrentPlan);
  const miningDuration = useSelector((state: RootState) => state.cart.miningDuration);

  const validUntil = dayjs().add(currentPlan.month, 'month').format('DD MMM. YYYY');

  return (
    <div className={s.container}>
      <div className={s.row}>
        <Typography variant="h5">Pool miner</Typography>
        <Typography variant="h5" className={s.minerDetailsContainer}>
          <Icon iconName={'poolMining'} width={32} height={20} />
          {formatHashrate(currentPlan.hps)}{' '}
          <Typography variant="body1" weight="normal" className={s.months}>
            / {miningDuration} {t('mo.')}
          </Typography>
        </Typography>
      </div>
      <div className={s.row}>
        <Typography variant="body1" className={s.validUntil}>
          {t('N.Pool_active_time.Title')}
        </Typography>
        <Typography variant="body1" className={s.validUntil}>
          {validUntil}
        </Typography>
      </div>
    </div>
  );
};
