import { Trans, useTranslation } from 'react-i18next';
import { NC_WALLET } from '@/shared/lib/utils/links';
import ModalLayout from '@/shared/ui/modal/modal';
import { Icon } from '@/shared/ui/icons';
import { Button } from '@/shared/ui/button';
import ncwalletIcon from '@/shared/ui/assets/unsupport-icons/nc-wallet.svg';
import styles from './reject-ncw-modal.module.scss';

export const RejectNcwModal = ({
  onClose,
  isOpen,
  email,
}: {
  onClose: (e: boolean) => void;
  isOpen: boolean;
  email: string;
}) => {
  const { t } = useTranslation();
  return (
    <ModalLayout background iconClose onClose={() => onClose(false)} isOpen={isOpen} id="reject-ncw">
      <div className={styles.wrapper} onClick={() => onClose(false)}>
        <div className={styles.root} onClick={(e) => e.stopPropagation()}>
          <div className={styles.block}>
            <span className={styles.close} onClick={() => onClose(false)}>
              <Icon iconName="close" width="20" height="20" />
            </span>
            <img src={ncwalletIcon} alt="icon" width={80} height={80} className={styles.icon} />
            <h6 className={styles.title}>{t('Connect_NCW.Not_found.Title')}</h6>
            <p className={styles.text}>
              <Trans
                i18nKey={t('Connect_NCW.Not_found.Description')}
                components={{ b: <strong /> }}
                values={{ email }}
              />
            </p>
          </div>
          <div className={styles.footer}>
            <Button color="blue" fullWidth onClick={() => onClose(false)} as="a" href={NC_WALLET} target="_blank">
              <Icon iconName="walletPlus" width="20" height="20" />
              {t('Connect_NCW.Create.Btn')}
            </Button>
            <Button color="blue" variant="outline" fullWidth onClick={() => onClose(false)}>
              {t('CANCEL')}
            </Button>
          </div>
        </div>
      </div>
    </ModalLayout>
  );
};
