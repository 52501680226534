import styles from './pool-miners-list.module.scss';
import { Icon, IconPlay } from '@/shared/ui/icons';
import { GOOGLE_SUBSCRIPTIONS } from '@/shared/lib/utils/links';
import { TMiner } from '@/shared/store/pool-miner/pool-miner.slice';
import { Trans, useTranslation } from 'react-i18next';
import { formatSpeed } from '@/shared/lib/utils/getFormatSpeed';
import { formatDate } from '@/shared/lib/utils/getFormatDate';
import React, { useMemo } from 'react';
import { useDevice } from '@/shared/lib/hooks/useDevice';
import { RestoreSubsButton } from '@/page/cart/ui/v1/restore-subs-button/restore-subs-button';

type PoolMinersListProps = {
  miners: TMiner[];
};

export const PoolMinersList = ({ miners }: PoolMinersListProps) => {
  const { t } = useTranslation();
  const { platformCt } = useDevice();
  const hasGoogleSubscription = useMemo(() => miners.some((i) => i.is_google_subscription), [miners]);

  return (
    <div className={styles.container}>
      <div className={styles.listTitle}>
        {t('ActiveMiners')} <span>({miners.length})</span>
      </div>

      <div className={styles.listContainer}>
        {miners.length === 0 ? (
          <div className={styles.emptyListContainer}>
            <Trans
              i18nKey={'NoActiveMiners'}
              components={{
                b: <strong />,
              }}
            />
          </div>
        ) : (
          miners.map((item) => (
            <div className={styles.listItemContainer} key={item.id}>
              <div className={styles.speedContainer}>
                <Icon iconName="poolMining" width={32} height={20} /> {formatSpeed(item.hps)}
              </div>
              <div className={styles.additionalInfoContainer}>
                <span className={styles.date}>{formatDate(item.end_time, { month: 'short' })}</span>
                {item.is_google_subscription && <IconPlay />}
              </div>
            </div>
          ))
        )}
        {platformCt === 'ios' ? (
          <div className={styles.restore}>
            <RestoreSubsButton type="credit-gear" />
          </div>
        ) : null}
      </div>
      {hasGoogleSubscription && (
        <div className={styles.googleSubscriptionsContainer}>
          <a href={GOOGLE_SUBSCRIPTIONS} target="_blank" rel="noreferrer">
            <Icon iconName="gear-credit-card" width={20} height={20} />
            {t('Dashboard.Subscriptions.Btn')}
          </a>
        </div>
      )}
    </div>
  );
};
