import { useTranslation } from 'react-i18next';

import s from './mining-duration.module.scss';
import { Typography } from '@/shared/ui/typography';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '@/shared/store/types';
import { Icon } from '@/shared/ui/icons';
import { setMiningDuration, useCurrentPlan } from '@/page/cart/store';
import { useState } from 'react';
import { formatPlanPrice } from './mining-duration.helpers';
import { MiningDurationsModal } from './mining-durations-modal';
import { BONUS_TAG_20 } from '@/shared/lib/constants/bonus-tag';
import cn from 'classnames';

export const MiningDuration = () => {
  const { t } = useTranslation();
  const dispatch: AppDispatch = useDispatch();

  const currentPlan = useSelector(useCurrentPlan);
  const { cryptoBtc2userCurrencyRate } = useSelector((store: RootState) => store.rate);
  const { currency: userCurrency } = useSelector((store: RootState) => store.user);
  const { currencies } = useSelector((store: RootState) => store.balance);
  const currentCurrencyObj = currencies?.find((cur) => cur?.symbol === userCurrency);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleSelect = (month: number) => dispatch(setMiningDuration({ miningDuration: month }));

  return (
    <div className={s.root}>
      <div className={s.top}>
        <Typography variant="h5" className={s.title}>
          <Icon iconName="clock" width={20} height={20} />
          {t('choose mining duration')}
        </Typography>
        <p className={s.top__text}>{t('Buy_scr.Price_month')}</p>
      </div>

      <div className={s.chooseDurationButton} onClick={() => setIsModalOpen(true)}>
        <div className={s.durationInfo}>
          <span className={s.durationInfo__text}>
            {currentPlan.month} {t('mo.')}
          </span>
          {currentPlan.discount && currentPlan.discount > 1 && (
            <span className={s.durationInfo__discount}>
              <span className={s.durationInfo__discount__text}>-{currentPlan.discount}%</span>
            </span>
          )}
          <span className={s.durationInfo__plan}>
            {formatPlanPrice(currentPlan, cryptoBtc2userCurrencyRate, currentCurrencyObj)} {userCurrency}
          </span>
        </div>

        <div className={s.durationTitle}>{t('N.Duration.Title')}</div>
      </div>
      {currentPlan?.tags?.includes(BONUS_TAG_20) && (
        <div className={cn(s.note, s.note__purple)}>
          <div className={cn(s.note__icon__container, s.note__icon__container__purple)}>
            <Icon iconName="white-gift-x2" width={20} height={20} className={s.note__icon} />
          </div>
          <span className={cn(s.note__description, s.note__description__purple)}>{t('BonusHashx2.Description')}</span>
        </div>
      )}
      <MiningDurationsModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} onSelected={handleSelect} />
    </div>
  );
};
