import React, { FC } from 'react';
import classNames from 'classnames';
import s from './colored-banner.module.scss';
import { useTranslation } from 'react-i18next';

export type ColoredBannerProps = {
  icon: React.ReactNode;
  text: string | React.ReactNode;
  variant?: 'yellow' | 'blue' | 'green' | 'orange';
};

export const ColoredBanner: FC<ColoredBannerProps> = ({ icon, text, variant = 'yellow' }) => {
  const { t } = useTranslation();

  return (
    <div className={classNames(s.banner, s[`banner--${variant}`])}>
      <div className={s.banner_icon}>{icon}</div>
      <div className={s.banner_text}>{typeof text === 'string' ? <span>{t(text)}</span> : text}</div>
    </div>
  );
};
