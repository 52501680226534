import { useTranslation } from 'react-i18next';

import s from './google-total-price.module.scss';
import { Typography } from '@/shared/ui/typography';
import { useSelector } from 'react-redux';
import { Icon } from '@/shared/ui/icons';
import { Discount } from '@/page/cart/ui/v2/total-price/discount';
import { useGoogleCurrentPlan } from '@/page/cart/store/cart-google-store';
import { getGoogleRegularPrice } from '@/page/cart/ui/v2/total-price/total-price.util';
import { CoinsIcon } from '@/page/cart/assets';
import { MinerInfo } from './miner-info';

const PLAN_TAG = 'bonus';

export const GoogleTotalPrice = () => {
  const { t } = useTranslation();
  const currentPlan = useSelector(useGoogleCurrentPlan);
  const bonusHps = currentPlan.hps * 0.1;

  return (
    <div className={s.container}>
      <Typography variant="h5" weight="bold" className={s.title}>
        <CoinsIcon /> {t('total price')}
      </Typography>
      <MinerInfo />
      <div className={s.totalPriceRows}>
        {currentPlan && currentPlan.discount > 0 && (
          <div className={s.flex}>
            <Icon iconName="percent" width={20} height={20} className={s.green} />
            <span className={s.discounts}>{t('applied discounts')}</span>
            <Discount discount={currentPlan.discount} promoDiscount={currentPlan.promo_discount} />
          </div>
        )}
      </div>
      {currentPlan?.tags?.includes(PLAN_TAG) && (
        <div className={s.bonuses}>
          {currentPlan?.tags?.includes(PLAN_TAG) && (
            <div className={s.bonus}>
              <Icon iconName="red-gift" width={20} height={20} />
              <span className={s.bonus__title}>{t('hps_increment')}:</span>
              <Discount promoDiscount={0} discount={0} bonus={bonusHps} />
            </div>
          )}
        </div>
      )}
      <div className={s.row}>
        <span className={s.totalPriceRowTitle}>{t('total price')} </span>
        <div className={s.priceContainer}>
          <span className={s.totalPrice}>{currentPlan && currentPlan?.month_price}</span>
          <p className={s.oldPrice}>{getGoogleRegularPrice(currentPlan)}</p>
        </div>
      </div>
    </div>
  );
};
