import { Navigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { cancelWithdrawal, selectWithdrawal } from '@/shared/store/withdrawal/withdrawal.slice';
import { WITHDRAW_PATH } from '@/shared/lib/utils/links';
import styles from './withdrawal-details.page.module.scss';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { formatDate } from '@/shared/lib/utils/getFormatDate';
import { Icon } from '@/shared/ui/icons';
import React, { useMemo } from 'react';
import { WithdrawalNote } from '@/page/withdrawal-details/ui/note/note';
import { WithdrawalActions } from '@/page/withdrawal-details/ui/actions/actions';
import { rootUser } from '@/shared/store/user/user.slice';
import { LoaderMobile } from '@/shared/ui/loader/loader';
import { useModalSet } from '@/shared/lib/context/modal/useModalSet';
import { AppDispatch, RootState } from '@/shared/store/types';
import { getTransactionBorderColor, getTransactionChipColor, mapStatus } from '@/features/balance';
import { getWithdrawalAddressInfo } from '@/shared/lib/utils/get-withdrawal-address-info';
import { formatBalance } from '@/shared/lib/utils/units';
import { getFormattedLocaleNumber } from '@/shared/lib/utils/getFormattedPrice';

export const WithdrawalDetailsPage = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const withdrawals = useSelector(selectWithdrawal);
  const { currencies } = useSelector((store: RootState) => store.balance);
  const transaction = withdrawals.withdrawalsToShow.concat(withdrawals.withdrawals).find((w) => w.id === id);
  const { openModal } = useModalSet();
  const loading = useSelector((store: { user: rootUser }) => store.user.loading);
  const dispatch: AppDispatch = useDispatch();
  const currency = transaction?.currency;

  const formattedBalance = useMemo(() => {
    if (transaction) {
      if (currency === 'HSH') {
        return getFormattedLocaleNumber(transaction.amount);
      }

      const currentCurrencyObj = currencies?.find((cur) => cur?.symbol === currency);
      return currentCurrencyObj ? formatBalance(transaction.amount, currentCurrencyObj?.unit) : '0.00';
    }
    return '0';
  }, [transaction?.amount, currency, currencies]);

  if (loading) {
    return <LoaderMobile />;
  }

  if (!transaction) {
    return <Navigate to={WITHDRAW_PATH} />;
  }

  const addressInfo = getWithdrawalAddressInfo(transaction.meta);
  const [statusLabel] = mapStatus(transaction?.status, t);
  const labelColor = getTransactionBorderColor(transaction.status);
  const chipColor = getTransactionChipColor(transaction.status);
  const icon = addressInfo.isNcw ? 'ncwalletMini' : 'bitcoin';

  const handleCancelWithdrawal = () => {
    dispatch(cancelWithdrawal({ id: transaction.id, openModal }));
  };

  const date = new Date(transaction?.created_at);
  const timestampInSeconds = Math.floor(date.getTime() / 1000);
  return (
    <div>
      <div className={styles.block}>
        <div className={styles.block__top}>
          <div className={cn(styles.label, styles[labelColor!])}>{statusLabel}</div>
          <div className={cn(styles.info, styles[chipColor!])}>
            <p className={styles.info__text}>
              <strong>{t('Amount')}:</strong> {`${formattedBalance} ${transaction.currency}`}
            </p>
            <p className={styles.info__text}>
              <strong>ID:</strong>{' '}
              {addressInfo.isNcw ? addressInfo.email || addressInfo.account_id : addressInfo.address}{' '}
              <Icon className={styles.info__icon} iconName={icon} width={20} height={20} />
            </p>
            <p className={styles.info__text}>
              <strong>{t('Requested at:')}</strong> {formatDate(timestampInSeconds)}
            </p>
            <p className={styles.info__text}>
              <strong>{t('Status')}:</strong> {statusLabel}
            </p>
          </div>

          <div className={styles.note}>
            <WithdrawalNote status={transaction.status} />
          </div>
        </div>
        <div className={styles.block__footer}>
          <WithdrawalActions
            status={transaction.status}
            onCancel={() =>
              openModal('MODAL_CANCEL_WITHDRAWAL', {
                amount: transaction?.amount,
                currency: transaction?.currency,
                onCancel: handleCancelWithdrawal,
              })
            }
          />
        </div>
      </div>
    </div>
  );
};
