import { useSelector } from 'react-redux';
import { RootState } from '@/shared/store/types';
import { formatBalance } from '@/shared/lib/utils/units';
import { Link } from 'react-router-dom';
import styles from './balance-history-item.module.scss';
import { HISTORY_PATH } from '@/shared/lib/utils/links';
import { Icon } from '@/shared/ui/icons';
import cn from 'classnames';
import { Withdrawal } from '@/shared/lib/backend/JsonRpcApi';
import { getTransactionBorderColor, getTransactionChipColor, mapStatus } from '@/features/balance';
import { useTranslation } from 'react-i18next';
import { getWithdrawalAddressInfo } from '@/shared/lib/utils/get-withdrawal-address-info';
import { formatBtcAddress } from '@/shared/lib/utils/format-btc-address';
import { useMemo } from 'react';
import { getFormattedLocaleNumber } from '@/shared/lib/utils/getFormattedPrice';

type BalanceHistoryItemProps = {
  withdrawal: Withdrawal;
  variant?: 'rounded' | 'default';
};

export const BalanceHistoryItem = ({ withdrawal, variant = 'default' }: BalanceHistoryItemProps) => {
  const { currencies } = useSelector((store: RootState) => store.balance);
  const { t } = useTranslation();

  const [label] = mapStatus(withdrawal?.status, t);
  const labelBorderColor = getTransactionBorderColor(withdrawal?.status);
  const labelBgColor = getTransactionChipColor(withdrawal?.status);
  const addressInfo = getWithdrawalAddressInfo(withdrawal.meta);
  const icon = addressInfo.isNcw ? 'ncwalletMini' : 'wallet';
  const cost = withdrawal?.amount;
  const currency = withdrawal?.currency;
  const id = withdrawal?.id;

  const formattedBalance = useMemo(() => {
    if (currency === 'HSH') {
      return getFormattedLocaleNumber(cost);
    }

    const currentCurrencyObj = currencies?.find((cur) => cur?.symbol === currency);
    return currentCurrencyObj ? formatBalance(cost, currentCurrencyObj?.unit) : '0.00';
  }, [cost, currency, currencies]);

  return (
    <Link className={cn(styles.item, styles[variant])} to={`${HISTORY_PATH}/${id}`}>
      <div className={styles.item__row}>
        <div className={styles.item__wallet}>
          <Icon iconName={icon} width={20} height={20} className={styles.item__icon} />
          <span className={styles.item__address}>
            {addressInfo.isNcw ? addressInfo.email || addressInfo.account_id : formatBtcAddress(addressInfo.address)}
          </span>
        </div>
      </div>
      <div className={styles.item__row}>
        <div className={cn(styles.item__color, styles[labelBorderColor], styles[`${labelBgColor}Bg`])}>{label}</div>
        <div className={styles.item__cost}>
          {formattedBalance} {currency}
        </div>
      </div>
    </Link>
  );
};
