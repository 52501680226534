import { PAGE_REF_LINK_BASE, POOL_REF_LINK_BASE } from '@/shared/lib/utils/links';

export const generatePoolRefLink = (userId: number, short: boolean, utm_source: string): string => {
  const params = new URLSearchParams({
    utm_source: utm_source,
    utm_medium: short ? 'bonus_str_sh' : 'bonus_str',
    aid: userId.toString(),
  });
  return `${POOL_REF_LINK_BASE}?${params.toString()}`;
};

export const generatePageRefLink = (userId: number, short: boolean, utm_source: string): string => {
  const params = new URLSearchParams({
    utm_source: utm_source,
    utm_medium: short ? 'bonus_try_sh' : 'bonus_try',
    aid: userId.toString(),
  });
  return `${PAGE_REF_LINK_BASE}?${params.toString()}`;
};
