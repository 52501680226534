import { deIconSrc, enIconSrc, esIconSrc, frIconSrc, itIconSrc, ptIconSrc, ruIconSrc } from '@/shared/ui/lang/flags';

export const codes = ['en', 'de', 'fr', 'es', 'it', 'ru', 'pt'] as const;
export type Code = (typeof codes)[number];

export interface Language {
  code: Code;
  title: string;
  icon: string;
}

export const languages: Record<Code, Language> = {
  de: { code: 'de', title: 'German', icon: deIconSrc },
  en: { code: 'en', title: 'English', icon: enIconSrc },
  es: { code: 'es', title: 'Spanish', icon: esIconSrc },
  fr: { code: 'fr', title: 'French', icon: frIconSrc },
  it: { code: 'it', title: 'Italian', icon: itIconSrc },
  pt: { code: 'pt', title: 'Portugal', icon: ptIconSrc },
  ru: { code: 'ru', title: 'Russian', icon: ruIconSrc },
};
