import { useCallback, useLayoutEffect, useRef, useState } from 'react';
import { development, isIosUnsafe, isMobileUnsafe } from '@/shared/lib/constants/device';

const useMobileDetectDevelopment = () => {
  const uaRef = useRef<string | undefined>();
  const [mobile, setMobile] = useState(false);
  const [ios, setIos] = useState(false);
  const setUa = useCallback((ua: string) => {
    uaRef.current = ua;
    setMobile(isMobileUnsafe());
    setIos(isIosUnsafe());
  }, []);
  useLayoutEffect(() => {
    function update() {
      const ua = navigator.userAgent;
      if (uaRef.current !== ua) setUa(ua);
    }

    const id = setInterval(update, 1000);
    window.addEventListener('resize', update);
    update();
    return () => {
      clearInterval(id);
      window.removeEventListener('resize', update);
    };
  }, [setUa]);
  return {
    isMobile: () => mobile,
    isIOS: () => ios,
  };
};

const useMobileDetect = () => {
  return {
    isMobile: isMobileUnsafe,
    isIOS: isIosUnsafe,
  };
};

export default development ? useMobileDetectDevelopment : useMobileDetect;
