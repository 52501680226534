import styles from './modal-mining-info.module.scss';
import { Icon } from '@/shared/ui/icons';
import { useTranslation } from 'react-i18next';
import { Button } from '@/shared/ui/button';
import ModalLayout from '@/shared/ui/modal/modal';
import { cloud_boost } from '@/shared/ui/assets/animations/lottie';
import Lottie from 'lottie-react';
import placeholder from './images/placeholder.svg';

export const ModalMiningInfo = ({ onClose, isOpen }: { onClose: (e: boolean) => void; isOpen: boolean }) => {
  const { t } = useTranslation();

  return (
    <ModalLayout background iconClose onClose={() => onClose(false)} isOpen={isOpen} id="MiningInfo">
      <div className={styles.root}>
        <div className={styles.block}>
          <span className={styles.close} onClick={() => onClose(false)}>
            <Icon iconName="close" width="20" height="20" />
          </span>
          <Lottie animationData={cloud_boost} placeholder={placeholder} loop={true} className={styles.icon} />
          <h6 className={styles.title}>{t('Pool.What.Title')}</h6>
          <p className={styles.text}>{t('Pool.What.Description')}</p>
        </div>
        <div className={styles.footer}>
          <Button color="blue" borderRadius={false} fullWidth onClick={() => onClose(false)}>
            <Icon iconName="checked" width="17" height="16" />
            OK
          </Button>
        </div>
      </div>
    </ModalLayout>
  );
};
