import { Trans, useTranslation } from 'react-i18next';
import { Discount } from './discount';
import s from './total-price.module.scss';
import { Typography } from '@/shared/ui/typography';
import { useSelector } from 'react-redux';
import { RootState } from '@/shared/store/types';
import { Icon } from '@/shared/ui/icons';
import { BONUS_TAG, BONUS_TAG_20 } from '@/shared/lib/constants/bonus-tag';
import { useCurrentPlan, useHashrate } from '@/page/cart/store';
import { formatSpeed } from '@/shared/lib/utils/getFormatSpeed';
import { useCurrentPlanPriceInfo } from '@/page/cart/hooks';
import cn from 'classnames';
import { useCallback } from 'react';
import { useModalSet } from '@/shared/lib/context/modal/useModalSet';

export const TotalPrice = () => {
  const { t } = useTranslation();
  const miningDuration = useSelector((state: RootState) => state.cart.miningDuration);
  const hashrate = useSelector(useHashrate);
  const currentPlan = useSelector(useCurrentPlan);
  const { openModal } = useModalSet();
  const { defaultFiatCurrencyApproximately } = useSelector((store: RootState) => store.user);

  const { formattedPrice, currentPriceFiat, formattedPriceRegular, userCurrency } = useCurrentPlanPriceInfo();

  const openHshModal = useCallback(() => {
    openModal('MODAl_INFO_BONUS_HSH');
  }, []);

  return (
    <div className={s.container}>
      <div className={s.totalPriceRows}>
        <div className={s.row}>
          <Typography
            variant="h5"
            weight="bold"
            style={{ display: 'flex', columnGap: '10px', fontSize: '16px', lineHeight: '20px' }}
          >
            <Icon iconName="cartSell" height={20} width={20} />
            Order Details
          </Typography>
        </div>
        <div className={s.row}>
          <div className={s.priceRow}>
            <Typography variant="h5" weight="bold">
              <span className={s.currentMiner}>
                Pool Miner {hashrate && formatSpeed(hashrate)} ({miningDuration} {t('mo.')})
              </span>
            </Typography>
            <div className={s.priceContainer}>
              <Typography variant="h3">
                {formattedPrice} {userCurrency}
              </Typography>
            </div>
            <div className={s.priceContainer}>
              <span className={s.priceUsd}>
                ~ {defaultFiatCurrencyApproximately?.symbol}
                {currentPriceFiat} {defaultFiatCurrencyApproximately?.code}
              </span>
            </div>
          </div>
          <div className={s.priceContainer}>
            <Icon iconName="offer" height={20} width={20} />
            {t('Regular Price')}
            <p className={s.oldPrice}>
              {' '}
              {formattedPriceRegular} {userCurrency}
            </p>
          </div>
        </div>
        <div className={s.plain}>
          <Icon iconName="percent" width={20} height={20} className={s.discount} />
          <span className={s.discounts}>{t('applied discounts')}</span>
          <Discount discount={currentPlan.discount} promoDiscount={0} />
        </div>
        {!currentPlan?.hsh_reward && currentPlan?.hps_bonus && currentPlan?.tags?.includes(BONUS_TAG_20) && (
          <div className={s.plain}>
            <Icon iconName="purple-gift-x2" width={20} height={20} />
            <div className={s.presentsWrapper}>
              <Trans i18nKey="hps_increment" />
              <span className={s.presentsPurple}> (x2)</span>
            </div>
            <Discount promoDiscount={0} discount={0} double={currentPlan?.hps_bonus} />
          </div>
        )}
      </div>
      {!currentPlan?.hsh_reward && currentPlan?.hps_bonus && currentPlan?.tags?.includes(BONUS_TAG) && (
        <div className={s.bonuses}>
          <div className={s.bonus}>
            <Icon iconName="blue-gift" width={20} height={20} />
            <span className={s.bonus__title}>{t('hps_increment')}</span>
            <Discount promoDiscount={0} discount={0} bonus={currentPlan?.hps_bonus} />
          </div>
        </div>
      )}
      {currentPlan?.hsh_reward && (
        <div className={s.bonuses}>
          <div className={s.bonus}>
            <Icon iconName="hsh" width={20} height={20} />
            <div>
              <span className={cn(s.bonus__title, s[`bonus__title--hsh`])} onClick={openHshModal}>
                {t('HashCoins')}
              </span>
              {currentPlan?.tags?.includes(BONUS_TAG_20) && <span className={s.bonus__double}> (x2)</span>}
            </div>
            <Discount promoDiscount={0} discount={0} bonusHSH={currentPlan?.hsh_reward} />
            <Icon
              iconName={'question'}
              width={20}
              height={20}
              className={s.bonus__title_question}
              onClick={openHshModal}
            />
          </div>
        </div>
      )}
    </div>
  );
};
