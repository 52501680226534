import ModalLayout from '@/shared/ui/modal/modal';
import { useTranslation } from 'react-i18next';
import styles from './modal-delete-account.module.scss';
import { Icon } from '@/shared/ui/icons';
import React from 'react';
import { Button } from '@/shared/ui/button';
import { rpc } from '@/shared/lib/backend/Rpc';
import { useLogout } from '@/shared/hooks';

export const ModalDeleteAccount = ({ onClose, isOpen }: { onClose: (e: boolean) => void; isOpen: boolean }) => {
  const { ModalBottomSheet } = ModalLayout;
  const { t } = useTranslation();
  const { logout } = useLogout();

  const handleDelete = async () => {
    try {
      await rpc.transmit('users.me.delete', {});
    } catch (e) {
      console.error(e);
    }
    await logout();
    onClose(false);
  };

  const handleClose = () => {
    onClose(false);
  };

  return (
    <ModalBottomSheet isOpen={isOpen} onClose={() => onClose(false)} className={styles.root} id="address">
      <div className={styles.header}>{t('Delete_acc.Page_title.Confirm')}</div>
      <div className={styles.list}>
        <div className={styles.icon}>
          <Icon iconName="delete-circle" width="70" height="70" />
        </div>
        <div className={styles.title}>{t('Delete_acc.Modal.Title')}</div>
        <div className={styles.text}>{t('Delete_acc.Modal.Description')}</div>
      </div>
      <div className={styles.buttons}>
        <Button color="red" fullWidth onClick={handleDelete}>
          <Icon iconName="checked" width="17" height="16" />
          {t('Confirm')}
        </Button>
        <Button variant="outline" fullWidth onClick={handleClose}>
          <Icon iconName="reject-blue" width="20" height="20" />
          {t('CANCEL')}
        </Button>
      </div>
    </ModalBottomSheet>
  );
};
