import { Icon } from '@/shared/ui/icons';
import { Trans } from 'react-i18next';
import { Alert } from '@/shared/ui/alert';
import s from './hsh-alert.module.scss';

export const HshAlert = ({ months }: { months: string }) => {
  return (
    <Alert variant="hsh" className={s.alert}>
      <div className={s.alert__content}>
        <Icon iconName="gift-yellow" width={30} height={30} className={s.alert__icon} />
        <span className={s.alert__text}>
          <Trans i18nKey="X2HSH.Descr" values={{ months }} />
        </span>
      </div>
    </Alert>
  );
};
