import styles from './empty-balance.module.scss';
import { Icon } from '@/shared/ui/icons';
import { Trans, useTranslation } from 'react-i18next';
import { Button } from '@/shared/ui/button';

export const EmptyBalance = ({ minAmount, userCurrency }: { minAmount: string; userCurrency: string }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <Icon iconName="warning-info" width={80} height={80} />
        <h6 className={styles.title}>
          <Trans i18nKey={t('You do not have enough BTC')} values={{ currency: userCurrency }} />
        </h6>
        <p className={styles.text}>
          <Trans i18nKey={t('WithdrawBTC.Description2')} values={{ amount: minAmount, currency: userCurrency }} />
        </p>
      </div>
      <div className={styles.footer}>
        <Button fullWidth disabled className={styles.btn}>
          <Icon iconName="transfer" width="24" height="20" />
          <Trans i18nKey={t('Withdraw Currency')} values={{ currency: userCurrency }} />
        </Button>
      </div>
    </div>
  );
};
