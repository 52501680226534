import { useEffect } from 'react';
import { toaster } from '@/shared/ui/custom-toast/toaster';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { WITHDRAW_PATH } from '@/shared/lib/utils/links';
import { useSelector } from 'react-redux';
import { rootUser } from '@/shared/store/user/user.slice';

export const useVerifyEmailNotification = () => {
  const email = useSelector((store: { user: rootUser }) => store.user.email);
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    const isNotificationShown = localStorage.getItem('isVerifyEmailNotificationShown');

    if (isNotificationShown || email) {
      return;
    }

    toaster.error(t('PlsConfirmEmail'), { onClick: () => navigate(WITHDRAW_PATH) });
    localStorage.setItem('isVerifyEmailNotificationShown', 'true');
  }, [email]);
};
