import styles from './social.module.scss';
import cn from 'classnames';
import DiscordSvg from './icons/discord.svg';
import FacebookSvg from './icons/facebook.svg';
import InstagramSvg from './icons/instagram.svg';
import TelegramSvg from './icons/telegram.svg';
import TwitterSvg from './icons/twitter.svg';
import YoutubeSvg from './icons/youtube.svg';
import { social } from '@/shared/lib/constants/socials';

export interface Props {
  outline?: boolean;
  color?: boolean;
  justify?: 'space-between' | 'center';
}

function renderItem(href: string, color = false, outline = false, icon: string, style: string) {
  const classes = cn(styles.link, outline && styles.outline, color && styles[style]);
  return (
    <li>
      <a href={href} className={classes} target="_blank" rel="noreferrer">
        <img src={icon} alt="" />
      </a>
    </li>
  );
}

function Social({ outline, color, justify }: Props) {
  const classes = cn(styles.social, outline && styles.outline, color && styles.colored);
  return (
    <ul className={cn(classes, justify && styles[justify])}>
      {renderItem(social.telegram, color, outline, TelegramSvg, 'telegram')}
      {renderItem(social.instagram, color, outline, InstagramSvg, 'inst')}
      {renderItem(social.twitter, color, outline, TwitterSvg, 'tw')}
      {renderItem(social.facebook, color, outline, FacebookSvg, 'fb')}
      {renderItem(social.discord, color, outline, DiscordSvg, 'discord')}
      {renderItem(social.youtube, color, outline, YoutubeSvg, 'youtube')}
    </ul>
  );
}

export { Social };
