import { createContext, PropsWithChildren, useCallback, useState } from 'react';
import { ModalName } from '@/shared/lib/context/modal/modal-render';

export type TModalContext = {
  modal: Modal | null;
};

export type TModalSetContext = {
  // eslint-disable-next-line
  openModal: (name: ModalName, options?: any) => void;
  closeModal: () => void;
};

export const ModalContext = createContext<TModalContext | null>(null);
export const ModalSetContext = createContext<TModalSetContext | null>(null);

export type Modal = {
  name: ModalName;
  // eslint-disable-next-line
  options?: any;
};

export const ModalContextProvider = ({ children }: PropsWithChildren) => {
  const [modal, setModal] = useState<Modal | null>(null);

  // eslint-disable-next-line
  const openModal = useCallback((name: ModalName, options?: any) => {
    setModal({
      name,
      options: options,
    });
  }, []);

  const closeModal = useCallback(() => {
    setModal(null);
  }, []);

  return (
    <ModalContext.Provider value={{ modal }}>
      <ModalSetContext.Provider value={{ openModal, closeModal }}>{children}</ModalSetContext.Provider>
    </ModalContext.Provider>
  );
};
