import i18next from 'i18next';
import { Code, codes, languages } from '@/shared/lib/constants/languages';

export function ensureCode(code: string | undefined | null): Code | undefined {
  if (!code) return undefined;
  return codes.includes(code as Code) ? (code as Code) : undefined;
}

export function setLanguage(code: Code) {
  void i18next.changeLanguage(code);
}

export function getLanguage(): Code {
  const code = i18next.language.substring(0, 2);
  return ensureCode(code) ?? 'en';
}
export const isValidCode = (code: string | undefined | null): code is Code =>
  Object.keys(languages).includes(code || '');
