import { createContext } from 'react';

export type TabsContextValue = {
  onChange: (value: string) => void;
  activeTab?: string;
  variant: 'default' | 'filled' | 'reset';
  keepMounted: boolean;
  orientation: 'horizontal' | 'vertical';
};

export const TabsContext = createContext<TabsContextValue | null>(null);
