import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type PromoType = {
  code: string;
  sold_count?: number;
  alltime_income?: number;
  active_count?: number;
};

export type PromocodesStore = {
  promocodes: PromoType[];
};

const initialState: PromocodesStore = {
  promocodes: [],
};
export const promocodesSlice = createSlice({
  name: 'promocodes',
  initialState,
  reducers: {
    setPromocodes(store, payload: PayloadAction<{ promocodes: PromoType[] }>) {
      store.promocodes = payload.payload.promocodes;
    },
  },
});

export const { setPromocodes } = promocodesSlice.actions;

export default promocodesSlice.reducer;
