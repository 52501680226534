import React from 'react';
import styles from './pool-mining-info.module.scss';
import { Icon } from '@/shared/ui/icons';
import { Trans, useTranslation } from 'react-i18next';

type PoolMiningInfoProps = {
  hasMiners: boolean;
};

export const PoolMiningInfo = ({ hasMiners }: PoolMiningInfoProps) => {
  const { t } = useTranslation();
  const description = hasMiners ? 'PM_page.Description' : 'PoolMining.Description';

  return (
    <div className={styles.container}>
      <div className={styles.iconContainer}>
        <Icon iconName="poolMining" width={128} height={80} />
      </div>
      <h3 className={styles.title}>{t('PM_page.Title')}</h3>
      <p className={styles.description}>
        <Trans
          i18nKey={description}
          components={{
            b: <strong />,
          }}
        />
      </p>
    </div>
  );
};
