export const STORAGE_THEME = 'color-theme';
export const STORAGE_TUTORIAL = 'tutorial-';
export const STORAGE_TUTORIAL_CURRENCY = 'currency';
export const STORAGE_TUTORIAL_SLIDER = 'slider';
export const STORAGE_TUTORIAL_ONBOARDING = 'onboarding';
export const CONFIRMED_EMAIL = 'confirmed-email';
export const STORAGE_BACKGROUND = 'background';
export const STORAGE_RANDOM_BACKGROUND = 'random-background';
export const DISABLE_MEGA_SALE_MODAL = 'disable-mega-sale-modal';
export const MEGA_SALE_MODAL_CLOSED_TIMESTAMP = 'mega-sale-modal-closed-timestamp';
