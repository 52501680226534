const SEVEN_DAY = 7 * 24 * 60 * 60 * 1000;

export function shouldShowContent(): boolean {
  const currentDate: Date = new Date();

  const lastShownDateString: string | null = localStorage.getItem('lastShownDate');

  if (lastShownDateString) {
    const lastShownDate: Date = new Date(lastShownDateString);

    return currentDate.getTime() - lastShownDate.getTime() >= SEVEN_DAY;
  } else {
    return true;
  }
}
